<template>
	<div
		class="position-relative"
		:class="[$style.componentBadge, getBadgeColor, getBadgeSize, {'componentBadgeMini': onlyIcon}]"
		@click="emitClick()"
	>
		<LightTooltip :disabled="!tooltip" :popper-class="'white-space-pre-line'" :open-delay="tooltipDelay">
			<div v-if="tooltip" slot="content">{{ tooltip }}</div>
			<div class="d-flex align-items-center">
				<template v-if="icon">
					<i :class="[{ 'mr-2': text }, icon]"></i>
				</template>
				<div v-if="text">{{ text }}</div>
			</div>
		</LightTooltip>
		<template v-if="isIndicator">
			<LightTooltip :disabled="!indicatorTooltip" :popper-class="'white-space-pre-line'" :open-delay="tooltipDelay">
				<div v-if="indicatorTooltip" slot="content">{{ indicatorTooltip }}</div>
				<div class="badge-indicator" :class="indicatorColorClassName"></div>
			</LightTooltip>
		</template>
	</div>
</template>
<script>
	import LightTooltip from '@/components/LightTooltip.vue'

	export default {
		components: { LightTooltip },
		props: {
			color: { type: String, default: 'white' },
			icon: { type: String, default: '' },
			tooltip: { type: String, default: '' },
			text: { type: String, default: '' },
			outline: { type: Boolean, default: false },
			size: {
				type: String,
				default: 'md',
				validator: (value) => {
					return ['sm', 'md'].includes(value)
				},
			},
			tooltipDelay: { type: Number, default: 0 },
			onlyIcon: { type: Boolean, default: false },
			isIndicator: { type: [Boolean, String], default: false },
			indicatorColorClassName: { type: String, default: '' },
			indicatorTooltip: { type: String, default: '' },
		},
		computed: {
			getBadgeColor() {
				let badgeColor = `badge-${this.color}`
				badgeColor += this.outline ? '-outline' : ''
				return badgeColor
			},
			getBadgeSize() {
				if (this.size === 'sm') {
					return 'g-fs-10 pl-1 pr-1 pt-0 pb-0 g-flh-16'
				}
				return ''
			},
		},
		methods: {
			emitClick() {
				this.$emit('onClick')
			},
		},
	}
</script>

<style>
.badge-gray {
	border-color: #8c9bac !important;
	color: #8c9bac !important;
}
</style>
<style lang="scss" module>
.componentBadge {
	display: inline-block;
	padding: 3px 10px;
	border-radius: 5px;
	font-size: 14px;
}
</style>
<style lang="scss" scoped>
.badge-indicator {
	position: absolute;
	right: -6px;
	bottom: -6px;
	width: 12px;
	height: 12px;
	border: 2px solid #fff;
	border-radius: 10px;
}

.componentBadgeMini {
	display: flex;
	width: 20px;
	height: 20px;
	align-items: center;
	justify-content: center;
	padding: 5px;
}
</style>
