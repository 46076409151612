import { getCorrectTypeValKit } from '@/assets/js/check/getCorrectTypeValKit.js'

const CacheLiveManager = class {
	constructor({ timeLiveMs } = {}) {
		const correctKit = getCorrectTypeValKit({ timeLiveMs: [timeLiveMs, 'integer'] })
		this.timeLiveMs = correctKit.timeLiveMs

		this.isCacheTimeLive = false
		this.keyString = ''
		this.timer = undefined
	}

	startTimer() {
		this.isCacheTimeLive = true
		clearTimeout(this.timer)
		this.timer = setTimeout(() => {
			this.isCacheTimeLive = false
		}, this.timeLiveMs)
	}

	setParams({ keyString }) {
		const correctKit = getCorrectTypeValKit({ keyString: [keyString, String] })
		this.keyString = correctKit.keyString
		this.startTimer()
	}

	getIsLive({ keyString }) {
		return this.isCacheTimeLive && this.keyString === keyString
	}
}

export default CacheLiveManager
