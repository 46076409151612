import { format } from 'date-fns'
import { getCorrectTypeValKit } from '@/assets/js/check/getCorrectTypeValKit.js'

const kit = {
	sensorList: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/traffic/sensors`
		return window.$nuxt.$axios.$get(url)
	},
	sensorListOld: ({ shoppingCenterId }) => {
		const url = '/sensors'
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},

	sensorCreate: ({ shoppingCenterId, sensorName, externalId, comment }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/traffic/sensors`
		const params = { name: sensorName, external_id: externalId, comment }
		return window.$nuxt.$axios.$post(url, params)
	},
	sensorUpdate: ({ shoppingCenterId, sensorId, sensorName, externalId, comment }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/traffic/sensors/${sensorId}`
		const params = { name: sensorName, external_id: externalId, comment }
		return window.$nuxt.$axios.$put(url, params)
	},
	sensorDelete: ({ shoppingCenterId, sensorId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/traffic/sensors/${sensorId}`
		return window.$nuxt.$axios.$delete(url)
	},

	trafficByZone: ({ mallId, zoneId, dateRange }) => {
		const correctKit = getCorrectTypeValKit({
			dateStart: [dateRange[0], 'dateCorrect'],
			dateEnd: [dateRange[1], 'dateCorrect'],
		})
		const url = `/api/v2/sc/${mallId}/traffic/by_zone/${zoneId}`
		const params = { params: {
			date_from: format(correctKit.dateStart, 'yyyy-MM-dd'),
			date_to: format(correctKit.dateEnd, 'yyyy-MM-dd'),
		} }
		return window.$nuxt.$axios.$get(url, params)
	},
	trafficShoppingCenterByDay: ({ shoppingCenterId, dateRange }) => {
		const correctKit = getCorrectTypeValKit({
			dateStart: [dateRange[0], 'dateCorrect'],
			dateEnd: [dateRange[1], 'dateCorrect'],
		})
		const url = `/api/v2/sc/${shoppingCenterId}/traffic/shopping_center/by_day`
		const params = { params: {
			date_from: format(correctKit.dateStart, 'yyyy-MM-dd'),
			date_to: format(correctKit.dateEnd, 'yyyy-MM-dd'),
		} }
		return window.$nuxt.$axios.$get(url, params)
	},
	trafficSellLocationByDay: ({ shoppingCenterId, dateRange, shopIdList }) => {
		const correctKit = getCorrectTypeValKit({
			dateStart: [dateRange[0], 'dateCorrect'],
			dateEnd: [dateRange[1], 'dateCorrect'],
		})
		const url = `/api/v2/sc/${shoppingCenterId}/traffic/sell_location/by_day`
		const params = { params: {
			start_date: format(correctKit.dateStart, 'yyyy-MM-dd'),
			end_date: format(correctKit.dateEnd, 'yyyy-MM-dd'),
			sell_location_ids: shopIdList,
		} }
		return window.$nuxt.$axios.$get(url, params)
	},
	trafficUploadManualData: ({ shoppingCenterId, formData }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/traffic/upload_manual_data`
		return window.$nuxt.$axios.$post(url, formData)
	},

	visitorList: ({ shoppingCenterId }) => {
		const url = '/visitors'
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	visitorMonthList: ({ shoppingCenterId, monthId }) => {
		const url = '/visitors'
		const params = {
			params: {
				entity_id: shoppingCenterId,
				month: monthId,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	visitorDayUpdate: ({ shoppingCenterId, monthId, dayId, visitors, visitorsByFloor }) => {
		const url = `/visitors/${dayId}`
		const params = {
			entity_id: shoppingCenterId,
			visitors,
			visitors_by_floor: visitorsByFloor,
			month: monthId,
		}
		return window.$nuxt.$axios.$put(url, params)
	},
	visitorMonthUpdate: ({ shoppingCenterId, monthId, manualVisitors, manualVisitorsByFloor, useManualData }) => {
		const url = `/visitors/month/${monthId}`
		const params = {
			entity_id: shoppingCenterId,
			manual_visitors: manualVisitors,
			manual_visitors_by_floor: manualVisitorsByFloor,
			use_manual_data: useManualData,
		}
		return window.$nuxt.$axios.$put(url, params)
	},
}

export default kit
