<template>
	<div v-if="false"></div>
</template>

<script>
	import LogRocket from 'logrocket'
	import { getAuthKit } from '@/assets/js/routerHelper.js'

	export default {
		mounted() {
			const authKit = getAuthKit()
			if (!authKit.loggedIn || authKit.user.skip_session) {
				return
			}
			LogRocket.identify(authKit.user.id, { email: authKit.user.email })
			const defaultLogrocketId = process.env.nodeEnv === 'production' ? 'bigjp8/rentu' : 'rentu-stage/stage-app'
			LogRocket.init(authKit.user.logrocket ? authKit.user.logrocket : defaultLogrocketId)
		},
	}
</script>
