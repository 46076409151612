import getDateDaysAgo from '@/assets/js/date/getDateDaysAgo.js'
import getDateDayStart from '@/assets/js/date/getDateDayStart.js'
import getDateDayEnd from '@/assets/js/date/getDateDayEnd.js'

import getDateHoursAgo from '@/assets/js/date/getDateHoursAgo.js'
import getDateHourStart from '@/assets/js/date/getDateHourStart.js'
import getDateHourEnd from '@/assets/js/date/getDateHourEnd.js'


const get = (anomalyKit) => {
	const { type, dateString, meta } = anomalyKit
	const dateTmp = new Date(dateString)
	const dateRange = []

	if ([1, 6].includes(type)) {
		dateRange[0] = getDateDayStart(getDateDaysAgo(dateTmp, 13))
		dateRange[1] = getDateDayEnd(dateTmp)
	}
	if ([2, 3, 4, 5, 9, 10, 11, 12, 13, 14, 15, 17].includes(type)) {
		dateRange[0] = getDateDayStart(getDateDaysAgo(dateTmp, 6))
		dateRange[1] = getDateDayEnd(dateTmp)
	}
	if ([16].includes(type)) {
		dateRange[0] = getDateDayStart(getDateDaysAgo(dateTmp, 3))
		dateRange[1] = getDateDayEnd(dateTmp)
	}
	if ([7].includes(type)) {
		dateRange[0] = getDateDayStart(getDateDaysAgo(dateTmp, 2))
		dateRange[1] = getDateDayEnd(dateTmp)
	}
	if ([8].includes(type)) {
		dateRange[0] = getDateHourStart(getDateHoursAgo(dateTmp, 1))
		dateRange[1] = getDateHourEnd(dateTmp)
	}
	if ([900, 1000].includes(type)) {
		if (meta.period.length === 2) {
			dateRange[0] = getDateDayStart(new Date(meta.period[0]))
			dateRange[1] = getDateDayEnd(new Date(meta.period[1]))
		} else {
			dateRange[0] = new Date(meta.period[0])
		}
	}
	return dateRange
}

export default get
