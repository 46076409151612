<template>
	<LightTooltip :disabled="tooltip === ''">
		<div
			slot="content"
			class="white-space-pre-wrap"
		>{{ tooltip }}</div>
		<button
			:disabled="disabled || loading"
			class="btn"
			:type="nativeType"
			:class="[
				btnClass,
				[`btn-${size}`],
				{ 'btn--fluid': fluid },
				{ 'btn--disabled': disabled || loading },
				{ [`btn-${color}`]: color && !outline },
				{ [`btn-outline-${color}`]: outline && color },
				{ disabled: disabled || loading }
			]"
			:data-testid="testId"
			@click="handleClick"
		>
			<i v-if="loading" class="fa-solid fa-spinner fa-spin"></i>
			<i v-else-if="icon" :class="icon"></i>
			<span v-if="text">{{ text }}</span>
			<slot></slot>
		</button>
	</LightTooltip>
</template>
<script>
	import LightTooltip from '@/components/LightTooltip.vue'

	export default {
		components: {
			LightTooltip,
		},
		props: {
			text: { type: String, default: '' },
			tooltip: { type: String, default: '' },
			icon: { type: String, default: '' },
			fluid: { type: Boolean, default: false },
			disabled: { type: Boolean, default: false },
			color: { type: String, default: 'default' },
			size: { type: String, default: 'md', validator: (val) => ['xs', 'sm', 'md', 'md2'].includes(val) },
			outline: { type: Boolean, default: false },
			loading: { type: Boolean, default: false },
			btnClass: { type: String, default: '' },
			testId: { type: String, default: 'new-button' },
			nativeType: { type: String, default: 'button', validator: (val) => ['button', 'submit', 'reset'].includes(val) },
		},
		methods: {
			handleClick(evt) {
				if (this.disabled || this.loading) {
					return
				}
				this.$emit('onClick', evt)
			},
		},
	}
</script>
<style scoped lang="scss">
	.btn {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		box-shadow: none;
		gap: 0.25rem;

		&:hover {
			box-shadow: none;
		}

		i {
			padding: 0 3px;
			margin: 0;
		}

		&--disabled {
			&:hover {
				transform: none;
			}
		}

		&--fluid {
			width: 100%;
		}

		&-md {
			height: 46px;
			padding-top: 12px;
		}

		&-md2 {
			width: 40px;
			height: 40px;
			padding-top: 12px;
		}

		&-sm {
			height: 32px;
		}

		&-xs {
			height: 28px;
		}
	}
</style>
