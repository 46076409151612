var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"btn",class:[
		{ 'rounded-circle': _vm.round },
		{ 'btn-block': _vm.block },
		{ 'btn-wd': _vm.wide },
		{ 'btn-icon btn-fab': _vm.icon },
		{ [`btn-${_vm.type}`]: _vm.type && !_vm.outline },
		{ [`btn-${_vm.size}`]: _vm.size },
		{ [`btn-outline-${_vm.type}`]: _vm.outline && _vm.type },
		{ 'btn-link': _vm.link },
		{ disabled: _vm.disabled && _vm.tag !== 'button' }
	],attrs:{"type":_vm.tag === 'button' ? _vm.nativeType : '',"disabled":_vm.disabled || _vm.loading},on:{"click":_vm.handleClick}},[_vm._t("loading",function(){return [(_vm.loading)?_c('i',{staticClass:"fa-solid fa-spinner fa-spin"}):_vm._e()]}),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }