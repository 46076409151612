<template>
	<div class="position-relative">
		<BlockWithLoader :is-show-loader="isShowLoader">
			<ElTable
				:data="queriedData"
				:row-key="tableRowKey"
				:class="[tableClass]"
				:row-class-name="rowClassNameFunction"
				:header-row-class-name="headerRowClassName"
				:height="tableHeight"
				:show-summary="isShowSummary"
				:summary-method="summaryMethodLocal"
				:span-method="spanMethod"
				:stripe="!disableStripe"
				:header-cell-class-name="headerCellClassName"
				:cell-style="{ overflow: 'visible' }"
				@sort-change="sortChange($event)"
				@row-click="onRowClickFunc"
				@selection-change="onSelectionChangeFunc"
			>
				<slot></slot>
			</ElTable>
		</BlockWithLoader>
	</div>
</template>

<script>
	import { Table } from 'element-ui'
	import Fuse from 'fuse.js'
	import BlockWithLoader from '@/components/other/BlockWithLoader.vue'

	import getSortedTable from '@/assets/js/other/getSortedTable.js'

	export default {
		components: {
			BlockWithLoader,
			ElTable: Table,
		},
		props: {
			tableData: { type: Array, default: () => ([]) },
			tableRowKey: { type: String, default: 'id' },
			tableClass: { type: String, default: '' },
			searchQuery: { type: String, default: '' },
			searchQueryProps: { type: Array, default: () => (['name', 'email', 'legalName', 'ownerName', 'inn']) },
			perPage: { type: Number, default: 25 },
			pageCurrent: { type: Number, default: 1 },
			rowClassNameFunction: { type: Function, default: () => (() => {}) },
			tableHeight: { type: [Number, String], default: undefined },
			isShowSummary: { type: Boolean, default: false },
			summaryMethod: { type: Function, default: () => (() => {}) },
			spanMethod: { type: Function, default: () => (() => {}) },
			disableStripe: { type: Boolean, default: false },
			sortFunc: { type: Function, default: getSortedTable },
			headerCellClassName: { type: String, default: '' },
			headerRowClassName: { type: String, default: 'thead-light' },
		},
		data() {
			return {
				sortProp: undefined,
				sortOrder: null,
				isShowLoader: false,
				totalPrev: 0,
			}
		},
		computed: {
			searchedData() {
				if (!this.searchQuery) {
					return this.tableData ?? []
				}
				const fuseSearch = this.getFuseSearch()
				const fuseData = fuseSearch.search(this.searchQuery) ?? []
				const items = fuseData.map((res) => res.item)
				return items
			},
			searchedDataSorted() {
				const sortProp = this.sortProp
				const sortOrder = this.sortOrder
				if (sortProp === undefined || sortOrder === null) {
					return this.searchedData
				}

				const isAscending = sortOrder === 'ascending'
				const result = this.sortFunc({
					tableItemList: this.searchedData,
					sortFieldName: this.sortProp,
					isAscending,
				})
				return result
			},
			queriedData() {
				const searchedData = this.searchedData ?? []
				const total = searchedData.length
				this.changeItemTotal(total)
				const from = this.perPage * (this.pageCurrent - 1)
				const highBound = from + this.perPage
				const to = total < highBound ? total : highBound

				const queriedData = (this.searchedDataSorted ?? []).slice(from, to)
				return queriedData
			},
		},
		updated() {
			this.isShowLoader = false
		},
		methods: {
			onRowClickFunc(row, column, event) {
				this.$emit('rowClick', { row, column, event })
			},
			onSelectionChangeFunc(event) {
				this.$emit('selectionChange', event)
			},
			sortChange({ prop, order /* 'ascending', 'descending', null */ }) {
				this.sortProp = prop
				this.sortOrder = order

				this.isShowLoader = true
			},
			summaryMethodLocal(incomeKit) {
				const result = this.summaryMethod({
					columns: incomeKit.columns,
					data: incomeKit.data,
					queriedData: this.queriedData,
					searchedData: this.searchedData,
				})
				return result
			},

			getFuseSearch() {
				const tableData = this.tableData
				const searchQueryProps = this.searchQueryProps

				return new Fuse(tableData ?? [], {
					keys: searchQueryProps,
					shouldSort: true,
					threshold: 0.13,
					minMatchCharLength: 1,
					includeScore: true,
					includeMatches: true,
				})
			},

			changeItemTotal(total) {
				if (total === this.totalPrev) {
					return
				}
				this.totalPrev = total
				this.$emit('changeItemTotal', total)
			},
		},
	}
</script>

<style lang="scss" scoped>
	::v-deep .el-table,
	::v-deep .el-table__append-wrapper {
		overflow: visible !important;
	}

	::v-deep .el-table__header-wrapper {
		position: sticky;
		top: 0;
	}
</style>
