import Vue from 'vue'
import { addRequestKit, removeRequestKit } from '@/plugins/parts/axiosRequest.js'
import { getResponseMessage } from '@/plugins/parts/axiosGetResponseMessage.js'
import { getPromiseAlertSessionOff } from '@/assets/js/alert/getPromiseAlertSessionOff.js'
import { authOut } from '@/assets/js/logInLogOut.js'
import { getRouterRoleList } from '@/assets/js/routerHelper.js'


const no401RedirectUrls = [
	'/check-credentials',
	'/reset-password',
	'/update-password',
	'/update-phone',
	'/link-role',
	'/login',
]

const code204Urls = [
	'/delete',
	'/sensors',
	'/events',
	'/file_resources',
	'/content/pages',
	'/content/categories',
	'/business_contacts',
	'/ofd_credentials',
]

const notRestrictedRequests = [
	'/notifications/set-seen',

	'/analytics/charts_data/customizable_income',
	'/analytics/charts_data/by_manual',
	'/analytics/charts_data/chart_data_v2',
	'/api/v2/shopping_centers/analytics/chart_data_v2',
	'/analytics/charts_data/kkts_by_days',
	'/analytics/top',
	'/analytics/charts_data/by_operations',
	'/analytics/mall/data',
	'/analytics/anomalies/list',

	'/check-credentials',
	'/reset-password',
	'/update-password',
	'/update-phone',

	'/api/v2/shopping_centers/dashboard/connections',
	'/api/v2/shopping_centers/dashboard/anomalies',
	'/api/v2/shopping_centers/dashboard/without_data',
	'/api/v2/shopping_centers/analytics/chart_data',
	'/api/v2/shopping_centers/categories/list',
	'/api/v2/shopping_centers/dashboard/profiles_top_list',
	'/api/v2/shopping_centers/dashboard/profiles_top',
	'/api/v2/shopping_centers/dashboard/profile_outsiders',
	'/api/v2/shopping_centers/dashboard/profile_leaders',
	'/api/v2/shopping_centers/dashboard/by_category',
	'/api/v2/shopping_centers/analytics/traffic_chart_data',

	'/misc/accept_xls',
	'/misc/renter_xls',
	'/reports/general',

	'/seen_by',
	'/api/v2/users/add_issue_to_favorites',
	'/api/v2/users/remove_issue_from_favorites',
]


const vue = new Vue()
const notifyBaseConfig = { timeout: 5000, icon: 'ni ni-bell-55', type: 'success', message: '' }


export default (...incomeList) => {
	const { app, $axios, redirect, route } = incomeList[0]

	// ++++++++++ listen for request message
	$axios.interceptors.request.use(
		(config) => {
			addRequestKit(config)

			if (['get', 'options'].includes(config.method)) {
				return config
			}

			const isAllowedRequest = notRestrictedRequests.some((url) => config.url.endsWith(url))
			if (isAllowedRequest) {
				return config
			}

			const roles = getRouterRoleList()
			const userIsVisitor = roles.length === 1 && roles[0] === 'visitor'
			if (userIsVisitor) {
				throw new Error('Visitors are only allowed to send `GET` and `OPTIONS` requests')
			}

			return config
		},
		(error) => {
			if (error.response) {
				addRequestKit(error.response.config)
			}
			return Promise.reject(error)
		},
	)
	$axios.interceptors.response.use(
		(response) => {
			removeRequestKit(response.config)
			return response
		},
		(error) => {
			if (error.response) {
				removeRequestKit(error.response.config)
			}
			return Promise.reject(error)
		},
	)
	// ---------- listen for request message

	$axios.interceptors.request.use(
		(config) => {
			if (process.browser) {
				vue?.$nuxt?.$emit('axiosStartRequest')
				const sessionToken = localStorage.getItem('sessionToken')
				config.headers['session-token'] = sessionToken
			}
			return config
		},
		(error) => Promise.reject(error),
	)
	$axios.interceptors.response.use(
		(response) => {
			const code = (response && response.status) || response.code
			if (response.config.url.includes('/sync_turnover_settings')) {
				return response
			}
			if (response.config.url.includes('/login')) {
				const sessionToken = response.data.session_token
				localStorage.setItem('sessionToken', sessionToken)
				$axios.defaults.headers.common['session-token'] = sessionToken
			}
			if (response.config.url.includes('/activation')) {
				const sessionToken = response.data.user.session_token
				localStorage.setItem('sessionToken', sessionToken)
				$axios.defaults.headers.common['session-token'] = sessionToken
			}
			if (code !== 204 || response.config.url.includes('/logout')) {
				return response
			}
			if (code204Urls.some((codeUrl) => response.config.url.includes(codeUrl))) {
				vue.$notify({ ...notifyBaseConfig, type: 'success', message: 'Сущность удалена' })
				return response
			}
			vue.$notify({ ...notifyBaseConfig, type: 'warning', message: 'Сущность не найдена' })
			return response
		},
		(error) => {
			const code = error?.response?.status || error?.code
			const response = error?.response?.data?.name || error?.response?.data?.status || error?.response?.data?.message
			const url = error?.response?.config?.url || ''

			if (code === 401) {
				if (no401RedirectUrls.includes(url)) {
					const message = getResponseMessage(url, response, 'Логин и/или пароль не верны')
					vue.$notify({ ...notifyBaseConfig, type: 'warning', message })
				} else if (!window.location.href.includes('/weekly-report/')) {
					const routerName = route.name
					if (!['index', 'login'].includes(routerName)) {
						localStorage.setItem('pagePathBeforeLogin', route.path)
					}
					getPromiseAlertSessionOff().then(() => {
						authOut({ isShowMessage: false })
						const fullPath = app.router.currentRoute.fullPath
						const loginPath = '/login'
						if (fullPath !== loginPath) {
							redirect(loginPath)
						}
					})
				}
				return Promise.reject(error)
			}
			if (code === 403) {
				if (!url.includes('/weekly_analytics')) {
					if (
						app.router.currentRoute.params.entity === '5d99a782abd6210030f1e1cd' &&
						['post', 'put', 'delete'].includes(error.response.config.method)
					) {
						const errmsg = 'Вы находитесь в тестовом ТЦ. Изменения не сохраняются.'
						vue.$notify({ ...notifyBaseConfig, type: 'warning', message: errmsg })
					} else {
						const errmsg = getResponseMessage(url, response, 'Доступ запрещен')
						vue.$notify({ ...notifyBaseConfig, type: 'danger', message: errmsg })
					}
				}
				return Promise.reject(error)
			}
			if (code === 409) {
				const errmsg = getResponseMessage(url, response, 'Сущность уже существует')
				vue.$notify({ ...notifyBaseConfig, type: 'warning', message: errmsg })
				return Promise.reject(error)
			}
			if (code === 422) {
				const errmsg = getResponseMessage(url, response, 'Не все поля формы заполнены корректно')
				vue.$notify({ ...notifyBaseConfig, type: 'warning', message: errmsg })
				return Promise.reject(error)
			}
			if (code === 429) {
				const errmsg = getResponseMessage('', response, 'Превышено количество попыток')
				vue.$notify({ ...notifyBaseConfig, type: 'warning', message: errmsg })
				return Promise.reject(error)
			}
			if (code === 500) {
				const errmsg = getResponseMessage(url, response, 'Ошибка во время запроса')
				vue.$notify({ ...notifyBaseConfig, type: 'danger', message: errmsg })
				return Promise.reject(error)
			}

			const uiIgnoreNotify = url.includes('ui_ignore_notify')
			if (uiIgnoreNotify) {
				return Promise.reject(error)
			}
			const errmsg = getResponseMessage(url, response, 'Произошла ошибка')
			vue.$notify({ ...notifyBaseConfig, type: 'danger', message: errmsg })
			return Promise.reject(error)
		},
	)
}
