
	import Vue from 'vue'
	import Draggable from 'vuedraggable'
	import LightTooltip from '@/components/LightTooltip.vue'
	import FakeBlock from '@/components/other/FakeBlock.vue'
	import DocsMenuElement from '@/components/pages/docs/DocsMenu/DocsMenuElement.vue'

	import { translate } from '@/assets/i18n/i18nextConfig.js'
	import { DocsMenuItem, DocsCategory, DocsPage } from '@/types/docs.ts'
	import { getPromiseQuery } from '@/assets/js/query/getPromiseQuery.js'
	import { getAuthUserKit } from '@/assets/js/routerHelper.js'

	type DocsMenuData = {
		items: DocsMenuItem[]
	}

	export default Vue.component('DocsMenu', {
		components: {
			Draggable,
			FakeBlock,
			DocsMenuElement,
			LightTooltip,
		},
		props: {
			categories: { type: Array, default: () => ([]) },
			ready: { type: Boolean, default: false },
		},
		data(): DocsMenuData {
			return {
				items: [],
			}
		},
		computed: {
			Items: {
				get(): DocsMenuItem[] {
					return this.items
				},
				set(items: DocsMenuItem[]) {
					this.items = items
					this.updateOrder(items.map((item) => item.id))
				},
			},
			isStaff(): boolean {
				return getAuthUserKit()?.is_staff
			},
		},
		watch: {
			categories(): void {
				this.init()
			},
			ready(): void {
				this.init()
			},
		},
		mounted(): void {
			this.init()
		},
		methods: {
			translate,
			init(): void {
				if (!this.ready) {
					this.items = []
					return
				}

				const cats = this.categories as DocsCategory[]
				const items: DocsMenuItem[] = cats.map((cat: DocsCategory) => {
					const children: DocsMenuItem[] = cat.pages.map((page: DocsPage) => {
						return {
							id: page.id,
							title: page.title,
							slug: page.slug,
							children: [],
							order: page.sortingNumber,
							parentId: cat.id,
							draft: page.draft,
						}
					})
					return {
						id: cat.id,
						title: cat.title,
						slug: cat.slug,
						children,
						order: cat.sortingNumber,
						parentId: '',
						draft: false,
					}
				}).sort((a: DocsMenuItem, b: DocsMenuItem) => {
					if (a.order > b.order) {
						return 1
					}
					if (a.order < b.order) {
						return -1
					}
					return 0
				})

				this.items = items
			},
			updateOrder(categoryIds: string[]): void {
				if (!this.isStaff) {
					return
				}

				getPromiseQuery({ docReorderCategories: { categoryIds } })
			},
		},
	})
