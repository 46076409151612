<template>
	<div>
		<MenuPanelTop></MenuPanelTop>
		<MenuPanelSide
			:panel-side-item-list="panelSideItemList"
			:panel-side-bottom-item-list="panelSideBottomItemList"
		></MenuPanelSide>
		<MenuPanelContent>
			<slot></slot>
		</MenuPanelContent>
		<FloatingMenuToggler
			v-show="sideMenuConfigVue.menuContainerSize === 'menuContainerHide'"
		></FloatingMenuToggler>
		<div
			v-show="isShowSubstrate"
			:class="[$style.substrate]"
			@click="onSubstrateClick()"
		></div>
	</div>
</template>

<script>
	import MenuPanelTop from '@/components/menu/MenuPanelTop.vue'
	import MenuPanelSide from '@/components/menu/MenuPanelSide.vue'
	import MenuPanelContent from '@/components/menu/MenuPanelContent.vue'
	import FloatingMenuToggler from '@/components/layouts/FloatingMenuToggler.vue'

	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'

	export default {
		components: {
			MenuPanelTop,
			MenuPanelSide,
			MenuPanelContent,
			FloatingMenuToggler,
		},
		props: {
			panelSideItemList: { type: Array, default: () => ([]) },
			panelSideBottomItemList: { type: Array, default: () => ([]) },
		},
		data() {
			return {
				sideMenuConfigVue,
			}
		},
		computed: {
			isShowSubstrate() {
				const isShowSubstrate =
					['menuContainerHide', 'menuContainerSmall'].includes(this.sideMenuConfigVue.menuContainerSize) &&
					this.sideMenuConfigVue.menuSize === 'menuBig'
				return isShowSubstrate
			},
		},
		watch: {
			$route() {
				if (this.isShowSubstrate) {
					this.sideMenuConfigVue.disableIsMenuActive()
				}
			},
		},
		mounted() {
			if (this.sideMenuConfigVue.isDisableOnMount) {
				this.sideMenuConfigVue.disableIsMenuActive()
			}
		},
		methods: {
			onSubstrateClick() {
				this.sideMenuConfigVue.disableIsMenuActive()
			},
		},
	}
</script>

<style module>
	.substrate {
		position: fixed;
		z-index: 1020;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(100, 100, 100, 0.5);
	}
</style>
