// import Vue from 'vue'

// const vue = new Vue()
// const notifyBaseConfig = { timeout: 5000, icon: 'ni ni-bell-55', type: 'success', message: '' }


// ++++++++++ request message
const timeoutMs = 13000
// const requestKitMessage = 'Все нормально, просто много данных, скоро все сформируем)'
const exceptionUrlList = ['/telegram/show', '/telegram/generate']

const requestKitMap = new Map()
let requestCount = 0
let showMessageCount = 0
let isTimeoutStart = false
let timeoutRequestMessage

const getIsExceptionConfig = ({ config }) => {
	return exceptionUrlList.includes(config.url)
}

const checkRequestCount = () => {
	if (requestCount < 0) {
		// eslint-disable-next-line no-console
		console.error(new Error(`requestCount (${requestCount}) cannot be less than zero`))
		requestCount = 0
	}
}

const clearTimeoutRequestMessage = () => {
	clearInterval(timeoutRequestMessage)
	showMessageCount = 0
	isTimeoutStart = false
}

const startRequestMessage = () => {
	if (isTimeoutStart) {
		return
	}
	isTimeoutStart = true
	timeoutRequestMessage = setInterval(() => {
		checkRequestCount()
		if (requestCount === 0) {
			return
		}

		// vue.$notify({ ...notifyBaseConfig, message: requestKitMessage })
		showMessageCount += 1
		if (showMessageCount > 4) {
			// eslint-disable-next-line no-console
			console.error(new Error(
				`Message shown more than ${showMessageCount} times. Need to check requestKitMap: `,
			), Array.from(requestKitMap.keys()))
			clearTimeoutRequestMessage()
		}
	}, timeoutMs)
}

const stopRequestMessage = () => {
	setTimeout(() => {
		checkRequestCount()
		if (requestCount > 0) {
			return
		}
		clearTimeoutRequestMessage()
	})
}


const addRequestKit = (config) => {
	const isExceptionConfig = getIsExceptionConfig({ config })
	if (isExceptionConfig) {
		return
	}

	const kit = { config }
	requestKitMap.set(config, kit)
	requestCount += 1
	startRequestMessage()
}

const removeRequestKit = (config) => {
	const isExceptionConfig = getIsExceptionConfig({ config })
	if (isExceptionConfig) {
		return
	}

	const isDelete = requestKitMap.delete(config)
	if (!isDelete) {
		// eslint-disable-next-line no-console
		console.error(new Error('config not found in requestKitMap'), 'config:', config)
	}
	requestCount -= 1
	stopRequestMessage()
}
// ---------- request message


export { addRequestKit, removeRequestKit }
