<template>
	<div>
		<TableWrapper
			:table-data="tableData"
			:table-row-key="tableRowKey"
			:table-class="tableClass"
			:search-query="searchQuery"
			:search-query-props="searchQueryProps"
			:page-current="pageMoreActive"
			:per-page="itemMorePerPage"
			:row-class-name-function="rowClassNameFunction"
			:table-height="tableHeight"
			:is-show-summary="isShowSummary"
			:summary-method="summaryMethod"
			:span-method="spanMethod"
			:sort-func="sortFunc"
			:header-cell-class-name="headerCellClassName"
			:header-row-class-name="headerRowClassName"
			:disable-stripe="disableStripe"
			@changeItemTotal="changeItemTotal($event)"
			@rowClick="onRowClick($event)"
			@selectionChange="onSelectionChange($event)"
		>
			<slot></slot>
		</TableWrapper>
		<BlockPaginationWithShowMore
			class="px-4 py-2 d-flex flex-wrap justify-content-center justify-content-sm-between align-items-center blockPagination"
			:class="paginationClassName"
			:page-active="pageActive"
			:item-total="total"
			:item-per-page="perPage"
			:page-more-active="pageMoreActive"
			:item-more-per-page="itemMorePerPage"
			@changePageActive="changePageActive($event)"
			@showMore="showMore()"
		></BlockPaginationWithShowMore>
	</div>
</template>

<script>
	import TableWrapper from '@/components/tables/TableWrapper.vue'
	import BlockPaginationWithShowMore from '@/components/pagination/BlockPaginationWithShowMore.vue'

	export default {
		components: {
			TableWrapper,
			BlockPaginationWithShowMore,
		},
		props: {
			tableData: { type: Array, default: () => ([]) },
			tableRowKey: { type: String, default: 'id' },
			tableClass: { type: String, default: '' },
			searchQuery: { type: String, default: '' },
			searchQueryProps: { type: Array, default: () => (['name', 'email', 'legalName', 'ownerName', 'inn']) },
			perPage: { type: Number, default: 25 },
			rowClassNameFunction: { type: Function, default: () => (() => {}) },
			rowClickFunction: { type: Function, default: () => (() => {}) },
			isShowSummary: { type: Boolean, default: false },
			summaryMethod: { type: Function, default: () => (() => {}) },
			spanMethod: { type: Function, default: () => (() => {}) },
			tableHeight: { type: [Number, String], default: undefined },
			signResetPage: { type: Boolean, default: false },
			sortFunc: { type: Function, default: undefined },
			headerCellClassName: { type: String, default: '' },
			headerRowClassName: { type: String, default: 'thead-light' },
			paginationClassName: { type: String, default: '' },
			disableStripe: { type: Boolean, default: false },
		},
		data() {
			return {
				pageActive: 1,
				total: 0,
				pageMoreActive: 1,
				itemMorePerPage: this.perPage,
			}
		},
		watch: {
			perPage() {
				this.resetPage()
				this.resetShowMore()
			},
			signResetPage() {
				this.resetPage()
				this.resetShowMore()
			},
		},
		methods: {
			onRowClick(event) {
				this.$emit('rowClick', event)
			},
			onSelectionChange(event) {
				this.$emit('selectionChange', event)
			},
			changeItemTotal(total) {
				this.total = total
				this.resetPage()
				this.resetShowMore()
			},
			changePageActive(event) {
				this.pageActive = event
				this.resetShowMore()
			},
			showMore() {
				this.pageActive += 1
				this.itemMorePerPage += this.perPage
				this.onChange()
			},
			resetPage() {
				this.pageActive = 1
				this.pageMoreActive = 1
				this.onChange()
			},
			resetShowMore() {
				this.pageMoreActive = this.pageActive
				this.itemMorePerPage = this.perPage
				this.onChange()
			},
			onChange() {
				this.$emit('onChange', {
					perPage: this.perPage,
					pageActive: this.pageActive,
					pageMoreActive: this.pageMoreActive,
					itemMorePerPage: this.itemMorePerPage,
				})
			},
		},
	}
</script>
