import Vue from 'vue'
import { windowWidthKit } from '@/assets/js/windowsEvent/windowWidthKit.js'

const menuContainerSizeList = ['menuContainerHide', 'menuContainerSmall', 'menuContainerBig']
const menuSizeList = ['menuHide', 'menuSmall', 'menuBig']

const sideMenuConfigVue = new Vue({
	data() {
		return {
			windowWidthKit,
			isMenuActive: true,
			isMenuMouseOver: false,
		}
	},
	computed: {
		isDisableOnMount() {
			const width = this.windowWidthKit.width
			return width < 1700
		},
		menuContainerSize() {
			const isMenuActive = this.isMenuActive
			const width = this.windowWidthKit.width

			let stateCurrent = ''
			if (width < 1200) {
				stateCurrent = menuContainerSizeList[0]
			} else if (width < 1700) {
				stateCurrent = menuContainerSizeList[1]
			} else {
				stateCurrent = isMenuActive ? menuContainerSizeList[2] : menuContainerSizeList[1]
			}

			return stateCurrent
		},
		menuSize() {
			const isMenuActive = this.isMenuActive
			const isMenuMouseOver = this.isMenuMouseOver
			const width = this.windowWidthKit.width

			let stateCurrent = ''
			if (isMenuActive || isMenuMouseOver) {
				stateCurrent = menuSizeList[2]
			} else if (width < 1200) {
				stateCurrent = menuSizeList[0]
			} else {
				stateCurrent = menuSizeList[1]
			}

			return stateCurrent
		},
	},
	watch: {
		menuContainerSize() {
			this.emitChange()
		},
		menuSize() {
			this.emitChange()
		},
	},
	methods: {
		emitChange() {
			this.$emit('change')
		},
		toggleIsMenuActive() {
			this.isMenuActive = !this.isMenuActive
			this.emitChange()
		},
		disableIsMenuActive() {
			this.isMenuActive = false
			this.emitChange()
		},
		onMouseEnter() {
			this.isMenuMouseOver = true
			this.emitChange()
		},
		onMouseLeave() {
			this.isMenuMouseOver = false
			this.emitChange()
		},
	},
})

export { sideMenuConfigVue }
