import { getPromiseQuery } from '@/assets/js/query/getPromiseQuery.js'
import { getAuthKit } from '@/assets/js/routerHelper'

export default async (context) => {
	const { redirect, route, store } = context
	const routerName = route.name

	if (['index', 'login', 'api-docs', 'entity-weekly-report-report_key'].includes(routerName)) {
		return redirect()
	}

	if (!getAuthKit()) {
		return redirect()
	}

	const isInitMallList = store.state.isInitMallList
	if (isInitMallList) {
		return redirect()
	}

	const mallId = route.params?.entity

	if (!mallId) {
		const result = await getPromiseQuery({ availableMalls: {} })
		try {
			store.commit('setMalls', result.shopping_centers)
			store.commit('setIsInitMallList', true)
		} catch (e) {}
		return redirect()
	}

	const mallResponse = await getPromiseQuery({ mallInformation: { mallId } })

	store.commit('setMalls', [mallResponse.shopping_center])

	getPromiseQuery({ availableMalls: {} }).then((response) => {
		try {
			store.commit('setMalls', response.shopping_centers)
			store.commit('setIsInitMallList', true)
		} catch (e) {}
	})
	return redirect()
}
