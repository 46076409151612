var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LightTooltip',{attrs:{"disabled":_vm.tooltip === ''}},[_c('div',{staticClass:"white-space-pre-wrap",attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(_vm.tooltip))]),_vm._v(" "),_c('button',{staticClass:"btn",class:[
			_vm.btnClass,
			[`btn-${_vm.size}`],
			{ 'btn--fluid': _vm.fluid },
			{ 'btn--disabled': _vm.disabled || _vm.loading },
			{ [`btn-${_vm.color}`]: _vm.color && !_vm.outline },
			{ [`btn-outline-${_vm.color}`]: _vm.outline && _vm.color },
			{ disabled: _vm.disabled || _vm.loading }
		],attrs:{"disabled":_vm.disabled || _vm.loading,"type":_vm.nativeType,"data-testid":_vm.testId},on:{"click":_vm.handleClick}},[(_vm.loading)?_c('i',{staticClass:"fa-solid fa-spinner fa-spin"}):(_vm.icon)?_c('i',{class:_vm.icon}):_vm._e(),_vm._v(" "),(_vm.text)?_c('span',[_vm._v(_vm._s(_vm.text))]):_vm._e(),_vm._v(" "),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }