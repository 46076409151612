<template>
	<div class="auth-layout" translate="no">
		<notifications></notifications>
		<div class="main-content">
			<nuxt></nuxt>
		</div>
	</div>
</template>
<script>
	export default {
		head() {
			return {
				meta: [
					{
						hid: 'viewport',
						name: 'viewport',
						content: 'width=device-width, initial-scale=0.31',
					},
				],
			}
		},
	}
</script>
