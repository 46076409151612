const kit = {
	zoneList: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/structure/zones`
		return window.$nuxt.$axios.$get(url)
	},
	zoneAdd: ({
		shoppingCenterId,
		sellLocationId,
		zoneName,
		zoneDescription,
		sensorIdList,
		sensorReverseIdList,
		specialFeature,

		openingOnSunday,
		openingOnMonday,
		openingOnTuesday,
		openingOnWednesday,
		openingOnThursday,
		openingOnFriday,
		openingOnSaturday,

		closingOnSunday,
		closingOnMonday,
		closingOnTuesday,
		closingOnWednesday,
		closingOnThursday,
		closingOnFriday,
		closingOnSaturday,
	}) => {
		const url = `/api/v2/sc/${shoppingCenterId}/structure/zones`
		const data = {
			shopping_center_id: shoppingCenterId,
			sell_location_id: sellLocationId,
			name: zoneName,
			description: zoneDescription,
			sensor_ids: sensorIdList,
			reverse_sensor_ids: sensorReverseIdList,
			special_feature: specialFeature,

			opening_on_sunday: openingOnSunday,
			opening_on_monday: openingOnMonday,
			opening_on_tuesday: openingOnTuesday,
			opening_on_wednesday: openingOnWednesday,
			opening_on_thursday: openingOnThursday,
			opening_on_friday: openingOnFriday,
			opening_on_saturday: openingOnSaturday,

			closing_on_sunday: closingOnSunday,
			closing_on_monday: closingOnMonday,
			closing_on_tuesday: closingOnTuesday,
			closing_on_wednesday: closingOnWednesday,
			closing_on_thursday: closingOnThursday,
			closing_on_friday: closingOnFriday,
			closing_on_saturday: closingOnSaturday,
		}
		return window.$nuxt.$axios.$post(url, data)
	},
	zoneUpdate: ({
		shoppingCenterId,
		sellLocationId,
		zoneId,
		zoneName,
		zoneDescription,
		sensorIdList,
		sensorReverseIdList,
		specialFeature,

		openingOnSunday,
		openingOnMonday,
		openingOnTuesday,
		openingOnWednesday,
		openingOnThursday,
		openingOnFriday,
		openingOnSaturday,

		closingOnSunday,
		closingOnMonday,
		closingOnTuesday,
		closingOnWednesday,
		closingOnThursday,
		closingOnFriday,
		closingOnSaturday,
	}) => {
		const url = `/api/v2/sc/${shoppingCenterId}/structure/zones/${zoneId}`
		const data = {
			shopping_center_id: shoppingCenterId,
			sell_location_id: sellLocationId,
			id: zoneId,
			name: zoneName,
			description: zoneDescription,
			sensor_ids: sensorIdList,
			reverse_sensor_ids: sensorReverseIdList,
			special_feature: specialFeature,

			opening_on_sunday: openingOnSunday,
			opening_on_monday: openingOnMonday,
			opening_on_tuesday: openingOnTuesday,
			opening_on_wednesday: openingOnWednesday,
			opening_on_thursday: openingOnThursday,
			opening_on_friday: openingOnFriday,
			opening_on_saturday: openingOnSaturday,

			closing_on_sunday: closingOnSunday,
			closing_on_monday: closingOnMonday,
			closing_on_tuesday: closingOnTuesday,
			closing_on_wednesday: closingOnWednesday,
			closing_on_thursday: closingOnThursday,
			closing_on_friday: closingOnFriday,
			closing_on_saturday: closingOnSaturday,
		}
		return window.$nuxt.$axios.$put(url, data)
	},
	zoneDelete: ({ shoppingCenterId, zoneId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/structure/zones/${zoneId}`
		return window.$nuxt.$axios.$delete(url)
	},
}

export default kit
