const kit = {
	dashboardAverageReceipts: ({ shoppingCenterId, periodName }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/dashboard/average_receipts/${periodName}`
		return window.$nuxt.$axios.$get(url)
	},
	dashboardAnomalies: ({ shoppingCenterId, periodName }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/dashboard/anomalies/${periodName}`
		return window.$nuxt.$axios.$get(url)
	},
	dashboardDynamicConnects: ({ shoppingCenterId, periodName }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/dashboard/dynamic_connects/${periodName}`
		return window.$nuxt.$axios.$get(url)
	},
	dashboardBlockCategories: ({ shoppingCenterId, periodName, dataType }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/dashboard/block_categories/${dataType}?period_type=${periodName}`
		return window.$nuxt.$axios.$get(url)
	},
	dashboardWeeklyDailySales: ({ mallId, periodName, dataType }) => {
		const url = `/api/v2/sc/${mallId}/dashboard/weekly_daily_sales/${dataType}_${periodName}`
		return window.$nuxt.$axios.$get(url)
	},
	dashboardLeaders: ({ shoppingCenterId, periodName, leaderType }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/dashboard/leaders/${leaderType}_${periodName}`
		return window.$nuxt.$axios.$get(url)
	},
	dashboardWithoutData: ({ shoppingCenterId, periodName }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/dashboard/without_data/${periodName}`
		return window.$nuxt.$axios.$get(url)
	},
	dashboardOutsiders: ({ shoppingCenterId, periodName, outsiderType }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/dashboard/outsiders/${outsiderType}_${periodName}`
		return window.$nuxt.$axios.$get(url)
	},
	dashboardPrimaryMetrics: ({ shoppingCenterId, periodName }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/dashboard/primary_metrics?period_type=${periodName}`
		return window.$nuxt.$axios.$get(url)
	},
	dashboardSalesDynamics: ({ shoppingCenterId, periodName, dataType }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/dashboard/sales_dynamics/${dataType}_${periodName}`
		return window.$nuxt.$axios.$get(url)
	},
	dashboardTops: ({ shoppingCenterId, periodName, dataType }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/dashboard/tops/${dataType}_${periodName}`
		return window.$nuxt.$axios.$get(url)
	},
}

export default kit
