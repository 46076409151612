import { getAuthKit, getRouterMallKit } from '@/assets/js/routerHelper.js'
import { accessRoleListKit } from '@/assets/js/access/accessRoleListKit.js'
import { hasAccess } from '@/assets/js/access/hasAccess.js'
import { MenuSideItem, def } from '@/assets/js/sideMenu/MenuSideItem.js'
import { translate } from '@/assets/i18n/i18nextConfig.js'

const getItemsFiltered = ({ itemList }) => {
	if (!itemList || !itemList.length) {
		return []
	}

	const itemsFiltered = itemList.filter((item) => {
		return hasAccess({ accessRoleList: item.accessRoleList })
	})

	itemsFiltered.forEach((item) => {
		item.childList = getItemsFiltered({ itemList: item.childList })
	})

	return itemsFiltered
}

const getMenuItemList = () => {
	const abbreviation = getRouterMallKit().abbreviation ?? ''
	const userRoles = getRouterMallKit().role ?? ''

	const ta = (key) => {
		return translate(key).replace('%ABBREVIATION%', abbreviation)
	}

	const getSettingsRoute = () => {
		if (accessRoleListKit.editRoles.includes(userRoles)) {
			return 'entity-settings-roles'
		}
		if (accessRoleListKit.editCommon.includes(userRoles)) {
			return 'entity-settings-shoppingCenter'
		}
		return 'entity-settings-positions'
	}

	const list = [
		new MenuSideItem({
			...def,
			text: translate('sideMenu.monitoring'),
			iconCssClass: 'fa-solid fa-chart-area text-primary',
			routeTo: { name: 'entity-monitoring' },
			accessRoleList: accessRoleListKit.common,
		}),
		new MenuSideItem({
			...def,
			text: ta('sideMenu.mallAnalysis'),
			iconCssClass: 'fa-solid fa-wave-pulse text-green',
			routeTo: { name: 'entity-dashboard' },
			accessRoleList: accessRoleListKit.showFinancial,
			childList: [
				new MenuSideItem({
					...def,
					text: ta('sideMenu.mallDashboardOperative'),
					routeTo: { name: 'entity-dashboard' },
					accessRoleList: accessRoleListKit.showFinancial,
				}),
				new MenuSideItem({
					...def,
					text: `${ta('sideMenu.mallDashboardMonthly')}`,
					routeTo: { name: 'entity-dashboard-v2' },
					accessRoleList: accessRoleListKit.showFinancial,
				}),
				new MenuSideItem({
					...def,
					text: ta('sideMenu.mallCharts'),
					routeTo: { name: 'entity-analysis-financial' },
					accessRoleList: accessRoleListKit.showFinancial,
				}),
			],
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.renters'),
			iconCssClass: 'fa-solid fa-user text-warning',
			routeTo: { name: 'entity-shops' },
			accessRoleList: accessRoleListKit.common,
			childList: [
				new MenuSideItem({
					...def,
					text: translate('sideMenu.byShops'),
					routeTo: { name: 'entity-shops' },
					accessRoleList: accessRoleListKit.common,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.byRenters'),
					routeTo: { name: 'entity-arendators' },
					accessRoleList: accessRoleListKit.common,
				}),
			],
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.schema'),
			iconCssClass: 'fa-solid fa-map-marked-alt text-info',
			routeTo: { name: 'entity-analytics-mallMap' },
			accessRoleList: accessRoleListKit.common,
			childList: [
				new MenuSideItem({
					...def,
					text: translate('sideMenu.situationalMap'),
					iconCssClass: 'fa-regular fa-circle-exclamation',
					isPageMustContainMap: true,
					routeTo: { name: 'entity-mallMap' },
					accessRoleList: accessRoleListKit.common,
				}),
				new MenuSideItem({
					...def,
					text: ta('sideMenu.mallStructure'),
					routeTo: { name: 'entity-structure' },
					accessRoleList: accessRoleListKit.common,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.analyticalMap'),
					iconCssClass: 'fa-regular fa-circle-exclamation',
					isPageMustContainMap: true,
					routeTo: { name: 'entity-analytics-mallMap' },
					isDisabled: !getAuthKit().user.is_staff,
					accessRoleList: accessRoleListKit.showFinancial,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.heatmap'),
					routeTo: { name: 'entity-analysis-mallMap' },
					accessRoleList: accessRoleListKit.showFinancial,
					isDisabled: true,
				}),
			],
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.comparison'),
			iconCssClass: 'fa-solid fa-line-height text-purple',
			routeTo: { name: 'entity-top' },
			accessRoleList: accessRoleListKit.showFinancial,
			childList: [
				new MenuSideItem({
					...def,
					text: translate('sideMenu.rentersTop'),
					routeTo: { name: 'entity-top' },
					accessRoleList: accessRoleListKit.showFinancial,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.graphical'),
					routeTo: { name: 'entity-analytics-comparison' },
					isBeta: true,
					accessRoleList: accessRoleListKit.showFinancial,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.abcXyz'),
					accessRoleList: accessRoleListKit.all,
					routeTo: { name: 'entity-analytics-abc' },
					isDisabled: true,
					isLink: false,
				}),
			],
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.reports'),
			iconCssClass: 'fa-solid fa-files text-danger',
			routeTo: { name: accessRoleListKit.showFinancial.includes(userRoles) ? 'entity-reports-general' : 'entity-reports-aboutRenter' },
			accessRoleList: accessRoleListKit.common,
			childList: [
				new MenuSideItem({
					...def,
					text: translate('sideMenu.reportsGeneral'),
					routeTo: { name: 'entity-reports-general' },
					accessRoleList: accessRoleListKit.showFinancial,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.shopsByOperationType'),
					routeTo: { name: 'entity-reports-shopsByOperationType' },
					accessRoleList: accessRoleListKit.showFinancial,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.shopsByAverage'),
					routeTo: { name: 'entity-reports-shopsByAverage' },
					accessRoleList: accessRoleListKit.showFinancial,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.shopByChecks'),
					routeTo: { name: 'entity-reports-shopByChecks' },
					accessRoleList: accessRoleListKit.showFinancial,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.shopByDate'),
					routeTo: { name: 'entity-reports-shopByDate' },
					accessRoleList: accessRoleListKit.showFinancial,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.aboutRenter'),
					routeTo: { name: 'entity-reports-aboutRenter' },
					accessRoleList: accessRoleListKit.common,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.reportsKkts'),
					routeTo: { name: 'entity-reports-kkts' },
					accessRoleList: accessRoleListKit.common,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.unknownKkts'),
					routeTo: { name: 'entity-reports-unknownKkts' },
					accessRoleList: ['staff'],
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.reportsTurnover'),
					routeTo: { name: 'entity-reports-turnover' },
					accessRoleList: accessRoleListKit.showFinancial,
					isBeta: true,
				}),
			],
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.anomalies'),
			iconCssClass: 'fa-solid fa-radiation text-yellow',
			routeTo: { name: 'entity-anomaly' },
			accessRoleList: accessRoleListKit.common,
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.log'),
			iconCssClass: 'fa-solid fa-calendar-days text-primary',
			routeTo: { name: 'entity-events' },
			accessRoleList: accessRoleListKit.common,
		}),
		new MenuSideItem({
			...def,
			text: ta('sideMenu.mallVisitors'),
			iconCssClass: 'fa-solid fa-users text-purple',
			routeTo: {
				name: ['traffic_director', 'traffic_manager'].includes(userRoles)
					? 'entity-visitors-sensors-control'
					: 'entity-visitors-tendency',
			},
			accessRoleList: ['admin', 'director', 'manager', 'marketer', 'visitor', 'traffic_director', 'traffic_manager'],
			isBeta: true,
			childList: [
				new MenuSideItem({
					...def,
					text: ta('sideMenu.mallVisitorsTendency'),
					routeTo: { name: 'entity-visitors-tendency' },
					accessRoleList: accessRoleListKit.showFinancial,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.topByDays'),
					routeTo: { name: 'entity-visitors-top' },
					accessRoleList: accessRoleListKit.showFinancial,
					isDisabled: true,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.sensorsControl'),
					routeTo: { name: 'entity-visitors-sensors-control' },
					accessRoleList: ['admin', 'traffic_director', 'traffic_manager'],
					isDisabled: true,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.sensorsSettings'),
					routeTo: { name: 'entity-visitors-sensors-settings' },
					accessRoleList: ['admin', 'traffic_director', 'traffic_manager'],
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.zonesControl'),
					routeTo: {},
					accessRoleList: ['admin', 'traffic_director', 'traffic_manager'],
					isDisabled: true,
					isLink: false,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.zonesSettings'),
					routeTo: { name: 'entity-visitors-zones-settings' },
					accessRoleList: ['admin', 'traffic_director', 'traffic_manager'],
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.reports'),
					routeTo: {},
					accessRoleList: ['admin', 'director', 'manager', 'marketer', 'visitor', 'traffic_director', 'traffic_manager'],
					isDisabled: true,
					isLink: false,
				}),
			],
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.horeca'),
			iconCssClass: 'fa-solid fa-utensils text-warning',
			routeTo: { name: 'entity-food' },
			isBeta: true,
			accessRoleList: accessRoleListKit.showFinancial,
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.settings'),
			iconCssClass: 'fa-solid fa-gear text-success',
			routeTo: { name: getSettingsRoute() },
			accessRoleList: accessRoleListKit.common,
			childList: [
				new MenuSideItem({
					...def,
					text: translate('sideMenu.roles'),
					routeTo: { name: 'entity-settings-roles' },
					accessRoleList: accessRoleListKit.editRoles,
				}),
				new MenuSideItem({
					...def,
					text: ta('sideMenu.mallData'),
					routeTo: { name: 'entity-settings-shoppingCenter' },
					accessRoleList: accessRoleListKit.editCommon,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.positions'),
					routeTo: { name: 'entity-settings-positions' },
					accessRoleList: accessRoleListKit.common,
				}),
				new MenuSideItem({
					...def,
					text: ta('sideMenu.mallMap'),
					iconCssClass: 'fa-regular fa-circle-exclamation',
					isPageMustContainMap: true,
					routeTo: { name: 'entity-settings-mallMap' },
					accessRoleList: accessRoleListKit.common,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.ofdAccounts'),
					routeTo: { name: 'entity-settings-accounts' },
					accessRoleList: accessRoleListKit.editRoles,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.ocr'),
					routeTo: { name: 'entity-settings-ocr' },
					accessRoleList: accessRoleListKit.editTurnover,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.turnover'),
					routeTo: { name: 'entity-settings-turnover' },
					accessRoleList: accessRoleListKit.editTurnover,
				}),
			],
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.manualEnter'),
			iconCssClass: 'fa-solid fa-calculator text-info',
			routeTo: { name: 'entity-settings-visitors' },
			accessRoleList: accessRoleListKit.editCommon,
			childList: [
				new MenuSideItem({
					...def,
					text: translate('sideMenu.visitors'),
					routeTo: { name: 'entity-settings-visitors' },
					accessRoleList: accessRoleListKit.editCommon,
				}),
				// new MenuSideItem({
				// 	...def,
				// 	text: translate('sideMenu.shops'),
				// 	routeTo: { name: 'entity-settings-manual' },
				// 	accessRoleList: accessRoleListKit.editCommon,
				// }),
			],
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.systemPages'),
			iconCssClass: 'fa-light fa-folder-gear text-warning',
			routeTo: { name: 'support-board' },
			accessRoleList: accessRoleListKit.staffAll,
			childList: [
				new MenuSideItem({
					...def,
					text: translate('sideMenu.supportBoard'),
					routeTo: { name: 'support-board' },
					accessRoleList: accessRoleListKit.staffAll,
				}),
				new MenuSideItem({
					...def,
					text: ta('sideMenu.mallMap'),
					routeTo: { name: 'entity-terminal' },
					accessRoleList: accessRoleListKit.staffAll,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.unknownKkts'),
					routeTo: { name: 'unknown-kkts' },
					accessRoleList: accessRoleListKit.staffAll,
				}),
			],
		}),
	]

	const menuItemList = getItemsFiltered({ itemList: list })
	return menuItemList
}


export { getMenuItemList }
