import { clearAll } from '@/assets/js/query/cacheQuery.js'
import { depotVue } from '@/assets/js/depotVue.js'

const clearCache = () => {
	clearAll()
	depotVue.clearAll()
	window.$nuxt.$store.commit('setIsInitMallList', false)
}

export { clearCache }
