import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1ec9ad87 = () => interopDefault(import('../pages/api-docs.vue' /* webpackChunkName: "pages/api-docs" */))
const _fe7e2192 = () => interopDefault(import('../pages/docs/index.vue' /* webpackChunkName: "pages/docs/index" */))
const _d1e04c8c = () => interopDefault(import('../pages/entities.vue' /* webpackChunkName: "pages/entities" */))
const _2e031140 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _a3145caa = () => interopDefault(import('../pages/logs/index.vue' /* webpackChunkName: "pages/logs/index" */))
const _5dff4202 = () => interopDefault(import('../pages/support-board/index.vue' /* webpackChunkName: "pages/support-board/index" */))
const _2e3e7284 = () => interopDefault(import('../pages/unknown-kkts/index.vue' /* webpackChunkName: "pages/unknown-kkts/index" */))
const _1bb8d179 = () => interopDefault(import('../pages/docs/dashboard.vue' /* webpackChunkName: "pages/docs/dashboard" */))
const _48659144 = () => interopDefault(import('../pages/docs/vue2editor.vue' /* webpackChunkName: "pages/docs/vue2editor" */))
const _edda1c70 = () => interopDefault(import('../pages/malls/add.vue' /* webpackChunkName: "pages/malls/add" */))
const _88c32a0a = () => interopDefault(import('../pages/malls/dashboard.vue' /* webpackChunkName: "pages/malls/dashboard" */))
const _42014b20 = () => interopDefault(import('../pages/malls/financial.vue' /* webpackChunkName: "pages/malls/financial" */))
const _6daab4f1 = () => interopDefault(import('../pages/malls/monitoring.vue' /* webpackChunkName: "pages/malls/monitoring" */))
const _51536bd3 = () => interopDefault(import('../pages/malls/profiles.vue' /* webpackChunkName: "pages/malls/profiles" */))
const _1626db14 = () => interopDefault(import('../pages/malls/support.vue' /* webpackChunkName: "pages/malls/support" */))
const _b16238ce = () => interopDefault(import('../pages/docs/categories/add.vue' /* webpackChunkName: "pages/docs/categories/add" */))
const _083defbf = () => interopDefault(import('../pages/malls/reports/general.vue' /* webpackChunkName: "pages/malls/reports/general" */))
const _ac912036 = () => interopDefault(import('../pages/malls/reports/operations.vue' /* webpackChunkName: "pages/malls/reports/operations" */))
const _68283f45 = () => interopDefault(import('../pages/malls/settings/users.vue' /* webpackChunkName: "pages/malls/settings/users" */))
const _645b6e29 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a2cd91f6 = () => interopDefault(import('../pages/docs/categories/_slug/index.vue' /* webpackChunkName: "pages/docs/categories/_slug/index" */))
const _11e1f5d7 = () => interopDefault(import('../pages/docs/pages/_id/index.vue' /* webpackChunkName: "pages/docs/pages/_id/index" */))
const _08311bd8 = () => interopDefault(import('../pages/docs/categories/_slug/add.vue' /* webpackChunkName: "pages/docs/categories/_slug/add" */))
const _225e0dd8 = () => interopDefault(import('../pages/docs/pages/_id/update.vue' /* webpackChunkName: "pages/docs/pages/_id/update" */))
const _0e1d0a5c = () => interopDefault(import('../pages/_entity/index.vue' /* webpackChunkName: "pages/_entity/index" */))
const _ca0028e6 = () => interopDefault(import('../pages/_entity/anomaly.vue' /* webpackChunkName: "pages/_entity/anomaly" */))
const _12c35d3c = () => interopDefault(import('../pages/_entity/arendators/index.vue' /* webpackChunkName: "pages/_entity/arendators/index" */))
const _5a7287d4 = () => interopDefault(import('../pages/_entity/contract_suspended.vue' /* webpackChunkName: "pages/_entity/contract_suspended" */))
const _1998d21e = () => interopDefault(import('../pages/_entity/dashboard.vue' /* webpackChunkName: "pages/_entity/dashboard" */))
const _f3840f8a = () => interopDefault(import('../pages/_entity/dashboard-v2.vue' /* webpackChunkName: "pages/_entity/dashboard-v2" */))
const _71591502 = () => interopDefault(import('../pages/_entity/events.vue' /* webpackChunkName: "pages/_entity/events" */))
const _8d5e5572 = () => interopDefault(import('../pages/_entity/food/index.vue' /* webpackChunkName: "pages/_entity/food/index" */))
const _219959df = () => interopDefault(import('../pages/_entity/mallCategoriesByRevenue.vue' /* webpackChunkName: "pages/_entity/mallCategoriesByRevenue" */))
const _57e35e9c = () => interopDefault(import('../pages/_entity/mallMap.vue' /* webpackChunkName: "pages/_entity/mallMap" */))
const _69fade31 = () => interopDefault(import('../pages/_entity/monitoring/index.vue' /* webpackChunkName: "pages/_entity/monitoring/index" */))
const _36ea351e = () => interopDefault(import('../pages/_entity/payment_required.vue' /* webpackChunkName: "pages/_entity/payment_required" */))
const _4adf579a = () => interopDefault(import('../pages/_entity/session_token.vue' /* webpackChunkName: "pages/_entity/session_token" */))
const _3c62ad8a = () => interopDefault(import('../pages/_entity/shops/index.vue' /* webpackChunkName: "pages/_entity/shops/index" */))
const _36008d7d = () => interopDefault(import('../pages/_entity/structure.vue' /* webpackChunkName: "pages/_entity/structure" */))
const _5401f3c8 = () => interopDefault(import('../pages/_entity/support/index.vue' /* webpackChunkName: "pages/_entity/support/index" */))
const _2ce279e5 = () => interopDefault(import('../pages/_entity/suspended.vue' /* webpackChunkName: "pages/_entity/suspended" */))
const _7b2961e2 = () => interopDefault(import('../pages/_entity/terminal.vue' /* webpackChunkName: "pages/_entity/terminal" */))
const _7a136122 = () => interopDefault(import('../pages/_entity/top/index.vue' /* webpackChunkName: "pages/_entity/top/index" */))
const _7b798c08 = () => interopDefault(import('../pages/_entity/analysis/financial.vue' /* webpackChunkName: "pages/_entity/analysis/financial" */))
const _5afccf5b = () => interopDefault(import('../pages/_entity/analysis/mallMap.vue' /* webpackChunkName: "pages/_entity/analysis/mallMap" */))
const _629ff263 = () => interopDefault(import('../pages/_entity/analytics/abc.vue' /* webpackChunkName: "pages/_entity/analytics/abc" */))
const _34f61198 = () => interopDefault(import('../pages/_entity/analytics/comparison.vue' /* webpackChunkName: "pages/_entity/analytics/comparison" */))
const _1ddb0929 = () => interopDefault(import('../pages/_entity/analytics/mallMap.vue' /* webpackChunkName: "pages/_entity/analytics/mallMap" */))
const _140560ee = () => interopDefault(import('../pages/_entity/analytics/mallMapOld.vue' /* webpackChunkName: "pages/_entity/analytics/mallMapOld" */))
const _47b31e53 = () => interopDefault(import('../pages/_entity/food/chart.vue' /* webpackChunkName: "pages/_entity/food/chart" */))
const _6d512e66 = () => interopDefault(import('../pages/_entity/reports/aboutRenter.vue' /* webpackChunkName: "pages/_entity/reports/aboutRenter" */))
const _600930a2 = () => interopDefault(import('../pages/_entity/reports/general.vue' /* webpackChunkName: "pages/_entity/reports/general" */))
const _e4edadd6 = () => interopDefault(import('../pages/_entity/reports/kkts.vue' /* webpackChunkName: "pages/_entity/reports/kkts" */))
const _6af1936e = () => interopDefault(import('../pages/_entity/reports/shopByChecks.vue' /* webpackChunkName: "pages/_entity/reports/shopByChecks" */))
const _0050281e = () => interopDefault(import('../pages/_entity/reports/shopByDate.vue' /* webpackChunkName: "pages/_entity/reports/shopByDate" */))
const _435f6cbf = () => interopDefault(import('../pages/_entity/reports/shopsByAverage.vue' /* webpackChunkName: "pages/_entity/reports/shopsByAverage" */))
const _57989a63 = () => interopDefault(import('../pages/_entity/reports/shopsByOperationType.vue' /* webpackChunkName: "pages/_entity/reports/shopsByOperationType" */))
const _71e61407 = () => interopDefault(import('../pages/_entity/reports/turnover.vue' /* webpackChunkName: "pages/_entity/reports/turnover" */))
const _1b4cdd83 = () => interopDefault(import('../pages/_entity/reports/unknownKkts.vue' /* webpackChunkName: "pages/_entity/reports/unknownKkts" */))
const _247dc248 = () => interopDefault(import('../pages/_entity/settings/accounts.vue' /* webpackChunkName: "pages/_entity/settings/accounts" */))
const _313eb3bc = () => interopDefault(import('../pages/_entity/settings/mallMap.vue' /* webpackChunkName: "pages/_entity/settings/mallMap" */))
const _29046a98 = () => interopDefault(import('../pages/_entity/settings/ocr.vue' /* webpackChunkName: "pages/_entity/settings/ocr" */))
const _0bf05264 = () => interopDefault(import('../pages/_entity/settings/positions.vue' /* webpackChunkName: "pages/_entity/settings/positions" */))
const _95cb1792 = () => interopDefault(import('../pages/_entity/settings/roles.vue' /* webpackChunkName: "pages/_entity/settings/roles" */))
const _f059e65a = () => interopDefault(import('../pages/_entity/settings/shoppingCenter.vue' /* webpackChunkName: "pages/_entity/settings/shoppingCenter" */))
const _5b5ba772 = () => interopDefault(import('../pages/_entity/settings/turnover.vue' /* webpackChunkName: "pages/_entity/settings/turnover" */))
const _bd70958a = () => interopDefault(import('../pages/_entity/settings/visitors.vue' /* webpackChunkName: "pages/_entity/settings/visitors" */))
const _fce5f4c8 = () => interopDefault(import('../pages/_entity/tmp/cascader.vue' /* webpackChunkName: "pages/_entity/tmp/cascader" */))
const _7cd0ecca = () => interopDefault(import('../pages/_entity/tmp/financial.vue' /* webpackChunkName: "pages/_entity/tmp/financial" */))
const _2e41504c = () => interopDefault(import('../pages/_entity/visitors/tendency.vue' /* webpackChunkName: "pages/_entity/visitors/tendency" */))
const _cd97321e = () => interopDefault(import('../pages/_entity/visitors/top.vue' /* webpackChunkName: "pages/_entity/visitors/top" */))
const _66b6183a = () => interopDefault(import('../pages/_entity/visitors/sensors/control.vue' /* webpackChunkName: "pages/_entity/visitors/sensors/control" */))
const _4b30208d = () => interopDefault(import('../pages/_entity/visitors/sensors/settings.vue' /* webpackChunkName: "pages/_entity/visitors/sensors/settings" */))
const _77c5a002 = () => interopDefault(import('../pages/_entity/visitors/zones/settings.vue' /* webpackChunkName: "pages/_entity/visitors/zones/settings" */))
const _14bf7ea4 = () => interopDefault(import('../pages/_entity/arendators/_id.vue' /* webpackChunkName: "pages/_entity/arendators/_id" */))
const _021aeb1c = () => interopDefault(import('../pages/_entity/shops/_id.vue' /* webpackChunkName: "pages/_entity/shops/_id" */))
const _19d6f64b = () => interopDefault(import('../pages/_entity/support/_supportTicketNumber.vue' /* webpackChunkName: "pages/_entity/support/_supportTicketNumber" */))
const _c24ddb24 = () => interopDefault(import('../pages/_entity/weekly-report/_report_key.vue' /* webpackChunkName: "pages/_entity/weekly-report/_report_key" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/api-docs",
    component: _1ec9ad87,
    name: "api-docs"
  }, {
    path: "/docs",
    component: _fe7e2192,
    name: "docs"
  }, {
    path: "/entities",
    component: _d1e04c8c,
    name: "entities"
  }, {
    path: "/login",
    component: _2e031140,
    name: "login"
  }, {
    path: "/logs",
    component: _a3145caa,
    name: "logs"
  }, {
    path: "/support-board",
    component: _5dff4202,
    name: "support-board"
  }, {
    path: "/unknown-kkts",
    component: _2e3e7284,
    name: "unknown-kkts"
  }, {
    path: "/docs/dashboard",
    component: _1bb8d179,
    name: "docs-dashboard"
  }, {
    path: "/docs/vue2editor",
    component: _48659144,
    name: "docs-vue2editor"
  }, {
    path: "/malls/add",
    component: _edda1c70,
    name: "malls-add"
  }, {
    path: "/malls/dashboard",
    component: _88c32a0a,
    name: "malls-dashboard"
  }, {
    path: "/malls/financial",
    component: _42014b20,
    name: "malls-financial"
  }, {
    path: "/malls/monitoring",
    component: _6daab4f1,
    name: "malls-monitoring"
  }, {
    path: "/malls/profiles",
    component: _51536bd3,
    name: "malls-profiles"
  }, {
    path: "/malls/support",
    component: _1626db14,
    name: "malls-support"
  }, {
    path: "/docs/categories/add",
    component: _b16238ce,
    name: "docs-categories-add"
  }, {
    path: "/malls/reports/general",
    component: _083defbf,
    name: "malls-reports-general"
  }, {
    path: "/malls/reports/operations",
    component: _ac912036,
    name: "malls-reports-operations"
  }, {
    path: "/malls/settings/users",
    component: _68283f45,
    name: "malls-settings-users"
  }, {
    path: "/",
    component: _645b6e29,
    name: "index"
  }, {
    path: "/docs/categories/:slug",
    component: _a2cd91f6,
    name: "docs-categories-slug"
  }, {
    path: "/docs/pages/:id",
    component: _11e1f5d7,
    name: "docs-pages-id"
  }, {
    path: "/docs/categories/:slug?/add",
    component: _08311bd8,
    name: "docs-categories-slug-add"
  }, {
    path: "/docs/pages/:id?/update",
    component: _225e0dd8,
    name: "docs-pages-id-update"
  }, {
    path: "/:entity",
    component: _0e1d0a5c,
    name: "entity"
  }, {
    path: "/:entity/anomaly",
    component: _ca0028e6,
    name: "entity-anomaly"
  }, {
    path: "/:entity/arendators",
    component: _12c35d3c,
    name: "entity-arendators"
  }, {
    path: "/:entity/contract_suspended",
    component: _5a7287d4,
    name: "entity-contract_suspended"
  }, {
    path: "/:entity/dashboard",
    component: _1998d21e,
    name: "entity-dashboard"
  }, {
    path: "/:entity/dashboard-v2",
    component: _f3840f8a,
    name: "entity-dashboard-v2"
  }, {
    path: "/:entity/events",
    component: _71591502,
    name: "entity-events"
  }, {
    path: "/:entity/food",
    component: _8d5e5572,
    name: "entity-food"
  }, {
    path: "/:entity/mallCategoriesByRevenue",
    component: _219959df,
    name: "entity-mallCategoriesByRevenue"
  }, {
    path: "/:entity/mallMap",
    component: _57e35e9c,
    name: "entity-mallMap"
  }, {
    path: "/:entity/monitoring",
    component: _69fade31,
    name: "entity-monitoring"
  }, {
    path: "/:entity/payment_required",
    component: _36ea351e,
    name: "entity-payment_required"
  }, {
    path: "/:entity/session_token",
    component: _4adf579a,
    name: "entity-session_token"
  }, {
    path: "/:entity/shops",
    component: _3c62ad8a,
    name: "entity-shops"
  }, {
    path: "/:entity/structure",
    component: _36008d7d,
    name: "entity-structure"
  }, {
    path: "/:entity/support",
    component: _5401f3c8,
    name: "entity-support"
  }, {
    path: "/:entity/suspended",
    component: _2ce279e5,
    name: "entity-suspended"
  }, {
    path: "/:entity/terminal",
    component: _7b2961e2,
    name: "entity-terminal"
  }, {
    path: "/:entity/top",
    component: _7a136122,
    name: "entity-top"
  }, {
    path: "/:entity/analysis/financial",
    component: _7b798c08,
    name: "entity-analysis-financial"
  }, {
    path: "/:entity/analysis/mallMap",
    component: _5afccf5b,
    name: "entity-analysis-mallMap"
  }, {
    path: "/:entity/analytics/abc",
    component: _629ff263,
    name: "entity-analytics-abc"
  }, {
    path: "/:entity/analytics/comparison",
    component: _34f61198,
    name: "entity-analytics-comparison"
  }, {
    path: "/:entity/analytics/mallMap",
    component: _1ddb0929,
    name: "entity-analytics-mallMap"
  }, {
    path: "/:entity/analytics/mallMapOld",
    component: _140560ee,
    name: "entity-analytics-mallMapOld"
  }, {
    path: "/:entity/food/chart",
    component: _47b31e53,
    name: "entity-food-chart"
  }, {
    path: "/:entity/reports/aboutRenter",
    component: _6d512e66,
    name: "entity-reports-aboutRenter"
  }, {
    path: "/:entity/reports/general",
    component: _600930a2,
    name: "entity-reports-general"
  }, {
    path: "/:entity/reports/kkts",
    component: _e4edadd6,
    name: "entity-reports-kkts"
  }, {
    path: "/:entity/reports/shopByChecks",
    component: _6af1936e,
    name: "entity-reports-shopByChecks"
  }, {
    path: "/:entity/reports/shopByDate",
    component: _0050281e,
    name: "entity-reports-shopByDate"
  }, {
    path: "/:entity/reports/shopsByAverage",
    component: _435f6cbf,
    name: "entity-reports-shopsByAverage"
  }, {
    path: "/:entity/reports/shopsByOperationType",
    component: _57989a63,
    name: "entity-reports-shopsByOperationType"
  }, {
    path: "/:entity/reports/turnover",
    component: _71e61407,
    name: "entity-reports-turnover"
  }, {
    path: "/:entity/reports/unknownKkts",
    component: _1b4cdd83,
    name: "entity-reports-unknownKkts"
  }, {
    path: "/:entity/settings/accounts",
    component: _247dc248,
    name: "entity-settings-accounts"
  }, {
    path: "/:entity/settings/mallMap",
    component: _313eb3bc,
    name: "entity-settings-mallMap"
  }, {
    path: "/:entity/settings/ocr",
    component: _29046a98,
    name: "entity-settings-ocr"
  }, {
    path: "/:entity/settings/positions",
    component: _0bf05264,
    name: "entity-settings-positions"
  }, {
    path: "/:entity/settings/roles",
    component: _95cb1792,
    name: "entity-settings-roles"
  }, {
    path: "/:entity/settings/shoppingCenter",
    component: _f059e65a,
    name: "entity-settings-shoppingCenter"
  }, {
    path: "/:entity/settings/turnover",
    component: _5b5ba772,
    name: "entity-settings-turnover"
  }, {
    path: "/:entity/settings/visitors",
    component: _bd70958a,
    name: "entity-settings-visitors"
  }, {
    path: "/:entity/tmp/cascader",
    component: _fce5f4c8,
    name: "entity-tmp-cascader"
  }, {
    path: "/:entity/tmp/financial",
    component: _7cd0ecca,
    name: "entity-tmp-financial"
  }, {
    path: "/:entity/visitors/tendency",
    component: _2e41504c,
    name: "entity-visitors-tendency"
  }, {
    path: "/:entity/visitors/top",
    component: _cd97321e,
    name: "entity-visitors-top"
  }, {
    path: "/:entity/visitors/sensors/control",
    component: _66b6183a,
    name: "entity-visitors-sensors-control"
  }, {
    path: "/:entity/visitors/sensors/settings",
    component: _4b30208d,
    name: "entity-visitors-sensors-settings"
  }, {
    path: "/:entity/visitors/zones/settings",
    component: _77c5a002,
    name: "entity-visitors-zones-settings"
  }, {
    path: "/:entity/arendators/:id",
    component: _14bf7ea4,
    name: "entity-arendators-id"
  }, {
    path: "/:entity/shops/:id",
    component: _021aeb1c,
    name: "entity-shops-id"
  }, {
    path: "/:entity/support/:supportTicketNumber",
    component: _19d6f64b,
    name: "entity-support-supportTicketNumber"
  }, {
    path: "/:entity/weekly-report/:report_key?",
    component: _c24ddb24,
    name: "entity-weekly-report-report_key"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
