<template>
	<div
		v-show="isHoverItemContainer && !sideMenuConfigVue.isMenuActive"
		ref="refContainer"
		:class="[$style.container, 'p-2']"
		:style="style"
	>
		<MenuItemChildOneLink
			:menu-side-item="menuSideItem"
		></MenuItemChildOneLink>
	</div>
</template>

<script>
	import MenuItemChildOneLink from '@/components/menu/menuSide/MenuItemChildOneLink.vue'

	import { MenuSideItem } from '@/assets/js/sideMenu/MenuSideItem.js'
	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'

	export default {
		components: {
			MenuItemChildOneLink,
		},
		props: {
			menuSideItem: { type: MenuSideItem, default: () => (new MenuSideItem()) },
			isHoverItemContainer: { type: Boolean, default: false },
			top: { type: Number, default: 0 },
			left: { type: Number, default: 0 },
		},
		data() {
			return {
				sideMenuConfigVue,
				topCorrected: this.top,
			}
		},
		computed: {
			style() {
				return {
					top: `${this.topCorrected}px`,
					left: `${this.left}px`,
				}
			},
		},
		watch: {
			isHoverItemContainer() {
				if (!this.isHoverItemContainer) {
					return
				}

				this.topCorrected = this.top
				this.correctPosition()
			},
		},
		methods: {
			correctPosition() {
				this.topCorrected -= 5
				this.$nextTick(() => {
					const refContainer = this.$refs.refContainer

					const documentHeight = document.documentElement.clientHeight
					// const documentWidth = document.documentElement.clientWidth

					const rect = refContainer.getBoundingClientRect()
					const diffBottom = documentHeight - rect.bottom
					if (diffBottom < 0) {
						this.topCorrected = rect.top + diffBottom
					}
					if (rect.top < 52) {
						this.topCorrected = 52
					}
				})
			},
		},
	}
</script>

<style lang="scss" module>
	.container {
		position: fixed;
		z-index: 1030;
		top: 0;
		left: 0;
		background-color: var(--white);
		border-radius: 6px;
		box-shadow: 0 0 3rem 0 rgb(136 152 170 / 25%);
	}
</style>
