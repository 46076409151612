import { getTypeValInfo } from '@/assets/js/check/parts/getTypeValInfo.js'

const getCorrectTypeValKit = (configKit) => {
	const configKitInfo = getTypeValInfo(configKit, Object)
	const configCorrectKit = configKitInfo.valCorrect
	if (!configKitInfo.isValidValueType || !configKitInfo.isValidValue) {
		// eslint-disable-next-line no-console
		console.error(new Error('configKit error'), configKitInfo)
	}

	const resultKit = {}
	Object.entries(configCorrectKit).forEach(([resultFieldName, configList]) => {
		const configListInfo = getTypeValInfo(configList, Array)
		const info = { resultKit, resultFieldName, configList }
		if (!configListInfo.isValidValueType || !configListInfo.isValidValue) {
			// eslint-disable-next-line no-console
			console.error(new Error('configList error'), configListInfo, info)
		}

		const typeValInfo = getTypeValInfo(configListInfo.valCorrect[0], configListInfo.valCorrect[1])
		resultKit[resultFieldName] = typeValInfo.valCorrect
		if (!typeValInfo.isValidValueType || !typeValInfo.isValidValue) {
			// eslint-disable-next-line no-console
			console.error(new Error('typeVal error'), typeValInfo, info)
		}
	})

	return resultKit
}

export { getCorrectTypeValKit }
