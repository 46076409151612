const kit = {
	checkLogin: ({ login, password }) => {
		const url = '/check-credentials'
		const params = { session: { login, password } }
		return window.$nuxt.$axios.$post(url, params)
	},

	resetPasswordGetOtp: ({ phone }) => {
		const url = '/reset-password'
		const params = {
			phone,
			state: 'get_otp',
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	accountActivationCheckPhone: ({ phone }) => {
		const url = '/api/v2/users/by_phone'
		const params = {
			phone,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	userActivation: ({ phone, otp, password }) => {
		const url = '/api/v2/users/activation'
		const params = {
			phone,
			otp,
			password,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	resetPasswordCheckOtp: ({ phone, otp }) => {
		const url = '/reset-password'
		const params = {
			phone,
			otp,
			state: 'check_otp',
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	resetPasswordChangePassword: ({ phone, passwordNew }) => {
		const url = '/reset-password'
		const params = {
			phone,
			state: 'apply_change',
			new_password: passwordNew,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	updatePassword: ({ passwordNew, otp }) => {
		const url = '/update-password'
		const params = { new: passwordNew, otp }
		return window.$nuxt.$axios.$post(url, params)
	},

	updatePhone: ({ phoneNew, otp }) => {
		const url = '/update-phone'
		const params = { new: phoneNew, otp }
		return window.$nuxt.$axios.$post(url, params)
	},

	telegramShow: () => {
		const url = '/telegram/show'
		return window.$nuxt.$axios.$get(url)
	},
	telegramGenerate: () => {
		const url = '/telegram/generate'
		return window.$nuxt.$axios.$patch(url)
	},
}

export default kit
