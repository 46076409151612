import { format } from 'date-fns'

const getCleanParams = (params) => {
	const objectNew = {}
	Object.entries(params).forEach(([key, val]) => {
		if (val === undefined || val === '' || (val?.length === 0)) {
			return
		}
		objectNew[key] = val
	})
	return objectNew
}

const queryLogsKit = {
	crossLogList: ({
		shopName,
		tenantLegalName,
		tenantInn,
		typeList,
		mallIdList,
		authIdList,
		dateRange,
		perPage,
		pageNumber,
	}) => {
		const url = '/api/v2/cross-logs'
		const params = {
			params: {
				sell_location_name: shopName,
				tenant_legal_name: tenantLegalName,
				tenant_inn: tenantInn,
				types: typeList,
				sc_ids: mallIdList,
				author_ids: authIdList,
				per_page: perPage,
				page: pageNumber,
			},
		}
		params.params.start_date = dateRange && dateRange[0] ? format(new Date(dateRange[0]), 'yyyy-MM-dd') : undefined
		params.params.end_date = dateRange && dateRange[1] ? format(new Date(dateRange[1]), 'yyyy-MM-dd') : undefined

		params.params = getCleanParams(params.params)
		return window.$nuxt.$axios.$get(url, params)
		// response: { total: 123, total_pages: 3, limit_value: 50, current_page 1, logs: [] }
	},

	crossSearchInIssueTracks: ({
		mallIdList,
		userIdList,
		dateRange,
		perPage,
		pageNumber,
	}) => {
		const url = '/api/v2/cross-search-in-issue-tracks'
		const params = {
			params: {
				sc_ids: mallIdList,
				user_ids: userIdList,
				per_page: perPage,
				page: pageNumber,
			},
		}
		params.params.start_date = dateRange && dateRange[0] ? format(new Date(dateRange[0]), 'yyyy-MM-dd') : undefined
		params.params.end_date = dateRange && dateRange[1] ? format(new Date(dateRange[1]), 'yyyy-MM-dd') : undefined

		params.params = getCleanParams(params.params)
		return window.$nuxt.$axios.$get(url, params)
		// response: { current_page: 1, limit_value: 50, total: 928, total_pages: 19, history_tracks: []
	},

	crossSearchInCommentTracks: ({
		mallIdList,
		userIdList,
		dateRange,
		perPage,
		pageNumber,
	}) => {
		const url = '/api/v2/cross-search-in-comment-tracks'
		const params = {
			params: {
				sc_ids: mallIdList,
				user_ids: userIdList,
				per_page: perPage,
				page: pageNumber,
			},
		}
		params.params.start_date = dateRange && dateRange[0] ? format(new Date(dateRange[0]), 'yyyy-MM-dd') : undefined
		params.params.end_date = dateRange && dateRange[1] ? format(new Date(dateRange[1]), 'yyyy-MM-dd') : undefined

		params.params = getCleanParams(params.params)
		return window.$nuxt.$axios.$get(url, params)
	},

	authorsNameList: () => {
		const url = '/api/v2/users'
		return window.$nuxt.$axios.$get(url)
	},


	logList: ({ shoppingCenterId, sellLocationId, pageNumber }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_location/${sellLocationId}/logs`
		const params = {
			params: {
				logs_type: 'sell_location',
				page: pageNumber,
				per_page: 20,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	logRenterCreate: ({ shoppingCenterId, renterId, logType, logContent }) => {
		const url = '/logs'
		const params = {
			entity_id: shoppingCenterId,
			arendator_id: renterId,
			type: logType,
			content: logContent,
			page: undefined,
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	logSellLocationCreate: ({ shoppingCenterId, sellLocationId, logType, logContent }) => {
		const url = '/logs'
		const params = {
			entity_id: shoppingCenterId,
			sell_location_id: sellLocationId,
			type: logType,
			content: logContent,
			page: 'sell_location',
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	logRenterByPageList: ({ shoppingCenterId, renterId, pageNumber }) => {
		const url = '/logs-by-page'
		const params = {
			params: {
				entity_id: shoppingCenterId,
				arendator_id: renterId,
				page: pageNumber,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	logSellLocationByPageList: ({ shoppingCenterId, sellLocationId, pageNumber }) => {
		const url = '/logs-by-page'
		const params = {
			params: {
				entity_id: shoppingCenterId,
				sell_location_id: sellLocationId,
				page: pageNumber,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
}

export { queryLogsKit }
