<template>
	<Modal :show="isShow" :size="'lg'" @close="emitCloseModal()">
		<div>
			<div v-if="addbutton" class="row mb-3 gap-2 no-gutters">
				<div class="col">
					<NewButton
						:text="translate('common.downloadFileBtn')"
						icon="fas fa-file-download g-fs-18"
						:fluid="true"
						btn-class="btn-outline-success"
						@onClick="downloadFile(file)"
					></NewButton>
				</div>
				<div class="col">
					<NewButton
						:tooltip="disabledAddFileToShopButton ? translate('modalFilePreview.selectShopFirstTooltip') : '' "
						:disabled="disabledAddFileToShopButton"
						:fluid="true"
						:text="translate('common.addToShop')"
						icon="fa-regular fa-shop g-fs-18"
						:btn-class="disabledAddFileToShopButton ? 'btn-outline-default' : 'btn-outline-warning'"
						@onClick="addFileToShop(file)"
					></NewButton>
				</div>
			</div>
			<div v-else class="mb-3">
				<NewButton
					:text="translate('common.downloadFileBtn')"
					icon="fas fa-file-download g-fs-18"
					:fluid="true"
					btn-class="btn-outline-success"
					@onClick="downloadFile(file)"
				></NewButton>
			</div>

			<template v-if="format === 'pdf'">
				<BlockWithLoader :is-show-loader="!isPdfLoaded">
					<div :class="$style.previewPdfContainer">
						<pdf
							v-for="i in numPagesPdf"
							:key="i"
							:class="$style.previewPdfItem"
							:src="srcPdf"
							:page="i"
							@page-loaded="onPdfPageLoaded(i)"
						></pdf>
					</div>
				</BlockWithLoader>
			</template>
			<template v-else-if="format === 'png' || format === 'jpg' || format === 'jpeg'">
				<img :class="$style.previewImg" :src="file.url" width="100%" />
			</template>
			<template v-else>
				<h2 class="text-center mt-4">{{ translate('modalFilePreview.previewUnavailable') }}</h2>
			</template>
		</div>
	</Modal>
</template>

<script>
	import pdf from '@teckel/vue-pdf'
	import Modal from '@/components/argon-core/Modal.vue'
	import NewButton from '@/components/other/NewButton.vue'
	import BlockWithLoader from '@/components/other/BlockWithLoader.vue'

	import { getPromiseQuery } from '@/assets/js/query/getPromiseQuery.js'
	import { getRouterMallId } from '@/assets/js/routerHelper.js'
	import { translate } from '@/assets/i18n/i18nextConfig.js'

	export default {
		components: {
			Modal,
			NewButton,
			pdf,
			BlockWithLoader,
		},
		props: {
			isShow: { type: Boolean, default: false },
			format: { type: String, default: '' },
			file: { type: Object, default: () => {} },
			resourceId: { type: String, default: null },
			addbutton: { type: Boolean, default: false },
			mallId: { type: String, default: null },
		},
		data() {
			return {
				disabledAddFileToShopButton: this.resourceId === '' || this.resourceId === null,
				shopCurrent: null,
				srcPdf: null,
				numPagesPdf: null,
				pdfPageNumberList: [],
				isPdfLoaded: false,
			}
		},
		watch: {
			isShow: {
				immediate: true,
				handler() {
					if (this.isShow) {
						this.entity = this.data
						this.getShop()
					}
				},
			},
			file: {
				immediate: true,
				handler() {
					if (!this.file || this.format !== 'pdf') {
						this.numPagesPdf = null
						return
					}
					const loadingTask = pdf.createLoadingTask(this.file.url)
					this.srcPdf = loadingTask
					this.srcPdf.promise.then((pdfFile) => {
						this.numPagesPdf = pdfFile.numPages
					})
				},
			},
		},
		methods: {
			translate,
			getShop() {
				if (this.resourceId === '' || this.resourceId === null || this.resourceId === getRouterMallId()) {
					return
				}
				const shoppingCenterId = this.mallId || getRouterMallId()
				getPromiseQuery({
					sellLocationInfo: { shoppingCenterId, sellLocationId: this.resourceId },
				})
					.then((response) => {
						this.shopCurrent = response.sell_location
					})
			},
			emitCloseModal() {
				document.body.classList.remove('modal-open')
				this.$emit('closeModal')
				this.clearPdfInfo()
			},
			downloadFile(file) {
				getPromiseQuery({
					downloadFile: { fileUrl: file.url },
				})
					.then((response) => {
						const url = window.URL.createObjectURL(new Blob([response]))
						const link = document.createElement('a')
						link.href = url
						link.setAttribute('download', `${file.name}.${file.format}`)
						link.click()
						window.URL.revokeObjectURL(url)
					})
			},
			addFileToShop(file) {
				this.disabledButton = true
				getPromiseQuery({
					fileResourcesReplicate: {
						shopId: this.resourceId,
						fileId: file.id,
					},
				}).then((response) => {
					this.$notify({
						message: `${translate('common.fileAddedToShop')} ${this.shopCurrent.name}`,
						type: 'success',
					})
					this.disabledButton = false
				})
			},
			onPdfPageLoaded(page) {
				this.pdfPageNumberList.push(page)
				if (this.numPagesPdf <= this.pdfPageNumberList.length) {
					this.isPdfLoaded = true
				}
			},
			clearPdfInfo() {
				this.srcPdf = null
				this.numPagesPdf = null
				this.pdfPageNumberList = []
				this.isPdfLoaded = false
			},
		},
	}
</script>

<style lang="scss" module>
	.previewPdfContainer {
		overflow: scroll;
		width: 100%;
		height: 400px;
		border: 0;
	}

	.previewPdfItem {
		display: flex;
		flex-direction: column;
		border: 1px solid var(--gray-700);
	}

	.previewImg {
		width: 100%;
	}
</style>
