var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
		'modal fade',
		_vm.show ? 'show d-block' : 'd-none',
		_vm.show ? _vm.$style.substrate : '',
		{ 'modal-mini': _vm.type === 'mini' }
	],attrs:{"tabindex":"-1","role":"dialog","aria-hidden":!_vm.show},on:{"mousedown":function($event){if($event.target !== $event.currentTarget)return null;return _vm.closeModal()}}},[_c('div',{class:[
			'modal-dialog modal-dialog-centered',
			_vm.modalClasses,
			{
				'modal-notice': _vm.type === 'notice',
				[`modal-${_vm.size}`]: _vm.size,
				'modal-dialog-scrollable': _vm.isScrollable,
				'max-height': _vm.maximizeHeight
			},
			_vm.show ? _vm.$style.showCustom : '',
		],style:({ maxWidth: `min(${_vm.sizePercent}%, 1600px)` }),on:{"mouseleave":function($event){return _vm.hoverCloseButton(true)},"mouseenter":function($event){return _vm.hoverCloseButton(false)}}},[_c('div',{class:[
				'modal-content',
				_vm.modalContentClasses,
				_vm.gradient ? `bg-gradient-${_vm.gradient}` : '',
			]},[(_vm.$slots.header)?_c('div',{class:['modal-header', _vm.headerClasses]},[_vm._t("header")],2):_vm._e(),_vm._v(" "),_vm._t("close-button",function(){return [(_vm.showClose)?_c('button',{staticClass:"close g-fs-20",class:{'hovered': _vm.closeHovered},attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.closeModal()}}},[_c('i',{staticClass:"fa-solid fa-xmark"})]):_vm._e()]}),_vm._v(" "),_c('div',{staticClass:"modal-body",class:_vm.bodyClasses},[_vm._t("default")],2),_vm._v(" "),(_vm.$slots.footer)?_c('div',{staticClass:"modal-footer",class:_vm.footerClasses},[_vm._t("footer")],2):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }