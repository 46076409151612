import { getPromiseQuery } from '@/assets/js/query/getPromiseQuery.js'

const cacheTimeLiveMsDefault = 1000 * 60 * 5

const cachePromiseKit = {}
const cacheTimeoutIDKit = {}


const getCacheKey = (name, paramKit) => {
	const paramSortedKit = Object.fromEntries(Object.entries(paramKit).sort())
	const cacheKey = JSON.stringify([name, paramSortedKit])
	return cacheKey
}

const resetCacheTimeLive = (cacheKey) => {
	clearTimeout(cacheTimeoutIDKit[cacheKey])
}

const clearCachePromise = (cacheKey) => {
	delete cachePromiseKit[cacheKey]
	resetCacheTimeLive(cacheKey)
}

const startCacheTimeLive = (cacheKey) => {
	resetCacheTimeLive(cacheKey)
	cacheTimeoutIDKit[cacheKey] = setTimeout(() => {
		clearCachePromise(cacheKey)
	}, cacheTimeLiveMsDefault)
}

const getIsCachePromiseLive = (cacheKey) => {
	return cachePromiseKit[cacheKey] !== undefined
}

const saveCachePromise = (cacheKey, cachePromise) => {
	cachePromiseKit[cacheKey] = cachePromise
	startCacheTimeLive(cacheKey)
}

const getCachePromise = (cacheKey) => {
	return cachePromiseKit[cacheKey]
}


const getPromiseCache = (queryName, queryParamKit) => {
	const cacheKey = getCacheKey(queryName, queryParamKit)
	const isCachePromiseLive = getIsCachePromiseLive(cacheKey)
	if (isCachePromiseLive) {
		return getCachePromise(cacheKey)
	}

	const cachePromise = getPromiseQuery({ [queryName]: queryParamKit })
	saveCachePromise(cacheKey, cachePromise)
	return cachePromise
}

const getPromiseCacheReset = (queryName, queryParamKit) => {
	const cacheKey = getCacheKey(queryName, queryParamKit)
	clearCachePromise(cacheKey)
	return getPromiseCache(queryName, queryParamKit)
}

const getIsCacheLive = (queryName, queryParamKit) => {
	const cacheKey = getCacheKey(queryName, queryParamKit)
	const isCachePromiseLive = getIsCachePromiseLive(cacheKey)
	return isCachePromiseLive
}

const clearCache = (queryName, queryParamKit) => {
	const cacheKey = getCacheKey(queryName, queryParamKit)
	clearCachePromise(cacheKey)
}

const clearAll = () => {
	Object.keys(cachePromiseKit).forEach((cacheKey) => {
		clearCachePromise(cacheKey)
	})
}

export { getPromiseCache, getPromiseCacheReset, getIsCacheLive, clearCache, clearAll }
