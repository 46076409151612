<template>
	<div v-clickOutside="closeDropDown" :class="[$style.container]">
		<div :class="[$style.togglerContainer]" @click="onTogglerClick()">
			<slot name="toggler">
				<div :class="[$style.togglerExample]">
					<i class="fa-regular fa-ellipsis g-fs-20"></i>
				</div>
			</slot>
		</div>
		<div
			:class="[
				$style.listContainer,
				isShowListContainer ? '' : $style.listContainerHide,
				positionCssClass,
			]"
		>
			<div
				v-for="(itemKit, itemKitIndex) in itemList"
				:key="itemKitIndex"
				:class="[
					$style.item,
					itemKit.isDisabled ? $style.itemDisabled : '',
				]"
				@click="onItemClick(itemKit)"
			>
				<slot
					:itemKit="itemKit"
					:itemKitIndex="itemKitIndex"
					:isDisabled="itemKit.isDisabled"
				>
					{{ itemKit }}
				</slot>
			</div>
		</div>
	</div>
</template>
<script>
	import { directiveClickOutsideKit } from '@/assets/js/vueDirectives/directiveClickOutsideKit.js'

	const positionList = ['top left', 'top right', 'bottom left', 'bottom right']
	export default {
		directives: {
			clickOutside: directiveClickOutsideKit,
		},
		props: {
			itemList: { type: Array, default: () => ([]), required: true },
			positionDropdown: {
				type: String,
				default: 'bottom right',
				validator: (val) => positionList.includes(val),
			},
		},
		data() {
			return {
				isShowListContainer: false,
			}
		},
		computed: {
			positionCssClass() {
				const kit = {
					'top left': this.$style.listContainerTopLeft,
					'top right': this.$style.listContainerTopRight,
					'bottom left': this.$style.listContainerBottomLeft,
					'bottom right': this.$style.listContainerBottomRight,
				}
				return kit[this.positionDropdown] ?? kit[positionList[3]]
			},
		},
		methods: {
			closeDropDown() {
				if (this.isShowListContainer) {
					this.isShowListContainer = false
				}
			},
			onTogglerClick() {
				this.isShowListContainer = !this.isShowListContainer
				this.$emit('onToggle', this.isShowListContainer)
			},
			onItemClick() {
				this.isShowListContainer = !this.isShowListContainer
				this.$emit('onToggle', this.isShowListContainer)
			},
		},
	}
</script>
<style lang="scss" module>
	.container {
		position: relative;
		display: inline-block;
	}

	.togglerContainer {
		cursor: pointer;
	}

	.listContainer {
		position: absolute;
		z-index: 1500;
		padding: 8px 0;
		border: 0 solid rgba(0, 0, 0, 0.15);
		background-color: #fff;
		border-radius: 7px;
		box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
	}

	.listContainerHide {
		display: none;
	}

	.listContainerTopLeft {
		bottom: 100%;
		left: 0;
	}

	.listContainerTopRight {
		right: 0;
		bottom: 100%;
	}

	.listContainerBottomLeft {
		top: 100%;
		left: 0;
	}

	.listContainerBottomRight {
		top: 100%;
		right: 0;
	}

	.itemDisabled {
		pointer-events: none;
	}

	.togglerExample {
		display: flex;
		height: 20px;
		align-items: center;
		justify-content: center;
		padding: 4px;
		border-radius: 4px;

		&:hover {
			background-color: #e2e1e1;
		}
	}
</style>
