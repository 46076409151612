import { format } from 'date-fns'

const kit = {
	miscAcceptXls: ({ ofd, kkt, tempFilePath }) => {
		const url = '/misc/accept_xls'
		const params = { ofd, kkt, temp_file_path: tempFilePath }
		return window.$nuxt.$axios.$post(url, params)
	},
	miscRenterXls: ({ formData }) => {
		const url = '/misc/renter_xls'
		return window.$nuxt.$axios.$post(url, formData)
	},


	indiciesBySellLocation: ({ shoppingCenterId, sellLocationId }) => {
		const url = `indicies/by-sell-location/${sellLocationId}`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	indiciesBySellLocationMonthly: ({ shoppingCenterId, sellLocationId }) => {
		const url = `indicies/by-sell-location/${sellLocationId}/monthly`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},


	topByHour: ({ shoppingCenterId, date, hours }) => {
		const url = 'top-by-the-hour'
		const params = { params: { entity_id: shoppingCenterId, date, hours } }
		return window.$nuxt.$axios.$get(url, params)
	},
	topByDay: ({ shoppingCenterId, date }) => {
		const url = 'top-by-day'
		const params = { params: { entity_id: shoppingCenterId, date } }
		return window.$nuxt.$axios.$get(url, params)
	},


	floorCreate: ({ mallId, level, name, rentAreaSize, expectedQuantity }) => {
		const url = '/floors'
		const params = {
			entity_id: mallId,
			level,
			name,
			rent_area_size: rentAreaSize,
			expected_quantity: expectedQuantity,
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	floorUpdate: ({ mallId, floorId, level, name, rentAreaSize, expectedQuantity }) => {
		const url = `/floors/${floorId}`
		const params = {
			entity_id: mallId,
			level,
			name,
			rent_area_size: rentAreaSize,
			expected_quantity: expectedQuantity,
		}
		return window.$nuxt.$axios.$put(url, params)
	},
	floorDelete: ({ mallId, floorId }) => {
		const url = `/floors/${floorId}/delete`
		const params = {
			params: { entity_id: mallId },
		}
		return window.$nuxt.$axios.$delete(url, params)
	},


	linkRole: ({ shoppingCenterId, name, email, phone, otp, otpNumber, role, isUserUpdate }) => {
		const url = '/link-role'
		const params = {
			entity_id: shoppingCenterId,
			name,
			role,
			phone,
			otp,
			otp_number: otpNumber,
			email,
			update_user: isUserUpdate,
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	unlinkRole: ({ shoppingCenterId, userId, roleId }) => {
		const url = '/unlink-role'
		const params = { entity_id: shoppingCenterId, userId, roleId }
		return window.$nuxt.$axios.$post(url, params)
	},
	generateOtpForRenter: ({ phone }) => {
		const url = '/generate-otp-for-renter'
		const params = { phone }
		return window.$nuxt.$axios.$post(url, params)
	},
	generateOtp: ({ phone }) => {
		const url = '/generate-otp'
		const params = { phone }
		return window.$nuxt.$axios.$post(url, params)
	},
	generateOtpPassword: () => {
		const url = '/generate-otp'
		return window.$nuxt.$axios.$post(url)
	},

	submitConnect: ({ email, phone, comment }) => {
		const url = '/api/v2/submit_connect'
		const params = { email, phone, comment }
		return window.$nuxt.$axios.$post(url, params)
	},

	entityAccounts: ({ shoppingCenterId }) => {
		const url = '/entities/accounts'
		const params = { params: { id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},

	entityAbcXyz: ({ shoppingCenterId, abcTop, abcBottom, xyzTop, xyzBottom }) => {
		const url = `/entities/${shoppingCenterId}`
		const data = { abc_top: abcTop, abc_bottom: abcBottom, xyz_top: xyzTop, xyz_bottom: xyzBottom }
		const params = {
			params: {
				entity_id: shoppingCenterId,
			},
		}
		return window.$nuxt.$axios.$put(url, data, params)
	},

	entityArea: ({ mallId, rentAreaSize, totalAreaSize, expectedQuantity }) => {
		const url = `/entities/${mallId}`
		const params = {
			params: {
				entity_id: mallId,
			},
			rent_area_size: rentAreaSize,
			total_area_size: totalAreaSize,
			expected_quantity: expectedQuantity,
		}
		return window.$nuxt.$axios.$put(url, params)
	},

	entityUpdate: ({ mallId, title, address, contactName, phone, email, externalId, defaultFloorId }) => {
		const url = `/api/v2/shopping_centers/${mallId}`
		const params = {
			title,
			address,
			contact_name: contactName,
			phone,
			email,
			external_id: externalId,
			default_floor_id: defaultFloorId,
		}
		return window.$nuxt.$axios.$put(url, params)
	},

	getStaffNote: ({ mallId }) => {
		const url = `/api/v2/shopping_centers/${mallId}/staff_note_show`
		return window.$nuxt.$axios.$get(url)
	},

	staffNoteUpdate: ({ mallId, staffNote }) => {
		const url = `/api/v2/shopping_centers/${mallId}/staff_note_update`
		const params = {
			staff_note: staffNote,
		}
		return window.$nuxt.$axios.$put(url, params)
	},

	entityAbcXyzParams: ({ shoppingCenterId }) => {
		const url = `/entities/${shoppingCenterId}/abc-xyz-params`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},

	entityLessorsCreate: ({ shoppingCenterId, lessors }) => {
		const url = '/lessors'
		const params = { lessors, entity_id: shoppingCenterId }
		return window.$nuxt.$axios.$put(url, params)
	},

	fileResources: ({ resourceId }) => {
		const url = '/api/v2/file_resources'
		const params = { params: { resource_id: resourceId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	fileResourcesCreate: ({ resourceId, fileName, file, resourceType }) => {
		const url = '/api/v2/file_resources'
		const formData = new FormData()
		formData.append(fileName, file, file.name)
		formData.append('resource_type', resourceType)
		formData.append('resource_id', resourceId)
		return window.$nuxt.$axios.$post(url, formData)
	},
	fileResourcesUpdate: ({ fileId, fileName, contentType, description }) => {
		const url = `/api/v2/file_resources/${fileId}`
		const params = {
			name: fileName,
			content_type: contentType,
			description,
		}
		return window.$nuxt.$axios.$put(url, params)
	},
	fileResourcesDelete: ({ fileId }) => {
		const url = `/api/v2/file_resources/${fileId}`
		return window.$nuxt.$axios.$delete(url)
	},
	fileResourcesReplicate: ({ shopId, fileId }) => {
		const url = `/api/v2/file_resources/${fileId}/replicate`
		const params = { resource_id: shopId, resource_type: 'shop' }
		return window.$nuxt.$axios.$post(url, params)
	},

	sectorCreate: ({ mallId, sectorName, lessorId }) => {
		const url = '/sectors'
		const params = { entity_id: mallId, name: sectorName, lessor_id: lessorId }
		return window.$nuxt.$axios.$post(url, params)
	},
	sectorUpdate: ({ mallId, sectorId, sectorName, lessorId }) => {
		const url = `/sectors/${sectorId}`
		const params = { entity_id: mallId, name: sectorName, lessor_id: lessorId }
		return window.$nuxt.$axios.$put(url, params)
	},
	sectorDelete: ({ mallId, sectorId }) => {
		const url = `/sectors/${sectorId}/delete`
		const params = { params: { entity_id: mallId } }
		return window.$nuxt.$axios.$delete(url, params)
	},

	correspondencesSendLetterKktsList: ({ shoppingCenterId, cc, to, message }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/correspondences/send_letter_kkts_list`
		const params = { cc, to, message }
		return window.$nuxt.$axios.$post(url, params)
	},

	setContractAgreementState: ({ shoppingCenterId, sellLocationId, contractId, agreementState }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_location/${sellLocationId}/contracts/${contractId}`
		const params = { contract: { agreement_state: agreementState } }
		return window.$nuxt.$axios.$put(url, params)
	},
	manualDataMonthGet: ({ shoppingCenterId, sellLocationId, monthId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}/manual_daily_data/${monthId}`
		return window.$nuxt.$axios.$get(url)
	},
	manualSlds: ({ shoppingCenterId, sellLocationId, manualData }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}/manual/slds`
		const params = { manual_data: manualData }
		return window.$nuxt.$axios.$post(url, params)
	},

	mallCategoriesByFloor: ({ shoppingCenterId }) => {
		const url = '/mall_categories_by_floor'
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	mallByRatingAndRentType: ({ shoppingCenterId }) => {
		const url = '/mall-by-rating-and-rent-type'
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},

	dashboardTest: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/dashboard/test`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},

	horeca: ({ shoppingCenterId, sellLocationId, dateRange }) => {
		const url = '/horeca'
		const params = {
			params: {
				entity_id: shoppingCenterId,
				shop: sellLocationId,
				begin: dateRange[0],
				end: dateRange[1],
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	horecaXlsx: ({ shoppingCenterId, sellLocationId, dateRange }) => {
		const url = '/horeca'
		const params = {
			params: {
				entity_id: shoppingCenterId,
				shop: sellLocationId,
				begin: dateRange[0],
				end: dateRange[1],
				type: 'xlsx',
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	inactiveKkts: ({ mallId }) => {
		const url = `/api/v2/sc/${mallId}/monitoring/inactive_kkts`
		return window.$nuxt.$axios.$get(url)
	},

	inactiveShops: ({ mallId }) => {
		const url = `/api/v2/sc/${mallId}/monitoring/inactive_sell_locations`
		return window.$nuxt.$axios.$get(url)
	},

	onlineConnections: ({ shoppingCenterId, beginDate, endDate }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/online_connections`
		const params = {
			params: {
				begin_date: beginDate,
				end_date: endDate,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	byApprovalStatus: ({ shoppingCenterId, compareDate }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/by_approval_status`
		const params = {
			params: { compare_date: compareDate },
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	SellLocationsStatusesList: ({ shoppingCenterId, type }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/sell_locations_list_by_status`
		const params = {
			params: { status: type },
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	connectionsChartKkts: ({ shoppingCenterId, beginDate, endDate }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/connections_chart/kkts`
		const params = {
			params: {
				begin_date: beginDate,
				end_date: endDate,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	connectionsChartSellLocations: ({ shoppingCenterId, beginDate, endDate }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/connections_chart/sell_locatons`
		const params = {
			params: {
				begin_date: beginDate,
				end_date: endDate,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	penetrationPercentage: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/connections_ratio`
		return window.$nuxt.$axios.$get(url)
	},

	turnoverSettings: ({ mallId }) => {
		const url = `/api/v2/sc/${mallId}/turnover_settings`
		return window.$nuxt.$axios.$get(url)
	},
	turnoverSettingsUpdate: ({ mallId, settings, lastRecalculationDate }) => {
		const url = `/entities/${mallId}`
		const params = {
			entity: {
				turnover_setting_attributes: {
					...settings,
					last_recalculation_date: format(new Date(lastRecalculationDate), 'yyyy-MM-dd'),
				},
			},
		}
		return window.$nuxt.$axios.$patch(url, params)
	},
	turnoverSettingsSync: ({ mallId, shopIdList, recalculationDate }) => {
		const url = `/api/v2/sc/${mallId}/sync_turnover_settings`
		const params = {
			sell_location_ids: shopIdList,
			last_recalculation_date: recalculationDate,
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	restrictionsLevelUpdate: ({ mallId, restrictionsLevel }) => {
		const url = `/api/v2/shopping_centers/${mallId}/limitations`
		const params = {
			level_of_restrictions: restrictionsLevel,
		}
		return window.$nuxt.$axios.$put(url, params)
	},

}

export default kit
