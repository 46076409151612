<template>
	<div
		v-if="isHoverItemContainer"
		ref="refContainer"
		:class="[
			$style.container,
			'p-2 pb-3',
			'd-flex flex-column gap-1'
		]"
		:style="style"
		@mouseover="onMouseOver()"
	>
		<div
			v-if="isShowHeaderText"
			:class="[$style.headerText, 'px-2 py-1 g-fs-16 mb-1']"
		>
			{{ menuSideItem.text }}
		</div>
		<MenuItemChildLink
			v-for="(child, childIndex) in menuSideItem.childList"
			:key="childIndex"
			:menu-side-item="menuSideItem"
			:child-index="childIndex"
			:is-hover-list="isHoverList"
		></MenuItemChildLink>
	</div>
</template>

<script>
	import MenuItemChildLink from '@/components/menu/menuSide/MenuItemChildLink.vue'

	import { MenuSideItem } from '@/assets/js/sideMenu/MenuSideItem.js'
	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'

	export default {
		components: {
			MenuItemChildLink,
		},
		props: {
			menuSideItem: { type: MenuSideItem, default: () => (new MenuSideItem()) },
			isHoverItemContainer: { type: Boolean, default: false },
			top: { type: Number, default: 0 },
			left: { type: Number, default: 0 },
		},
		data() {
			return {
				sideMenuConfigVue,
				topCorrected: this.top,
				isHoverList: false,
			}
		},
		computed: {
			style() {
				return {
					top: `${this.topCorrected}px`,
					left: `${this.left}px`,
				}
			},
			isShowHeaderText() {
				return this.sideMenuConfigVue.isMenuActive === false
			},
		},
		watch: {
			isHoverItemContainer() {
				if (!this.isHoverItemContainer) {
					return
				}

				this.isHoverList = false
				this.topCorrected = this.top
				this.correctPosition()
			},
		},
		methods: {
			onMouseOver() {
				this.isHoverList = true
			},
			correctPosition() {
				if (this.isShowHeaderText) {
					this.topCorrected -= 45
				} else {
					this.topCorrected -= 5
				}

				this.$nextTick(() => {
					const refContainer = this.$refs.refContainer

					const documentHeight = document.documentElement.clientHeight
					// const documentWidth = document.documentElement.clientWidth

					const rect = refContainer.getBoundingClientRect()
					const diffBottom = documentHeight - rect.bottom
					if (diffBottom < 0) {
						this.topCorrected = rect.top + diffBottom
					}
					if (rect.top < 52) {
						this.topCorrected = 52
					}
				})
			},
		},
	}
</script>

<style lang="scss" module>
	.container {
		position: fixed;
		z-index: 1030;
		top: 0;
		left: 0;
		background-color: var(--white);
		border-radius: 6px;
		box-shadow: 0 0 3rem 0 rgb(136 152 170 / 25%);
	}

	.headerText {
		border-bottom: 1px solid #cdd5de;
		background: #eaeaea;
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
		font-family: 'TTRoundsNeue', 'Comfortaa', sans-serif;
		font-weight: 600;
	}
</style>
