// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import '@/polyfills.js'
// Notifications plugin. Used on Notifications page
// Validation plugin used to validate forms
// Sidebar on the right. Used as a local plugin in layoutMain.vue
import Vue from 'vue'
// element ui language configuration
import lang from 'element-ui/lib/locale/lang/ru-RU'
import locale from 'element-ui/lib/locale'

// eslint-disable-next-line no-unused-vars
import VeeValidate, { configure, extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/ru.json'

import Notifications from '@/components/argon-core/NotificationPlugin'

Object.keys(rules).forEach((rule) => {
	extend(rule, {
		...rules[rule], // copies rule configuration
		message: messages[rule], // assign message
	})
})

locale.use(lang)

Vue.use(Notifications)
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
	},
})
