<template>
	<div
		:class="[$style.container, 'g-fs-16 py-2 px-4']"
		@click="toggleIsMenuActive()"
	>
		<div
			v-if="sideMenuConfigVue.isMenuActive"
			class="d-flex flex-row justify-content-between align-items-center w-100"
		>
			<i class="fa-regular fa-angle-double-left"></i>
			<div class="d-flex flex-column align-items-center justify-content-center">
				<div>{{ translate('common.hide') }}</div>
			</div>
			<i class="fa-regular fa-angle-double-left"></i>
		</div>
		<div v-else class="d-flex flex-column justify-content-center">
			<i class="fa-regular fa-angle-double-right"></i>
		</div>
	</div>
</template>

<script>
	import { translate } from '@/assets/i18n/i18nextConfig.js'
	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'

	export default {
		data() {
			return {
				sideMenuConfigVue,
			}
		},
		methods: {
			translate,
			toggleIsMenuActive() {
				this.sideMenuConfigVue.toggleIsMenuActive()
			},
		},
	}
</script>

<style module>
	.container {
		display: flex;
		width: 250px;
		height: 62px;
		align-items: center;
		justify-content: left;
		border-top: 1px solid #ccc;
		background-color: #f1f5f9;
		cursor: pointer;
		font-family: 'TTRoundsNeue', 'Comfortaa', sans-serif;
		font-weight: 600;
	}
</style>
