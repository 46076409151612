<template>
	<div>
		<div
			:class="[
				'py-3','px-4', 'g-fs-14', 'm-3', 'd-flex', 'align-items-center', 'justify-content-between', $style.topAlert, `bg-${bgColor}`
			]"
			:style="{'color': color}"
		>
			<div class="d-flex flex-row align-items-start mr-5">
				<template v-if="icon">
					<div class="pr-4">
						<i :class="icon" class="g-fs-32"></i>
					</div>
				</template>
				<div class="d-flex flex-column align-items-start gap-2">
					{{ text }}
					<slot name="button"></slot>
				</div>
			</div>
			<button v-if="closeBtn" type="button" class="close" data-dismiss="alert" aria-label="Close" @click="close">
				<span :style="{'color': color}">×</span>
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			icon: { type: String, default: null },
			text: { type: String, default: '' },
			bgColor: { type: String, default: 'primary' },
			color: { type: String, default: '#fff' },
			closeBtn: { type: Boolean, default: true },
		},
		methods: {
			close() {
				this.$emit('close')
			},
		},
	}
</script>

<style lang="scss" module>
	.topAlert {
		border-radius: 4px;
	}
</style>
