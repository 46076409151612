<template>
	<div :class="layoutClass" class="auth-layout" translate="no">
		<notifications></notifications>
		<BaseNav
			v-model="showMenu"
			type="white"
			:transparent="true"
			menu-classes="justify-content-end"
			class="fixed-top navbar-horizontal navbar-main py-2"
			expand="lg"
			:class="[hideNavbar ? 'hidden-from-top' : '', 'fixed-top-importnat']"
		>
			<div slot="brand" class="navbar-wrapper pt-4">
				<NLink class="navbar-brand" to="/">
					<LogoTop svg-class="img-fluid g-height-30" :svg-type-name="'white'"></LogoTop>
				</NLink>
			</div>

			<template #default>
				<div class="navbar-collapse-header">
					<div class="row">
						<div class="col-6 collapse-brand">
							<NLink to="/">
								<LogoTop svg-class="img-fluid g-height-30"></LogoTop>
							</NLink>
						</div>
						<div class="col-6 collapse-close">
							<button type="button" class="navbar-toggler" aria-label="navbar toggle" @click="showMenu = false">
								<span></span>
								<span></span>
							</button>
						</div>
					</div>
				</div>

				<ul v-if="!isAuth" class="navbar-nav ml-auto">
					<li v-if="authKit.loggedIn" class="nav-item">
						<NLink :to="authKit.user.redirectUrl" class="nav-link">
							<span class="nav-link-inner--text">Личный кабинет</span>
						</NLink>
					</li>
					<li v-if="!authKit.loggedIn" class="nav-item mr-lg-2 d-flex flex-row align-items-center">
						<button v-scroll-to="{ element: '#connection_form', duration: 1000 }" class="btn btn-neutral connect-btn">
							<span class="nav-link-inner--text">Попробовать бесплатно</span>
						</button>
					</li>
					<li v-if="!authKit.loggedIn" class="nav-item">
						<NLink to="/login" class="btn btn-neutral login-btn">
							<span class="nav-link-inner--text">Войти</span>
						</NLink>
					</li>
				</ul>
			</template>
		</BaseNav>

		<div class="main-content">
			<Nuxt></Nuxt>
		</div>

		<footer id="footer-main" class="py-5 w-100" :class="isAuth ? 'position-bottom' : 'bg-default'">
			<div class="container">
				<div class="row align-items-center justify-content-xl-between">
					<div class="col-md-6">
						<div class="copyright text-center text-lg-left text-muted">
							© {{ year }} Rentu.ru
							<br />
							ООО «Центр обработки фискальных данных»
							<br />
							ОГРН 1197232026420
						</div>
					</div>
					<div class="col-md-6">
						<p class="text-right mb-0">
							<a class="text-muted footer-phone" href="tel:88002006372">8-800-200-63-72</a>
						</p>
						<ul class="nav nav-footer justify-content-center justify-content-xl-end">
							<li class="nav-item text-right text-muted g-fs-11">
								<span>
									Продолжая использовать наш сайт, вы даете согласие на обработку файлов Cookies и других пользовательских данных, в
									соответствии с
								</span>
								<a href="/files/privacy_policy.pdf" class="nav-link d-inline pl-0 text-muted text-underline g-fs-11" target="_blank">
									Политикой конфиденциальности
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import BaseNav from '@/components/argon-core/Navbar/BaseNav.vue'
	import LogoTop from '@/components/layouts/LogoTop.vue'

	import { getAuthKit } from '@/assets/js/routerHelper.js'

	export default {
		components: {
			BaseNav,
			LogoTop,
		},
		props: {
			backgroundColor: {
				type: String,
				default: 'black',
			},
		},
		data() {
			return {
				showMenu: false,
				menuTransitionDuration: 250,
				year: new Date().getFullYear(),
				pageClass: 'login-page',
			}
		},
		computed: {
			...mapGetters(['isNavbarHidden']),
			title() {
				return `${this.$route.name} Page`
			},
			layoutClass() {
				const exceptions = ['index', 'home']
				if (!exceptions.includes(this.$route.name)) {
					return 'bg-default'
				}
				return ''
			},
			hideNavbar() {
				const exceptions = ['index', 'home']
				if (exceptions.includes(this.$route.name)) {
					return this.isNavbarHidden
				}
				return false
			},
			isAuth() {
				const exceptions = ['index', 'home']
				return !exceptions.includes(this.$route.name)
			},
			authKit() {
				return getAuthKit()
			},
		},
		watch: {
			// eslint-disable-next-line func-names
			'$route.path': function () {
				if (this.showMenu) {
					this.closeMenu()
				}
			},
		},
		methods: {
			closeMenu() {
				document.body.classList.remove('nav-open')
				this.showMenu = false
			},
		},
	}
</script>
<style lang="scss" scoped>
	.auth-layout {
		min-height: 100vh;
	}

	.navbar {
		transition: 0.4s ease-in-out top;
	}

	.hidden-from-top {
		top: -100px;
	}

	.fixed-top-importnat {
		position: fixed !important;
	}

	.connect-btn {
		border: 1px solid rgb(22, 195, 200);
		color: rgb(82, 95, 127);

		&:hover {
			background: rgb(22, 195, 200);
			color: var(--white);
		}
	}

	.login-btn {
		border: 1px solid rgb(255, 108, 60);
		color: rgb(82, 95, 127);

		&:hover {
			background: rgb(255, 108, 60);
			color: var(--white);
		}
	}

	.footer-phone {
		font-family: Open Sans, sans-serif;
		font-size: 28px;
		font-weight: 500;
	}

	.position-bottom {
		position: absolute;
		bottom: 0;
	}
</style>
