<template>
	<div v-if="false"></div>
</template>

<script>
	import { getAuthKit } from '@/assets/js/routerHelper.js'

	export default {
		mounted() {
			const authKit = getAuthKit()
			if (!authKit.loggedIn) {
				return
			}
			if (!process.env.sentryDisable) {
				window.Sentry.setUser({
					id: authKit.user.id,
					email: authKit.user.email,
				})
			}
		},
	}
</script>
