import { accessRoleListKit } from '@/assets/js/access/accessRoleListKit.js'
import { hasAccess } from '@/assets/js/access/hasAccess.js'
import { MenuSideItem, def } from '@/assets/js/sideMenu/MenuSideItem.js'
import { translate } from '@/assets/i18n/i18nextConfig.js'

const getItemsFiltered = ({ itemList }) => {
	if (!itemList || !itemList.length) {
		return []
	}

	const itemsFiltered = itemList.filter((item) => {
		return hasAccess({ accessRoleList: item.accessRoleList })
	})

	itemsFiltered.forEach((item) => {
		item.childList = getItemsFiltered({ itemList: item.childList })
	})

	return itemsFiltered
}

const getMenuItemListBottom = () => {
	const list = [
		new MenuSideItem({
			...def,
			text: translate('sideMenu.support'),
			iconCssClass: 'fa-regular fa-message text-purple',
			accessRoleList: accessRoleListKit.common,
			routeTo: { name: 'entity-support' },
			isShowSupportInfo: true,
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.docs'),
			iconCssClass: 'fa-solid fa-book text-success',
			routeTo: { name: 'docs', meta: { css: 'far fa-comment-alt text-purple' } },
			accessRoleList: accessRoleListKit.admin,
		}),
	]

	const menuItemList = getItemsFiltered({ itemList: list })
	return menuItemList
}


export { getMenuItemListBottom }
