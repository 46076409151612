<template>
	<div v-if="false"></div>
</template>

<script>
	import { translate } from '@/assets/i18n/i18nextConfig.js'

	export default {
		data() {
			return {
				messageConfigDefaultKit: {
					verticalAlign: 'top',
					horizontalAlign: 'left',
					timeout: 5000,
					icon: 'ni ni-bell-55',
				},
			}
		},
		computed: {
			isOffline() {
				return this.$nuxt.isOffline
			},
			isOnline() {
				return this.$nuxt.isOnline
			},
		},
		watch: {
			isOffline() {
				if (this.isOffline) {
					this.showOfflineMessage()
				}
			},
			isOnline() {
				if (this.isOnline) {
					this.showOnlineMessage()
				}
			},
		},
		methods: {
			showOfflineMessage() {
				this.$notify({
					...this.messageConfigDefaultKit,
					message: translate('common.connectionLost'),
					type: 'danger',
				})
			},
			showOnlineMessage() {
				this.$notify({
					...this.messageConfigDefaultKit,
					message: translate('common.connectionRestored'),
					type: 'success',
				})
			},
		},
	}
</script>
