<template>
	<div :class="[$style.svgLoader, isShow ? '' : $style.loaded]">
		<IconLoader
			:style="style"
			:class="[$style.loaderSvg, $style.centerTotal]"
			max-width="100%"
			max-height="100%"
		></IconLoader>
	</div>
</template>
<script>
	import IconLoader from '@/assets/svg/iconLoader.svg'

	export default {
		components: {
			IconLoader,
		},
		props: {
			loaded: { type: Boolean, default: false, description: 'Should loader disapear' },
			width: { type: String, default: '75px' },
			height: { type: String, default: '75px' },
			isAutoSize: { type: Boolean, default: false },
		},
		data() {
			return {
				isShow: false,
			}
		},
		computed: {
			style() {
				return {
					width: this.isAutoSize ? '' : this.width,
					height: this.isAutoSize ? '' : this.height,
				}
			},
		},
		watch: {
			loaded: {
				immediate: true,
				handler() {
					this.isShow = !this.loaded
				},
			},
		},
		methods: {
			show() {
				this.isShow = true
			},
			hide() {
				this.isShow = false
			},
		},
	}
</script>
<style lang="scss" module>
	.svgLoader {
		position: absolute;
		z-index: 99;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: rgba(255, 255, 255, 0.9);
		opacity: 1;
		transition: visibility 0s linear 0s, opacity 300ms;
		visibility: visible;

		&.loaded {
			opacity: 0;
			transition: visibility 0s ease 300ms, opacity 300ms ease;
			visibility: hidden;
		}
	}

	.loaderSvg {
		position: relative;

		&.centerTotal {
			top: 50%;
			left: 50%;
			display: block;
			transform: translate(-50%, -50%);
		}
	}
</style>
