import months from './months.json'
import messages from './messages.json'
import abbreviation from './abbreviation.json'
import common from './common.json'
import sideMenu from './sideMenu.json'
import menu from './menu.json'
import terminal from './terminal.json'
import ocr from './ocr.json'
import modalSellLocationCreate from './components/modals/modalSellLocationCreate.json'
import modalRenterCreate from './components/modals/modalRenterCreate.json'
import modalCategory from './components/modals/modalCategory.json'
import modalPosition from './components/modals/modalPosition.json'
import modalRentalPeriods from './components/modals/modalRentalPeriods.json'
import blockFiles from './components/modals/blockFiles.json'
import blockSellLocationRentPeriodEdit from './components/blocks/blockSellLocationRentPeriodEdit.json'
import tableRentalPeriod from './components/tables/tableRentalPeriod.json'
import tableUnknownPaymentsKkts from './components/tables/tableUnknownPaymentsKkts.json'
import tableRenters from './components/tables/tableRenters.json'
import tableKktsActivity from './components/tables/tableKktsActivity.json'
import qualityRating from './components/pages/qualityRating.json'
import roles from './pages/_entity/settings/roles.json'
import formRoleEdit from './components/form/formRoleEdit.json'
import shoppingCenter from './pages/_entity/settings/shoppingCenter.json'
import blockLatestLog from './components/blocks/blockLatestLog.json'
import connectedKkts from './components/blocks/connectedKkts.json'
import penetrationDynamics from './components/blocks/penetrationDynamics.json'
import kktsActivityBlock from './components/blocks/kktsActivityBlock.json'
import getCompletenessDataList from './assets/js/getCompletenessDataList.json'
import fullnessBar from './components/sellLocation/fullnessBar.json'
import kktCircleKit from './components/kkt/kktCircleKit.json'
import monitoring from './pages/_entity/monitoring.json'
import shopByDate from './pages/_entity/reports/shopByDate.json'
import aboutRenter from './pages/_entity/reports/aboutRenter.json'
import shops from './pages/_entity/shops/shops.json'
import sendEmail from './components/modals/sendEmail.json'
import tableDashboardLogs from './components/tables/tableDashboardLogs.json'
import roleConfigs from './assets/js/access/roleConfigs.json'
import roleKitConfigs from './assets/js/access/roleKitConfigs.json'
import ModalRenterCreateShop from './components/pages/arendators/ModalRenterCreateShop.json'
import rentTypeList from './assets/js/other/rentTypeList.json'
import getKktsDueHint from './assets/js/other/getKktsDueHint.json'
import agreementState from './assets/js/other/agreementState.json'
import supportTicketNewModal from './components/support/supportTicketNewModal.json'
import ClassmatesEditModal from './components/modals/sell_location/ClassmatesEditModal.json'
import tableShopUnknownKktList from './components/tables/tableShopUnknownKktList.json'
import userCard from './components/pages/userProfile/userCard.json'
import dashboard from './pages/_entity/dashboard/dashboard.json'
import blockBaseDataMobile from './pages/_entity/dashboard/blockBaseDataMobile.json'
import blockBaseData from './pages/_entity/dashboard/blockBaseData.json'
import blockPrimaryMetrics from './pages/_entity/dashboard/blockPrimaryMetrics.json'
import blockDailySales from './pages/_entity/dashboard/blockDailySales.json'
import blockCategories from './pages/_entity/dashboard/blockCategories.json'
import blockSalesDynamics from './pages/_entity/dashboard/blockSalesDynamics.json'
import blockAvgCheck from './pages/_entity/dashboard/blockAvgCheck.json'
import blockTop10 from './pages/_entity/dashboard/blockTop10.json'
import blockLeaders from './pages/_entity/dashboard/blockLeaders.json'
import blockOutsiders from './pages/_entity/dashboard/blockOutsiders.json'
import blockNoData from './pages/_entity/dashboard/blockNoData.json'
import { unknownKkts } from './pages/unknownKkts/unknownKkts.ts'
import editSellLocationStatusModal from './components/pages/editSellLocationStatusModal.json'
import alerts from './assets/js/alert/alerts.json'
import arendator from './pages/_entity/arendators/arendator.json'
import archiveModal from './components/pages/arendators/archiveModal.json'
import blockSummaryData from './components/blocks/shop/blockSummaryData.json'
import blockContacts from './components/blocks/blockContacts.json'
import modalRentalData from './components/modals/sell_location/modalRentalData.json'
import modalNames from './components/modals/sell_location/modalNames.json'
import mallAnalyticsMap from './components/pages/svgMap/mallAnalyticsMap.json'
import mapSubstrate from './components/other/mapSubstrate.json'
import shopByChecks from './pages/_entity/reports/shopByChecks.json'
import getTableColumnsSettings from './assets/js/other/getTableColumnsSettings.json'
import paginationInfo from './components/pagination/paginationInfo.json'
import blockMallsSummaryVisitors from './components/malls/blockMallsSummaryVisitors.json'
import blockFinancialPanelChoose from './components/blocks/blockFinancialPanelChoose.json'
import blockStatsSellLocations from './components/blocks/blockStatsSellLocations.json'
import sellLocationTypeCheckbox from './components/form/sellLocationTypeCheckbox.json'
import blockFilterSellLocations2 from './components/blocks/blockFilterSellLocations2.json'
import blockChooseLineParam from './components/blocks/blockChooseLineParam.json'
import blockFinancialChooseLineConfigList from './components/blocks/blockFinancialChooseLineConfigList.json'
import financialLineTypeConfig from './assets/js/financial/financialLineTypeConfig.json'
import blockFinancialChartAggregation from './components/blocks/blockFinancialChartAggregation.json'
import financialTable from './components/tables/financialTable.json'
import financialChooseLineTypeSelect from './components/financial/financialChooseLineTypeSelect.json'
import financialChooseLineTypeCascader from './components/financial/financialChooseLineTypeCascader.json'
import financialChartTooltip from './components/financialChartTooltip/financialChartTooltip.json'
import blockFinancialLineSeriesConfig from './components/blocks/blockFinancialLineSeriesConfig.json'
import times from './times.json'
import turnover from './turnover.json'
import fileAttachment from './components/form/fileAttachment.json'
import blockMallsDashboardCategories from './components/malls/blockMallsDashboardCategories'
import mallsDashboard from './pages/malls/mallsDashboard.json'
import modalNoData from './components/malls/modalNoData'
import blockMallsBaseData from './components/malls/blockMallsBaseData'
import selectMallsInline from './components/malls/selectMallsInline'
import supportBoard from './pages/support-board/supportBoard.json'
import supportBoardUsersForSupport from './components/form/selectUsersForSupport.json'
import supportBoardComponent from './components/support/supportBoard.json'
import supportTicket from './components/support/supportTicket.json'
import modalFilePreview from './components/modals/modalFilePreview.json'
import supportBoardFilters from './components/support/supportBoardFilters.json'
import supportTicketRequestTypeList from './assets/js/support/supportTicketRequestTypeList.json'
import supportState from './assets/js/support/supportState.json'
import mallsSupport from './pages/malls/support.json'
import entitySupport from './pages/_entity/support/support.json'
import assignee from './components/support/assignee.json'
import date from './date.js'
import supportBoardMallList from './components/support/supportBoardMallList.json'
import modalMallsTopProfiles from './components/malls/modalMallsTopProfiles.json'
import blockMallsTop10 from './components/malls/blockMallsTop10.json'
import blockMallsOutsiders from './components/malls/blockMallsOutsiders.json'
import blockMallsLeaders from './components/malls/blockMallsLeaders.json'
import blockMallsSummaryCheckIncome from './components/malls/blockMallsSummaryCheckIncome.json'
import modalMallsBaseDataDetails from './components/malls/modalMallsBaseDataDetails.json'
import blockMallsBaseDataContent from './components/malls/blockMallsBaseDataContent.json'
import blockMallsSummaryIncome from './components/malls/blockMallsSummaryIncome.json'
import modalTicketNewSellLocation from './components/modals/modalTicketNewSellLocation.json'
import shopGeneralCard from './components/blocks/shop/shopGeneralCard.json'
import classmatesTop from './components/blocks/top/classmatesTop.json'
import tableTop from './components/tables/tableTop.json'
import shopCommentBlock from './components/blocks/shop/shopCommentBlock.json'
import modalArchiveInfo from './components/modals/modalArchiveInfo.json'
import agreementStateModal from './components/modals/sell_location/agreementStateModal.json'
import modalLogs from './components/modals/sell_location/modalLogs.json'
import logsCard from './components/blocks/shop/logsCard.json'
import regionalityModal from './components/modals/sell_location/regionalityModal.json'
import contractDetailsCard from './components/blocks/shop/contractDetailsCard.json'
import blockFinancialKktPanelChoose from './components/blocks/blockFinancialKktPanelChoose.json'
import modalRenterUpdate from './components/modals/sell_location/modalRenterUpdate.json'
import modalRenter from './components/modals/sell_location/modalRenter.json'
import renterCard from './components/blocks/shop/renterCard.json'
import modalAnomaliesConfig from './components/modals/sell_location/modalAnomaliesConfig.json'
import shopAdditionalCard from './components/blocks/shop/shopAdditionalCard.json'
import shopBlockSupport from './components/blocks/shop/shopBlockSupport.json'
import sellLocationEdit from './components/modals/sellLocationEdit.json'
import modalFileUpload from './components/modals/modalFileUpload.json'
import modalShopInfo from './components/modals/modalShopInfo.json'
import getConnectionStatusTooltip from './assets/js/other/getConnectionStatusTooltip.json'
import xlsUploadModal from './components/xlsUploadModal.json'
import changeRenterModal from './components/pages/shop/changeRenterModal.json'
import shop from './pages/_entity/shops/shop.json'
import chartPerUserConversion from './components/charts/chartPerUserConversion.json'
import blockChartIndex from './components/blocks/blockChartIndex.json'
import blockChartIndexHelper from './assets/js/chart/blockChartIndexHelper.json'
import blockOperationsChart from './components/blocks/blockOperationsChart.json'
import blockOperationsChartPanelChoose from './components/blocks/blockOperationsChartPanelChoose.json'
import blockAdvanceChart from './components/blocks/blockAdvanceChart.json'
import blockAdvanceChartPanelChoose from './components/blocks/blockAdvanceChartPanelChoose.json'
import averageChecksChart from './components/charts/averageChecksChart.json'
import chartHistoryCheck from './components/blocks/chartHistoryCheck.json'
import checksChartAm5 from './components/charts/checksChartAm5.json'
import checksListModal from './components/modals/checksListModal.json'
import shopHeatMap from './components/charts/shopHeatMap.json'
import heatMapAveragesByHoursSells from './components/charts/heatMapAveragesByHoursSells.json'
import shopHeatMapFilters from './components/charts/shopHeatMapFilters.json'
import shopHeatMapTopModal from './components/modals/shopHeatMapTopModal.json'
import shopHeatMapTopByYearModal from './components/modals/shopHeatMapTopByYearModal.json'
import shopHeatMapTopBySellLocationModal from './components/modals/shopHeatMapTopBySellLocationModal.json'
import heatMapAveragesByWeek from './components/charts/heatMapAveragesByWeek.json'
import supportTicketNotes from './components/support/notes/supportTicketNotes.json'
import reminderNotificator from './components/support/reminder/ReminderNotificator.json'
import pageFinancial from './pages/_entity/analysis/financial.json'
import contractCard from './components/blocks/shop/contractCard.json'
import reminders from './components/support/reminder/Reminders.json'
import docs from './pages/_entity/docs.json'
import messageInput from './components/messageInput/MessageInput.json'
import login from './pages/login.json'
import financial from './financial.js'
import modalMallInformation from './components/modals/modalMallInformation.json'
import mallInformationBadge from './components/mallInformationBadge/mallInformationBadge.json'
import analyticsMallMap from './pages/_entity/analytics/mallMap.json'
import shopsByAverage from './pages/_entity/reports/shopsByAverage.json'
import anomaly from './pages/_entity/anomaly.json'
import tendency from './pages/malls/visitors/tendency.json'
import settingsZones from './pages/_entity/visitors/zones/settingsZones.json'
import settingsSensors from './pages/_entity/visitors/sensors/settingsSensors.json'
import positions from './pages/_entity/settings/positions.json'
import modalFloor from './components/modals/modalFloor.json'
import modalSector from './components/modals/modalSector.json'
import modalEntity from './components/modals/modalEntity.json'
import outdoorPositions from './components/tables/outdoorPositions.json'
import entities from './pages/entities.json'
import blockSupportInfoMallList from './components/other/blockSupportInfoMallList.json'
import reports from './pages/_entity/reports/reports.json'
import add from './pages/malls/add.json'
import access from './access.json'
import TableSellLocationKktList from './components/tables/TableSellLocationKktList.json'
import map from './components/map/map.json'

const en = {
	months,
	messages,
	modalCategory,
	modalPosition,
	modalRentalPeriods,
	tableRentalPeriod,
	tableUnknownPaymentsKkts,
	tableRenters,
	tableKktsActivity,
	blockSellLocationRentPeriodEdit,
	abbreviation,
	common,
	modalSellLocationCreate,
	modalRenterCreate,
	blockFiles,
	qualityRating,
	roles,
	formRoleEdit,
	shoppingCenter,
	blockLatestLog,
	connectedKkts,
	getCompletenessDataList,
	fullnessBar,
	kktCircleKit,
	penetrationDynamics,
	kktsActivityBlock,
	monitoring,
	shopByDate,
	aboutRenter,
	shops,
	sendEmail,
	tableDashboardLogs,
	roleConfigs,
	roleKitConfigs,
	ModalRenterCreateShop,
	getKktsDueHint,
	agreementState,
	rentTypeList,
	supportTicketNewModal,
	ClassmatesEditModal,
	tableShopUnknownKktList,
	userCard,
	dashboard,
	blockBaseDataMobile,
	blockBaseData,
	blockPrimaryMetrics,
	blockDailySales,
	blockCategories,
	blockSalesDynamics,
	blockAvgCheck,
	blockTop10,
	blockLeaders,
	blockOutsiders,
	blockNoData,
	editSellLocationStatusModal,
	alerts,
	arendator,
	archiveModal,
	blockSummaryData,
	blockContacts,
	modalRentalData,
	modalNames,
	mallAnalyticsMap,
	mapSubstrate,
	shopByChecks,
	getTableColumnsSettings,
	paginationInfo,
	blockMallsSummaryVisitors,
	blockFinancialPanelChoose,
	blockStatsSellLocations,
	sellLocationTypeCheckbox,
	blockFilterSellLocations2,
	blockChooseLineParam,
	blockFinancialChooseLineConfigList,
	financialLineTypeConfig,
	blockFinancialChartAggregation,
	financialTable,
	financialChooseLineTypeSelect,
	financialChooseLineTypeCascader,
	financialChartTooltip,
	blockFinancialLineSeriesConfig,
	times,
	fileAttachment,
	blockMallsDashboardCategories,
	mallsDashboard,
	modalNoData,
	blockMallsBaseData,
	selectMallsInline,
	supportBoard,
	supportBoardUsersForSupport,
	supportBoardComponent,
	supportTicket,
	modalFilePreview,
	supportBoardFilters,
	supportTicketRequestTypeList,
	supportState,
	mallsSupport,
	entitySupport,
	assignee,
	date,
	supportBoardMallList,
	modalMallsTopProfiles,
	blockMallsTop10,
	blockMallsOutsiders,
	blockMallsLeaders,
	blockMallsSummaryCheckIncome,
	modalMallsBaseDataDetails,
	blockMallsBaseDataContent,
	blockMallsSummaryIncome,
	modalTicketNewSellLocation,
	shopGeneralCard,
	classmatesTop,
	tableTop,
	shopCommentBlock,
	modalArchiveInfo,
	agreementStateModal,
	modalLogs,
	logsCard,
	regionalityModal,
	contractDetailsCard,
	blockFinancialKktPanelChoose,
	modalRenterUpdate,
	modalRenter,
	renterCard,
	modalAnomaliesConfig,
	shopAdditionalCard,
	shopBlockSupport,
	sellLocationEdit,
	modalFileUpload,
	modalShopInfo,
	getConnectionStatusTooltip,
	xlsUploadModal,
	changeRenterModal,
	shop,
	chartPerUserConversion,
	blockChartIndex,
	blockChartIndexHelper,
	blockOperationsChart,
	blockOperationsChartPanelChoose,
	blockAdvanceChart,
	blockAdvanceChartPanelChoose,
	averageChecksChart,
	chartHistoryCheck,
	checksChartAm5,
	checksListModal,
	shopHeatMap,
	heatMapAveragesByHoursSells,
	shopHeatMapFilters,
	shopHeatMapTopModal,
	shopHeatMapTopByYearModal,
	shopHeatMapTopBySellLocationModal,
	heatMapAveragesByWeek,
	supportTicketNotes,
	reminderNotificator,
	pageFinancial,
	contractCard,
	turnover,
	menu,
	terminal,
	reminders,
	docs,
	messageInput,
	login,
	financial,
	modalMallInformation,
	mallInformationBadge,
	sideMenu,
	unknownKkts,
	analyticsMallMap,
	shopsByAverage,
	anomaly,
	ocr,
	tendency,
	settingsZones,
	settingsSensors,
	positions,
	modalFloor,
	modalSector,
	modalEntity,
	outdoorPositions,
	entities,
	blockSupportInfoMallList,
	reports,
	add,
	access,
	TableSellLocationKktList,
	map,
}
export default en
