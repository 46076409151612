<template>
	<div>
		<MenuPanelTop></MenuPanelTop>
		<MenuPanelContent :full-width="true">
			<slot></slot>
		</MenuPanelContent>
	</div>
</template>

<script>
	import MenuPanelTop from '@/components/menu/MenuPanelTop.vue'
	import MenuPanelContent from '@/components/menu/MenuPanelContent.vue'

	export default {
		components: {
			MenuPanelTop,
			MenuPanelContent,
		},
	}
</script>
