import { format } from 'date-fns'
import { getCorrectTypeValKit } from '@/assets/js/check/getCorrectTypeValKit.js'

const kit = {
	reportGeneral: ({ shoppingCenterId, dateRange, orderField, sellLocationIds, isEmptyDateRange }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/reports/general`
		if (isEmptyDateRange) {
			const params = {
				order_field: orderField,
				sell_location_ids: sellLocationIds,
			}
			return window.$nuxt.$axios.$post(url, params)
		}

		const correctKit = getCorrectTypeValKit({
			dateStart: [dateRange[0], 'dateCorrect'],
			dateEnd: [dateRange[1], 'dateCorrect'],
		})
		const params = {
			start_date: format(correctKit.dateStart, 'yyyy-MM-dd'),
			end_date: format(correctKit.dateEnd, 'yyyy-MM-dd'),
			order_field: orderField,
			sell_location_ids: sellLocationIds,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	reportGeneralXlsx: ({ shoppingCenterId, dateRange }) => {
		const correctKit = getCorrectTypeValKit({
			dateStart: [dateRange[0], 'dateCorrect'],
			dateEnd: [dateRange[1], 'dateCorrect'],
		})
		const url = '/reports/general'
		const params = {
			params: {
				entity_id: shoppingCenterId,
				begin: format(correctKit.dateStart, 'yyyy-MM-dd'),
				end: format(correctKit.dateEnd, 'yyyy-MM-dd'),
				type: 'xlsx',
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},


	reportShopsByAverage: ({ mallId, dateRange }) => {
		const correctKit = getCorrectTypeValKit({
			dateStart: [dateRange[0], 'dateCorrect'],
			dateEnd: [dateRange[1], 'dateCorrect'],
		})
		const url = '/reports/shops-by-average'
		const params = {
			params: {
				entity_id: mallId,
				begin: format(correctKit.dateStart, 'yyyy-MM-dd'),
				end: format(correctKit.dateEnd, 'yyyy-MM-dd'),
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportShopsByAverageXlsx: ({ mallId, dateRange }) => {
		const url = '/reports/shops-by-average'
		const params = {
			params: {
				entity_id: mallId,
				begin: dateRange[0],
				end: dateRange[1],
				type: 'xlsx',
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	reportsKkts: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/reports/kkts/report`
		return window.$nuxt.$axios.$get(url)
	},
	reportsKktsXlsx: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/reports/kkts/spreadsheet.xlsx`
		const params = { responseType: 'blob', params: {} }
		return window.$nuxt.$axios.$get(url, params)
	},

	reportsUnknownKkts: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/unknown_payment_terminals`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},

	reportsByOperationsByDate: ({ mallId, shopId, dateRange, groupBy }) => {
		const url = `/api/v2/sc/${mallId}/sell_location/${shopId}/reports/by_operations/by_date`
		const params = {
			params: {
				date_from: dateRange[0],
				date_to: dateRange[1],
				group_by: groupBy,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportsByOperationsByDateXlsx: ({ mallId, shopId, dateRange, groupBy }) => {
		const url = `/api/v2/sc/${mallId}/sell_location/${shopId}/reports/by_operations/by_date.xlsx`
		const params = {
			params: {
				date_from: dateRange[0],
				date_to: dateRange[1],
				group_by: groupBy,
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	reportsShopsByOperationType: ({ shoppingCenterId, dateRange }) => {
		const url = '/reports//shops-by-operation-type'
		const params = {
			params: {
				entity_id: shoppingCenterId,
				begin: dateRange[0],
				end: dateRange[1],
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportsShopsByOperationTypeXlsx: ({ mallId, dateRange }) => {
		const url = '/reports//shops-by-operation-type'
		const params = {
			params: {
				entity_id: mallId,
				begin: dateRange[0],
				end: dateRange[1],
				type: 'xlsx',
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	reportsShoppingCentersByOperationType: ({ mallIds, dateRange }) => {
		const url = '/api/v2/shopping_centers/reports/by_operations'
		const params = {
			params: {
				shopping_center_ids: mallIds,
				begin: dateRange[0],
				end: dateRange[1],
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportsShoppingCentersByOperationTypeXlsx: ({ mallIds, dateRange }) => {
		const url = '/api/v2/shopping_centers/reports/by_operations.xlsx'
		const params = {
			params: {
				shopping_center_ids: mallIds,
				begin: dateRange[0],
				end: dateRange[1],
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	reportsShoppingCentersByMonths: ({ mallIds, dateRange }) => {
		const url = '/api/v2/shopping_centers/reports/by_months'
		const params = {
			params: {
				shopping_center_ids: mallIds,
				begin: dateRange[0],
				end: dateRange[1],
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportsShoppingCentersByMonthsXlsx: ({ mallIds, dateRange }) => {
		const url = '/api/v2/shopping_centers/reports/by_months.xlsx'
		const params = {
			params: {
				shopping_center_ids: mallIds,
				begin: dateRange[0],
				end: dateRange[1],
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	reportsWeeklyAnalytics: ({ mallId, reportKey }) => {
		const url = `/api/v2/sc/${mallId}/reports/weekly_analytics/${reportKey}`
		return window.$nuxt.$axios.$get(url)
	},

	reportsByOperationsByChecks: ({ shoppingCenterId, sellLocationId, dateRange }) => {
		const correctKit = getCorrectTypeValKit({
			dateStart: [dateRange[0], 'dateCorrect'],
			dateEnd: [dateRange[1], 'dateCorrect'],
		})
		const url = `/api/v2/sc/${shoppingCenterId}/sell_location/${sellLocationId}/reports/by_operations/by_checks`
		const params = {
			params: {
				start_date: format(correctKit.dateStart, 'yyyy-MM-dd'),
				end_date: format(correctKit.dateEnd, 'yyyy-MM-dd'),
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportsByOperationsByChecksXlsx: ({ shoppingCenterId, sellLocationId, dateRange }) => {
		const correctKit = getCorrectTypeValKit({
			dateStart: [dateRange[0], 'dateCorrect'],
			dateEnd: [dateRange[1], 'dateCorrect'],
		})
		const url = `/api/v2/sc/${shoppingCenterId}/sell_location/${sellLocationId}/reports/by_operations/by_checks.xlsx`
		const params = {
			params: {
				start_date: format(correctKit.dateStart, 'yyyy-MM-dd'),
				end_date: format(correctKit.dateEnd, 'yyyy-MM-dd'),
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	reportTurnoverByCategoryXlsx: ({ shoppingCenterId, dateRange }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/reports/shops_matrix/turnover_by_category.xlsx`
		const params = {
			params: {
				begin_date: dateRange[0],
				end_date: dateRange[1],
				type: 'xlsx',
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportTurnoverXlsx: ({ shoppingCenterId, dateRange }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/reports/shops_matrix/turnover.xlsx`
		const params = {
			params: {
				begin_date: dateRange[0],
				end_date: dateRange[1],
				type: 'xlsx',
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportIncomeByCategoryXlsx: ({ shoppingCenterId, dateRange }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/reports/shops_matrix/income_by_category.xlsx`
		const params = {
			params: {
				begin_date: dateRange[0],
				end_date: dateRange[1],
				type: 'xlsx',
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportIncomeXlsx: ({ shoppingCenterId, dateRange }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/reports/shops_matrix/income.xlsx`
		const params = {
			params: {
				begin_date: dateRange[0],
				end_date: dateRange[1],
				type: 'xlsx',
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportSummaryTurnoverByCategory: ({ mallId, dateRange }) => {
		const url = `/api/v2/sc/${mallId}/reports/shops_matrix/summary_by_category`
		const params = {
			params: {
				data_field: 'turnover',
				begin_date: dateRange[0],
				end_date: dateRange[1],
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportSummaryTurnover: ({ mallId, dateRange }) => {
		const url = `/api/v2/sc/${mallId}/reports/shops_matrix/summary`
		const params = {
			params: {
				data_field: 'turnover',
				begin_date: dateRange[0],
				end_date: dateRange[1],
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportSummaryIncomeByCategory: ({ mallId, dateRange }) => {
		const url = `/api/v2/sc/${mallId}/reports/shops_matrix/summary_by_category`
		const params = {
			params: {
				data_field: 'income',
				begin_date: dateRange[0],
				end_date: dateRange[1],
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportSummaryIncome: ({ mallId, dateRange }) => {
		const url = `/api/v2/sc/${mallId}/reports/shops_matrix/summary`
		const params = {
			params: {
				data_field: 'income',
				begin_date: dateRange[0],
				end_date: dateRange[1],
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	reportSummaryTurnoverByCategoryXlsx: ({ shoppingCenterId, dateRange }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/reports/shops_matrix/summary_by_category.xlsx`
		const params = {
			params: {
				data_field: 'turnover',
				begin_date: dateRange[0],
				end_date: dateRange[1],
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportSummaryTurnoverXlsx: ({ shoppingCenterId, dateRange }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/reports/shops_matrix/summary.xlsx`
		const params = {
			params: {
				data_field: 'turnover',
				begin_date: dateRange[0],
				end_date: dateRange[1],
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportSummaryIncomeByCategoryXlsx: ({ shoppingCenterId, dateRange }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/reports/shops_matrix/summary_by_category.xlsx`
		const params = {
			params: {
				data_field: 'income',
				begin_date: dateRange[0],
				end_date: dateRange[1],
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	reportSummaryIncomeXlsx: ({ shoppingCenterId, dateRange }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/reports/shops_matrix/summary.xlsx`
		const params = {
			params: {
				data_field: 'income',
				begin_date: dateRange[0],
				end_date: dateRange[1],
			},
			responseType: 'blob',
		}
		return window.$nuxt.$axios.$get(url, params)
	},
}

export default kit
