export default {
	thisMonth: 'This month',
	dateNameKit: {
		short: {
			week: 'week.',
			year: 'y.',
		},
	},
	long: {
		sunday: 'Воскресенье',
		monday: 'Понедельник',
		tuesday: 'Вторник',
		wednesday: 'Среда',
		thursday: 'Четверг',
		friday: 'Пятница',
		saturday: 'Суббота',
	},
	short: {
		sunday: 'Sun',
		monday: 'Mon',
		tuesday: 'Tue',
		wednesday: 'Wed',
		thursday: 'Thu',
		friday: 'Fri',
		saturday: 'Sat',
	},
	shortByNumber: {
		0: 'Sun',
		1: 'Mon',
		2: 'Tue',
		3: 'Wed',
		4: 'Thu',
		5: 'Fri',
		6: 'Sat',
	},
	shortByNumber7: {
		1: 'Mon',
		2: 'Tue',
		3: 'Wed',
		4: 'Thu',
		5: 'Fri',
		6: 'Sat',
		7: 'Sun',
	},
	arrMonthCompare: [
		'january',
		'february',
		'march',
		'april',
		'may',
		'june',
		'july',
		'august',
		'september',
		'october',
		'november',
		'december',
	],
}
