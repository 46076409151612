import { format } from 'date-fns'

const queryShopKit = {
	sellLocationList: ({ mallId }) => {
		const url = `/api/v2/sc/${mallId}/sell_locations/list`
		return window.$nuxt.$axios.$get(url)
	},

	sellLocationSelectList: ({ mallId }) => {
		const url = `/api/v2/sc/${mallId}/sell_locations/select_list`
		return window.$nuxt.$axios.$get(url)
	},

	sellLocationIikoList: ({ mallId }) => {
		const url = `/api/v2/sc/${mallId}/sell_locations/iiko_list`
		return window.$nuxt.$axios.$get(url)
	},

	shopLFLList: ({ mallId, arrDate }) => {
		const url = `/api/v2/sc/${mallId}/sell_locations/lfl_list`
		const params = {
			params: {
				start_date: format(arrDate[0], 'yyyy-MM-dd'),
				end_date: format(arrDate[1], 'yyyy-MM-dd'),
			},
		}
		return window.$nuxt.$axios.$get(url, params)
		// {
		// 	'success' => true,
		// 	'list' => [ { /* duplicate of select_list response */ }, ... ]
		// }
	},

	sellLocationArchive: ({ shoppingCenterId, sellLocationId, contractEndDate, comment, isPageSellLocation }) => {
		const url = `/sell_locations/${sellLocationId}/archive`
		const data = {
			contractEndDate,
			comment,
		}
		if (isPageSellLocation) {
			data.page = 'sell_location'
		}
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$post(url, data, params)
	},
	sellLocationUnarchive: ({ shoppingCenterId, sellLocationId, isPageSellLocation }) => {
		const url = `/sell_locations/${sellLocationId}/unarchive`
		const data = {
			entity_id: shoppingCenterId,
		}
		if (isPageSellLocation) {
			data.page = 'sell_location'
		}
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$post(url, data, params)
	},
	sellLocationDelete: ({ shoppingCenterId, sellLocationId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}`
		return window.$nuxt.$axios.$delete(url)
	},

	sellLocationUpdate: ({
		shoppingCenterId,
		sellLocationId,
		sellLocationName,
		alternativeNames,
		positionId,
		categoryId,
		profileId,
		searchTags,
		htmlDecription,
		priceCategory,
		typeList,
		contractId,
		contractNew,
		subtenantLegalName,
		subtenantInn,
		externalId,
		yandexOrgId,
	}) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}`

		const incomeData = {
			id: sellLocationId,
			name: sellLocationName,
			alternative_names: alternativeNames,
			position_id: positionId,
			category_id: categoryId,
			profile_id: profileId,
			price_category: priceCategory,
			types: typeList,
			contract_id: contractId,
			search_tags: searchTags,
			html_description: htmlDecription,
			new_contract: contractNew,
			subtenant_legal_name: subtenantLegalName,
			subtenant_inn: subtenantInn,
			external_id: externalId,
			yandex_org_id: yandexOrgId,
		}

		const data = {}

		Object.entries(incomeData).forEach(([key, value]) => {
			if (value !== undefined) {
				data[key] = value
			}
		})

		return window.$nuxt.$axios.$put(url, data)
	},


	sellLocationStatusUpdate: ({
		shoppingCenterId,
		sellLocationId,
		kktsKit,
		approvalStatus,
		approvalStatusComment,
		requireIntervention,
	}) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}/update_status`
		const params = {
			approval_status: approvalStatus,
			approval_status_comment: approvalStatusComment,
			require_intervention: requireIntervention,
			kkts: kktsKit,
		}
		return window.$nuxt.$axios.$put(url, params)
	},

	shopTicketList: ({ mallId, shopId }) => {
		const url = `/api/v2/sc/${mallId}/sell_locations/${shopId}/tickets`
		return window.$nuxt.$axios.$get(url)
	},

	sellLocationClassmate: ({ shoppingCenterId, sellLocationId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}/classmates`
		return window.$nuxt.$axios.$get(url)
	},
	sellLocationClassmateUpdate: ({ shoppingCenterId, sellLocationId, excludedList, includedList }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}/update_classmates`
		const params = {
			delisted_classmate_ids: excludedList,
			enlisted_classmate_ids: includedList,
		}
		return window.$nuxt.$axios.$put(url, params)
	},

	sellLocationComment: ({ mallId, sellLocationId, comment }) => {
		const url = `/api/v2/sc/${mallId}/sell_locations/${sellLocationId}/update_comment`
		const params = { comment }
		return window.$nuxt.$axios.$put(url, params)
	},

	sellLocationCreateFromSellLocation: ({
		shoppingCenterId,
		renterId,
		sellLocationName,
		sellLocationAlternativeNameList,
		positionId,
		categoryId,
		specializationIdList,
		floorId,
		sectorId,
		positionAreaSize,
		activity,
		sellLocationTypeList,
		priceCategory,
		rentalPeriodKit,
		isNewContract,
		contractId,
		contractDateString,
		newContractKit,
		profileId,
	}) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations`
		const params = {
			page_source: 'shops_index',
			arendator_id: renterId,
			name: sellLocationName,
			alternative_names: sellLocationAlternativeNameList,
			position_id: positionId,
			category_id: categoryId,
			specialization_ids: specializationIdList,
			floor_id: floorId,
			sector_id: sectorId,
			area_size: positionAreaSize,
			activity,
			types: sellLocationTypeList,
			price_category: priceCategory,
			rental_period: rentalPeriodKit,
			is_new_contract: isNewContract,
			contract_id: contractId,
			contract_date: contractDateString,
			profile_id: profileId,
		}
		if (isNewContract) {
			params.new_contract = newContractKit
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	sellLocationCreateFromRenter: ({
		shoppingCenterId,
		renterId,
		sellLocationName,
		positionId,
		categoryId,
		profileId,
		specializationIdList,
		floorId,
		sectorId,
		positionAreaSize,
		activity,
		sellLocationTypeList,
		priceCategory,
		rentalPeriodKit,
		comment,
		approvalStatus,
		kktList,
		isNewContract,
		contractId,
		contractDateString,
		contractDateString2,
		newContractKit,
	}) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations`
		const params = {
			arendator_id: renterId,
			name: sellLocationName,
			position_id: positionId,
			category_id: categoryId,
			profile_id: profileId,
			specialization_ids: specializationIdList,
			floor_id: floorId,
			sector_id: sectorId,
			area_size: positionAreaSize,
			activity,
			types: sellLocationTypeList,
			price_category: priceCategory,
			rental_period: rentalPeriodKit,
			comment,
			approvalStatus,
			kktsArray: kktList,
			is_new_contract: isNewContract,
			contract_id: contractId,
			contract_date: contractDateString,
			contractDate: contractDateString2,
		}
		if (isNewContract) {
			params.new_contract = newContractKit
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	sellLocationInfo: ({ shoppingCenterId, sellLocationId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}/basic_data`
		return window.$nuxt.$axios.$get(url)
	},

	sellLocationOcrBep: ({ shoppingCenterId, sellLocationId, dateString }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}/kpi_and_ocr?begin_date=${dateString}`
		return window.$nuxt.$axios.$get(url)
	},

	sellLocationRentalPeriodList: ({ shoppingCenterId, sellLocationId }) => {
		const url = `api/v2/sc/${shoppingCenterId}/sell_location/${sellLocationId}/rental_periods`
		return window.$nuxt.$axios.$get(url)
	},
	sellLocationRentalPeriodCreate: ({
		shoppingCenterId,
		sellLocationId,
		rentalPeriodName,
		dateStart,
		rentType,
		rate,
		pricePerM2,
		pricePerMonth,
		plannedRevenue,
	}) => {
		const url = `api/v2/sc/${shoppingCenterId}/sell_location/${sellLocationId}/rental_periods`
		const data = {
			name: rentalPeriodName,
			start_date: dateStart,
			rent_type: rentType,
			rate,
			price_per_m2: pricePerM2,
			price_per_month: pricePerMonth,
			planned_revenue: plannedRevenue,
		}
		return window.$nuxt.$axios.$post(url, data)
	},
	sellLocationRentalPeriodUpdate: ({
		shoppingCenterId,
		sellLocationId,
		rentalPeriodId,
		rentalPeriodName,
		dateStart,
		rentType,
		rate,
		pricePerM2,
		pricePerMonth,
		plannedRevenue,
	}) => {
		const url = `api/v2/sc/${shoppingCenterId}/sell_location/${sellLocationId}/rental_periods/${rentalPeriodId}`
		const data = {
			name: rentalPeriodName,
			start_date: dateStart,
			rent_type: rentType,
			rate,
			price_per_m2: pricePerM2,
			price_per_month: pricePerMonth,
			planned_revenue: plannedRevenue,
		}
		return window.$nuxt.$axios.$put(url, data)
	},
	sellLocationRentalPeriodDelete: ({ shoppingCenterId, sellLocationId, rentalPeriodId }) => {
		const url = `api/v2/sc/${shoppingCenterId}/sell_location/${sellLocationId}/rental_periods/${rentalPeriodId}`
		return window.$nuxt.$axios.$delete(url)
	},
	sellLocationRentalPeriodRentTypeUpdate: ({ shoppingCenterId, sellLocationId, rentalPeriodId, rentType }) => {
		const url = `api/v2/sc/${shoppingCenterId}/sell_location/${sellLocationId}/rental_periods/${rentalPeriodId}`
		const params = { rent_type: rentType }
		return window.$nuxt.$axios.$put(url, params)
	},

	shopTurnoverSettingsUpdate: ({ mallId, shopId, settings, lastRecalculationDate }) => {
		const url = `api/v2/sc/${mallId}/sell_locations/${shopId}/update_turnover_settings`
		const params = {
			...settings,
			last_recalculation_date: format(new Date(lastRecalculationDate), 'yyyy-MM-dd'),
		}
		return window.$nuxt.$axios.$put(url, params)
	},

	sellLocationUploadSalesData: ({ shoppingCenterId, sellLocationId, formData }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}/upload_sales_data`
		return window.$nuxt.$axios.$post(url, formData)
	},

	sellLocationContractDetails: ({ shoppingCenterId, sellLocationId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}/contract_details`
		return window.$nuxt.$axios.$get(url)
	},

	sellLocationUpdateBrand: ({ shoppingCenterId, sellLocationId, data }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}/update_brand`
		return window.$nuxt.$axios.$put(url, data)
	},

	sellLocationQualityUpdate: ({ shoppingCenterId, sellLocationId, quality }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}`
		const data = { quality }
		return window.$nuxt.$axios.$put(url, data)
	},

	sellLocationChangeRenter: ({ shoppingCenterId, sellLocationId, renterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}/change_renter`
		const data = { new_renter_id: renterId }
		return window.$nuxt.$axios.$post(url, data)
	},
}

export { queryShopKit }
