import 'es6-promise/auto'

export default (function initPollyFills() {
	if (!Array.prototype.find) {
		// eslint-disable-next-line no-extend-native
		Object.defineProperty(Array.prototype, 'find', {
			value(predicate) {
				// 1. Let O be ? ToObject(this value).
				if (this == null) {
					throw new TypeError('"this" is null or not defined')
				}

				const o = Object(this)

				// 2. Let len be ? ToLength(? Get(O, "length")).
				// eslint-disable-next-line no-bitwise
				const len = o.length >>> 0

				// 3. If IsCallable(predicate) is false, throw a TypeError exception.
				if (typeof predicate !== 'function') {
					throw new TypeError('predicate must be a function')
				}

				// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
				// eslint-disable-next-line prefer-rest-params
				const thisArg = arguments[1]

				// 5. Let k be 0.
				let k = 0

				// 6. Repeat, while k < len
				while (k < len) {
					// a. Let Pk be ! ToString(k).
					// b. Let kValue be ? Get(O, Pk).
					// c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
					// d. If testResult is true, return kValue.
					const kValue = o[k]
					if (predicate.call(thisArg, kValue, k, o)) {
						return kValue
					}
					// e. Increase k by 1.
					k += 1
				}

				// 7. Return undefined.
				// eslint-disable-next-line consistent-return
				return undefined
			},
		})
	}
	if (typeof Object.assign !== 'function') {
		// Must be writable: true, enumerable: false, configurable: true
		Object.defineProperty(Object, 'assign', {
			value: function assign(target, varArgs) { // .length of function is 2
				if (target == null) { // TypeError if undefined or null
					throw new TypeError('Cannot convert undefined or null to object')
				}

				const to = Object(target)

				for (let index = 1; index < arguments.length; index++) {
					// eslint-disable-next-line prefer-rest-params
					const nextSource = arguments[index]

					if (nextSource != null) { // Skip over if undefined or null
						// eslint-disable-next-line no-restricted-syntax
						for (const nextKey in nextSource) {
							// Avoid bugs when hasOwnProperty is shadowed
							if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
								to[nextKey] = nextSource[nextKey]
							}
						}
					}
				}
				return to
			},
			writable: true,
			configurable: true,
		})
	}
	if (!String.prototype.startsWith) {
		// eslint-disable-next-line
		String.prototype.startsWith = function (search, pos) {
			return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search
		}
	}
	if (!String.prototype.includes) {
		// eslint-disable-next-line
		String.prototype.includes = function (search, start) {
			if (typeof start !== 'number') {
				// eslint-disable-next-line no-param-reassign
				start = 0
			}

			if (start + search.length > this.length) {
				return false
			}
			return this.includes(search, start)
		}
	}
}())
