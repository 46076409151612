<template>
	<nav :class="classes" class="navbar">
		<div :class="containerClasses">
			<slot name="brand"></slot>

			<div v-if="showButton" class="navbar-toggler p-0">
				<div v-if="authKit.loggedIn">
					<NLink :to="authKit.user.redirectUrl">
						<button class="btn btn-outline-warning btn-sm">
							Личный кабинет
						</button>
					</NLink>
				</div>
				<div v-else>
					<button v-scroll-to="{ element: '#connection_form', duration: 1000 }" class="btn btn-outline-success btn-sm">
						Попробовать бесплатно
					</button>
					<NLink to="/login">
						<button class="btn btn-outline-warning btn-sm">
							<i class="fa-regular fa-right-to-bracket"></i>
						</button>
					</NLink>
				</div>
			</div>

			<div
				v-show="show"
				v-clickOutside="closeMenu"
				class="navbar-collapse navbar-custom-collapse collapse show"
				:class="menuClasses"
			>
				<slot :close-menu="closeMenu"></slot>
			</div>
		</div>
	</nav>
</template>
<script>
	import { getAuthKit } from '@/assets/js/routerHelper.js'
	import { directiveClickOutsideKit } from '@/assets/js/vueDirectives/directiveClickOutsideKit.js'

	export default {
		name: 'BaseNav',
		directives: {
			clickOutside: directiveClickOutsideKit,
		},
		model: {
			prop: 'show',
			event: 'change',
		},
		props: {
			show: {
				type: Boolean,
				default: false,
				description: 'Whether navbar menu is shown (valid for viewports < specified by `expand` prop)',
			},
			transparent: {
				type: Boolean,
				default: false,
				description: 'Whether navbar is transparent',
			},
			expand: {
				type: String,
				default: 'lg',
				description: 'Breakpoint where nav should expand',
			},
			menuClasses: {
				type: [String, Object, Array],
				default: '',
				description: 'Navbar menu (items) classes. Can be used to align menu items to the right/left',
			},
			containerClasses: {
				type: [String, Object, Array],
				default: 'container',
				description: 'Container classes. Can be used to control container classes (contains both navbar brand and menu items)',
			},
			showButton: {
				type: Boolean,
				default: false,
				description: 'Show navbar button',
			},
			type: {
				type: String,
				default: '',
				validator(value) {
					return ['', 'dark', 'success', 'danger', 'warning', 'white', 'primary', 'light', 'info', 'vue'].includes(value)
				},
				description: 'Navbar color type',
			},
		},
		computed: {
			classes() {
				const color = `bg-${this.type}`
				const classes = [{ 'navbar-transparent': this.transparent }, { [`navbar-expand-${this.expand}`]: this.expand }]
				if (this.position) {
					classes.push(`navbar-${this.position}`)
				}
				if (!this.transparent) {
					classes.push(color)
				}
				return classes
			},
			authKit() {
				return getAuthKit()
			},
			hasMenu() {
				return this.$slots.default
			},
		},
		methods: {
			toggleMenu() {
				this.$emit('change', !this.show)
			},
			closeMenu() {
				this.$emit('change', false)
			},
		},
	}
</script>
