import i18next from 'i18next'
import { setDefaultOptions } from 'date-fns'
import { ru, enUS } from 'date-fns/locale'
import enLangKit from './en/en-EN.js'
import ruLangKit from './ru/ru-RU.js'

const dateFnsLangKit = { ru, en: enUS }

const refreshDateFnsLocale = () => {
	const langName = i18next.language
	const locale = dateFnsLangKit[langName] ?? dateFnsLangKit.ru
	const firstWeekContainsDate = 4
	setDefaultOptions({ locale, firstWeekContainsDate })
}

// import { en, ru } from '@/assets/i18n/tmp.js'
// import { en, ru } from '@/assets/i18n/tmp2.js'
// тестовый вариант организации словарей
// eslint-disable-next-line no-console
// console.log('enLangKit', enLangKit, en, ru)

const supportedLngs = ['ru', 'en']

const LOCALES = {
	ru: {
		translation: ruLangKit,
	},
	en: {
		translation: enLangKit,
	},
}

i18next.init({
	supportedLngs,
	lgn: 'ru',
	fallbackLng: 'ru',
	debug: false,
	resources: LOCALES,
})

class I18nError extends Error {
	name = 'I18nError'

	constructor(key, type = 'key') {
		let message = ''
		if (type === 'key') {
			message = `Invalid i18n key: ${key}`
		} else if (key === 'value') {
			message = `Empty value for i18n key: ${key}`
		}

		super(message)
		Object.setPrototypeOf(this, I18nError.prototype)
	}
}

const translate = (key) => {
	const text = i18next.t(key)
	if (text === key) {
		throw new I18nError(key, 'key')
	}
	if (text === '') {
		throw new I18nError(key, 'value')
	}
	return text.replaceAll('\\n ', '\\n')
}

const changeLang = (langNameNew) => {
	const promise = i18next.changeLanguage(langNameNew).then(() => {
		refreshDateFnsLocale()
	})
	return promise
}
changeLang(i18next.language)

// import { i18n } from '@/assets/i18n/i18nextConfig.js'
export { i18next as i18n, translate, changeLang, LOCALES, supportedLngs }
