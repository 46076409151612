const colorsClasses = [
	'anomaly-yellow',
	'bg-danger',
	'bg-secondary',
]

const list1 = [1, 2, 4, 5, 11, 13, 14, 15, 900]
const list2 = [3, 6, 7, 8, 9, 10, 12, 16, 17, 1000]

const getAnomalyCssColorClass = (number) => {
	if (list1.includes(number)) {
		return colorsClasses[0]
	}
	if (list2.includes(number)) {
		return colorsClasses[1]
	}
	return colorsClasses[2]
}

const titles = {
	1: 'Средний чек возврата значительно превышает средний чек прихода',
	2: 'Высокий процент возврата за период',
	3: 'Аномально высокий процент расчета за безналичный расчет',
	4: 'Значительное падение выручки за период',
	5: 'Падение среднего чека в 2 раза за последний период',
	6: 'Аномально большая сумма возврата',
	7: 'Аномально низкий средний чек за наличный расчёт',
	8: 'Высокая частота возвратов за короткий период',
	9: 'Аномальное постоянство количества дневных продаж',
	10: 'Аномальное постоянство дневной выручки',
	11: 'Нетипичная концентрация выручки в ряде дней',
	12: 'Аномальная концентрация возвратов в ряде дней',
	13: 'Резкое падение продаж в некоторых днях',
	14: 'Отсутствие продаж в ряде дней',
	15: 'Отсутствие продаж в ряде дней',
	16: 'Отсутствие продаж за наличный расчет',
	17: 'Отсутствие продаж в ряде дней',
}

const getAnomalyTittle = (data) => {
	if (data.type === 900 || data.type === 1000) {
		return data.meta.title
	}
	return titles[data.type] ?? ''
}


const recommendations = {
	1: 'Проверить по отчетам, взять на контроль',
	2: 'Проверить по отчетам, взять на контроль',
	3: 'Как правило идет сокрытие выручки за наличный расчет',
	4: 'Возможно сокрытие выручки',
	5: 'Средний чек относительно стабильный показатель, проверить по отчетам',
	6: 'Сумма возврата по чеку, выше максимальной суммы чека прихода, за 15 дней до этого',
	7: 'Возможно идет сокрытие крупных продаж за наличный расчет',
	8: 'Обычно свидетельствует об уменьшении положительного оборота',
	9: 'Проверить дневные показатели продаж, возможно сокрытие продаж сверх \'установленного плана\'',
	10: 'Проверить дневные показатели продаж, возможно сокрытие продаж сверх \'установленного плана\'',
	11: 'Проверить дневные показатели продаж, возможно сокрытие выручки в определенные часы',
	12: 'Как правило свидетельствует о намеренном уменьшении прибыли, путем имитации возврата товарооборота',
	13: 'Необходим детальный анализ ситуации по дням, возможно намеренное сокрытие продаж',
	14: 'Необходим детальный анализ ситуации по дням, возможно намеренное сокрытие продаж',
	15: 'Необходим детальный анализ ситуации по дням, возможно намеренное сокрытие продаж',
	16: 'Необходим детальный анализ ситуации по дням, возможно намеренное сокрытие продаж',
	17: 'Необходим детальный анализ ситуации по дням, возможно намеренное сокрытие продаж',
}

const getAnomalyRecommendation = (data) => {
	if (data.type === 900 || data.type === 1000) {
		return data.meta.description
	}
	return recommendations[data.type] ?? ''
}

export {
	getAnomalyCssColorClass,
	getAnomalyTittle,
	getAnomalyRecommendation,
}
