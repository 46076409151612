import { getRouterRoleList } from '@/assets/js/routerHelper.js'

const hasAccess = ({ accessRoleList = [] } = {}) => {
	if (accessRoleList.includes('all')) {
		return true
	}

	const roleList = getRouterRoleList()
	return accessRoleList.some((role) => roleList.includes(role))
}

export { hasAccess }
