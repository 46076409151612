import { getCorrectTypeValKit } from '@/assets/js/check/getCorrectTypeValKit.js'

const emptyPropsKit = {
	text: '',
	iconCssClass: '',
	itemCssClass: '',
	routeTo: {},
	accessRoleList: [],
	isDisabled: false,
	isLink: false,
	childList: [],
	isShowSupportInfo: false,
	isBeta: false,
	isPageMustContainMap: false,
}

const MenuSideItem = class {
	constructor(valueKit = emptyPropsKit) {
		const correctKit = getCorrectTypeValKit({
			text: [valueKit.text, String],
			iconCssClass: [valueKit.iconCssClass, String],
			itemCssClass: [valueKit.itemCssClass, String],
			routeTo: [valueKit.routeTo, Object],
			accessRoleList: [valueKit.accessRoleList, Array],
			isDisabled: [valueKit.isDisabled, Boolean],
			isLink: [valueKit.isLink, Boolean],
			childList: [valueKit.childList, Array],
			isShowSupportInfo: [valueKit.isShowSupportInfo, Boolean],
			isBeta: [valueKit.isBeta, Boolean],
			isPageMustContainMap: [valueKit.isPageMustContainMap, Boolean],
		})
		this.text = correctKit.text
		this.iconCssClass = correctKit.iconCssClass
		this.itemCssClass = correctKit.itemCssClass
		this.routeTo = correctKit.routeTo
		this.accessRoleList = correctKit.accessRoleList
		this.isDisabled = correctKit.isDisabled
		this.isLink = correctKit.isLink
		this.childList = correctKit.childList
		this.isShowSupportInfo = correctKit.isShowSupportInfo
		this.isBeta = correctKit.isBeta
		this.isPageMustContainMap = correctKit.isPageMustContainMap
	}
}

const def = {
	text: 'test',
	iconCssClass: 'fa-solid fa-file',
	itemCssClass: '',
	// routeTo: {}, // commented to fall with default data
	// accessRoleList: [], // commented to fall with default data
	isDisabled: false,
	isLink: true,
	childList: [],
	isBeta: false,
	isShowSupportInfo: false,
	isPageMustContainMap: false,
}

export { def, MenuSideItem }
export default MenuSideItem
