const unknownKkts = {
	title: 'Неизвестные кассы',
	wasFound: 'Обнаружена',
	wasRejected: 'Архивирована',
	postponed: 'Не актуальна',
	possiblePlacesKkt: 'Возможные места присутствия кассы',
	noPlacesFound: 'Места не обнаружены',
}

export { unknownKkts }
