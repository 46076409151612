<template>
	<div :class="[$style.container, menuCssClass]">
		<MenuActiveToggler></MenuActiveToggler>
	</div>
</template>

<script>
	import MenuActiveToggler from '@/components/other/MenuActiveToggler.vue'
	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'

	export default {
		components: {
			MenuActiveToggler,
		},
		data() {
			return {
				sideMenuConfigVue,
			}
		},
		computed: {
			menuCssClass() {
				return this.$style[this.sideMenuConfigVue.menuSize] ?? ''
			},
		},
	}
</script>

<style module>
	.container {
		position: fixed;
		z-index: 2000;
		bottom: 10px;
		display: flex;
		width: 50px;
		height: 50px;
		align-items: center;
		justify-content: center;
		background-color: var(--green);
		border-radius: 50%;
		transition: left 0.3s;
	}

	.menuBig {
		left: 260px;
	}

	.menuSmall {
		left: 72px;
	}

	.menuHide {
		left: 10px;
	}
</style>
