import Vue from 'vue'

const storeKit = {
	state: () => {
		return {
			list: [],
		}
	},
	getters: {},
	mutations: {
		setList(state, list) {
			state.list = list
		},
		updateNotification(state, data) {
			const itemIndex = state.list.findIndex((item) => item.id === data.id)
			if (itemIndex !== -1) {
				Vue.set(state.list, itemIndex, data)
			}
		},
		addNotification(state, data) {
			state.list.unshift(data)
		},
	},
	actions: {},
}

export default storeKit
