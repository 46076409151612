<template>
	<div class="d-flex flex-column justify-content-start menu-panel-side-list">
		<MenuSideItemContainer
			v-for="(item, itemIndex) in panelSideItemList"
			:key="`MenuSideItemContainer-${itemIndex}`"
			:menu-side-item="item"
		></MenuSideItemContainer>
	</div>
</template>

<script>
	import MenuSideItemContainer from '@/components/menu/menuSide/MenuSideItemContainer.vue'

	export default {
		components: {
			MenuSideItemContainer,
		},
		props: {
			panelSideItemList: { type: Array, default: () => ([]) },
		},
	}
</script>

<style scoped>
.menu-panel-side-list {
	gap: 2px;
}
</style>
