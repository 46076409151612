// const getIsDate = (value) => {
// 	const date = new Date(value)
// 	const dateValue = date.valueOf()
// 	const isDate = !Number.isNaN(dateValue)
// 	return isDate
// }
// const compareDates = (aValue, bValue, isAscending) => {
// 	return isAscending ? new Date(aValue) - new Date(bValue) : new Date(bValue) - new Date(aValue)
// }

const compareNumbers = (aValue, bValue, isAscending) => {
	return isAscending ? aValue - bValue : bValue - aValue
}
const compareStrings = (aValue, bValue, isAscending) => {
	return isAscending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue)
}
const compareArrays = (aValue, bValue, isAscending) => {
	if (aValue.length !== bValue.length) {
		return compareNumbers(aValue.length, bValue.length, isAscending)
	}
	if (typeof aValue[0] === 'number' && typeof bValue[0] === 'number') {
		return compareNumbers(aValue[0], bValue[0], isAscending)
	}
	return compareStrings(String(aValue[0]), String(bValue[0]), isAscending)
}

const getObjectValue = ({ object, valueName }) => {
	if (object[valueName] !== undefined) {
		return object[valueName]
	}

	const partList = valueName.split('.')
	const result = partList.reduce((obj, part) => {
		return (obj === undefined) ? obj : obj?.[part]
	}, object)
	return result
}

export default ({ tableItemList, sortFieldName, isAscending }) => {
	const tableDataClone = [...tableItemList]

	tableDataClone.sort((aObject, bObject) => {
		const aValue = getObjectValue({ object: aObject, valueName: sortFieldName })
		const bValue = getObjectValue({ object: bObject, valueName: sortFieldName })

		if (typeof aValue === 'number' && typeof bValue === 'number') {
			return compareNumbers(aValue, bValue, isAscending)
		}
		if (Array.isArray(aValue) && Array.isArray(bValue)) {
			return compareArrays(aValue, bValue, isAscending)
		}
		// if (getIsDate(aValue) && getIsDate(bValue)) {
		// 	return compareDates(aValue, bValue, isAscending)
		// }

		return compareStrings(String(aValue), String(bValue), isAscending)
	})

	return tableDataClone
}
