export const state = () => ({
	auth: {},
	hiddenNavbar: true,
	isInitMallList: false,
})

export const getters = {
	isNavbarHidden(stateCurrent) {
		return stateCurrent.hiddenNavbar
	},
}

export const mutations = {
	toggleNavbar(stateCurrent, isHidden) {
		stateCurrent.hiddenNavbar = isHidden
	},
	setFavoriteMallList(stateCurrent, value) {
		stateCurrent.auth.user.favorite_shopping_center_ids = value
	},
	setMalls(stateCurrent, malls) {
		if (!stateCurrent.auth.user) {
			stateCurrent.auth.user = {}
		}
		stateCurrent.auth.user.malls = malls
	},
	setIsInitMallList(stateCurrent, bool) {
		stateCurrent.isInitMallList = bool
	},
}

export const actions = {
}
