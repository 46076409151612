import { format, endOfDay, startOfDay } from 'date-fns'
import { getCorrectKit } from '@/assets/js/check/getCorrectKit.js'

const kit = {
	/**
	 *
	 * @param {object} param0
	 * @param {string} param0.mallId
	 * @param {date} param0.dateStart Period start. Required.
	 * @param {date} param0.dateEnd Period end. Required.
	 * @param {array} param0.arrShopId
			Array of shop ids.
			All shops are selected if empty.
	 * @param {array} param0.arrOperationType ['income', 'refund']
			Operation types to include in avg check calculation.
	 * @param {array} param0.arrPaymentMethod ['cash', 'ecash', 'advanced', 'prepaid', 'credit', 'provision']
			Payments methods to include in avg check calculation.
	 * @returns {promise}
	 */
	analyticsChartsDataAvgReceipts: ({
		mallId,
		dateStart,
		dateEnd,
		arrShopId,
		arrOperationType,
		arrPaymentMethod,
	}) => {
		const arrPosiblePaymentMethod = ['cash', 'ecash', 'advanced', 'prepaid', 'credit', 'provision']
		const correctKit = getCorrectKit({
			mallId: { val: mallId, type: String },
			dateStart: { val: dateStart, type: 'dateCorrect' },
			dateEnd: { val: dateEnd, type: 'dateCorrect' },
			arrShopId: { val: arrShopId, type: Array },
			arrOperationType: {
				val: arrOperationType,
				type: Array,
				validator: (arr) => arr.length > 0 && arr.every((val) => ['income', 'refund'].includes(val)),
				valDefault: ['income'],
			},
			arrPaymentMethod: {
				val: arrPaymentMethod,
				type: Array,
				validator: (arr) => arr.length > 0 && arr.every((val) => arrPosiblePaymentMethod.includes(val)),
				valDefault: ['cash'],
			},
		})
		const url = `/api/v2/sc/${mallId}/analytics/charts_data/avg_receipts`
		const params = {
			start_date: format(correctKit.dateStart, 'yyyy-MM-dd'),
			end_date: format(correctKit.dateEnd, 'yyyy-MM-dd'),
			sell_location_ids: correctKit.arrShopId,
			operation_types: correctKit.arrOperationType,
			payment_methods: correctKit.arrPaymentMethod,
		}

		return window.$nuxt.$axios.$post(url, params)
		// {
		//   status: success,
		//   data: [
		//     {
		//       date: // date iso8601,
		//       value: // avg check sum for the day,
		//       sum: // sum for avg,
		//       count: // checks for avg
		//     }
		//   ]
		// }
	},

	modernizedHourlyHeatmap: ({ shoppingCenterId, sellLocationId, dateRange }) => {
		const correctKit = getCorrectKit({
			dateStart: { val: dateRange[0], type: 'dateCorrect' },
			dateEnd: { val: dateRange[1], type: 'dateCorrect' },
		})
		const url = '/analytics/modernized-hourly-heatmap'
		const params = {
			params: {
				entity_id: shoppingCenterId,
				start_date: startOfDay(correctKit.dateStart),
				end_date: endOfDay(correctKit.dateEnd),
			},
		}
		if (sellLocationId) {
			params.params.sl_id = sellLocationId
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	analyticsSensorsHeatmap: ({ shoppingCenterId, period, startDate }) => {
		const url = '/analytics/sensors-heatmap'
		const params = { params: { entity_id: shoppingCenterId, period } }
		if (startDate) {
			params.params.start_date = startDate
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	analyticsAbcXyz: ({ shoppingCenterId, monthFrom, monthTo, positionType, filterCategories, filterFloors }) => {
		const url = '/analytics/abc-xyz'
		const params = {
			params: {
				entity_id: shoppingCenterId,
				month_from: monthFrom,
				month_to: monthTo,
				position_type: positionType,
				filter_categories: filterCategories,
				filter_floors: filterFloors,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},


	analyticsChartCustomizableIncomeData: ({
		mallId,
		dateRange,
		shopIdList,
		refund,
		vat,
		advanced,
		provision,
		prepaid,
		credit,
		isPerArea,
	}) => {
		const url = `api/v2/sc/${mallId}/analytics/charts_data/customizable_income`
		const params = {
			entity_id: mallId,
			start_date: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_date: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
			sell_location_ids: shopIdList,
			refund,
			vat,
			advanced,
			provision,
			prepaid,
			credit,
			per_area: isPerArea,
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	analyticsChartDataByManual: ({ shoppingCenterId, dateRange, sellLocationIdList, isPerArea, roundScale }) => {
		const url = `api/v2/sc/${shoppingCenterId}/analytics/charts_data/by_manual`
		const params = {
			start_date: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_date: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
			per_area: isPerArea,
			round_scale: roundScale,
			sell_location_ids: sellLocationIdList,
		}
		return window.$nuxt.$axios.$post(url, params)
	},


	// analyticsChartsDataV2: ({ mallId, dateRange, shopIdList, isPerArea, roundScale }) => {
	// 	const correctKit = getCorrectKit({
	// 		dateRange0: { val: dateRange[0], type: 'dateCorrect' },
	// 		dateRange1: { val: dateRange[1], type: 'dateCorrect' },
	// 	})
	// 	const url = `/api/v2/sc/${mallId}/analytics/charts_data/chart_data_v2`
	// 	const params = {
	// 		begin_date: format(correctKit.dateRange0, 'yyyy-MM-dd'),
	// 		end_date: format(correctKit.dateRange1, 'yyyy-MM-dd'),
	// 		sell_location_ids: shopIdList,
	// 		is_per_area: isPerArea,
	// 		round_scale: roundScale,
	// 	}
	// 	return window.$nuxt.$axios.$post(url, params)
	// },
	analyticsMallsChartsDataV2: ({ mallIdList, dateRange, shopIdList, isPerArea, roundScale, categoryNameList, profileNameList }) => {
		const correctKit = getCorrectKit({
			dateRange0: { val: dateRange[0], type: 'dateCorrect' },
			dateRange1: { val: dateRange[1], type: 'dateCorrect' },
			mallIdList: { val: mallIdList, type: Array },
		})
		const url = '/api/v2/shopping_centers/analytics/chart_data_v2'
		const params = {
			begin_date: format(correctKit.dateRange0, 'yyyy-MM-dd'),
			end_date: format(correctKit.dateRange1, 'yyyy-MM-dd'),
			shopping_center_ids: correctKit.mallIdList,
			sell_location_ids: shopIdList,
			is_per_area: isPerArea,
			round_scale: roundScale,
			category_names: categoryNameList,
			profile_names: profileNameList,
		}
		return window.$nuxt.$axios.$post(url, params)
	},


	financialKktChart: ({ shoppingCenterId, sellLocationIdList, dateRange }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/analytics/charts_data/kkts_by_days`
		const params = {
			start_day: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_day: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
			round_scale: 0,
			sell_location_ids: sellLocationIdList,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	analyticsMallMapV2: ({ mallId, beginDate, endDate, dataType }) => {
		const url = `/api/v2/sc/${mallId}/analytics/map_locations`
		const params = {
			params: {
				begin_date: beginDate,
				end_date: endDate,
				data_type: dataType,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	analyticsMallMap: ({ shoppingCenterId, dateMonthStringRange, floorId, filterCategories, isByCategories }) => {
		const url = '/analytics/mall-map'
		const params = { params: {
			entity_id: shoppingCenterId,
			month_from: dateMonthStringRange[0],
			month_to: dateMonthStringRange[1],
			floor_id: floorId,
			filter_categories: filterCategories,
			by_categories: isByCategories,
		} }
		return window.$nuxt.$axios.$get(url, params)
	},

	analyticsTop: ({ shoppingCenterId, sellLocationIdList, dateRange, isPerArea, orderField }) => {
		const correctKit = getCorrectKit({
			dateStart: { val: dateRange[0], type: 'dateCorrect' },
			dateEnd: { val: dateRange[1], type: 'dateCorrect' },
		})

		if (correctKit.dateStart > correctKit.dateEnd) {
			const temp = new Date(correctKit.dateEnd)
			correctKit.dateEnd = correctKit.dateStart
			correctKit.dateStart = temp
		}

		const url = `/api/v2/sc/${shoppingCenterId}/analytics/top`
		const params = {
			sell_location_ids: sellLocationIdList,
			per_area: isPerArea,
			begin_date: format(correctKit.dateStart, 'yyyy-MM-dd'),
			end_date: format(correctKit.dateEnd, 'yyyy-MM-dd'),
		}
		if (orderField) {
			params.order_field = orderField
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	operationsChartData: ({ shoppingCenterId, dateRange, sellLocationIdList, isArea, roundScale }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/analytics/charts_data/by_operations`
		const params = {
			start_date: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_date: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
			sell_location_ids: sellLocationIdList,
			per_area: isArea,
			round_scale: roundScale,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	analyticsWeekBySensorsHeatmap: ({ shoppingCenterId, dateStartString }) => {
		const url = '/analytics/week-by-sensors-heatmap'
		const params = {
			params: {
				entity_id: shoppingCenterId,
				period: 'week',
				start_date: dateStartString,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	reportAnalyticsDaysInYear: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/reports/visitors_heatmap/days_in_year.xlsx`
		const params = { responseType: 'blob' }
		return window.$nuxt.$axios.$get(url, params)
	},

	analyticsTreemapSellLocations: ({
		shoppingCenterId,
		dateMonthRange,
		isByCategories,
		filterCategoryList,
		filterFloorList,
	}) => {
		const url = '/analytics/treemap-sell-locations'
		const params = {
			params: {
				entity_id: shoppingCenterId,
				month_from: dateMonthRange[0],
				month_to: dateMonthRange[1],
				filter_categories: filterCategoryList,
				filter_floors: filterFloorList,
			},
		}
		if (isByCategories) {
			params.params.by_categories = isByCategories
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	analyticsMallSellLocations: ({ shoppingCenterId }) => {
		const url = '/analytics/mall/sell_locations'
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},

	analyticsMallData: ({ shoppingCenterId, sellLocationIdList, isShowContract }) => {
		const url = '/analytics/mall/data'
		const params = {
			entity_id: shoppingCenterId,
			sell_location_ids: sellLocationIdList,
			is_show_contract: isShowContract,
			is_show_renter: true,
			type: 'xlsx',
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	analyticsMallIndex: ({ shoppingCenterId, key }) => {
		const url = '/analytics/mall/index'
		const params = {
			responseType: 'blob',
			params: { entity_id: shoppingCenterId, key },
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	analyticsTopMallVisitors: ({ shoppingCenterId, dateRange }) => {
		const correctKit = getCorrectKit({
			dateStart: { val: dateRange[0], type: 'dateCorrect' },
			dateEnd: { val: dateRange[1], type: 'dateCorrect' },
		})
		const url = '/analytics/top/mall-visitors'
		const params = {
			params: {
				entity_id: shoppingCenterId,
				start_date: format(correctKit.dateStart, 'yyyy-MM-dd'),
				end_date: format(correctKit.dateEnd, 'yyyy-MM-dd'),
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	analyticsExtended: ({ mallId, shopId, dateStart, dateEnd, dataType }) => {
		const url = `/api/v2/sc/${mallId}/sell_locations/${shopId}/extended_analytics`
		const params = {
			params: {
				begin_date: dateStart,
				end_date: dateEnd,
				data_type: dataType,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
}

export default kit
