<template>
	<div>
		<TableWithPagination
			class="tableOverflowVisible"
			:table-data="tableItemList"
			:is-show-pagination="false"
			:header-cell-class-name="headerCellClassName"
			:header-row-class-name="headerRowClassName"
			:pagination-class-name="paginationClassName"
		>
			<ElTableColumn prop="format" width="80" sortable>
				<template #default="{ row }">
					<div class="text-center">
						<ElTooltip
							:content="translate('common.toView')"
							placement="top"
						>
							<i class="g-fs-32 cursor-pointer" :class="getFileFormatIcon(row.format)" @click="showPreview(row)"></i>
						</ElTooltip>
					</div>
				</template>
			</ElTableColumn>
			<ElTableColumn prop="name" :label="translate('common.title')" width="250" sortable>
				<template #default="{ row }">
					<div v-if="editData.id === row.id" class="mx-2">
						<BaseInput
							v-model="editData.name"
							rules="required"
							:name="translate('common.fileName')"
							:placeholder="translate('common.fileName')"
							no-space
						></BaseInput>
					</div>
					<template v-else>
						<template v-if="previewFile(row.format)">
							<span class="g-fs-14 g-fw-bold cursor-pointer" @click="showPreview(row)">
								{{ row.name }}
							</span>
						</template>
						<template v-else>
							<span class="g-fs-14 g-fw-bold">
								{{ row.name }}
							</span>
						</template>
					</template>
				</template>
			</ElTableColumn>
			<ElTableColumn prop="description" :label="translate('common.description')" sortable>
				<template #default="{ row }">
					<div v-if="editData.id === row.id" class="mx-2">
						<BaseInput
							v-model="editData.description"
							rules="required"
							:name="translate('common.description')"
							:placeholder="translate('common.description')"
							no-space
						></BaseInput>
					</div>
					<span v-else>{{ row.description }}</span>
				</template>
			</ElTableColumn>
			<ElTableColumn prop="content_type" :label="translate('common.type')" width="150" sortable>
				<template #default="{ row }">
					<div v-if="editData.id === row.id" class="mx-2">
						<ElSelect v-model="editData.content_type">
							<ElOption
								v-for="item in contentTypeList"
								:key="item.type"
								:label="item.description"
								:value="item.type"
							>
							</ElOption>
						</ElSelect>
					</div>
					<span v-else>{{ getFileContentDescription(row.content_type) }}</span>
				</template>
			</ElTableColumn>
			<ElTableColumn prop="created_at" :label="translate('common.createdDate')" width="150" sortable>
				<template #default="{ row }">
					<DateFormatted :date-string="row.created_at" :is-default-format="true"></DateFormatted>
				</template>
			</ElTableColumn>
			<ElTableColumn v-if="isActions" class-name="cellActions" width="70">
				<template #default="{ row }">
					<div v-if="editData.id === row.id">
						<BaseButton
							type="success"
							class="mx-0 p-1 mb-1"
							:disabled="submitted"
							block
							@click="updateFile()"
						>
							<i class="fa-solid fa-check"></i>
						</BaseButton>
						<BaseButton
							type="warning"
							class="m-0 p-1"
							block
							@click="discardUpdate()"
						>
							<i class="fa-solid fa-times"></i>
						</BaseButton>
					</div>
					<DropdownContainer
						v-else
						v-slot="{ itemKit }"
						:item-list="actionList"
					>
						<DropdownItem
							:text="itemKit.text"
							:icon-class="itemKit.iconClass"
							:is-disabled="false"
							@selectItem="onActionSelect(itemKit.value, row)"
						></DropdownItem>
					</DropdownContainer>
				</template>
			</ElTableColumn>
		</TableWithPagination>
		<ModalFilePreview
			v-if="previewData.opened"
			:is-show="previewData.opened"
			:format="previewData.format"
			:file="previewData.file"
			:resource-id="resourceId"
			@closeModal="previewModalClosed()"
		></ModalFilePreview>
	</div>
</template>
<script>
	import { TableColumn, Tooltip, Select, Option } from 'element-ui'
	import BaseButton from '@/components/argon-core/BaseButton.vue'
	import BaseInput from '@/components/argon-core/Inputs/BaseInput.vue'
	import DateFormatted from '@/components/other/DateFormatted.vue'
	import TableWithPagination from '@/components/tables/TableWithPagination.vue'
	import ModalFilePreview from '@/components/modals/ModalFilePreview.vue'
	import DropdownContainer from '@/components/other/DropdownContainer.vue'
	import DropdownItem from '@/components/other/DropdownItem.vue'

	import { getPromiseQuery } from '@/assets/js/query/getPromiseQuery.js'
	import { translate } from '@/assets/i18n/i18nextConfig.js'

	export default {
		components: {
			ModalFilePreview,
			ElTableColumn: TableColumn,
			ElTooltip: Tooltip,
			ElSelect: Select,
			ElOption: Option,
			BaseButton,
			BaseInput,
			DateFormatted,
			TableWithPagination,
			DropdownContainer,
			DropdownItem,
		},
		props: {
			resourceId: { type: String, default: null },
			tableItemList: { type: Array, default: () => ([]) },
			isActions: { type: Boolean, default: true },
			headerCellClassName: { type: String, default: '' },
			headerRowClassName: { type: String, default: 'thead-light' },
			paginationClassName: { type: String, default: '' },
		},
		data() {
			const contentTypeList = [
				{ type: 'form', description: translate('common.questionnaire') },
				{ type: 'contract', description: translate('common.rentContract') },
				{ type: 'sales_data', description: translate('common.salesData') },
				{ type: 'other', description: translate('common.other') },
			]
			return {
				submitted: false,
				editData: {
					id: null,
					name: null,
					description: null,
					content_type: '',
				},
				contentTypeList,
				previewData: {
					opened: false,
					format: '',
					link: '',
					file: {},
				},
				actionList: [
					{
						value: 'download',
						text: translate('common.download'),
						iconClass: 'fa-solid fa-file-download text-primary',
						isDisabled: false,
					},
					{
						value: 'edit',
						text: translate('common.edit'),
						iconClass: 'fa-solid fa-pencil-alt text-warning',
						isDisabled: false,
					},
					{
						value: 'delete',
						text: translate('common.delete'),
						iconClass: 'fa-solid fa-trash text-danger',
						isDisabled: false,
					},
				],
			}
		},
		methods: {
			translate,
			getFileContentDescription(contentType) {
				let fileContent = this.contentTypeList.find((item) => item.type === contentType)
				if (!fileContent) {
					fileContent = {
						type: 'other',
						description: translate('common.other'),
					}
				}
				return fileContent.description
			},
			getFileFormatIcon(format) {
				const kit = {
					pdf: 'fas fa-file-pdf text-danger',
					xls: 'fas fa-file-excel text-success',
					xlsx: 'fas fa-file-excel text-success',
					csv: 'fas fa-file-excel text-success',
					doc: 'fas fa-file-word text-info',
					docx: 'fas fa-file-word text-info',
					png: 'fas fa-file-image text-warning',
					jpg: 'fas fa-file-image text-warning',
					jpeg: 'fas fa-file-image text-warning',
					default: 'fas fa-file text-primary',
				}
				return kit[format] ?? kit.default
			},
			previewFile(format) {
				return ['pdf', 'png', 'jpg', 'jpeg'].includes(format)
			},
			downloadFile(file) {
				getPromiseQuery({
					downloadFile: { fileUrl: file.url },
				})
					.then((response) => {
						const url = window.URL.createObjectURL(new Blob([response]))
						const link = document.createElement('a')
						link.href = url
						link.setAttribute('download', `${file.name}.${file.format}`)
						link.click()
						window.URL.revokeObjectURL(url)
					})
			},
			startEditFile(fileData) {
				this.editData.id = fileData.id
				this.editData.name = fileData.name
				this.editData.description = fileData.description
				this.editData.content_type = fileData.content_type
			},
			updateFile() {
				if (!this.submitted) {
					this.submitted = true
					getPromiseQuery({
						fileResourcesUpdate: {
							fileId: this.editData.id,
							fileName: this.editData.name,
							contentType: this.editData.content_type,
							description: this.editData.description,
						},
					})
						.then((response) => {
							this.submitted = false
							this.$notify({ message: translate('messages.fileInfoUpdateSuccess'), type: 'success' })
							this.discardUpdate()
							this.$emit('editEvent')
						})
						.catch(() => {
							this.submitted = false
						})
				}
			},
			discardUpdate() {
				this.editData.id = null
				this.editData.name = null
				this.editData.description = null
			},
			deleteFile(fileData) {
				this.$emit('deleteEvent', fileData)
			},
			showPreview(file) {
				this.previewData.opened = true
				this.previewData.format = file.format
				this.previewData.link = file.url
				this.previewData.file = file
			},
			previewModalClosed() {
				this.previewData.opened = false
			},
			onActionSelect(value, row) {
				if (value === 'download') {
					this.downloadFile(row)
					return
				}
				if (value === 'edit') {
					this.startEditFile(row)
					return
				}
				if (value === 'delete') {
					this.deleteFile(row)
				}
			},
		},
	}
</script>
<style lang="scss" scoped>

	::v-deep .tableOverflowVisible {
		overflow: visible;

		.el-table {
			overflow: visible;
		}

		.el-table__body-wrapper {
			overflow: visible;
		}
	}

	::v-deep .cellActions .cell {
		overflow: visible;
	}

	::v-deep .el-table__header-wrapper {
		position: sticky;
		z-index: 100;
		top: 0 !important;
	}
</style>
