<template>
	<div
		:class="[
			$style.containerMain,
			sideMenuConfigVue.isMenuActive ? $style.active: ''
		]"
		@click="toggleIsMenuActive()"
	>
		<div :class="$style.lineContainer">
			<div :class="[$style.line, $style.line1]"></div>
			<div :class="[$style.line, $style.line2]"></div>
			<div :class="[$style.line, $style.line3]"></div>
		</div>
	</div>
</template>

<script>
	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'

	export default {
		data() {
			return {
				sideMenuConfigVue,
			}
		},
		methods: {
			toggleIsMenuActive() {
				this.sideMenuConfigVue.toggleIsMenuActive()
			},
		},
	}
</script>

<style lang="scss" module>
	.containerMain {
		cursor: pointer;
	}

	.lineContainer {
		position: relative;
		width: 18px;
		height: 18px;
	}

	.line {
		width: 18px;
		height: 2px;
		background-color: var(--white);
		transform-origin: 2px 2px;
		transition-duration: 0.3s;
		transition-property: width, height, transform, top, bottom, left;
	}

	.line1,
	.line2,
	.line3 {
		position: absolute;
		left: 0;
	}

	.line1 {
		top: 3px;
	}

	.line2 {
		top: 8px;
	}

	.line3 {
		bottom: 3px;
	}

	.active {
		.line1 {
			top: 0;
			width: 24px;
			height: 4px;
			transform: rotate(45deg);
		}

		.line2 {
			left: 50%;
			width: 0;
		}

		.line3 {
			bottom: 0;
			width: 24px;
			height: 4px;
			transform: rotate(-45deg);
		}
	}
</style>
