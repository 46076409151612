<template>
	<div :class="svgClass">
		<IconLogoColor v-if="svgTypeName === 'color'"></IconLogoColor>
		<IconLogoWhite v-else-if="svgTypeName === 'white'"></IconLogoWhite>
		<IconLogoWhiteMini v-else-if="svgTypeName === 'whiteMini'"></IconLogoWhiteMini>
	</div>
</template>

<script>
	import IconLogoColor from '@/components/icons/IconLogoColor.vue'
	import IconLogoWhite from '@/components/icons/IconLogoWhite.vue'
	import IconLogoWhiteMini from '@/components/icons/IconLogoWhiteMini.vue'

	export default {
		components: {
			IconLogoColor,
			IconLogoWhite,
			IconLogoWhiteMini,
		},
		props: {
			svgClass: { type: String, default: 'w-100' },
			svgTypeName: {
				type: String,
				default: 'color',
				validator(value) {
					return ['color', 'white', 'whiteMini'].includes(value)
				},
			},
		},
	}
</script>
