<template>
	<div v-if="false"></div>
</template>

<script>
	import { mapMutations } from 'vuex'
	import { getAuthKit, getAuthUserKit } from '@/assets/js/routerHelper.js'

	export default {
		data() {
			return {
				authKit: getAuthKit(),
				authUserKit: getAuthUserKit(),
				ws: null,
				wsn: null,
			}
		},
		mounted() {
			if (!this.authKit.loggedIn) {
				return
			}
			this.connectGenericWs()
			this.connectNotificationsWs()
		},
		beforeMount() {
			this.$nuxt.$on('getPositionMonthsAndYears', this.onGetPositionMonthsAndYears)
		},
		beforeDestroy() {
			this.ws = null
			this.wsn = null
			this.$nuxt.$off('getPositionMonthsAndYears', this.onGetPositionMonthsAndYears)
		},
		methods: {
			...mapMutations({ addNotification: 'storeNotificationListKit/addNotification' }),
			onGetPositionMonthsAndYears(data) {
				const payload = {
					command: 'message',
					identifier: JSON.stringify({
						channel: 'UserChannel',
						id: data.user_id,
					}),
					data: JSON.stringify({
						action: 'load_position_months',
						user_id: data.user_id,
						position_id: data.position_id,
					}),
				}
				this.ws.send(JSON.stringify(payload))
			},
			connectGenericWs() {
				this.ws = new WebSocket(process.env.websocketUrl)
				this.ws.onopen = (e) => {
					const subscribe = {
						command: 'subscribe',
						identifier: JSON.stringify({ channel: 'UserChannel', id: this.authUserKit.id }),
					}
					this.ws.send(JSON.stringify(subscribe))
				}
				this.ws.onmessage = (e) => {
					const incomingMessage = JSON.parse(e.data)
					if (incomingMessage.message && incomingMessage.identifier) {
						const { action, payload } = incomingMessage.message
						this.$nuxt.$emit(action, payload)
					}
				}
			},
			connectNotificationsWs() {
				this.wsn = new WebSocket(process.env.websocketUrl)
				this.wsn.onopen = (event) => {
					const subscribe = {
						command: 'subscribe',
						identifier: JSON.stringify({
							channel: 'NotificationChannel',
							notification_room_id: this.$auth.user.notification_room_uuid,
						}),
					}
					this.wsn.send(JSON.stringify(subscribe))
				}
				this.wsn.onmessage = (event) => {
					const data = JSON.parse(event.data)
					if (data.identifier && data.message) {
						let message = data.message.message
						let timeout = 5000
						if (data.message.priority > 1) {
							this.addNotification(data.message)
							if (data.message.meta && data.message.notification_type === 'success') {
								if (data.message.meta.action === 'create_sell_location') {
									this.$nuxt.$emit(data.message.meta.action, data.message.meta.id)
									message = `
										${message}
										<br>
										<a 
											class="text-underline text-white"
											target="_blank"
											href="/${data.message.meta.entity_id}/shops/${data.message.meta.id}"
										>Нажмите для перехода</a>`
									timeout = 20000
								} else if (data.message.meta.action === 'create_renter') {
									message = `
										${message}
										<br>
										<a
											class="text-underline text-white"
											target="_blank"
											href="/${data.message.meta.entity_id}/arendators/${data.message.meta.id}"
										>Нажмите для перехода</a>`
									timeout = 20000
								}
							}
							if (
								data.message.meta &&
								data.message.notification_type === 'warning' &&
								data.message.meta.action === 'new_anomaly'
							) {
								message = `
									${message}
									<br>
									<a
										class="text-underline text-white"
										target="_blank"
										href="/${data.message.meta.entity_id}/shops/${data.message.meta.id}"
									>Нажмите для перехода</a>`
								timeout = 20000
							}
						}
						if (!data.message.alert_seen) {
							this.$notify({
								title: data.message.title ? data.message.title : null,
								message,
								timeout,
								icon: 'ni ni-bell-55',
								type: data.message.notification_type,
							})
						}
					}
				}
			},
		},
	}
</script>
