import { queryShopKit } from '@/assets/js/query/queryShopKit.js'
import queryRenterKit from '@/assets/js/query/queryRenterKit.js'
import queryPositionKit from '@/assets/js/query/queryPositionKit.js'
import queryDocKit from '@/assets/js/query/queryDocKit.js'
import querySupportKit from '@/assets/js/query/querySupportKit.js'
import queryBusinessContactsKit from '@/assets/js/query/queryBusinessContactsKit.js'
import queryReportKit from '@/assets/js/query/queryReportKit.js'
import queryAnomalyKit from '@/assets/js/query/queryAnomalyKit.js'
import queryKktKit from '@/assets/js/query/queryKktKit.js'
import queryTrafficKit from '@/assets/js/query/queryTrafficKit.js'
import queryZoneKit from '@/assets/js/query/queryZoneKit.js'
import queryMonitoringKit from '@/assets/js/query/queryMonitoringKit.js'
import queryDashboardKit from '@/assets/js/query/queryDashboardKit.js'
import queryLoginKit from '@/assets/js/query/queryLoginKit.js'
import queryAnalyticsKit from '@/assets/js/query/queryAnalyticsKit.js'
import queryOtherKit from '@/assets/js/query/queryOtherKit.js'
import queryOther2Kit from '@/assets/js/query/queryOther2Kit.js'
import queryOFDCredentialsKit from '@/assets/js/query/queryOFDCredentialsKit.js'
import { queryMallsKit } from '@/assets/js/query/queryMallsKit.js'
import { queryLogsKit } from '@/assets/js/query/queryLogsKit.js'

const getKit = (objList) => {
	const kit = {}
	objList.forEach((queryKit) => {
		Object.entries(queryKit).forEach(([queryName, queryFunc]) => {
			if (kit[queryName]) {
				// eslint-disable-next-line no-console
				console.warn(new Error(`duplicate queryName ${queryName}`))
			}
			kit[queryName] = queryFunc
		})
	})

	return kit
}

const kit = getKit([
	queryShopKit,
	queryRenterKit,
	queryPositionKit,
	queryDocKit,
	querySupportKit,
	queryBusinessContactsKit,
	queryReportKit,
	queryAnomalyKit,
	queryKktKit,
	queryTrafficKit,
	queryZoneKit,
	queryMonitoringKit,
	queryDashboardKit,
	queryLoginKit,
	queryAnalyticsKit,
	queryOtherKit,
	queryOther2Kit,
	queryMallsKit,
	queryOFDCredentialsKit,
	queryLogsKit,
])

const getPromiseQuery = (queryKit) => {
	const [queryName, queryProps] = Object.entries(queryKit)[0]
	const queryFunc = kit[queryName]
	if (!queryFunc) {
		const error = new Error('query not found')
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
	return queryFunc(queryProps)
}

// import { getPromiseQuery } from '@/assets/js/query/getPromiseQuery.js'
export { getPromiseQuery }
