<template>
	<div class="wrapper" translate="no">
		<Notifications></Notifications>
		<MenuMain
			:panel-side-item-list="panelSideItemList"
			:panel-side-bottom-item-list="panelSideBottomItemList"
		>
			<div>
				<AlertProblem
					v-if="reloadAlert && alertData"
					:icon="alertData.icon"
					:text="alertData.text"
					:bg-color="alertData.color"
					@close="closeAlertProblem(alertData.id)"
				></AlertProblem>
				<Nuxt></Nuxt>
			</div>
		</MenuMain>

		<SellLocationAnomaliesModal
			v-if="anomalyModalData.isTableModalOpened"
			:shop-name="anomalyModalData.name"
			:opened="anomalyModalData.isTableModalOpened"
			:initital-data="anomalyModalData.tableDataInitital"
			:shop-id="anomalyModalData.id"
			:show-archived-initialy="anomalyModalData.showArchived"
			@update-opened="handleAnomalyModalClose"
			@anomalyCounterChange="handleAnomalyCounterChange"
		></SellLocationAnomaliesModal>
		<LogRocket></LogRocket>
		<SentrySetUser></SentrySetUser>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	import SellLocationAnomaliesModal from '@/components/SellLocationAnomaliesModal.vue'
	import MenuMain from '@/components/menu/MenuMain.vue'
	import AlertProblem from '@/components/notification/AlertProblem.vue'
	import LogRocket from '@/components/LogRocket.vue'
	import SentrySetUser from '@/components/SentrySetUser.vue'

	import { getMenuItemList } from '@/assets/js/sideMenu/getMenuItemList.js'
	import { getMenuItemListBottom } from '@/assets/js/sideMenu/getMenuItemListBottom.js'
	import { getAuthKit, getRouterMallId, getRouterMallKit } from '@/assets/js/routerHelper.js'
	import { translate } from '@/assets/i18n/i18nextConfig.js'

	export default {
		components: {
			MenuMain,
			SellLocationAnomaliesModal,
			AlertProblem,
			LogRocket,
			SentrySetUser,
		},
		data() {
			return {
				shoppingCenterId: getRouterMallId(),
				panelSideItemList: getMenuItemList({ }),
				panelSideBottomItemList: getMenuItemListBottom({ }),
				anomalyModalData: {
					id: '',
					name: '',
					isTableModalOpened: false,
					tableDataInitial: [],
					showArchived: false,
				},
				ws: null,
				wsn: null,
				systemStatus: 'hidden',
				reloadAlert: false,
				alertData: {},
			}
		},
		head() {
			return {
				title: `RENTU | ${this.currentMall.title}`,
				meta: [
					{ hid: 'robots', name: 'robots', content: 'noindex, nofollow' },
					{ hid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=0.31' },
				],
			}
		},
		computed: {
			authKit() {
				return getAuthKit()
			},
			watchIsOffline() {
				return this.$nuxt.isOffline
			},
			watchIsOnline() {
				return this.$nuxt.isOnline
			},
			currentMall() {
				if (getRouterMallId() && this.authKit.user) {
					return getRouterMallKit()
				}
				return {}
			},
			alertList() {
				return this.authKit.user.alerts || []
			},
		},
		watch: {
			$route() {
				const mallId = getRouterMallId()
				const isChanged = mallId !== this.shoppingCenterId
				if (isChanged) {
					this.panelSideItemList = getMenuItemList({ })
					this.panelSideBottomItemList = getMenuItemListBottom({ })
				}
				this.mallId = mallId
			},
			watchIsOffline(newVal, oldVal) {
				if (!newVal) {
					return
				}
				this.$notify({
					verticalAlign: 'top',
					horizontalAlign: 'left',
					message: translate('common.connectionLost'),
					timeout: 5000,
					icon: 'ni ni-bell-55',
					type: 'danger',
				})
			},
			watchIsOnline(newVal, oldVal) {
				if (!newVal) {
					return
				}
				this.$notify({
					verticalAlign: 'top',
					horizontalAlign: 'left',
					message: translate('common.connectionRestored'),
					timeout: 5000,
					icon: 'ni ni-bell-55',
					type: 'success',
				})
			},
		},
		beforeMount() {
			this.$nuxt.$on('anomalyModalOpenEvent', this.anomalyModalOpenEvent)
			this.$nuxt.$on('anomalyModalArchivedActiveEvent', this.onAnomalyModalArchivedActiveEvent)
			this.$nuxt.$on('getPositionMonthsAndYears', this.onGetPositionMonthsAndYears)
		},
		mounted() {
			// const statusStorageString = localStorage.getItem('system_status')
			const statusStorageString = null

			if (statusStorageString) {
				let statusStorage = {}
				try {
					statusStorage = JSON.parse(statusStorageString)
				} catch (e) {
					statusStorage = {}
				}

				if (new Date().getTime() > statusStorage.expiry) {
					localStorage.removeItem('system_status')
					this.systemStatus = ''
				} else {
					this.systemStatus = statusStorage.item || ''
				}
			}
			this.getAlertData()
		},
		beforeDestroy() {
			this.$nuxt.$off('anomalyModalOpenEvent', this.anomalyModalOpenEvent)
			this.$nuxt.$off('anomalyModalArchivedActiveEvent', this.onAnomalyModalArchivedActiveEvent)
			this.$nuxt.$off('getPositionMonthsAndYears', this.onGetPositionMonthsAndYears)
		},
		methods: {
			...mapMutations({ addNotification: 'storeNotificationListKit/addNotification' }),
			anomalyModalOpenEvent(data) {
				this.anomalyModalData = data
			},
			onAnomalyModalArchivedActiveEvent() {
				this.anomalyModalData.showArchived = true
			},
			onGetPositionMonthsAndYears(data) {
				const payload = {
					command: 'message',
					identifier: JSON.stringify({
						channel: 'UserChannel',
						id: data.user_id,
					}),
					data: JSON.stringify({
						action: 'load_position_months',
						user_id: data.user_id,
						position_id: data.position_id,
					}),
				}
				this.ws.send(JSON.stringify(payload))
			},
			handleAnomalyModalClose() {
				this.anomalyModalData.isTableModalOpened = false
				this.$nuxt.$emit(`${this.anomalyModalData.id}CloseEvent`, {
					isTableModalOpened: this.anomalyModalData.isTableModalOpened,
				})
			},
			handleAnomalyCounterChange(level) {
				this.$nuxt.$emit(`${this.anomalyModalData.id}AnomalyCounterDecrement`, level)
			},
			getAlertData() {
				const currentRouteName = this.$route.name
				const currentMallId = this.currentMall ? this.currentMall.id : null
				const result = this.alertList.find((alertItem) => alertItem.sc_ids.includes(currentMallId) && (
					alertItem.pages.includes(currentRouteName) || alertItem.pages.length === 0
				))
				if (result && !this.getCookie(`rentu-alert-id-${result.id}`)) {
					this.alertData = result
					this.reloadAlert = true
				}
			},
			getCookie(cookieName) {
				const cookie = {}
				document.cookie.split(';').forEach((el) => {
					const [key, value] = el.split('=')
					cookie[key.trim()] = value
				})
				return cookie[cookieName]
			},
			closeAlertProblem(id) {
				const oneDay = 86400e3
				const date = new Date(Date.now() + oneDay).toUTCString()
				document.cookie = `rentu-alert-id-${id}=true; expires=${date}`
				this.reloadAlert = false
				this.getAlertData()
			},
		},
	}
</script>
<style lang="scss" scoped>
	.systemstatus {
		position: relative;
		z-index: 1000;
		background: var(--yellow);

		&__close {
			position: absolute;
			top: 50%;
			right: 12px;
			cursor: pointer;
			font-size: 22px;
			transform: translateY(-50%);
		}
	}

	::v-deep .badge-floating {
		position: absolute;
		top: -22px;
	}
</style>
