
const getWindowWidth = () => {
	return window.innerWidth
}
const calcKit = (object) => {
	const width = getWindowWidth()

	object.width = width

	object.isMobile = width <= 760

	object.isXs = width >= 0 && width < 576
	object.isSm = width >= 576 && width < 768
	object.isMd = width >= 768 && width < 992
	object.isLg = width >= 992 && width < 1200
	object.isXl = width >= 1200
}

const windowWidthKit = { }

if (process.browser) {
	calcKit(windowWidthKit)
	window.addEventListener('resize', (event) => {
		windowWidthKit.event = event
		windowWidthKit.timeStamp = event.timeStamp
		calcKit(windowWidthKit)
	})
}

export { windowWidthKit }
