<template>
	<BaseDropdown
		class="clear-button"
		tag="li"
		:title-classes="`nav-link dropdown-transparent notifications-dropdown ${$style.notificationDropdown}`"
		title-tag="a"
		menu-classes="dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
		@change="setNotificationsSeen($event)"
	>
		<template #title>
			<div class="position-relative d-inline">
				<i class="fa-solid fa-bell g-fs-20 text-white"></i>
				<span
					v-if="unseenNotificationsCount > 0"
					:class="[$style.notificationCountBadge, 'badge badge-pill badge-danger border-white']"
				>{{ unseenNotificationsCount }}</span>
			</div>
		</template>

		<div class="px-3 py-3">
			<h6 class="text-sm text-muted m-0">{{ translate('common.notifications') }}</h6>
		</div>
		<div :class="[$style.notificationListContainer, 'list-group list-group-flush']">
			<DropdownNotificationItem
				v-for="notification in notificationList"
				:key="notification.id"
				:title="notification.title"
				:body="notification.message"
				:created-at="notification.created_at"
				:seen="notification.seen"
				:priority="notification.priority"
				:meta="notification.meta"
				:entity="notification.entity"
			></DropdownNotificationItem>
		</div>
	</BaseDropdown>
</template>

<script>
	import * as queryString from 'querystring'
	import { mapState, mapMutations } from 'vuex'

	import BaseDropdown from '@/components/argon-core/BaseDropdown.vue'
	import DropdownNotificationItem from '@/components/DropdownNotificationItem.vue'

	import { translate } from '@/assets/i18n/i18nextConfig.js'
	import { getPromiseQuery } from '@/assets/js/query/getPromiseQuery.js'

	export default {
		components: {
			BaseDropdown,
			DropdownNotificationItem,
		},
		data() {
			return {
				isFetchedNotifications: false,
				nextNotificationCursor: '',
			}
		},
		computed: {
			...mapState(['storeNotificationListKit']),
			notificationList() {
				return this.storeNotificationListKit.list
			},
			unseenNotificationsCount() {
				return this.notificationList.filter((notification) => !notification.seen).length
			},
		},
		mounted() {
			this.getNotifications()
		},
		methods: {
			translate,
			...mapMutations({
				setNotificationList: 'storeNotificationListKit/setList',
				updateNotification: 'storeNotificationListKit/updateNotification',
			}),
			setNotificationsSeen(state) {
				if (!state) {
					return
				}

				setTimeout(() => {
					this.notificationList.forEach((notification) => {
						if (notification.seen) {
							return
						}

						getPromiseQuery({
							notificationSetSeen: { notificationId: notification.id },
						}).then((res) => {
							if (res.status !== 200) {
								return
							}

							const notificationCopy = { ...notification }
							notificationCopy.seen = true
							this.updateNotification(notificationCopy)
						})
					})
				}, 500)
			},
			getNotifications() {
				if (this.isFetchedNotifications) {
					return
				}
				const cursor = queryString.stringify({ cursor: this.nextNotificationCursor })
				getPromiseQuery({ notificationList: { cursor } }).then((data) => {
					this.isFetchedNotifications = true
					this.currentNotificationCursor = this.nextNotificationCursor
					this.nextNotificationCursor = data.cursor

					const listFiltered = data.list.map((notification) => {
						const notificationData = {
							id: notification.id,
							title: notification.title,
							created_at: notification.created_at,
							message: notification.message,
							notificationType: notification.notification_type,
							priority: notification.priority,
							seen: notification.seen,
							meta: notification.meta,
							entity: notification.entity,
						}
						if (!notification.alert_seen) {
							this.$notify({ message: notification.message, type: notification.type })
						}

						return notificationData
					})

					this.setNotificationList(listFiltered)
				})
			},
		},
	}
</script>

<style lang="scss" module>
	.notificationCountBadge {
		position: absolute;
		top: -9px;
		left: 6px;
		padding: 2px 4px;
		border: 2px solid var(--white);
		font-size: 10px;
		transform: none;
	}

	.notificationListContainer {
		max-height: 50vh;
		overflow-y: auto;
	}

	.notificationDropdown::after {
		content: none !important;
	}
</style>
