const BASE_DOCS_ROUTE = '/api/v2/content'

const kit = {
	docCategories: () => {
		const url = `${BASE_DOCS_ROUTE}/categories`
		return window.$nuxt.$axios.$get(url)
	},

	docReorderCategories: ({ categoryIds }) => {
		const url = `${BASE_DOCS_ROUTE}/categories/reorder`
		const params = { category_ids: categoryIds }
		return window.$nuxt.$axios.$put(url, params)
	},

	docAddCategory: ({ title, slug }) => {
		const url = `${BASE_DOCS_ROUTE}/categories`
		const params = { title, slug }
		return window.$nuxt.$axios.$post(url, params)
	},
	docUpdateCategory: ({ categoryId, title, slug }) => {
		const url = `${BASE_DOCS_ROUTE}/categories/${categoryId}`
		const params = { title, slug }
		return window.$nuxt.$axios.$put(url, params)
	},
	docDeleteCategory: ({ categoryId }) => {
		const url = `${BASE_DOCS_ROUTE}/categories/${categoryId}`
		return window.$nuxt.$axios.$delete(url)
	},

	docPage: ({ pageId }) => {
		const url = `${BASE_DOCS_ROUTE}/pages/${pageId}`
		return window.$nuxt.$axios.$get(url)
	},
	docAddPage: ({
		categoryId,
		title,
		slug,
		contentHtml,
		description,
	}) => {
		const url = `${BASE_DOCS_ROUTE}/pages`
		const params = {
			category_id: categoryId,
			title,
			slug,
			html_content: contentHtml,
			sorting_number: 0,
			draft: true,
			description,
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	docUpdatePage: ({
		pageId,
		categoryId,
		title,
		slug,
		contentHtml,
		draft,
		description,
	}) => {
		const url = `${BASE_DOCS_ROUTE}/pages/${pageId}`
		const params = {
			category_id: categoryId,
			title,
			slug,
			html_content: contentHtml,
			draft,
			description,
		}
		return window.$nuxt.$axios.$put(url, params)
	},
	docDeletePage: ({ pageId }) => {
		const url = `${BASE_DOCS_ROUTE}/pages/${pageId}`
		return window.$nuxt.$axios.$delete(url)
	},
	docReorderPages: ({ categoryId, pageIds }) => {
		const url = `${BASE_DOCS_ROUTE}/categories/${categoryId}/pages/reorder`
		const params = { page_ids: pageIds }
		return window.$nuxt.$axios.$put(url, params)
	},
}

export default kit
