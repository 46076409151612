export default ({ app }) => {
	// Include Google Analytics Script
	(function tmp(w, d, s, l, i) {
		w[l] = w[l] || []; w[l].push({ 'gtm.start':
			new Date().getTime(),
		event: 'gtm.js' }); const f = d.getElementsByTagName(s)[0]
		const j = d.createElement(s); const dl = l !== 'dataLayer' ? `&l=${l}` : ''; j.async = true; j.src =
		`https://www.googletagmanager.com/gtag/js?id=${i}${dl}`; f.parentNode.insertBefore(j, f)
	}(window, document, 'script', 'dataLayer', 'UA-152512243-1'))
	// Set the current page
	window.dataLayer = window.dataLayer || []
	// eslint-disable-next-line prefer-rest-params
	function gtag() { window.dataLayer.push(arguments) }
	gtag('js', new Date())
	gtag('config', 'UA-152512243-1')
}
