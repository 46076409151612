import { getIsValidValueConstructor } from '@/assets/js/check/parts/getIsValidValueConstructor.js'
import { getConstructorDefault } from '@/assets/js/check/parts/getConstructorDefault.js'

const typeCustomConfigKit = {
	dateCorrect: {
		constructor: Date,
		getIsValid: (val) => {
			return Number.isNaN(val.getTime()) === false
		},
		getDefault: () => {
			return new Date()
		},
	},
	integer: {
		constructor: Number,
		getIsValid: (val) => {
			return Number.isInteger(val)
		},
		getDefault: () => {
			return 0
		},
	},
}

const getTypeValCustomInfo = (val, type) => {
	const typeCustomConfig = typeCustomConfigKit[type]
	const isTypeCorrect = getIsValidValueConstructor(val, typeCustomConfig.constructor)
	const isValCorrect = isTypeCorrect ? typeCustomConfig.getIsValid(val) : false
	const valCorrect = isTypeCorrect && isValCorrect ? val : typeCustomConfig.getDefault()

	const info = {
		paramKit: { val, type },
		info: {
			isTypeCustom: true,
			isTypeCorrect,
			isValCorrect,
		},
		hasError: !isTypeCorrect || !isValCorrect,
		valCorrect,
	}
	return Object.freeze(info)
}

const getValTypeInfo = (val, type) => {
	if (typeCustomConfigKit[type]) {
		return getTypeValCustomInfo(val, type)
	}

	const isTypeCorrect = getIsValidValueConstructor(val, type)
	const valCorrect = isTypeCorrect ? val : getConstructorDefault(type)

	const info = {
		paramKit: { val, type },
		info: {
			isTypeCustom: false,
			isTypeCorrect,
			isValCorrect: isTypeCorrect,
		},
		hasError: !isTypeCorrect,
		valCorrect,
	}
	return Object.freeze(info)
}

export { getValTypeInfo }
