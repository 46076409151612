import Vue from 'vue'
import { depotVue } from '@/assets/js/depotVue.js'


const getDepotPromise = (objectName) => {
	const funcDepotName = `${objectName}_getPromiseRequest`
	if (!depotVue[funcDepotName]) {
		// eslint-disable-next-line no-console
		console.warn(new Error('depotVue[funcDepotName] not found'), funcDepotName)
	}
	return depotVue[funcDepotName]
}


/**
getPromiseDepotRequest([ 'positionList', 'floorList', 'sectorList' ])
 */
const getPromiseDepotRequest = (objectNameList) => {
	const params = { isNeedResetCache: false }

	const promiseAll = []
	objectNameList.forEach((objectName) => {
		const funcDepot = getDepotPromise(objectName)
		const promise = funcDepot(params)
		promiseAll.push(promise)
	})

	return Promise.all(promiseAll)
}

const getPromiseDepotUpdateRequest = (objectNameList) => {
	const params = { isNeedResetCache: true }

	const promiseAll = []
	objectNameList.forEach((objectName) => {
		const funcDepot = getDepotPromise(objectName)
		const promise = funcDepot(params)
		promiseAll.push(promise)
	})

	return Promise.all(promiseAll)
}


/**
const objIsReady = getObjIsReady(['positionList', 'floorList', 'sectorList'])
 */
const getObjIsReady = (trackNameList) => {
	const vueInstance = new Vue({
		computed: {
			isReady() {
				return trackNameList.every((trackName) => depotVue[`${trackName}_isReady`])
			},
		},
	})
	return vueInstance
}


export {
	depotVue as depot,
	getObjIsReady,
	getPromiseDepotRequest,
	getPromiseDepotUpdateRequest,
}
