const accessRoleListKit = {
	allRoles: ['all'],
	common: ['admin', 'director', 'manager', 'marketer', 'visitor', 'operator'],
	editCommon: ['admin', 'director', 'manager', 'operator'],
	showFinancial: ['admin', 'director', 'manager', 'marketer', 'visitor'],
	downloadReport: ['admin', 'director', 'manager', 'marketer'],
	renterChat: ['admin', 'director', 'manager', 'marketer'],
	editTurnover: ['admin', 'director', 'manager', 'staff'],
	editRoles: ['admin', 'director'],
	showAttendance: ['admin', 'director', 'manager', 'marketer', 'visitor', 'operator', 'traffic_director'],
	editRolesSPP: ['admin', 'traffic_director'],
	showAttendanceSPP: ['admin', 'traffic_manager', 'traffic_viewer'],
	editSensorsSPP: ['admin', 'traffic_manager'],
	admin: ['admin'],
	staff: ['staff'],
	allMalls: ['systemAdmin', 'systemStaff', 'admin', 'director', 'manager', 'marketer', 'visitor', 'staff'],
	system: ['systemAdmin', 'systemStaff'],
	systemAdmin: ['systemAdmin'],
	systemStaff: ['systemStaff'],
	staffAll: ['systemStaff', 'staff'],
}

const allSet = new Set()
Object.entries(accessRoleListKit).forEach(([key, list]) => {
	list.forEach((roleName) => {
		allSet.add(roleName)
	})
})
accessRoleListKit.all = [...allSet]
Object.freeze(accessRoleListKit)

export { accessRoleListKit }
