export default {
	thisMonth: 'Этот месяц',
	dateNameKit: {
		short: {
			week: 'нед.',
			year: 'г.',
		},
	},
	long: {
		sunday: 'Воскресенье',
		monday: 'Понедельник',
		tuesday: 'Вторник',
		wednesday: 'Среда',
		thursday: 'Четверг',
		friday: 'Пятница',
		saturday: 'Суббота',
	},
	short: {
		sunday: 'Вс',
		monday: 'Пн',
		tuesday: 'Вт',
		wednesday: 'Ср',
		thursday: 'Чт',
		friday: 'Пт',
		saturday: 'Сб',
	},
	shortByNumber: {
		0: 'Вс',
		1: 'Пн',
		2: 'Вт',
		3: 'Ср',
		4: 'Чт',
		5: 'Пт',
		6: 'Сб',
	},
	shortByNumber7: {
		1: 'Пн',
		2: 'Вт',
		3: 'Ср',
		4: 'Чт',
		5: 'Пт',
		6: 'Сб',
		7: 'Вс',
	},
	arrMonthCompare: [
		'январем',
		'февралем',
		'мартом',
		'апрелем',
		'маем',
		'июнем',
		'июлем',
		'августом',
		'сентябрем',
		'октябрем',
		'ноябрем',
		'декабрем',
	],
}
