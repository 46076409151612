import { getAuthKit, getAuthMallList, getAuthRoleListByMallId, getAuthMallKitByMallId } from '@/assets/js/routerHelper.js'
import { accessRoleListKit } from '@/assets/js/access/accessRoleListKit.js'

const getRedirectUrlForEntity = ({ route }) => {
	const mallId = route.params?.entity ?? ''
	const mallKit = getAuthMallKitByMallId(mallId)
	const mallKitRoleList = mallKit?.sc_roles ?? []

	let urlKitName = mallKit.role
	if (!mallKitRoleList.includes('staff') && mallKit.level_of_restrictions === 'payment_required') {
		urlKitName = 'payment_required'
	}
	if (!mallKitRoleList.includes('staff') && mallKit.level_of_restrictions === 'suspended') {
		urlKitName = 'suspended'
	}
	if (!mallKitRoleList.includes('staff') && mallKit.level_of_restrictions === 'contract_suspended') {
		urlKitName = 'contract_suspended'
	}

	const urlKit = {
		default: `/${mallId}/monitoring`,
		traffic_director: `/${mallId}/analysis/info`,
		traffic_manager: `/${mallId}/analysis/info`,
		traffic_viewer: `/${mallId}/analysis/info`,
		payment_required: `/${mallId}/payment_required`,
		suspended: `/${mallId}/suspended`,
		contract_suspended: `/${mallId}/contract_suspended`,
	}
	return urlKit[urlKitName] ?? urlKit.default
}

const accessKitByRouterName = {
	logs: { accessList: accessRoleListKit.system, redirectUrl: '/entities' },
}


const getIsPageAllAccess = (strRouterName) => {
	return ['index', 'login', 'api-docs'].includes(strRouterName)
}
const getIsUserLogIn = () => {
	const authKit = getAuthKit()
	return Boolean(authKit.loggedIn)
}
const getIsEmptyMallsNeedRedirect = (strRouterName) => {
	const mallList = getAuthMallList()
	const isEmptyMallList = mallList.length === 0
	const isPageEntities = strRouterName === 'entities'
	return isEmptyMallList && isPageEntities
}
const getIsEmptyMallsNeedRedirectEntities = (strRouterName) => {
	const mallList = getAuthMallList()
	const isEmptyMallList = mallList.length === 0
	const isPageEntities = strRouterName === 'entities'
	return isEmptyMallList && isPageEntities === false
}
const getIsMallAvailable = (route) => {
	const mallList = getAuthMallList()
	const isPageMall = Boolean(route.params.entity)
	const isValidMallId = mallList.some((item) => item.id === route.params.entity)
	return !isPageMall || isValidMallId
}


export default (context) => {
	const { redirect, route } = context
	const strRouterName = route.name

	if (strRouterName === 'entity-weekly-report-report_key') {
		return redirect()
	}

	const isPageAllAccess = getIsPageAllAccess(strRouterName)
	if (isPageAllAccess) {
		return redirect()
	}

	const isUserLogIn = getIsUserLogIn()
	if (isUserLogIn === false) {
		localStorage.setItem('pagePathBeforeLogin', route.path)
		return redirect('/login')
	}

	const isEmptyMallsNeedRedirect = getIsEmptyMallsNeedRedirect(strRouterName)
	if (isEmptyMallsNeedRedirect) {
		return redirect()
	}
	const isEmptyMallsNeedRedirectEntities = getIsEmptyMallsNeedRedirectEntities(strRouterName)
	if (isEmptyMallsNeedRedirectEntities) {
		return redirect('/entities')
	}

	const isMallAvailable = getIsMallAvailable(route)
	if (isMallAvailable === false) {
		return redirect('/entities')
	}


	const isPageEntity = strRouterName === 'entity'
	if (isPageEntity) {
		return redirect(getRedirectUrlForEntity({ route }))
	}


	const accessKit = accessKitByRouterName[strRouterName]
	if (accessKit === undefined) {
		return redirect()
	}


	const mallId = route.params?.entity ?? ''
	const roleList = getAuthRoleListByMallId(mallId)
	const hasAccess = roleList.some((role) => accessKit.accessList.includes(role))
	if (hasAccess) {
		return redirect()
	}
	return redirect(accessKit.redirectUrl)
}
