
	import Vue from 'vue'
	import Draggable from 'vuedraggable'
	import DocsMenuLink from '@/components/pages/docs/DocsMenu/DocsMenuLink.vue'
	import DocsMenuButtons from '@/components/pages/docs/DocsMenu/DocsMenuButtons.vue'
	import LightTooltip from '@/components/LightTooltip.vue'

	import { translate } from '@/assets/i18n/i18nextConfig.js'
	import { DocsMenuItem } from '@/types/docs.ts'
	import { getAuthUserKit } from '@/assets/js/routerHelper.js'
	import { getPromiseQuery } from '@/assets/js/query/getPromiseQuery.js'

	export default Vue.component('DocsMenuElement', {
		components: {
			Draggable,
			DocsMenuLink,
			DocsMenuButtons,
			LightTooltip,
		},
		props: {
			item: { type: Object, default: () => ({}) },
			type: {
				type: String,
				default: 'category',
				validator(value: string): boolean {
					return ['page', 'category'].includes(value)
				},
			},
		},
		data() {
			return {
				open: false,
			}
		},
		computed: {
			Item(): DocsMenuItem {
				return this.item as DocsMenuItem
			},
			Children: {
				get(): DocsMenuItem[] {
					return this.Item.children
				},
				set(children: DocsMenuItem[]) {
					this.Item.children = children
					this.updateOrder(children.map((c) => c.id))
				},
			},
			isActive(): boolean {
				return this.checkIsActive(this.Item)
			},
			hasActiveChild(): boolean {
				return this.Children.some(this.checkIsActive)
			},
			isOpen(): boolean {
				return this.isActive || this.hasActiveChild
			},
			isStaff(): boolean {
				return getAuthUserKit()?.is_staff
			},
		},
		watch: {
			// eslint-disable-next-line func-names
			'$route.path': function () {
				this.open = this.isOpen
			},
		},
		mounted(): void {
			this.open = this.isOpen
		},
		methods: {
			translate,
			checkIsActive(item: DocsMenuItem): boolean {
				const w = window as any
				const params = w.$nuxt.$route?.params
				return params?.id === item.id || params?.slug === item.slug
			},
			updateOrder(pageIds: string[]): void {
				if (!this.isStaff) {
					return
				}

				const categoryId = this.Item.id
				getPromiseQuery({ docReorderPages: { categoryId, pageIds } })
			},
			handleToggle(event: any): void {
				this.open = event.target.open
			},
		},
	})
