<template>
	<div
		class="list-group-item list-group-item-action notification-item"
		:class="seen ? '' : 'unseen-notification'"
	>
		<div class="d-flex justify-content-between align-items-center">
			<h4 class="mb-0 text-sm">{{ title ? title : translate('common.notification') }}</h4>
			<div class="text-right text-muted g-fs-12">{{ timePassed }}</div>
		</div>
		<p class="text-sm mb-0 mt-1">{{ body }}</p>
		<p v-if="entity" class="d-block text-gray g-fs-10 mb-0 mt-1">{{ entity.name }}</p>
	</div>
</template>

<script>
	import { translate } from '@/assets/i18n/i18nextConfig.js'
	import { format, formatDistance, differenceInDays } from 'date-fns'

	export default {
		props: {
			title: {
				type: String,
				default: undefined,
				description: 'Title of notification',
			},
			body: {
				type: String,
				default: undefined,
				description: 'Body of notification',
			},
			createdAt: {
				type: String,
				default: undefined,
				description: 'Notification create_at',
			},
			seen: {
				type: Boolean,
				default: true,
				description: 'Was notification seen',
			},
			priority: {
				type: Number,
				default: 1,
				description: 'Notification priority (low = 1, medium = 2, high = 3)',
			},
			meta: {
				type: Object,
				default: () => {},
				description: 'meta information to use',
			},
			entity: {
				type: Object,
				default: () => {},
				description: 'meta information to use',
			},
		},
		computed: {
			timePassed() {
				const date = new Date(this.createdAt)
				const now = new Date()
				const diffDays = differenceInDays(now, date)
				if (diffDays < 1) {
					return formatDistance(date, now)
				}
				return format(new Date(date), 'dd MMM H:mm')
			},
		},
		methods: {
			translate,
		},
	}
</script>
<style scoped lang="scss">
	.notification-item {
		transition: all 0.5s ease;

		&.unseen-notification {
			background-color: #dcdcdc !important;
		}
	}
</style>
