var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('NuxtLink',{class:[
		'docs-menu-item-link',
		_vm.isActive ? 'docs-menu-item-link_active' : '',
	],attrs:{"to":{
		name: _vm.routeName,
		params: { slug: _vm.Item.slug, id: _vm.Item.id },
	}}},[_vm._v(_vm._s(_vm.Item.title))])
}
var staticRenderFns = []

export { render, staticRenderFns }