const kit = {
	renterList: ({ mallId }) => {
		const url = `/api/v2/sc/${mallId}/renters`
		return window.$nuxt.$axios.$get(url)
	},
	renterShortList: ({ mallId }) => {
		const url = '/arendators'
		const params = { params: { entity_id: mallId, type: 'short' } }
		return window.$nuxt.$axios.$get(url, params)
	},

	renterInfo: ({ mallId, renterId }) => {
		const url = `/arendators/${renterId}`
		const params = { params: { entity_id: mallId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	renterCreate: ({
		mallId,
		inn,
		kpp,
		legalAddress,
		legalName,
		ownerName,
		ownerEmail,
		ownerPhone,
		managerName,
		managerEmail,
		managerPhone,
		comment,
		contracts,
	}) => {
		const url = '/arendators'
		const data = {
			entity_id: mallId,
			inn,
			kpp,
			legalName,
			legalAddress,
			ownerName,
			ownerEmail,
			ownerPhone,
			managerName,
			managerEmail,
			managerPhone,
			comment,
			contracts,
		}
		const params = { params: { entity_id: mallId } }
		return window.$nuxt.$axios.$post(url, data, params)
	},
	renterUpdate: ({
		mallId,
		renterId,
		legalName,
		legalAddress,
		ownerName,
		ownerEmail,
		ownerPhone,
		managerName,
		managerEmail,
		managerPhone,
		contracts,
	}) => {
		const url = `/arendators/${renterId}`
		const params = {
			entity_id: mallId,
			legalName,
			legalAddress,
			ownerName,
			ownerEmail,
			ownerPhone,
			managerName,
			managerEmail,
			managerPhone,
			contracts,
		}
		return window.$nuxt.$axios.$put(url, params)
	},
	renterDelete: ({ mallId, renterId }) => {
		const url = `/arendators/${renterId}`
		const params = { params: { entity_id: mallId } }
		return window.$nuxt.$axios.$delete(url, params)
	},

	renterArchive: ({ mallId, renterId, comment }) => {
		const url = `/arendators/${renterId}/archive`
		const data = { state: true, page: 'sell_location', entity_id: mallId }
		if (comment) {
			data.comment = comment
		}
		const params = { params: { entity_id: mallId } }
		return window.$nuxt.$axios.$post(url, data, params)
	},
	renterUnarchive: ({ mallId, renterId }) => {
		const url = `/arendators/${renterId}/archive`
		const data = { state: false, page: 'sell_location', entity_id: mallId }
		const params = { params: { entity_id: mallId } }
		return window.$nuxt.$axios.$post(url, data, params)
	},

	renterContractList: ({ mallId, renterId }) => {
		const url = `/api/v2/sc/${mallId}/renters/${renterId}/contracts`
		return window.$nuxt.$axios.$get(url)
	},

	renterData: ({ mallId, renterId }) => {
		const url = `/api/v2/sc/${mallId}/renters/${renterId}/data`
		return window.$nuxt.$axios.$get(url)
	},
}

export default kit
