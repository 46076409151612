const map = new Map()

const getFuncCheckClickOutside = ({ element, binding, vnode }) => {
	const checkClickOutside = (event) => {
		// check that click was outside the element and his childrens
		if (element.contains(event.target)) {
			return
		}
		vnode.context[binding.expression](event)
	}
	return checkClickOutside
}

document.body.addEventListener('click', (event) => {
	[...map.values()].forEach((checkClickOutside) => {
		checkClickOutside(event)
	})
})


const directiveClickOutsideKit = {
	bind(element, binding, vnode) {
		const checkClickOutside = getFuncCheckClickOutside({ element, binding, vnode })
		map.set(element, checkClickOutside)
	},
	unbind(element) {
		map.delete(element)
	},
}

export { directiveClickOutsideKit }
