const kit = {
	businessContactsList: ({ resourceId }) => {
		const url = '/api/v2/business_contacts'
		const params = { params: { resource_id: resourceId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	publicBusinessContactsList: ({ resourceId }) => {
		const url = '/api/v2/business_contacts/public'
		const params = { params: { resource_id: resourceId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	businessContactsAdd: (data) => {
		const { resourceId, resourceType, fullName, email, phone, comment } = data
		const url = '/api/v2/business_contacts'
		const params = {
			resource_id: resourceId,
			resource_type: resourceType,
			full_name: fullName,
			email,
			phone,
			comment,
			public: data.public,
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	businessContactsUpdate: (data) => {
		const { contactId, fullName, email, phone, comment } = data
		const url = `/api/v2/business_contacts/${contactId}`
		const params = {
			full_name: fullName,
			email,
			phone,
			comment,
			public: data.public,
			notifiable: data.notifiable,
		}
		return window.$nuxt.$axios.$put(url, params)
	},
	businessContactsDelete: ({ contactId }) => {
		const url = `/api/v2/business_contacts/${contactId}`
		return window.$nuxt.$axios.$delete(url)
	},
}

export default kit
