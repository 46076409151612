<template>
	<div class="d-block">
		<div :class="[$style.containerFake]"></div>
		<nav :class="[$style.container, 'navbar-top']">
			<div class="d-flex align-items-center gap-2">
				<h2 class="h2 text-white m-0">[ OLD ]</h2>
				<MenuLogo></MenuLogo>
				<MenuMallList></MenuMallList>
			</div>
			<div class="d-flex align-items-center">
				<NewButton
					v-if="canSeeLanguageButton"
					:text="language"
					@onClick="onChangeLanguage()"
				></NewButton>
				<MenuNotificationList></MenuNotificationList>
				<MenuBlockUser></MenuBlockUser>
			</div>
		</nav>
	</div>
</template>

<script>
	import { i18n, changeLang } from '@/assets/i18n/i18nextConfig.js'
	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'

	import MenuLogo from '@/components/menu/MenuLogo.vue'
	import MenuMallList from '@/components/menu/MenuMallList.vue'
	import MenuNotificationList from '@/components/menu/MenuNotificationList.vue'
	import MenuBlockUser from '@/components/menu/MenuBlockUser.vue'
	import NewButton from '@/components/other/NewButton.vue'

	export default {
		components: {
			MenuLogo,
			MenuMallList,
			MenuNotificationList,
			MenuBlockUser,
			NewButton,
		},
		data() {
			return {
				sideMenuConfigVue,
				language: 'ru',
			}
		},
		computed: {
			canSeeLanguageButton() {
				// set to true to see the button
				return false
				// return true
			},
		},
		mounted() {
			this.language = i18n.language
		},
		methods: {
			onChangeLanguage() {
				// works only before page reload
				// after page reload value resets to 'ru'
				const newLang = i18n.language === 'ru' ? 'en' : 'ru'
				changeLang(newLang).then(() => {
					this.language = newLang
				})
			},
		},
	}
</script>

<style lang="scss" module>
	.containerFake {
		width: 100%;
		height: 52px;
		opacity: 0;
	}

	.container {
		position: fixed;
		z-index: 1035;
		top: 0;
		right: 0;
		left: 0;
		display: flex;
		width: 100%;
		height: 52px;
		align-items: center;
		justify-content: space-between;
		background: var(--warning);

		/* background: url('/img/snowflakes-pattern.png'), #264780; */
	}
</style>
