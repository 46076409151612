import { getIsDefined } from '@/assets/js/check/parts/getIsDefined.js'

const getIsValidValueConstructor = (val, constructor) => {
	const isDefinedVal = getIsDefined(val)
	if (!isDefinedVal) {
		return false
	}

	const isDefinedConstructor = getIsDefined(constructor)
	if (!isDefinedConstructor) {
		return false
	}

	const valPrototype = Object.getPrototypeOf(val)
	const valConstructor = valPrototype === null ? Object : valPrototype.constructor
	const isValCreatedByConstructor = valConstructor === constructor
	if (!isValCreatedByConstructor) {
		return false
	}

	return true
}

export { getIsValidValueConstructor }
