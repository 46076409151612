/* eslint-disable no-console */
import * as Sentry from '@sentry/browser'
import { CaptureConsole } from '@sentry/integrations'
import { BrowserTracing } from '@sentry/tracing'


if (process.env.sentryDisable) {
	window.Sentry = null
} else {
	Sentry.init({
		dsn: 'https://d7f30d9049e6444e8fed4da2d5e380bc@o881413.ingest.sentry.io/5835794',
		environment: process.env.nodeEnv,
		// release: `rentu@${process.env.packageVersion}:${process.env.nodeEnv}-${process.env.commitSHA}`,
		release: process.env.commitSHA,
		tracesSampleRate: 0.2,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 0.4,
		integrations: [
			new BrowserTracing(),
			new CaptureConsole({ levels: ['error'] }),
			new Sentry.Replay({
				maskAllText: true,
				blockAllMedia: true,
			}),
		],
	})
	window.Sentry = Sentry
}
