<template>
	<FakeBlock v-if="!isReadySupportInfo && !isUpdateSupportInfo" width="50px"></FakeBlock>
	<LightTooltip v-else-if="isReadySupportInfo || isUpdateSupportInfo">
		<div slot="content">{{ translate('common.unreadSlashOpened') }}</div>
		<div
			:class="[
				$style.countContainerInner,
				countOpened > 0 ? $style.countContainerInner_newData : '',
				'g-flh-14 g-fs-10'
			]"
		>{{ countUnread }} / {{ countOpened }}</div>
	</LightTooltip>
</template>

<script>
	import { mapState, mapActions } from 'vuex'
	import { getRouterMallId } from '@/assets/js/routerHelper.js'
	import { translate } from '@/assets/i18n/i18nextConfig.js'

	import LightTooltip from '@/components/LightTooltip.vue'
	import FakeBlock from '@/components/other/FakeBlock.vue'

	export default {
		components: {
			LightTooltip,
			FakeBlock,
		},
		computed: {
			...mapState(['storeSupportInfo']),
			isReadySupportInfo() {
				return this.storeSupportInfo.isReady
			},
			isUpdateSupportInfo() {
				return this.storeSupportInfo.isUpdate
			},
			countUnread() {
				return this.storeSupportInfo.countUnread
			},
			countOpened() {
				return this.storeSupportInfo.countOpened
			},
			mallId() {
				return getRouterMallId()
			},
		},
		watch: {
			mallId() {
				this.getSupportInfo()
			},
		},
		mounted() {
			this.getSupportInfo()
		},
		methods: {
			translate,
			...mapActions({ getSupportInfo: 'storeSupportInfo/get' }),
		},
	}
</script>

<style lang="scss" module>
	.countContainerInner {
		padding: 0 4px;
		border: 2px solid var(--gray);
		border-radius: 4px;
		color: var(--gray);
		font-family: "TTRoundsNeue", "Comfortaa", sans-serif;
		font-size: 14px;
		font-weight: 600;

		&_newData {
			border: 2px solid var(--orange);
			color: var(--orange);
		}
	}
</style>
