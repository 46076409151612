<template>
	<AlertProblem
		v-if="reloadAlert && alertData"
		:icon="alertData.icon"
		:text="alertData.text"
		:bg-color="alertData.color"
		@close="closeAlertProblem(alertData.id)"
	></AlertProblem>
</template>

<script>
	import AlertProblem from '@/components/notification/AlertProblem.vue'

	import { getAuthUserKit, getRouterMallId } from '@/assets/js/routerHelper.js'

	export default {
		components: {
			AlertProblem,
		},
		data() {
			return {
				alertList: getAuthUserKit()?.alerts ?? [],
				reloadAlert: false,
				alertData: {},
			}
		},
		mounted() {
			this.getAlertData()
		},
		methods: {
			getAlertData() {
				const currentRouteName = this.$route.name
				const currentShoppingCenterId = getRouterMallId()
				const result = this.alertList.find((alertItem) => alertItem.sc_ids.includes(currentShoppingCenterId) && (
					alertItem.pages.includes(currentRouteName) || alertItem.pages.length === 0
				))
				if (result && !this.getCookie(`rentu-alert-id-${result.id}`)) {
					this.alertData = result
					this.reloadAlert = true
				}
			},
			getCookie(cookieName) {
				const cookie = {}
				document.cookie.split(';').forEach((el) => {
					const [key, value] = el.split('=')
					cookie[key.trim()] = value
				})
				return cookie[cookieName]
			},
			closeAlertProblem(id) {
				const oneDay = 86400e3
				const date = new Date(Date.now() + oneDay).toUTCString()
				document.cookie = `rentu-alert-id-${id}=true; expires=${date}`
				this.reloadAlert = false
				this.getAlertData()
			},
		},
	}
</script>
