import { getValTypeInfo } from '@/assets/js/check/parts/getValTypeInfo.js'

const getValTypeValidatorInfo = (val, type, valDefault, validator) => {
	const valTypeInfo = getValTypeInfo(val, type)
	const valDefaultTypeInfo = getValTypeInfo(valDefault, type)
	const validatorTypeInfo = getValTypeInfo(validator, Function)
	const isValPassValidator = validatorTypeInfo.valCorrect(valTypeInfo.valCorrect)
	const isValDefaultPassValidator = validatorTypeInfo.valCorrect(valDefaultTypeInfo.valCorrect)
	const valDefaultCorrect = isValDefaultPassValidator ? valDefaultTypeInfo.valCorrect : {}
	const valCorrect = isValPassValidator ? val : valDefaultCorrect
	const hasError =
		valTypeInfo.hasError ||
		valDefaultTypeInfo.hasError ||
		validatorTypeInfo.hasError ||
		!isValPassValidator ||
		!isValDefaultPassValidator
	const info = {
		paramKit: { val, type, valDefault, validator },
		info: {
			valTypeInfo,
			valDefaultTypeInfo,
			validatorTypeInfo,
			isValPassValidator,
			isValDefaultPassValidator,
		},
		hasError,
		valCorrect,
	}
	return Object.freeze(info)
}

export { getValTypeValidatorInfo }
