<template>
	<div class="position-relative">
		<SvgLoader
			ref="refSvgLoader"
			:width="loaderWidth"
			:height="loaderHeight"
			:is-auto-size="isAutoSizeLoader"
		></SvgLoader>
		<slot></slot>
	</div>
</template>

<script>
	import SvgLoader from '@/components/pages/SvgLoader.vue'

	export default {
		components: {
			SvgLoader,
		},
		props: {
			isShowLoader: { type: Boolean, default: false },
			loaderWidth: { type: String, default: undefined },
			loaderHeight: { type: String, default: undefined },
			isAutoSizeLoader: { type: Boolean, default: false },
		},
		watch: {
			isShowLoader: {
				immediate: true,
				handler() {
					this.refreshShow()
				},
			},
		},
		mounted() {
			this.refreshShow()
		},
		methods: {
			refreshShow() {
				if (!this.$refs.refSvgLoader) {
					return
				}
				if (this.isShowLoader) {
					this.show()
				} else {
					this.hide()
				}
			},
			show() {
				this.$refs.refSvgLoader.show()
			},
			hide() {
				this.$refs.refSvgLoader.hide()
			},
		},
	}
</script>
