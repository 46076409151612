<template>
	<div
		:class="[
			'modal fade',
			show ? 'show d-block' : 'd-none',
			show ? $style.substrate : '',
			{ 'modal-mini': type === 'mini' }
		]"
		tabindex="-1"
		role="dialog"
		:aria-hidden="!show"
		@mousedown.self="closeModal()"
	>
		<div
			:class="[
				'modal-dialog modal-dialog-centered',
				modalClasses,
				{
					'modal-notice': type === 'notice',
					[`modal-${size}`]: size,
					'modal-dialog-scrollable': isScrollable,
					'max-height': maximizeHeight
				},
				show ? $style.showCustom : '',
			]"
			:style="{ maxWidth: `min(${sizePercent}%, 1600px)` }"
			@mouseleave="hoverCloseButton(true)"
			@mouseenter="hoverCloseButton(false)"
		>
			<div
				:class="[
					'modal-content',
					modalContentClasses,
					gradient ? `bg-gradient-${gradient}` : '',
				]"
			>
				<div
					v-if="$slots.header"
					:class="['modal-header', headerClasses]"
				>
					<slot name="header"></slot>
				</div>
				<slot name="close-button">
					<button
						v-if="showClose"
						type="button"
						class="close g-fs-20"
						data-dismiss="modal"
						aria-label="Close"
						:class="{'hovered': closeHovered}"
						@click="closeModal()"
					>
						<i class="fa-solid fa-xmark"></i>
					</button>
				</slot>
				<div class="modal-body" :class="bodyClasses">
					<slot></slot>
				</div>
				<div v-if="$slots.footer" class="modal-footer" :class="footerClasses">
					<slot name="footer"></slot>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			show: Boolean,
			showClose: {
				type: Boolean,
				default: true,
			},
			type: {
				type: String,
				default: '',
				validator(value) {
					const acceptedValues = ['', 'notice', 'mini']
					return acceptedValues.includes(value)
				},
				description: 'Modal type (notice|mini|"") ',
			},
			modalClasses: {
				type: [Object, String],
				default: '',
				description: 'Modal dialog css classes',
			},
			size: {
				type: String,
				description: 'Modal size',
				default: '',
				validator(value) {
					const acceptedValues = ['', 'sm', 'lg', 'xl', 'wide']
					return acceptedValues.includes(value)
				},
			},
			sizePercent: {
				type: Number,
				default: null,
				description: 'Modal size in percent',
			},
			modalContentClasses: {
				type: [Object, String],
				default: '',
				description: 'Modal dialog content css classes',
			},
			gradient: {
				type: String,
				default: '',
				description: 'Modal gradient type (danger, primary etc)',
			},
			headerClasses: {
				type: [Object, String],
				default: '',
				description: 'Modal Header css classes',
			},
			bodyClasses: {
				type: [Object, String],
				default: '',
				description: 'Modal Body css classes',
			},
			footerClasses: {
				type: [Object, String],
				default: '',
				description: 'Modal Footer css classes',
			},
			animationDuration: {
				type: Number,
				default: 500,
				description: 'Modal transition duration',
			},
			isScrollable: {
				type: Boolean,
				default: false,
			},
			maximizeHeight: {
				type: Boolean, default: false,
			},
		},
		data() {
			return {
				closeHovered: false,
			}
		},
		watch: {
			show(val) {
				const documentClasses = document.body.classList
				if (val) {
					documentClasses.add('modal-open')
				} else {
					documentClasses.remove('modal-open')
				}
			},
			$route() {
				const documentClasses = document.body.classList
				documentClasses.remove('modal-open')
			},
		},
		methods: {
			hoverCloseButton(state) {
				this.closeHovered = state
			},
			closeModal() {
				this.$emit('update:show', false)
				this.$emit('close')
			},
		},
	}
</script>
<style lang="scss" scoped>
	.close {
		position: absolute;
		top: -5px;
		right: -50px;
		border: none;
		background: transparent;
		color: rgba(255, 255, 255, 0.7);
		transition: color 0.3s ease;
	}

	.close:hover {
		color: rgba(255, 255, 255, 1) !important;
		opacity: 0.75 !important;
	}

	.hovered {
		color: rgba(255, 255, 255, 1) !important;
		opacity: 0.75 !important;
	}

	.fa-xmark {
		font-size: 50px;
	}
</style>
<style lang="scss" module>
	.substrate {
		background-color: rgba(0, 0, 0, 0.3);
		overflow-x: hidden;
		overflow-y: auto;
	}

	.showCustom {
		animation-duration: 0.3s;
		animation-name: animation1;
	}

	@keyframes animation1 {
		from {
			opacity: 0;
			transform: translateY(-45px);
		}

		to {
			opacity: 1;
			transform: translateY(0);
		}
	}
</style>
