import { getAuthUserKit } from '@/assets/js/routerHelper.js'
import { translate } from '@/assets/i18n/i18nextConfig.js'
import { accessRoleListKit } from '@/assets/js/access/accessRoleListKit.js'

const restrictionsLevelList = ['suspended', 'contract_suspended', 'payment_required']

const isLimitedAccess = (level) => {
	return restrictionsLevelList.includes(level)
}
const limitAccessIcon = (level) => {
	if (!accessRoleListKit.staff) {
		return 'fa-regular fa-triangle-exclamation'
	}

	if (level === 'payment_required') {
		return 'fa-regular fa-triangle-exclamation'
	}
	if (level === 'suspended') {
		return 'fa-regular fa-circle-stop'
	}
	if (level === 'contract_suspended') {
		return 'fa-regular fa-ban'
	}

	return 'fa-regular fa-triangle-exclamation'
}
const limitAccessText = (level) => {
	if (!getAuthUserKit().is_staff) {
		return translate('common.limitedAccess.limited')
	}

	if (level === 'payment_required') {
		return translate('common.limitedAccess.payment_required')
	}
	if (level === 'suspended') {
		return translate('common.limitedAccess.suspended')
	}
	if (level === 'contract_suspended') {
		return translate('common.limitedAccess.contract_suspended')
	}

	return ''
}

export { limitAccessText, isLimitedAccess, limitAccessIcon }
