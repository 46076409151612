<template>
	<div
		class="d-inline-flex cursor-pointer align-items-center gap-1 text-copy-container"
		@click.prevent="onCopyClick()"
	>
		<span>{{ text }}</span>
		<i class="fa-regular fa-copy"></i>
	</div>
</template>

<script>
	export default {
		props: {
			text: { type: String, default: '' },
			copyText: { type: String, default: '' },
			notificationMessage: { type: String, default: '' },
		},
		methods: {
			emitCopySuccess() {
				this.$emit('copySuccess')
			},
			onCopyClick() {
				navigator.clipboard.writeText(this.copyText)
				this.$notify({
					verticalAlign: 'top',
					horizontalAlign: 'right',
					message: this.notificationMessage,
					timeout: 5000,
					icon: 'fa fa-copy',
					type: 'info',
				})
				this.emitCopySuccess()
			},
		},
	}
</script>

<style lang="scss" scoped>
	.text-copy-container {
		i {
			display: none;
		}

		&:hover i {
			display: block;
		}
	}
</style>
