<template>
	<div :class="[$style.contentMain, containerCssClass]">
		<slot></slot>
	</div>
</template>

<script>
	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'

	export default {
		props: {
			fullWidth: { type: Boolean, default: false },
		},
		data() {
			return {
				sideMenuConfigVue,
				containerCssClass: '',
			}
		},
		mounted() {
			// computed don't work
			this.containerCssClass = this.$style[sideMenuConfigVue.menuContainerSize] ?? ''
			sideMenuConfigVue.$on('change', () => {
				this.containerCssClass = this.fullWidth ? this.$style.menuContainerHide : this.$style[sideMenuConfigVue.menuContainerSize] ?? ''
			})
			if (this.fullWidth) {
				this.containerCssClass = this.$style.menuContainerHide
			}
		},
	}
</script>

<style module>
	.contentMain {
		position: relative;
		width: 100%;
		transition: padding-left 0.3s;
	}

	.menuContainerHide {
		padding-left: 0;
	}

	.menuContainerSmall {
		padding-left: 62px;
	}

	.menuContainerBig {
		padding-left: 250px;
	}

	.substrate {
		position: absolute;
		z-index: 1020;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(100, 100, 100, 0.5);
	}
</style>
