const kit = {
	anomalyUpdateStatus: ({ shoppingCenterId, anomalyId, status, comment }) => {
		const url = `/anomalies/${anomalyId}/update-status`
		const params = { entity_id: shoppingCenterId, status, comment }
		return window.$nuxt.$axios.$put(url, params)
	},

	anomalyLevelUpdate: ({ shoppingCenterId, sellLocationId, anomalyLevel }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}`
		const data = { anomaly_level: anomalyLevel }
		return window.$nuxt.$axios.$put(url, data)
	},

	analyticsAnomalyConfig: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/analytics/anomalies/anomalies_config`
		return window.$nuxt.$axios.$get(url)
	},

	analyticsAnomalyList: ({ shoppingCenterId, sellLocationIdList }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/analytics/anomalies/list`
		const params = { sell_location_ids: sellLocationIdList }
		return window.$nuxt.$axios.$post(url, params)
	},
}

export default kit
