var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"clickOutside",rawName:"v-clickOutside",value:(_vm.closeDropDown),expression:"closeDropDown"}],class:[_vm.$style.container]},[_c('div',{class:[_vm.$style.togglerContainer],on:{"click":function($event){return _vm.onTogglerClick()}}},[_vm._t("toggler",function(){return [_c('div',{class:[_vm.$style.togglerExample]},[_c('i',{staticClass:"fa-regular fa-ellipsis g-fs-20"})])]})],2),_vm._v(" "),_c('div',{class:[
			_vm.$style.listContainer,
			_vm.isShowListContainer ? '' : _vm.$style.listContainerHide,
			_vm.positionCssClass,
		]},_vm._l((_vm.itemList),function(itemKit,itemKitIndex){return _c('div',{key:itemKitIndex,class:[
				_vm.$style.item,
				itemKit.isDisabled ? _vm.$style.itemDisabled : '',
			],on:{"click":function($event){return _vm.onItemClick(itemKit)}}},[_vm._t("default",function(){return [_vm._v("\n\t\t\t\t"+_vm._s(itemKit)+"\n\t\t\t")]},{"itemKit":itemKit,"itemKitIndex":itemKitIndex,"isDisabled":itemKit.isDisabled})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }