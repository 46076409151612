<template>
	<div>
		{{ showNotesString }}
	</div>
</template>

<script>
	import { translate } from '@/assets/i18n/i18nextConfig.js'

	export default {
		props: {
			pageActive: { type: Number, default: 1, required: true },
			itemTotal: { type: Number, default: 0, required: true },
			itemPerPage: { type: Number, default: 1, required: true },
		},
		computed: {
			from() {
				return this.itemPerPage * (this.pageActive - 1) + 1
			},
			to() {
				const highBound = this.from + this.itemPerPage - 1
				return highBound > this.itemTotal ? this.itemTotal : highBound
			},
			showNotesString() {
				return translate('paginationInfo.showNotes')
					.replaceAll('%from%', this.from)
					.replaceAll('%to%', this.to)
					.replaceAll('%itemTotal%', this.itemTotal)
			},
		},
		methods: {
			translate,
		},
	}
</script>
