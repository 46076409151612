import { format } from 'date-fns'
import { getCorrectTypeValKit } from '@/assets/js/check/getCorrectTypeValKit.js'

const kit = {
	availableMalls: () => {
		const url = '/api/v2/shopping_centers/available_sc'
		return window.$nuxt.$axios.$get(url)
	},

	availableSellLocations: ({ mallId }) => {
		const url = `/api/v2/sc/${mallId}/renters/available_sell_locations`
		return window.$nuxt.$axios.$get(url)
	},

	categoryList: ({ shoppingCenterId }) => {
		const url = `/categories?entity_id=${shoppingCenterId}`
		return window.$nuxt.$axios.$get(url)
	},

	checksByHour: ({ shoppingCenterId, sellLocationId, date, hours }) => {
		const url = '/checks-by-the-hour'
		const params = {
			params: {
				entity_id: shoppingCenterId,
				sell_location_id: sellLocationId,
				date,
				hours,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	floorListAndSectorList: ({ mallId }) => {
		const url = `/api/v2/sc/${mallId}/structure`
		return window.$nuxt.$axios.$get(url)
	},

	latestLogList: ({ shoppingCenterId }) => {
		const url = '/latests-logs'
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	latestStatusLogList: ({ shoppingCenterId }) => {
		const url = '/latests-status-logs'
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},

	mallCategoriesByArea: ({ mallId }) => {
		const url = '/mall-categories-by-area'
		const params = { params: { entity_id: mallId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	mallMapLocationKit: ({ shoppingCenterId }) => {
		const url = '/mall-map-locations'
		const params = { params: { entity_id: shoppingCenterId, type: 'map_positions_select' } }
		return window.$nuxt.$axios.$get(url, params)
	},
	mallStructureByCategories: ({ mallId }) => {
		const url = '/mall-structure-by-categories'
		const params = { params: { entity_id: mallId } }
		return window.$nuxt.$axios.$get(url, params)
	},

	mapLocationsStructure: ({ mallId }) => {
		const url = '/mall-map-locations/structure'
		const params = { params: { entity_id: mallId } }
		return window.$nuxt.$axios.$get(url, params)
	},


	notificationList: ({ cursor }) => {
		const url = `/notifications/messages?${cursor}`
		return window.$nuxt.$axios.$get(url)
	},

	notificationSetSeen: ({ notificationId }) => {
		const url = '/notifications/set-seen'
		const params = { notification_id: notificationId }
		return window.$nuxt.$axios.patch(url, params)
	},
	notificationCreate: ({ shoppingCenterId, message, title, priority, type }) => {
		const url = '/notifications/create'
		const params = {
			entity_id: shoppingCenterId,
			message,
			title,
			priority,
			type,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	ofdList: () => {
		const url = '/api/v2/ofds'
		return window.$nuxt.$axios.$get(url)
	},

	pressingUrl: ({ fileName, folder }) => {
		const url = '/api/v2/s3_upload/generate_presigned_url'
		const params = { params: {
			file_name: fileName,
			folder,
		} }
		return window.$nuxt.$axios.$get(url, params)
	},

	mallEventList: ({ mallId, dateRange = [] }) => {
		const url = '/events'
		const params = {
			params: {
				entity_id: mallId,
				begin_date: format(dateRange[0], 'yyyy-MM-dd'),
				end_date: format(dateRange[1], 'yyyy-MM-dd'),
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	mallEventCreate: ({ shoppingCenterId, name, description, dateRange, type, sellLocationIdList, userId }) => {
		const url = '/events'
		const params = {
			entity_id: shoppingCenterId,
			user_id: userId,
			sell_location_ids: sellLocationIdList,
			name,
			description,
			start_date: format(dateRange[0], 'yyyy-MM-dd'),
			end_date: format(dateRange[1], 'yyyy-MM-dd'),
			type,
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	mallEventUpdate: ({ shoppingCenterId, eventId, name, description, dateRange, type, sellLocationIdList, userId }) => {
		const url = `/events/${eventId}`
		const params = {
			entity_id: shoppingCenterId,
			user_id: userId,
			sell_location_ids: sellLocationIdList,
			event_id: eventId,
			name,
			description,
			start_date: format(dateRange[0], 'yyyy-MM-dd'),
			end_date: format(dateRange[1], 'yyyy-MM-dd'),
			type,
		}
		return window.$nuxt.$axios.$put(url, params)
	},
	mallEventDelete: ({ shoppingCenterId, eventId }) => {
		const url = `/events/${eventId}`
		const params = { params: { entity_id: shoppingCenterId, event_id: eventId } }
		return window.$nuxt.$axios.$delete(url, params)
	},

	profileList: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/profiles`
		return window.$nuxt.$axios.$get(url)
	},

	createSettings: ({ shoppingCenterId, profileId, ranges }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/profiles/${profileId}`
		return window.$nuxt.$axios.$put(url, ranges)
	},

	userList: ({ mallId }) => {
		const url = '/roles'
		const params = {
			params: { entity_id: mallId },
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	usersList: () => {
		const url = '/api/v2/users/list'
		return window.$nuxt.$axios.$post(url)
	},

	addNewUser: ({ userEmail, userName, phoneNumber }) => {
		const url = '/api/v2/users'
		const params = {
			email: userEmail,
			name: userName,
			phone: phoneNumber,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	checkUser: ({ phoneNumber }) => {
		const url = '/api/v2/users/exist_by_phone'
		const params = {
			phone: phoneNumber,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	changeUserRole: ({ userId, mallId, role }) => {
		const url = `/api/v2/users/${userId}/modify_role`
		const params = {
			sc_id: mallId,
		}
		if (role !== 'null') {
			params.role = role
		}
		return window.$nuxt.$axios.$put(url, params)
	},

	rolesModifyCheck: ({ shoppingCenterId }) => {
		const url = '/roles_modify'
		const params = {
			params: { entity_id: shoppingCenterId },
		}
		return window.$nuxt.$axios.$get(url, params)
	},

	weatherDaily: ({ shoppingCenterId, dateRange }) => {
		const correctKit = getCorrectTypeValKit({
			dateStart: [dateRange[0], 'dateCorrect'],
			dateEnd: [dateRange[1], 'dateCorrect'],
		})
		const url = `/api/v2/sc/${shoppingCenterId}/weather/daily`
		const params = { params: {
			start_date: format(correctKit.dateStart, 'yyyy-MM-dd'),
			end_date: format(correctKit.dateEnd, 'yyyy-MM-dd'),
		} }
		return window.$nuxt.$axios.$get(url, params)
	},

	uploadByPressingUrl: ({ url, file }) => {
		return window.$nuxt.$axios.$put(url, file)
	},

	downloadFile: ({ fileUrl }) => {
		const params = { responseType: 'blob' }
		return window.$nuxt.$axios.$get(fileUrl, params)
	},

	staffList: () => {
		const url = '/api/v2/users/staff'
		return window.$nuxt.$axios.$get(url)
	},

	serverSettings: () => {
		const url = '/api/v2/server_settings'
		return window.$nuxt.$axios.$get(url)
	},
}

export default kit
