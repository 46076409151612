var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.componentName,{tag:"component",class:[
		_vm.$style.item,
		_vm.isActive ? _vm.$style.item_active : '',
		_vm.childCurrent.isDisabled ? _vm.$style.item_disabled : '',
		_vm.isShowHighLight ? _vm.$style.item_highlight : '',
		'clear-button gap-2 justify-content-start g-fs-16 g-flh-16 px-2 py-2',
	],attrs:{"to":_vm.childCurrent.routeTo}},[_c('div',[_vm._v(_vm._s(_vm.childCurrent.text))]),_vm._v(" "),(_vm.childCurrent.isPageMustContainMap && !_vm.hasMap)?_c('LightTooltip',{attrs:{"placement":"top"}},[_c('div',{staticClass:"white-space-pre-line",attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(_vm.translate('common.uploadMapToUnlock')))]),_vm._v(" "),_c('i',{staticClass:"text-warning",class:_vm.childCurrent.iconCssClass})]):_vm._e(),_vm._v(" "),(_vm.childCurrent.isBeta && _vm.isEnvStateProduction)?_c('Badge',{staticClass:"m-0 g-fs-12 px-2",attrs:{"color":"primary","tooltip":_vm.translate('common.betaTooltip'),"icon":"fa-regular fa-atom-simple","outline":true}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }