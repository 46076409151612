<template>
	<div
		:class="[
			$style.item,
			isDisabled ? $style.itemDisabled : ''
		]"
		@click="onClick()"
	>
		<i v-if="iconClass" :class="[iconClass, 'mr-1']"></i> {{ text }}
	</div>
</template>
<script>
	export default {
		props: {
			text: { type: String, default: '' },
			iconClass: { type: String, default: '' },
			isDisabled: { type: Boolean, default: false },
		},
		methods: {
			onClick() {
				this.$emit('selectItem')
			},
		},
	}
</script>
<style lang="scss" module>
	.item {
		display: block;
		width: 100%;
		padding: 0.5rem 1rem;
		border: 0;
		background-color: transparent;
		clear: both;
		color: #46494b;
		cursor: pointer;
		font-size: 0.875rem;
		font-weight: 400;
		text-align: inherit;
		white-space: nowrap;

		&:hover {
			background-color: #f6f9fc;
			color: #3a3c3e;
			text-decoration: none;
		}
	}

	.itemDisabled {
		background-color: transparent;
		color: #adb5bd;
		cursor: default;
		pointer-events: none;
	}
</style>
