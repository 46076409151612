import { getValTypeInfo } from '@/assets/js/check/parts/getValTypeInfo.js'
import { getValTypeValidatorInfo } from '@/assets/js/check/parts/getValTypeValidatorInfo.js'

const showError = (errorName, info) => {
	// eslint-disable-next-line no-console
	console.error(new Error(errorName), info)
}

const getCorrectKit = (config) => {
	const configInfo = getValTypeInfo(config, Object)
	if (configInfo.hasError) {
		showError('config error', { configInfo })
	}

	const resultKit = {}
	Object.entries(configInfo.valCorrect).forEach(([resultFieldName, paramKit]) => {
		const paramKitInfo = getValTypeInfo(paramKit, Object)
		const info = { resultKit, resultFieldName }
		if (paramKitInfo.hasError) {
			showError('paramKit error', { paramKitInfo, info })
		}
		const { val, type, valDefault, validator } = paramKitInfo.valCorrect

		const valInfo = validator
			? getValTypeValidatorInfo(val, type, valDefault, validator)
			: getValTypeInfo(val, type)

		if (valInfo.hasError) {
			showError('val error', { valInfo, info })
		}
		resultKit[resultFieldName] = valInfo.valCorrect
	})

	return resultKit
}

export { getCorrectKit }
