<template>
	<component
		:is="componentName"
		:to="childCurrent.routeTo"
		:class="[
			$style.item,
			isActive ? $style.item_active : '',
			childCurrent.isDisabled ? $style.item_disabled : '',
			isShowHighLight ? $style.item_highlight : '',
			'clear-button gap-2 justify-content-start g-fs-16 g-flh-16 px-2 py-2',
		]"
	>
		<div>{{ childCurrent.text }}</div>

		<LightTooltip v-if="childCurrent.isPageMustContainMap && !hasMap" placement="top">
			<div
				slot="content"
				class="white-space-pre-line"
			>{{ translate('common.uploadMapToUnlock') }}</div>
			<i class="text-warning" :class="childCurrent.iconCssClass"></i>
		</LightTooltip>
		<Badge
			v-if="childCurrent.isBeta && isEnvStateProduction"
			class="m-0 g-fs-12 px-2"
			color="primary"
			:tooltip="translate('common.betaTooltip')"
			icon="fa-regular fa-atom-simple"
			:outline="true"
		></Badge>
	</component>
</template>

<script>
	import { MenuSideItem } from '@/assets/js/sideMenu/MenuSideItem.js'
	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'
	import { getRouterMallKit } from '@/assets/js/routerHelper.js'
	import { translate } from '@/assets/i18n/i18nextConfig.js'

	import LightTooltip from '@/components/LightTooltip.vue'
	import Badge from '@/components/other/Badge.vue'

	export default {
		components: {
			LightTooltip,
			Badge,
		},
		props: {
			menuSideItem: { type: MenuSideItem, default: () => (new MenuSideItem()) },
			childIndex: { type: Number, default: 0 },
			isHoverList: { type: Boolean, default: false },
		},
		data() {
			return {
				sideMenuConfigVue,
				loadingTheReport: false,
			}
		},
		computed: {
			componentName() {
				return this.childCurrent.isLink ? 'NuxtLink' : 'div'
			},
			isActive() {
				if (!this.childCurrent.isLink) {
					return false
				}
				const hrefCurrent = this.$route.fullPath
				const href = this.$router.resolve(this.childCurrent.routeTo).href ?? ''
				const isActive = hrefCurrent === href
				return isActive
			},
			childCurrent() {
				return this.menuSideItem.childList[this.childIndex]
			},
			isLinkSameParent() {
				if (!this.childCurrent.isLink || !this.menuSideItem.isLink) {
					return false
				}
				const hrefCurrent = this.$router.resolve(this.childCurrent.routeTo).href
				const hrefParent = this.$router.resolve(this.menuSideItem.routeTo).href
				return hrefCurrent === hrefParent
			},
			isShowHighLight() {
				return this.isLinkSameParent && !this.isHoverList
			},
			isEnvStateProduction() {
				return process.env.nodeEnv === 'production'
			},
			hasMap() {
				return getRouterMallKit().has_map_locations ?? ''
			},
		},
		methods: {
			translate,
		},
	}
</script>

<style lang="scss" module>
	.item {
		border: 1px solid transparent;
		color: var(--gray-dark);
		font-family: 'TTRoundsNeue', 'Comfortaa', sans-serif;
		transition: background 0.2s ease;

		&:hover {
			color: var(--gray-dark);
		}

		&_active {
			background: rgba(22, 195, 200, 0.2);
		}

		&_disabled {
			color: var(--gray-600);
			cursor: default;
			pointer-events: none;
		}

		&_highlight {
			border: 1px dotted rgba(100, 100, 100, 0.2);
		}
	}
</style>
