<!-- eslint-disable max-len -->
<template>
	<svg
		id="IconLogoColor"
		ref="refIcon"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 2374 420"
		width="100%"
		height="100%"
		version="1.1"
	>
		<path :class="$style.letterR" d="M400.86 417.24a30.85 30.85 0 01-37.79-10.07c-18.09-25.07-46.19-45.18-83.87-60-43.72-17.14-91-23.42-122.91-25.67a78.89 78.89 0 01-72.68-86.69c4.15-40.26 38.73-70.52 79.21-70.52h129.9A58.61 58.61 0 00351.23 104c-.72-23.71-20.63-42.35-44.36-42.35H30.81A30.81 30.81 0 010 30.81 30.81 30.81 0 0130.81 0h275.48c57.61 0 105.43 45.75 106.58 103.36A120.3 120.3 0 01292.72 226H162.45c-9.79 0-18 8-17.62 17.77a17.09 17.09 0 0015.79 16.35c71.29 5 192 26.48 252.7 111.32a30.79 30.79 0 01-12.46 45.84z"></path>
		<path :class="$style.letterE3" d="M866.62 287.56L705 349.17 543.1 287.8c-19.67-7.46-42.56-.55-52.12 15.71a29 29 0 00-2.29 25.29c3.43 9.11 11.31 16.58 21.63 20.49l167.5 63.49a77.58 77.58 0 0054.52 0L899.55 349c10.3-3.93 18.17-11.41 21.58-20.53a29 29 0 00-2.35-25.29c-9.61-16.25-32.51-23.11-52.16-15.62z">
			<animate
				dur="2s"
				repeatCount="1"
				begin="IconLogoColor.mouseenter; 0s"
				restart="whenNotActive"
				attributeName="opacity"
				values="1; 1; 0; 0; 1; 1"
				keyTimes="0; 0.49; 0.5; 0.55; 0.56; 1"
				calcMode="spline"
				keySplines="
					.42,0,.58,1;
					.42,0,.58,1;
					.42,0,.58,1;
					.42,0,.58,1;
					.42,0,.58,1;
				"
			></animate>
		</path>
		<path :class="$style.letterE2" d="M510.3 242.84l167.5 63.49a77.58 77.58 0 0054.52 0l167.23-63.75c10.3-3.93 18.17-11.41 21.58-20.53a29 29 0 00-2.35-25.29c-9.6-16.24-32.51-23.1-52.16-15.62L705 242.71l-161.9-61.37c-19.67-7.46-42.56-.55-52.12 15.71a29 29 0 00-2.29 25.29c3.42 9.12 11.31 16.59 21.61 20.5z">
			<animate
				dur="2s"
				repeatCount="1"
				begin="IconLogoColor.mouseenter; 0s"
				restart="whenNotActive"
				attributeName="opacity"
				values="1; 1; 0; 0; 1; 1"
				keyTimes="0; 0.24; 0.25; 0.75; 0.76; 1"
				calcMode="spline"
				keySplines="
					.42,0,.58,1;
					.42,0,.58,1;
					.42,0,.58,1;
					.42,0,.58,1;
					.42,0,.58,1;
				"
			></animate>
		</path>
		<path :class="$style.letterE1" d="M505.052 132l184.086 66.988c12.914 4.012 17.914 4.012 31.774-.02L909.072 131c18.98-6 20.356-46.082 2.012-54L720.134 4.445a31.193 31.193 0 00-23.834-.156L500.71 77c-18.516 7.683-19.658 43-.551 52.736.365.186 4.508 2.122 4.893 2.264">
			<animateTransform
				dur="2s"
				repeatCount="1"
				attributeName="transform"
				begin="IconLogoColor.mouseenter; 0s"
				restart="whenNotActive"
				type="translate"
				values="0,0; 0,0; 0,112; 0,112; 0,218; 0,218; 0,112; 0,112; 0,0; 0,0"
				keyTimes="0; 0.05; 0.25; 0.3; 0.5; 0.55; 0.7; 0.75; 0.95; 1"
				calcMode="spline"
				keySplines="
					.42,0,.58,1;
					.42,0,.58,1;
					.42,0,.58,1;
					.42,0,.58,1;
					.42,0,.58,1;
					.42,0,.58,1;
					.42,0,.58,1;
					.42,0,.58,1;
					.42,0,.58,1;
				"
			>
			</animateTransform>
		</path>
		<path :class="$style.letterN" d="M1025.12 419.32A32.12 32.12 0 01993 387.2V50.59a48.94 48.94 0 0185-33.1l251.23 286.46a13.43 13.43 0 0023.53-8.86V33.5a32.12 32.12 0 0132.12-32.12A32.12 32.12 0 011417 33.5v336.55a48.95 48.95 0 01-85 33.1l-255.66-291.52a10.9 10.9 0 00-19.09 7.19V387.2a32.12 32.12 0 01-32.13 32.12z"></path>
		<path :class="$style.letterT" d="M1846 3h-312a30 30 0 00-30 30 30 30 0 0030 30h126v327a30 30 0 0060 0V63h126a30 30 0 0030-30 30 30 0 00-30-30z"></path>
		<path :class="$style.letterU" d="M2176.12 415h-2.67a197.89 197.89 0 01-197.88-197.36l-.48-183.56A30 30 0 012005 4a30 30 0 0130.08 29.92l.48 183.56c.2 75.83 62.05 137.52 137.88 137.52h2.67A137.88 137.88 0 002314 217.12V34a30 30 0 0130-30 30 30 0 0130 30v183.12C2374 326.4 2285.4 415 2176.12 415z"></path>
	</svg>
</template>

<script>
	export default {
		mounted() {
			document.addEventListener('click', this.handlerClick, { passive: true })
			this.$nuxt.$on('axiosStartRequest', this.handlerNuxtEvent)
		},
		beforeDestroy() {
			document.removeEventListener('click', this.handlerClick)
			this.$nuxt.$off('axiosStartRequest', this.handlerNuxtEvent)
		},
		methods: {
			handlerClick(event) {
				this.startAnimation()
			},
			handlerNuxtEvent() {
				this.startAnimation()
			},
			startAnimation() {
				const iconElement = this.$refs.refIcon
				if (!iconElement) {
					return
				}
				const mouseenter = new Event('mouseenter')
				iconElement.dispatchEvent(mouseenter)
			},
		},
	}
</script>

<style lang="scss" module>
.letterR,
.letterN,
.letterT,
.letterU {
	fill: #344154;
}

.letterE1 {
	fill: #ff6d3b;
}

.letterE2,
.letterE3 {
	fill: #17c2c9;
}
</style>
