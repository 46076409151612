<template>
	<div>
		<!-- HACK: fake column because the wrapper div changes the column order -->
		<ElTableColumn width="1"></ElTableColumn>
		<ElTableColumn
			v-for="(item, itemIndex) in columnData"
			:key="itemIndex"
			:label="item.label"
			:width="item.width"
			:min-width="item['min-width']"
		>
			<template #default>
				<FakeBlock width="100%" height="15px"></FakeBlock>
			</template>
		</ElTableColumn>
	</div>
</template>

<script>
	import { TableColumn } from 'element-ui'
	import FakeBlock from '@/components/other/FakeBlock.vue'

	export default {
		components: {
			ElTableColumn: TableColumn,
			FakeBlock,
		},
		props: {
			columnData: { type: Array, default: () => ([]) },
		},
	}
</script>
