<template>
	<component
		:is="componentName"
		:to="menuSideItem.routeTo"
		:class="[
			$style.item,
			isActive ? $style.isActive : '',
			menuSideItem.isDisabled ? $style.isDisabled : '',
			'mb-1'
		]"
	>
		<div class="d-flex align-items-center">
			<div class="mr-2 g-flh-8">
				<BadgeSupportInfo v-show="!sideMenuConfigVue.isMenuActive">
					<div :class="[menuSideItem.iconCssClass, 'col-25 text-center g-fs-18']"></div>
				</BadgeSupportInfo>
				<div
					v-show="sideMenuConfigVue.isMenuActive"
					:class="[menuSideItem.iconCssClass, 'col-25 text-center g-fs-18']"
				></div>
			</div>
			<div
				v-show="sideMenuConfigVue.isMenuActive"
				:class="[$style.itemText, 'g-fs-16 g-flh-18']"
			>{{ menuSideItem.text }}</div>
		</div>
		<div
			v-if="menuSideItem.childList.length > 0"
			v-show="isHoverItemContainer && sideMenuConfigVue.isMenuActive"
			class="ml-2 mr-2 g-flh-16 g-height-15"
		>
			<i class="fa fa-angle-right text-gray"></i>
		</div>
		<BlockSupportInfo v-show="sideMenuConfigVue.isMenuActive"></BlockSupportInfo>
	</component>
</template>

<script>
	import BadgeSupportInfo from '@/components/other/BadgeSupportInfo.vue'
	import BlockSupportInfo from '@/components/other/BlockSupportInfo.vue'

	import { MenuSideItem } from '@/assets/js/sideMenu/MenuSideItem.js'
	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'

	export default {
		components: {
			BadgeSupportInfo,
			BlockSupportInfo,
		},
		props: {
			menuSideItem: { type: MenuSideItem, default: () => (new MenuSideItem()) },
			isHoverItemContainer: { type: Boolean, default: false },
		},
		data() {
			return {
				sideMenuConfigVue,
			}
		},
		computed: {
			componentName() {
				return this.menuSideItem.isLink ? 'NuxtLink' : 'div'
			},
			isActive() {
				if (!this.menuSideItem.isLink) {
					return false
				}
				const hrefCurrent = this.$route.fullPath
				const href = this.$router.resolve(this.menuSideItem.routeTo).href ?? ''
				const isActive = hrefCurrent.startsWith(href)
				return isActive
			},
			hasActiveChild() {
				const hrefCurrent = this.$route.fullPath
				const hasActiveChild = this.menuSideItem.childList.some((child) => {
					if (!child.isLink) {
						return false
					}
					const hrefLocal = this.$router.resolve(child.routeTo).href ?? ''
					const isActiveChild = hrefCurrent.startsWith(hrefLocal)
					return isActiveChild
				})
				return hasActiveChild
			},
		},

	}
</script>

<style lang="scss" module>
	.item {
		display: flex;
		box-sizing: border-box;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		border-radius: 8px;
		color: var(--gray-dark);
		transition: background 0.2s ease;
		white-space: nowrap;

		&:hover {
			background: #e4e7e9;
			color: var(--gray-dark);
		}
	}

	.itemText {
		flex: 1;
		font-family: 'TTRoundsNeue', 'Comfortaa', sans-serif;
	}

	.isActive {
		background: rgba(22, 195, 200, 0.2);
	}

	.hasActiveChild {
		background: rgba(22, 195, 200, 0.2);
	}

	.isDisabled {
		color: var(--gray-600);
		cursor: default;
		pointer-events: none;
	}
</style>
