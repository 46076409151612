<template>
	<div
		class="d-flex flex-row align-items-center justify-content-center p-1 border-top g-fs-10"
	>
		<div
			class="clear-button gap-1 align-items-center w-100"
			:class="$style.item"
		>
			<i v-show="showIcon" class="fa-solid fa-layer-group"></i>
			<span>{{ appVersionText }}</span>
		</div>
	</div>
</template>

<script>
	import { translate } from '@/assets/i18n/i18nextConfig.js'
	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'

	export default {
		computed: {
			appVersion() {
				return this.$config.clientVersion
			},
			appVersionDate() {
				return this.$config.clientVersionDate
			},
			appVersionText() {
				if (sideMenuConfigVue.isMenuActive) {
					return `${translate('common.version')} ${this.appVersion} / ${this.appVersionDate}`
				}
				return `v${this.appVersion}`
			},
			showIcon() {
				return sideMenuConfigVue.isMenuActive
			},
		},
	}
</script>

<style lang="scss" module>
.item {
	color: var(--gray-600);
	cursor: pointer;
	font-family: 'TTRoundsNeue', 'Comfortaa', sans-serif;
	transition: background 0.2s ease;
	white-space: nowrap;

	&:hover {
		color: var(--gray-dark);
	}
}
</style>
