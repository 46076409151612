import { getPromiseQuery } from '@/assets/js/query/getPromiseQuery.js'
import { getRouterMallId } from '@/assets/js/routerHelper.js'
import CachePromise from '@/assets/js/cache/CachePromise.js'

const sellLocationSelectListCachePromise = new CachePromise({ timeLiveMs: 1000 * 60 * 5 })
const renterListCachePromise = new CachePromise({ timeLiveMs: 1000 * 60 * 5 })
const categoryListCachePromise = new CachePromise({ timeLiveMs: 1000 * 60 * 5 })
const profileListCachePromise = new CachePromise({ timeLiveMs: 1000 * 60 * 5 })
const positionListCachePromise = new CachePromise({ timeLiveMs: 1000 * 60 * 5 })
const floorListAndSectorListCachePromise = new CachePromise({ timeLiveMs: 1000 * 60 * 5 })
const mallMapLocationKitCachePromise = new CachePromise({ timeLiveMs: 1000 * 60 * 5 })
const zoneListCachePromise = new CachePromise({ timeLiveMs: 1000 * 60 * 5 })
const docCategoriesCachePromise = new CachePromise({ timeLiveMs: 1000 * 60 * 5 })
const unknownKktListCachePromise = new CachePromise({ timeLiveMs: 1000 * 60 * 5 })

const kktProviderListCachePromise = new CachePromise({ timeLiveMs: 1000 * 30 })
const ofdListCachePromise = new CachePromise({ timeLiveMs: 1000 * 30 })

const kit = {
	sellLocationSelectList: ({ isNeedResetCache, funcProgress }) => {
		const mallId = getRouterMallId()
		const promise = sellLocationSelectListCachePromise.getPromise({
			isNeedResetCache,
			keyString: mallId,
			getPromiseWhenNoLive: () => {
				funcProgress()
				return getPromiseQuery({ sellLocationSelectList: { mallId } })
			},
		})
		return promise
	},

	renterList: ({ isNeedResetCache, funcProgress }) => {
		const mallId = getRouterMallId()
		const promise = renterListCachePromise.getPromise({
			isNeedResetCache,
			keyString: mallId,
			getPromiseWhenNoLive: () => {
				funcProgress()
				return getPromiseQuery({ renterList: { mallId } })
			},
		})
		return promise
	},

	positionList: ({ isNeedResetCache, funcProgress }) => {
		const mallId = getRouterMallId()
		const promise = positionListCachePromise.getPromise({
			isNeedResetCache,
			keyString: mallId,
			getPromiseWhenNoLive: () => {
				funcProgress()
				return getPromiseQuery({ positionList: { mallId } })
			},
		})
		return promise
	},

	categoryList: ({ isNeedResetCache, funcProgress }) => {
		const shoppingCenterId = getRouterMallId()
		const promise = categoryListCachePromise.getPromise({
			isNeedResetCache,
			keyString: shoppingCenterId,
			getPromiseWhenNoLive: () => {
				funcProgress()
				return getPromiseQuery({ categoryList: { shoppingCenterId } })
			},
		})
		return promise
	},
	profileList: ({ isNeedResetCache, funcProgress }) => {
		const shoppingCenterId = getRouterMallId()
		const promise = profileListCachePromise.getPromise({
			isNeedResetCache,
			keyString: shoppingCenterId,
			getPromiseWhenNoLive: () => {
				funcProgress()
				return getPromiseQuery({ profileList: { shoppingCenterId } })
			},
		})
		return promise
	},

	floorListAndSectorList: ({ isNeedResetCache, funcProgress }) => {
		const mallId = getRouterMallId()
		const promise = floorListAndSectorListCachePromise.getPromise({
			isNeedResetCache,
			keyString: mallId,
			getPromiseWhenNoLive: () => {
				funcProgress()
				return getPromiseQuery({ floorListAndSectorList: { mallId } })
			},
		})
		return promise
	},

	mallMapLocationKit: ({ isNeedResetCache, funcProgress }) => {
		const shoppingCenterId = getRouterMallId()
		const promise = mallMapLocationKitCachePromise.getPromise({
			isNeedResetCache,
			keyString: shoppingCenterId,
			getPromiseWhenNoLive: () => {
				funcProgress()
				return getPromiseQuery({ mallMapLocationKit: { shoppingCenterId } })
			},
		})
		return promise
	},

	zoneList: ({ isNeedResetCache, funcProgress }) => {
		const shoppingCenterId = getRouterMallId()
		const promise = zoneListCachePromise.getPromise({
			isNeedResetCache,
			keyString: shoppingCenterId,
			getPromiseWhenNoLive: () => {
				funcProgress()
				return getPromiseQuery({ zoneList: { shoppingCenterId } })
			},
		})
		return promise
	},

	kktProviderList: ({ isNeedResetCache, funcProgress }) => {
		const shoppingCenterId = getRouterMallId()
		const promise = kktProviderListCachePromise.getPromise({
			isNeedResetCache,
			keyString: shoppingCenterId,
			getPromiseWhenNoLive: () => {
				funcProgress()
				return getPromiseQuery({ kktProviderList: { shoppingCenterId } })
			},
		})
		return promise
	},

	ofdList: ({ isNeedResetCache, funcProgress }) => {
		const shoppingCenterId = getRouterMallId()
		const promise = ofdListCachePromise.getPromise({
			isNeedResetCache,
			keyString: shoppingCenterId,
			getPromiseWhenNoLive: () => {
				funcProgress()
				return getPromiseQuery({ ofdList: { shoppingCenterId } })
			},
		})
		return promise
	},

	docCategories: ({ isNeedResetCache, funcProgress }) => {
		const promise = docCategoriesCachePromise.getPromise({
			isNeedResetCache,
			keyString: '',
			getPromiseWhenNoLive: () => {
				funcProgress()
				return getPromiseQuery({ docCategories: {} })
			},
		})
		return promise
	},

	reportsUnknownKkts: ({ isNeedResetCache, funcProgress }) => {
		const shoppingCenterId = getRouterMallId()
		const promise = unknownKktListCachePromise.getPromise({
			isNeedResetCache,
			keyString: shoppingCenterId,
			getPromiseWhenNoLive: () => {
				funcProgress()
				return getPromiseQuery({ reportsUnknownKkts: { shoppingCenterId } })
			},
		})
		return promise
	},
}


const func = (queryKit) => {
	const [queryName, queryProps] = Object.entries(queryKit)[0]
	const queryFunc = kit[queryName]
	if (!queryFunc) {
		const error = new Error('query not found')
		// eslint-disable-next-line no-console
		console.error(error)
		return Promise.reject(error)
	}
	return queryFunc(queryProps)
}

export default func
