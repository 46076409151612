import Vue from 'vue'
import getPromiseCurrent from '@/assets/js/objects/getPromiseCurrent.js'
import { getRouterMallId } from '@/assets/js/routerHelper.js'
import { getPromiseCache, getPromiseCacheReset, getIsCacheLive, clearCache } from '@/assets/js/query/cacheQuery.js'
import { getKitByFieldName } from '@/assets/js/helper/getKitByFieldName.js'

const depotVue = new Vue({
	data() {
		return {
			isNuxtReady: false,
			mallIdPrev: '',

			sellLocationList_isReady: false,
			sellLocationList: [],

			sellLocationSelectList_isReady: false,
			sellLocationSelectList: [],

			renterList_isReady: false,
			renterList: [],

			positionList_isReady: false,
			positionList: [],

			categoryList_isReady: false,
			categoryList: [],

			profileList_isReady: false,
			profileList: [],

			floorList_isReady: false,
			floorList: [],

			sectorList_isReady: false,
			sectorList: [],

			mallMapLocationKit_isReady: false,
			mallMapLocationKit: {},

			ofdList_isReady: false,
			ofdList: [],

			kktProviderList_isReady: false,
			kktProviderList: [],

			zoneList_isReady: false,
			zoneList: [],

			docCategories_isReady: false,
			docCategories: [],

			unknownKktList_isReady: false,
			unknownKktList: [],
		}
	},
	computed: {
		mallId() {
			return this.isNuxtReady ? getRouterMallId() : ''
		},
		sellLocationListById() {
			return getKitByFieldName(this.sellLocationList, 'id')
		},
		sellLocationSelectListById() {
			return getKitByFieldName(this.sellLocationSelectList, 'id')
		},
		renterListById() {
			return getKitByFieldName(this.renterList, 'id')
		},
		categoryListById() {
			return getKitByFieldName(this.categoryList, 'id')
		},
		profileListById() {
			return getKitByFieldName(this.profileList, 'id')
		},
		floorListById() {
			return getKitByFieldName(this.floorList, 'id')
		},
		sectorListById() {
			return getKitByFieldName(this.sectorList, 'id')
		},
		ofdListById() {
			return getKitByFieldName(this.ofdList, 'id')
		},
		zoneListById() {
			return getKitByFieldName(this.zoneList, 'id')
		},
		docCategoriesById() {
			return getKitByFieldName(this.docCategories, 'id')
		},
		unknownKktListById() {
			return getKitByFieldName(this.unknownKktList, 'id')
		},

		floorListAndSectorList_isReady() {
			return this.floorList_isReady && this.sectorList_isReady
		},

		positionListById() {
			return getKitByFieldName(this.positionList, 'id')
		},
		positionIndoorList() {
			return this.positionList.filter((positionItem) => positionItem.type !== 'outdoor')
		},
		positionOutdoorList() {
			return this.positionList.filter((positionItem) => positionItem.type === 'outdoor')
		},
		positionAvailableList() {
			return this.positionList.filter((positionItem) => positionItem.is_available)
		},
	},
	watch: {
		mallId: {
			immediate: true,
			handler() {
				if (this.mallIdPrev !== this.mallId) {
					const queryParamList = ['sellLocationList', { mallId: this.mallIdPrev }]
					clearCache(...queryParamList)
				}
				this.mallIdPrev = this.mallId
			},
		},
	},
	methods: {
		clearAll() {
			this.sellLocationList_isReady = false
			this.sellLocationList = []
			this.sellLocationSelectList_isReady = false
			this.sellLocationSelectList = []
			this.renterList_isReady = false
			this.renterList = []
			this.positionList_isReady = false
			this.positionList = []
			this.categoryList_isReady = false
			this.categoryList = []
			this.profileList_isReady = false
			this.profileList = []
			this.floorList_isReady = false
			this.floorList = []
			this.sectorList_isReady = false
			this.sectorList = []
			this.mallMapLocationKit_isReady = false
			this.mallMapLocationKit = {}
			this.ofdList_isReady = false
			this.ofdList = []
			this.kktProviderList_isReady = false
			this.kktProviderList = []
			this.zoneList_isReady = false
			this.zoneList = []
			this.docCategories_isReady = false
			this.docCategories = []
			this.unknownKktList_isReady = false
			this.unknownKktList = []
		},

		sellLocationList_getPromiseRequest({ isNeedResetCache }) {
			const mallId = getRouterMallId()
			const queryParamList = ['sellLocationList', { mallId }]

			const promise = isNeedResetCache
				? getPromiseCacheReset(...queryParamList)
				: getPromiseCache(...queryParamList)
			const isCacheLive = getIsCacheLive(...queryParamList)
			this.sellLocationList_isReady = isCacheLive

			promise.then((sellLocationListResponse) => {
				this.sellLocationList = sellLocationListResponse.sell_locations
				this.sellLocationList_isReady = true
			})
			return promise
		},
		sellLocationSelectList_getPromiseRequest({ isNeedResetCache }) {
			return getPromiseCurrent({ sellLocationSelectList: {
				isNeedResetCache,
				funcProgress: () => {
					this.sellLocationSelectList_isReady = false
				},
			} })
				.then((sellLocationSelectListResponse) => {
					this.sellLocationSelectList = sellLocationSelectListResponse.sell_locations
					this.sellLocationSelectList_isReady = true
				})
		},
		renterList_getPromiseRequest({ isNeedResetCache }) {
			return getPromiseCurrent({ renterList: {
				isNeedResetCache,
				funcProgress: () => {
					this.renterList_isReady = false
				},
			} })
				.then((renterListResponse) => {
					this.renterList = renterListResponse.renters
					this.renterList_isReady = true
				})
		},
		positionList_getPromiseRequest({ isNeedResetCache }) {
			return getPromiseCurrent({ positionList: {
				isNeedResetCache,
				funcProgress: () => {
					this.positionList_isReady = false
				},
			} })
				.then((positionListResponse) => {
					this.positionList = positionListResponse.positions
					this.positionList_isReady = true
				})
		},
		categoryList_getPromiseRequest({ isNeedResetCache }) {
			return getPromiseCurrent({ categoryList: {
				isNeedResetCache,
				funcProgress: () => {
					this.categoryList_isReady = false
				},
			} })
				.then((categoryListResponse) => {
					this.categoryList = categoryListResponse
					this.categoryList_isReady = true
				})
		},
		profileList_getPromiseRequest({ isNeedResetCache }) {
			return getPromiseCurrent({ profileList: {
				isNeedResetCache,
				funcProgress: () => {
					this.profileList_isReady = false
				},
			} })
				.then((profileListResponse) => {
					this.profileList = profileListResponse.profiles
					this.profileList_isReady = true
				})
		},
		floorListAndSectorList_getPromiseRequest({ isNeedResetCache }) {
			return getPromiseCurrent({ floorListAndSectorList: {
				isNeedResetCache,
				funcProgress: () => {
					this.floorList_isReady = false
					this.sectorList_isReady = false
				},
			} })
				.then((floorListAndSectorListResponse) => {
					this.floorList = floorListAndSectorListResponse.structure.floors
					this.sectorList = floorListAndSectorListResponse.structure.sectors
					this.floorList_isReady = true
					this.sectorList_isReady = true
				})
		},
		floorList_getPromiseRequest({ isNeedResetCache }) {
			return this.floorListAndSectorList_getPromiseRequest({ isNeedResetCache })
		},
		sectorList_getPromiseRequest({ isNeedResetCache }) {
			return this.floorListAndSectorList_getPromiseRequest({ isNeedResetCache })
		},

		mallMapLocationKit_getPromiseRequest({ isNeedResetCache }) {
			return getPromiseCurrent({ mallMapLocationKit: {
				isNeedResetCache,
				funcProgress: () => {
					this.mallMapLocationKit_isReady = false
				},
			} })
				.then((mallMapLocationListResponse) => {
					this.mallMapLocationKit = mallMapLocationListResponse
					this.mallMapLocationKit_isReady = true
				})
		},

		ofdList_getPromiseRequest({ isNeedResetCache }) {
			return getPromiseCurrent({ ofdList: {
				isNeedResetCache,
				funcProgress: () => {
					this.ofdList_isReady = false
				},
			} })
				.then((ofdListResponse) => {
					this.ofdList = ofdListResponse.ofds
					this.ofdList_isReady = true
				})
		},

		kktProviderList_getPromiseRequest({ isNeedResetCache }) {
			return getPromiseCurrent({ kktProviderList: {
				isNeedResetCache,
				funcProgress: () => {
					this.kktProviderList_isReady = false
				},
			} })
				.then((kktProviderListResponse) => {
					this.kktProviderList = kktProviderListResponse
					this.kktProviderList_isReady = true
				})
		},

		unknownKktList_getPromiseRequest({ isNeedResetCache }) {
			return getPromiseCurrent({ reportsUnknownKkts: {
				isNeedResetCache,
				funcProgress: () => {
					this.unknownKktList_isReady = false
				},
			} })
				.then((unknownKktListResponse) => {
					this.unknownKktList = unknownKktListResponse.unknown_payment_terminals
					this.unknownKktList_isReady = true
				})
		},

		zoneList_getPromiseRequest({ isNeedResetCache }) {
			return getPromiseCurrent({ zoneList: {
				isNeedResetCache,
				funcProgress: () => {
					this.zoneList_isReady = false
				},
			} })
				.then((zoneListResponse) => {
					this.zoneList = zoneListResponse
					this.zoneList_isReady = true
				})
		},

		docCategories_getPromiseRequest({ isNeedResetCache }) {
			return getPromiseCurrent({ docCategories: {
				isNeedResetCache,
				funcProgress: () => {
					this.docCategories_isReady = false
				},
			} })
				.then((docCategoriesResponse) => {
					this.docCategories = docCategoriesResponse
					this.docCategories_isReady = true
				})
		},
	},
})

window.onNuxtReady(() => {
	depotVue.isNuxtReady = true
})

export { depotVue }
