import { accessRoleListKit } from '@/assets/js/access/accessRoleListKit.js'
import { MenuSideItem, def } from '@/assets/js/sideMenu/MenuSideItem.js'
import { translate } from '@/assets/i18n/i18nextConfig.js'
import { hasAccess } from '@/assets/js/access/hasAccess.js'

const getItemsFiltered = ({ itemList }) => {
	if (!itemList || !itemList.length) {
		return []
	}

	const itemsFiltered = itemList.filter((item) => {
		return hasAccess({ accessRoleList: item.accessRoleList })
	})

	itemsFiltered.forEach((item) => {
		item.childList = getItemsFiltered({ itemList: item.childList })
	})

	return itemsFiltered
}
const getMallsMenuItemList = () => {
	const list = [
		// new MenuSideItem({
		//	 ...def,
		//	 text: ta('sideMenu.monitoring'),
		//	 iconCssClass: 'fa-solid fa-chart-area text-primary',
		//	 routeTo: { name: 'malls-monitoring' },
		//	 accessRoleList: accessRoleListKit.all
		// }),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.dashboard'),
			iconCssClass: 'fa-regular fa-objects-column text-warning',
			routeTo: { name: 'malls-dashboard' },
			accessRoleList: accessRoleListKit.all,
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.analysis'),
			iconCssClass: 'fa-solid fa-wave-pulse text-green',
			routeTo: { name: 'malls-financial' },
			accessRoleList: accessRoleListKit.all,
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.addMall'),
			iconCssClass: 'fa-solid fa-plus text-success',
			routeTo: { name: 'malls-add' },
			accessRoleList: ['systemAdmin'],
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.reports'),
			iconCssClass: 'fa-solid fa-files text-danger',
			routeTo: { name: 'malls-reports-general' },
			accessRoleList: accessRoleListKit.all,
			childList: [
				new MenuSideItem({
					...def,
					text: translate('sideMenu.shopsByMonths'),
					routeTo: { name: 'malls-reports-general' },
					accessRoleList: accessRoleListKit.all,
				}),
				new MenuSideItem({
					...def,
					text: translate('sideMenu.shopsByOperationType'),
					routeTo: { name: 'malls-reports-operations' },
					accessRoleList: accessRoleListKit.all,
				}),
			],
		}),
		new MenuSideItem({
			...def,
			text: translate('sideMenu.support'),
			iconCssClass: 'fa-regular fa-message text-purple',
			routeTo: { name: 'malls-support' },
			accessRoleList: accessRoleListKit.all,
			isShowSupportInfo: true,
		}),
		// new MenuSideItem({
		//	 ...def,
		//	 text: translate('sideMenu.profilesAnlysis'),
		//	 iconCssClass: 'fa-regular fa-objects-align-bottom',
		//	 routeTo: { name: 'malls-profiles' },
		//	 accessRoleList: accessRoleListKit.all
		// })
	]

	return getItemsFiltered({ itemList: list })
}


export { getMallsMenuItemList }
