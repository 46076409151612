
	import Vue from 'vue'
	import LightTooltip from '@/components/LightTooltip.vue'

	import { translate } from '@/assets/i18n/i18nextConfig.js'

	export default Vue.component('DocsMenuButtons', {
		components: {
			LightTooltip,
		},
		props: {
			itemId: { type: String, default: '' },
			slug: { type: String, default: '' },
			parentId: { type: String, default: '' },
			type: {
				type: String,
				default: 'page',
				validator(value: string): boolean {
					return ['category', 'page'].includes(value)
				},
			},
		},
		methods: {
			translate,
		},
	})
