import { hasAccess } from '@/assets/js/access/hasAccess.js'

export default {
	inserted: (el, binding, vnode) => {
		const accessRoleList = binding.value ?? []
		if (!hasAccess({ accessRoleList })) {
			el.remove()
		}
	},
}
