var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.Children.length === 0)?_c('div',{class:[
		'docs-menu-item',
		_vm.isActive ? 'docs-menu-item_active' : '',
	],attrs:{"draggable":_vm.isStaff}},[(_vm.Item.draft)?_c('LightTooltip',[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(_vm._s(_vm.translate('docs.draft')))]),_vm._v(" "),_c('i',{staticClass:"fa-regular fa-triangle-person-digging g-fs-8 text-gray-600"})]):_c('i',{staticClass:"fa-solid fa-circle g-fs-8 text-gray-600"}),_vm._v(" "),_c('DocsMenuLink',{attrs:{"item":_vm.Item,"type":_vm.type}}),_vm._v(" "),(_vm.isStaff)?_c('DocsMenuButtons',{attrs:{"type":_vm.type,"item-id":_vm.Item.id,"slug":_vm.Item.slug,"parent-id":_vm.Item.parentId}}):_vm._e()],1):_c('details',{attrs:{"open":_vm.open,"draggable":_vm.isStaff},on:{"toggle":_vm.handleToggle}},[_c('summary',{class:[
			'docs-menu-item',
			_vm.isActive ? 'docs-menu-item_active' : '',
		]},[_c('i',{staticClass:"fa-solid g-fs-12",class:_vm.open ? 'fa-chevron-down' : 'fa-chevron-right'}),_vm._v(" "),_c('DocsMenuLink',{attrs:{"item":_vm.Item,"type":_vm.type}}),_vm._v(" "),(_vm.isStaff)?_c('DocsMenuButtons',{attrs:{"type":_vm.type,"item-id":_vm.Item.id,"slug":_vm.Item.slug,"parent-id":_vm.Item.parentId}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column gap-2 pl-4 py-2"},[(_vm.isStaff)?_c('Draggable',{staticClass:"d-flex flex-column gap-2",model:{value:(_vm.Children),callback:function ($$v) {_vm.Children=$$v},expression:"Children"}},[_c('transition-group',_vm._l((_vm.Children.slice(0, 9)),function(child){return _c('DocsMenuElement',{key:`docs-menu-item_${_vm.Item.id}_${child.id}`,attrs:{"item":child,"type":"page"}})}),1)],1):_vm._l((_vm.Children.slice(0, 9)),function(child){return _c('DocsMenuElement',{key:`docs-menu-item_${_vm.Item.id}_${child.id}`,attrs:{"item":child,"type":"page"}})}),_vm._v(" "),(_vm.Children.length > 9)?_c('NuxtLink',{staticClass:"docs-menu-item text-center justify-content-center",attrs:{"to":{
				name: 'docs-categories-slug',
				params: { slug: _vm.Item.slug },
			},"draggable":false}},[_vm._v(_vm._s(_vm.translate('common.showMore')))]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }