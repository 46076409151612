const kit = {
	supportAllTicketList: () => {
		const url = 'api/v2/support/tickets'
		return window.$nuxt.$axios.$get(url)
	},

	supportTicketListByMallByPage: ({ shoppingCenterId, pageNumber = 1, showClosed = false, type = [] }) => {
		let url = `/api/v2/sc/${shoppingCenterId}/support/tickets?page=${pageNumber}`
		if (showClosed) {
			url = `${url}&show_closed=true`
		}
		if (type.length > 0) {
			url = `${url}&type[]=${type.join(',')}`
		}
		return window.$nuxt.$axios.$get(url)
	},
	supportTicketListByPage: ({ pageNumber = 1, showClosed = false, type = [] }) => {
		let url = `/api/v2/support/tickets?page=${pageNumber}&show_closed=${showClosed}`
		if (type.length > 0) {
			url = `${url}&type[]=${type.join(',')}`
		}
		return window.$nuxt.$axios.$get(url)
	},

	supportTicket: ({ shoppingCenterId, supportTicketId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/support/tickets/${supportTicketId}`
		return window.$nuxt.$axios.$get(url)
	},

	supportTicketByNumber: ({ shoppingCenterId, supportTicketNumber, uiIgnoreNotify = false }) => {
		let url = `/api/v2/sc/${shoppingCenterId}/support/tickets/by_number/${supportTicketNumber}`
		if (uiIgnoreNotify) {
			url += '?ui_ignore_notify'
		}
		return window.$nuxt.$axios.$get(url)
	},

	supportTicketOpen: ({ shoppingCenterId, supportTicketId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/support/tickets/${supportTicketId}/open`
		return window.$nuxt.$axios.$post(url)
	},

	supportTicketClose: ({ shoppingCenterId, supportTicketId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/support/tickets/${supportTicketId}/close`
		return window.$nuxt.$axios.$post(url)
	},

	supportTicketUpdate: ({ shoppingCenterId, sellLocationId, supportTicketId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/support/tickets/${supportTicketId}`
		const params = { resource_id: sellLocationId }
		return window.$nuxt.$axios.$put(url, params)
	},

	supportTicketCreate: ({
		mallId,
		title,
		requestType,
		description,
		sellLocationId,
		fileList,
		participants,
		assigneeId,
		type,
	}) => {
		const url = `/api/v2/sc/${mallId}/support/tickets`
		const params = new FormData()
		params.append('title', title)
		params.append('request_type', requestType)
		params.append('description', description)
		if (sellLocationId) {
			params.append('resource_id', sellLocationId)
		}
		if (fileList.length > 0) {
			fileList.forEach((item) => {
				params.append('files[]', item)
			})
		}
		if (participants.length > 0) {
			participants.forEach((item) => {
				params.append('participants[]', item)
			})
		}
		if (assigneeId) {
			params.append('assignee_id', assigneeId)
		}
		if (type) {
			params.append('type', type)
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	supportTicketAccomplish: ({ shoppingCenterId, supportTicketId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/support/tickets/${supportTicketId}/accomplish`
		return window.$nuxt.$axios.$post(url)
	},

	supportTicketCommentAdd: ({ mallId, supportTicketId, description, fileList }) => {
		const url = `/api/v2/sc/${mallId}/support/tickets/${supportTicketId}/comments`
		const params = new FormData()
		params.append('description', description)
		if (fileList.length > 0) {
			fileList.forEach((item) => {
				params.append('files[]', item)
			})
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	supportTicketCommentList: ({ shoppingCenterId, supportTicketId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/support/tickets/${supportTicketId}/comments`
		return window.$nuxt.$axios.$get(url)
	},

	supportTicketCommentSeen: ({ mallId, supportTicketId, supportCommentId }) => {
		const url = `/api/v2/sc/${mallId}/support/tickets/${supportTicketId}/comments/${supportCommentId}/seen_by`
		return window.$nuxt.$axios.$post(url)
	},

	supportTicketParticipantAdd: ({ shoppingCenterId, supportTicketId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/support/tickets/${supportTicketId}/add_to_participants`
		return window.$nuxt.$axios.$post(url)
	},

	supportTicketParticipantRemove: ({ shoppingCenterId, supportTicketId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/support/tickets/${supportTicketId}/remove_from_participants`
		return window.$nuxt.$axios.$post(url)
	},

	addTicketToFavorites: ({ supportTicketId }) => {
		const url = '/api/v2/users/add_issue_to_favorites'
		return window.$nuxt.$axios.$post(url, { favorite_issue_id: supportTicketId })
	},

	removeTicketFromFavorites: ({ supportTicketId }) => {
		const url = '/api/v2/users/remove_issue_from_favorites'
		return window.$nuxt.$axios.$post(url, { favorite_issue_id: supportTicketId })
	},

	supportTicketSetPriority: ({ shoppingCenterId, supportTicketId, priority }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/support/tickets/${supportTicketId}`
		const params = { priority }
		return window.$nuxt.$axios.$put(url, params)
	},

	supportTicketSetAssignee: ({ shoppingCenterId, supportTicketId, assigneeId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/support/tickets/${supportTicketId}`
		const params = new FormData()
		params.append('assignee_id', assigneeId)
		return window.$nuxt.$axios.$patch(url, params)
	},

	supportTicketNotes: ({ shoppingCenterId, ticketId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/notes?resource_id=${ticketId}`
		return window.$nuxt.$axios.$get(url)
	},

	supportTicketNoteAdd: ({ shoppingCenterId, ticketId, content }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/notes?resource_id=${ticketId}`
		const params = new FormData()
		params.append('content', content)
		return window.$nuxt.$axios.$post(url, params)
	},

	supportTicketNoteUpdate: ({ shoppingCenterId, ticketId, noteId, content }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/notes/${noteId}`
		const params = new FormData()
		params.append('content', content)
		params.append('resource_id', ticketId)
		return window.$nuxt.$axios.$put(url, params)
	},

	supportTicketSetReminder: ({ shoppingCenterId, ticketId, reminderName, remindAt }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/support/tickets/${ticketId}`
		const params = {
			reminders_attributes: [
				{
					name: reminderName,
					remind_at: remindAt,
				},
			],
		}
		return window.$nuxt.$axios.$put(url, params)
	},

	supportTicketUpdateReminder: ({ shoppingCenterId, ticketId, reminderId, reminderName, remindAt }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/support/tickets/${ticketId}`
		const params = {
			reminders_attributes: [
				{
					id: reminderId,
					name: reminderName,
					remind_at: remindAt,
				},
			],
		}
		return window.$nuxt.$axios.$patch(url, params)
	},

	supportTicketDeleteReminder: ({ shoppingCenterId, ticketId, reminderId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/support/tickets/${ticketId}`
		const params = {
			reminders_attributes: [
				{
					id: reminderId,
					_destroy: 1,
				},
			],
		}
		return window.$nuxt.$axios.$put(url, params)
	},
}

export default kit
