<template>
	<ElTooltip
		effect="light"
		:placement="placement"
		:visible-arrow="false"
		:open-delay="openDelay"
		:popper-class="`light-tooltip ${popperClass}`"
		:disabled="disabled"
	>
		<div slot="content"><slot name="content"></slot></div>
		<slot></slot>
	</ElTooltip>
</template>

<script>
	import { Tooltip } from 'element-ui'

	export default {
		components: {
			ElTooltip: Tooltip,
		},
		props: {
			placement: { type: String, default: 'top' },
			disabled: { type: Boolean, default: false },
			popperClass: { type: String, default: '' },
			openDelay: { type: Number, default: 0 },
		},
	}
</script>
<style>
.tooltip-width-limited {
	max-width: 300px;
}

.tooltip-max-height-400 {
	max-height: 400px;
	-ms-overflow-style: none;
	overflow-y: auto;
	scrollbar-width: none;
}

.tooltip-max-height-400::-webkit-scrollbar {
	display: none;
}
</style>
