const messageList = [
	{ url: '/positions', status: 'already_exist', message: 'Площадка с таким номером уже существует' },
	{
		url: '/positions',
		status: 'position with this name already exist in this shopping center',
		message: 'Площадка с таким номером уже существует',
	},

	{ url: '/link-role', status: 'wrong_token', message: 'Вы ввели неверный токен' },
	{ url: '/link-role', status: 'token_expired', message: 'Введенный вами токен уже истек' },
	{ url: '/link-role', status: 'token_not_found', message: 'Токен не найден' },

	{ url: '/generate-otp-for-renter', status: 'no_phone', message: 'Нет номера телефона' },
	{ url: '/generate-otp-for-renter', status: 'error_while_sending', message: 'Ошибка во время отправки sms' },
	{ url: '/generate-otp-for-renter', status: 'no_user', message: 'Пользователь не найден' },
	{ url: '/generate-otp-for-renter', status: 'not_active', message: 'Токен неактивен' },

	{ url: '/generate-otp', status: 'no_phone', message: 'Нет номера телефона' },
	{ url: '/generate-otp', status: 'error_while_sending', message: 'Ошибка во время отправки sms' },
	{ url: '/generate-otp', status: 'no_user', message: 'Пользователь не найден' },
	{ url: '/generate-otp', status: 'not_active', message: 'Токен неактивен' },

	{ url: '/update-phone', status: 'otp_expire', message: 'Одноразовый пароль устарел' },
	{ url: '/update-phone', status: 'otp_reach_maximum', message: 'Превышено максимальное число попыток' },
	{ url: '/update-phone', status: 'wrong_token', message: 'Неверный одноразовый пароль' },
	{ url: '/update-phone', status: 'no_user', message: 'Пользователь не найден' },
	{ url: '/update-phone', status: 'not_active', message: 'Токен неактивен' },

	{ url: '/update-password', status: 'otp_expire', message: 'Одноразовый пароль устарел' },
	{ url: '/update-password', status: 'otp_reach_maximum', message: 'Превышено максимальное число попыток' },
	{ url: '/update-password', status: 'wrong_token', message: 'Неверный одноразовый пароль' },
	{ url: '/update-password', status: 'no_user', message: 'Пользователь не найден' },
	{ url: '/update-password', status: 'not_active', message: 'Токен неактивен' },

	{
		url: '/chat/messages/upload',
		status: '',
		message: 'Во время загрузки одного или нескольких файлов произошла ошибка',
	},

	{ url: '/misc/accept_xls', status: '', message: 'Что-то пошло не так во время загрузки файла' },

	{
		url: '/misc/renter_xls',
		status: '',
		message: 'Во время запроса возникла ошибка. Возможно вы загрузили не тот файл или его структура была нарушена.',
	},

	{ url: '/toggl-hints', status: 'no_user', message: 'Пользователь не найден' },

	{ url: '/reset-password', status: 'wrong_credentials', message: 'Пользователь не найден' },
	{ url: '/reset-password', status: 'error_while_sending', message: 'Ошибка во время отправки sms' },
	{ url: '/reset-password', status: 'otp_expire', message: 'Одноразовый пароль устарел' },
	{ url: '/reset-password', status: 'otp_reach_maximum', message: 'Превышено максимальное число попыток' },
	{ url: '/reset-password', status: 'wrong_token', message: 'Неверный одноразовый пароль' },
	{ url: '/reset-password', status: 'wrong_user', message: 'Логин или пароль введены не верны' },
	{ url: '/reset-password', status: 'not_active', message: 'Токен неактивен' },
	{ url: '/reset-password', status: 'short_password', message: 'Пароль должен содержать не менее 8 символов' },

	{ url: '/check-credentials', status: 'wrong_credentials', message: 'Логин или пароль введены не верны' },
	{ url: '/check-credentials', status: 'no_phone', message: 'Нет номера телефона' },
	{ url: '/check-credentials', status: 'error_while_sending', message: 'Ошибка во время отправки sms' },
	{ url: '/check-credentials', status: 'not_active', message: 'Токен неактивен' },

	{ url: '/login', status: 'otp_expire', message: 'Одноразовый пароль устарел' },
	{ url: '/login', status: 'otp_reach_maximum', message: 'Превышено максимальное число попыток' },
	{ url: '/login', status: 'wrong_token', message: 'Неверный одноразовый пароль' },
	{ url: '/login', status: 'wrong_user', message: 'Логин или пароль введены не верны' },
	{ url: '/login', status: 'not_active', message: 'Токен неактивен' },

	{ url: '/lessors', status: 'error_while_sending', message: 'Ошибка во время отправки sms' },

	{ url: '/entities/*', status: 'error_while_sending', message: 'Ошибка во время отправки sms' },
]

const messageListTextByUrlStatus = messageList.reduce((acc, item) => {
	acc[`${item.url}-${item.status}`] = item.message
	return acc
}, {})


const getResponseMessage = (requestUrl, response, defaultMessage) => {
	if (Array.isArray(response)) {
		return response.map((item) => {
			return messageListTextByUrlStatus[`${requestUrl}-${item}`] ?? defaultMessage
		}).join(', ')
	}
	return messageListTextByUrlStatus[`${requestUrl}-${response}`] ?? defaultMessage
}

export { getResponseMessage }
