type KeysConverterCases = 'camelCase'|'snake_case'


export class KeysConverter {
	static methods = {
		camelCase: KeysConverter.toCamelCase,
		snake_case: KeysConverter.toSnakeCase,
	}

	static stringMethods = {
		camelCase: KeysConverter.toCamelCaseString,
		snake_case: KeysConverter.toSnakeCaseString,
	}

	// snake_case => camelCase

	static toCamelCase(input: any): any {
		return KeysConverter.convert(input, 'camelCase')
	}

	static toCamelCaseString(input: string): string {
		const keys = input.split('__')

		const res: string[] = keys.map((key: string) => {
			const startsWithUnderscore = key.startsWith('_')
			const words: string[] = key.split('_')
			const vals: string[] = words.map((word: string, index: number) => {
				if (index === 0 || (index === 1 && startsWithUnderscore)) {
					return word
				}

				return `${word.charAt(0).toUpperCase()}${word.substring(1)}`
			})
			return `${startsWithUnderscore ? '_' : ''}${vals.join('')}`
		})

		return res.join('__')
	}

	// camelCase => snake_case

	static toSnakeCase(input: any): any {
		return KeysConverter.convert(input, 'snake_case')
	}

	static toSnakeCaseString(input: string): string {
		const keys = input.split('__')

		const res: string[] = keys.map((key: string) => {
			return key.replace(/[A-Z]/g, (letter, index) => {
				if (index === 0) {
					return letter.toLowerCase()
				}
				return `_${letter.toLowerCase()}`
			})
		})

		return res.join('__')
	}

	// Generic methods

	static convert(input: any, targetCase: KeysConverterCases): any {
		if (Array.isArray(input)) {
			return input.map((val) => KeysConverter.convert(val, targetCase))
		}
		if (typeof input === 'object' && input !== null) {
			return KeysConverter.convertObject(input, targetCase)
		}
		if (typeof input === 'string') {
			return KeysConverter.convertString(input, targetCase)
		}

		return input
	}

	static convertObject(input: any, targetCase: KeysConverterCases): any {
		const type = typeof input
		if (type !== 'object') {
			throw new Error(`KeysConverter error: object expected. ${type} was provided`)
		}

		const convertString = KeysConverter.stringMethods[targetCase]
		const convert = KeysConverter.methods[targetCase]

		const keys: string[] = Object.keys(input)
		const processedObject: any = {}
		keys.forEach((key: string) => {
			const newKey = convertString(key)
			let value = input[key]
			if (typeof value === 'object' && value !== null) {
				value = convert(value)
			}
			processedObject[newKey] = value
		})
		return processedObject
	}

	static convertString(input: string, targetCase: KeysConverterCases): string {
		const type = typeof input
		if (type !== 'string') {
			throw new Error(`KeysConverter error: string expected. ${type} was provided`)
		}

		return KeysConverter.stringMethods[targetCase](input)
	}
}
