<template>
	<div>
		<Modal :show.sync="isModalOpened" :size="'xl'" :header-classes="'pb-0'">
			<template slot="header">
				<div class="flex-row">
					<h5 class="modal-title">Список аномалий по торговой точке {{ shopName }}</h5>
					<ElSwitch
						active-text="Показывать архивные"
						:value="isShowArchived"
						active-color="#13ce66"
						inactive-color="#d8d7d7"
						class="mt-3"
						@change="toggleArchived()"
					></ElSwitch>
				</div>
			</template>
			<div class="anomaliesTable">
				<TableWithPagination
					class="w-100"
					:table-data="tableData"
					:row-class-name-function="rowClassName"
				>
					<ElTableColumn label="" prop="type" width="70">
						<template #default="{ row }">
							<div class="d-flex flex-column align-items-center">
								<div class="anomaly-status-circle col-25 g-height-25 mb-2" :class="getAnomalyCssColorClass(row.type)"></div>
								<ElTooltip effect="dark" placement="left" :open-delay="100" :content="'Отработано'">
									<BaseButton
										v-if="row.status === 'new'"
										type="link"
										class="mx-0 action-icon action-accept py-2 text-success"
										@click="openCommentModal(row.id, 'completed')"
									>
										<i class="fas fa-check g-fs-16 d-block"></i>
									</BaseButton>
								</ElTooltip>
								<ElTooltip effect="dark" placement="left" :open-delay="100" :content="'Архивировать'">
									<BaseButton
										v-if="row.status === 'new'"
										type="link"
										class="mx-0 action-icon action-refuse py-2 text-warning"
										@click="openCommentModal(row.id, 'archived')"
									>
										<i class="fas fa-times g-fs-16 d-block"></i>
									</BaseButton>
								</ElTooltip>
							</div>
						</template>
					</ElTableColumn>
					<ElTableColumn label="Дата фиксации" prop="created_at" width="150">
						<template #default="{ row }">
							{{ getDateFormatted(row.created_at) }}
						</template>
					</ElTableColumn>
					<ElTableColumn label="Описание" prop="type">
						<template #default="{ row }">
							{{ getAnomalyTittle(row) }}
						</template>
					</ElTableColumn>
					<ElTableColumn label="Период аномалии" prop="period">
						<template #default="{ row }">
							{{ anomalyPeriod(row) }}
						</template>
					</ElTableColumn>
					<ElTableColumn label="Рекомендации" prop="type">
						<template #default="{ row }">
							{{ getAnomalyRecommendation(row) }}
						</template>
					</ElTableColumn>
				</TableWithPagination>
			</div>
		</Modal>
		<Modal v-if="isCommentModalOpened" :show.sync="isCommentModalOpened" :size="'lg'">
			<template slot="header">
				<h5 id="exampleModalLabel" class="modal-title">Изменение статуса аномалии</h5>
			</template>
			<div>
				<p>Для завершения изменения статуса аномалии, оставьте комментарий и нажмите на кнопку подтверждения</p>
				<ValidationObserver v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(submitCommentForm)">
						<div class="form-row">
							<div class="col-md-12">
								<label class="form-control-label d-block g-fs-14 g-flh-21 d-flex flex-row align-items-center">
									Комментарий
									<ElTooltip effect="dark" placement="top" :open-delay="100">
										<div slot="content">Краткое описание порядка отработки аномалии или причинах возникновения</div>
										<i class="far fa-question-circle text-gray help-icon cursor-help g-fs-14 ml-1"></i>
									</ElTooltip>
								</label>

								<BaseInput rules="required" name="Комментарий">
									<textarea
										ref="message"
										v-model="commentModal.comment"
										name="Комментарий"
										placeholder="Комментарий"
										class="form-control"
										rows="3"
									>
									</textarea>
								</BaseInput>
							</div>
						</div>
						<BaseButton type="danger" native-type="submit">Подтвердить изменение статуса</BaseButton>
					</form>
				</ValidationObserver>
			</div>
		</Modal>
	</div>
</template>

<script>
	import { ValidationObserver } from 'vee-validate'
	import { TableColumn, Switch, Tooltip } from 'element-ui'
	import Modal from '@/components/argon-core/Modal.vue'
	import BaseButton from '@/components/argon-core/BaseButton.vue'
	import BaseInput from '@/components/argon-core/Inputs/BaseInput.vue'
	import TableWithPagination from '@/components/tables/TableWithPagination.vue'

	import {
		getAnomalyCssColorClass,
		getAnomalyTittle,
		getAnomalyRecommendation,
	} from '@/assets/js/other/anomalyConfigs.js'

	import getDateRangeAnomalies from '@/assets/js/other/getDateRangeAnomalies.js'
	import { getPromiseQuery } from '@/assets/js/query/getPromiseQuery.js'
	import { getRouterMallId } from '@/assets/js/routerHelper.js'

	const getDateFormatted = (dateIncome) => {
		const date = new Date(dateIncome)
		const year = date.getFullYear()
		const month = `0${(date.getMonth() + 1)}`.slice(-2)
		const day = `0${date.getDate()}`.slice(-2)
		return `${day}.${month}.${year}`
	}

	export default {
		components: {
			ElTableColumn: TableColumn,
			ElSwitch: Switch,
			ElTooltip: Tooltip,
			BaseButton,
			BaseInput,
			TableWithPagination,
			Modal,
			ValidationObserver,
		},
		props: {
			inititalData: { type: Array, description: 'List of anomalies', default: () => [] },
			shopName: { type: String, description: 'Sell Location name', default: () => null },
			shopId: { type: String, description: 'Sell Location id', default: () => null },
			anomaliesCount: { type: Object, description: 'Anomalies count by multiple conditions', default: () => { } },
			showArchivedInitialy: { type: Boolean, description: 'Show archived anomalies', default: false },
			opened: { type: Boolean, description: 'The modal window is open', default: false },
		},
		data() {
			return {
				tableData: [],
				fullTableData: [],
				showArchived: this.showArchivedInitialy,
				propsToSearch: ['legalName', 'managerName', 'inn', 'managerPhone'],
				tableColumns: [],
				commentModalOpened: false,
				commentModal: {
					comment: '',
					status: '',
					selectedAnomaly: null,
				},
			}
		},
		computed: {
			isModalOpened: {
				get() {
					return this.opened
				},
				set(newValue) {
					this.$emit('update-opened', newValue)
				},
			},
			isCommentModalOpened: {
				get() {
					return this.commentModalOpened
				},
				set(val) {
					this.commentModalOpened = !this.commentModalOpened
				},
			},
			isShowArchived: {
				get() {
					return this.showArchived
				},
				set(val) {
					this.showArchived = true
					this.fillTable()
				},
			},
		},
		watch: {
			isModalOpened(newVal, oldVal) {
				this.checkExistingData()
				this.fillTable()
			},
			isCommentModalOpened(newVal, oldVal) {
				if (!newVal) this.setTableData(this.sortedAnomalies())
			},
			showArchivedInitialy(newVal, oldVal) {
				this.isShowArchived = newVal
			},
		},
		mounted() {
			this.setTableData(this.inititalData)
			this.checkExistingData()
			this.fillTable()
		},
		methods: {
			getDateFormatted,
			getAnomalyCssColorClass,
			getAnomalyTittle,
			getAnomalyRecommendation,
			getAnomalies() {
				if (!this.opened) {
					return
				}
				const shoppingCenterId = getRouterMallId()
				Promise.all([
					getPromiseQuery({ analyticsAnomalyList: { shoppingCenterId, sellLocationIdList: [this.shopId] } }),
					getPromiseQuery({ analyticsAnomalyConfig: { shoppingCenterId } }),
				])
					.then(([analyticsAnomalyListResponse, analyticsAnomalyConfigResponse]) => {
						const anomalyTypeKit = analyticsAnomalyConfigResponse.anomalies_types
						const anomalyList = analyticsAnomalyListResponse.anomalies[this.shopId] ?? []
						const anomalyListNew = []
						anomalyList.forEach((anomaly) => {
							const anomalyCopy = { ...anomaly }
							let anomalyLevel = ''
							Object.entries(anomalyTypeKit).forEach(([level, typeList]) => {
								if (typeList.includes(anomaly.type)) {
									anomalyLevel = level
								}
							})
							anomalyCopy.level = anomalyLevel
							anomalyListNew.push(anomalyCopy)
						})

						this.setTableData(anomalyListNew)
					})
			},
			checkExistingData() {
				this.getAnomalies()
				// let existingData = window.localStorage.getItem(`${this.shopId}-anomalies`)
				// let existingDataExpire = window.localStorage.getItem(`${this.shopId}-anomalies-expire`)
				// existingData = JSON.parse(existingData)
				// existingDataExpire = new Date(existingDataExpire)
				// if (existingData && existingData.length && existingDataExpire && new Date() < existingDataExpire) {
				//	 this.setTableData(existingData)
				// } else {
				// }
			},
			setTableData(data) {
				this.fullTableData = data

				if (!this.isShowArchived) {
					this.tableData = this.newAnomalies()
				} else {
					this.tableData = this.sortedAnomalies()
				}

				if (this.shopId) {
					window.localStorage.setItem(`${this.shopId}-anomalies`, JSON.stringify(this.sortedAnomalies()))

					const dt = new Date()
					dt.setHours(dt.getHours() + 1)
					window.localStorage.setItem(`${this.shopId}-anomalies-expire`, dt)
				}
			},
			toggleArchived() {
				this.showArchived = !this.showArchived
				this.fillTable()
			},
			sortByDate(a, b) {
				return new Date(b.date) - new Date(a.date)
			},
			newAnomalies() {
				return this.fullTableData.filter((row) => row.status === 'new').sort(this.sortByDate)
			},
			completedAnomalies() {
				return this.fullTableData.filter((row) => row.status !== 'new' && row.status !== 'archived').sort(this.sortByDate)
			},
			archivedAnomalies() {
				return this.fullTableData.filter((row) => row.status === 'archived').sort(this.sortByDate)
			},
			sortedAnomalies() {
				return [...this.newAnomalies(), ...this.completedAnomalies(), ...this.archivedAnomalies()]
			},
			fillTable() {
				if (this.isShowArchived) {
					this.tableData = this.sortedAnomalies()
				} else if (this.fullTableData.length > 0) {
					this.tableData = this.newAnomalies()
				} else {
					this.tableData = this.inititalData.filter((row) => row.status === 'new')
				}
			},

			anomalyPeriod(anomaly) {
				const dateRange = getDateRangeAnomalies({ type: anomaly.type, dateString: anomaly.date, meta: anomaly.meta })
				const dateRangeFormatted = dateRange.map((item) => getDateFormatted(item))
				if (dateRange.length === 2) {
					return `${dateRangeFormatted[0]} - ${dateRangeFormatted[1]}`
				}
				if (dateRange.length === 1) {
					return `${dateRangeFormatted[0]}`
				}
				return ''
			},

			rowClassName({ row, rowIndex }) {
				return row.status !== 'new' ? 'archived-tr' : ''
			},

			openCommentModal(id, status) {
				this.commentModal.id = id
				this.commentModal.status = status
				this.commentModal.comment = ''
				this.isCommentModalOpened = true
				this.$nextTick(() => {
					this.$refs.message.focus()
				})
			},

			async submitCommentForm() {
				const shoppingCenterId = getRouterMallId()
				getPromiseQuery({
					anomalyUpdateStatus: {
						shoppingCenterId,
						anomalyId: this.commentModal.id,
						status: this.commentModal.status,
						comment: this.commentModal.comment,
					},
				})
					.then((response) => {
						const anomaly = this.tableData.find((obj) => obj.id === this.commentModal.id)
						if (anomaly) {
							anomaly.status = this.commentModal.status
							const anomalyIndex = this.fullTableData.findIndex((o) => o.id === this.commentModal.id)
							if (anomalyIndex >= 0) {
								this.fullTableData[anomalyIndex] = anomaly
							}
							this.$emit('anomalyCounterChange', anomaly.level)
						}
						this.isCommentModalOpened = false
						this.fillTable()
						this.$notify({
							message: 'Статус успешно изменён', timeout: 5000, icon: 'ni ni-bell-55', type: 'success',
						})
					})
			},
		},
	}
</script>
<style lang="scss" scoped>
::v-deep .el-table__body .cell {
	font-size: 14px;
}

::v-deep .anomaly-status-circle {
	position: relative;
	width: 28px;
	height: 28px;
	border-radius: 20%;

	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-45%);
	}
}

::v-deep .card .table td,
::v-deep .card .table th,
::v-deep .card .el-table td,
::v-deep .card .el-table th {
	padding-right: 10px !important;
	padding-left: 10px !important;
}

::v-deep .header-cell .cell {
	justify-content: center;
}

.action-icon.action-accept:hover {
	color: #1ca4a8 !important;
}

.action-icon.action-refuse:hover {
	color: #ca5832 !important;
}

.available-data-card {
	border-width: 4px 0 0 0;
}

.hoverable {
	position: relative;
	cursor: pointer;
	transition: all 0.25s ease;
}

.hoverable:hover {
	box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
	transform: translateY(-3px);
}

.link {
	color: var(--gray-dark);
}

.link:hover {
	color: var(--blue);
}

.anomaly-yellow {
	background-color: #efa654 !important;
}

.border-yellow {
	border-color: #efa654 !important;
}
</style>
