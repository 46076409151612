import swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'
import { translate } from '@/assets/i18n/i18nextConfig.js'

const swalWithBootstrapButtons = swal.mixin({
	customClass: {
		confirmButton: 'btn btn-success',
		cancelButton: 'btn btn-danger',
	},
	buttonsStyling: false,
})

const getPromiseAlertSessionOff = () => {
	const promise = swalWithBootstrapButtons
		.fire({
			icon: 'info',
			title: translate('common.sessionTimeOff'),
			html: translate('common.pleaseLogin'),
			showCancelButton: false,
			confirmButtonText: translate('common.ok'),
			showClass: { popup: 'animated fadeIn fast' },
			hideClass: { popup: 'animated fadeOut faster' },
		})
		.then((result) => {
			return Promise.resolve()
		})
	return promise
}

export { getPromiseAlertSessionOff }
