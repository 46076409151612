<template>
	<div
		ref="refMenuSideItemContainer"
		:class="['px-2', menuSideItem.itemCssClass]"
		@mouseover="onMouseOver()"
		@mouseleave="onMouseLeave()"
	>
		<MenuItemLinkContainer
			v-if="!menuSideItem.isShowSupportInfo"
			:menu-side-item="menuSideItem"
			:is-hover-item-container="isHover"
		></MenuItemLinkContainer>
		<MenuItemLinkSupportContainer
			v-else-if="menuSideItem.isShowSupportInfo"
			:menu-side-item="menuSideItem"
			:is-hover-item-container="isHover"
		></MenuItemLinkSupportContainer>
		<MenuItemDropdownNoChild
			v-else-if="menuSideItem.childList.length === 0"
			:menu-side-item="menuSideItem"
			:is-hover-item-container="isHover"
			:top="top"
			:left="left"
		></MenuItemDropdownNoChild>
		<MenuItemDropdownChildList
			v-if="menuSideItem.childList.length > 0"
			:menu-side-item="menuSideItem"
			:is-hover-item-container="isHover"
			:top="top"
			:left="left"
		></MenuItemDropdownChildList>
	</div>
</template>

<script>
	import MenuItemLinkContainer from '@/components/menu/menuSide/MenuItemLinkContainer.vue'
	import MenuItemLinkSupportContainer from '@/components/menu/menuSide/MenuItemLinkSupportContainer.vue'
	import MenuItemDropdownNoChild from '@/components/menu/menuSide/MenuItemDropdownNoChild.vue'
	import MenuItemDropdownChildList from '@/components/menu/menuSide/MenuItemDropdownChildList.vue'

	import { MenuSideItem } from '@/assets/js/sideMenu/MenuSideItem.js'

	export default {
		components: {
			MenuItemLinkContainer,
			MenuItemLinkSupportContainer,
			MenuItemDropdownNoChild,
			MenuItemDropdownChildList,
		},
		props: {
			menuSideItem: { type: MenuSideItem, default: () => (new MenuSideItem()) },
		},
		data() {
			return {
				isHover: false,
				top: 0,
				left: 0,
			}
		},
		watch: {
			$route() {
				this.isHover = false
			},
		},
		methods: {
			onMouseOver() {
				const refContainer = this.$refs.refMenuSideItemContainer
				const rect = refContainer.getBoundingClientRect()
				this.top = rect.top
				this.left = rect.right

				this.isHover = true
			},
			onMouseLeave() {
				this.isHover = false
			},
		},
	}
</script>
