<template>
	<NLink
		:class="[
			isMini ? $style.logoWrapperMini : $style.logoWrapper,
			'd-block g-fs-16',
		]"
		:to="toUrl"
	>
		<LogoTop :svg-type-name="isMini ? 'whiteMini' : 'white'"></LogoTop>
	</NLink>
</template>

<script>
	import LogoTop from '@/components/layouts/LogoTop.vue'

	import { getAuthKit } from '@/assets/js/routerHelper.js'
	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'

	export default {
		components: {
			LogoTop,
		},
		data() {
			return {
				sideMenuConfigVue,
			}
		},
		computed: {
			authKit() {
				return getAuthKit()
			},
			toUrl() {
				return this.authKit?.user?.redirectUrl ?? '/'
			},
			isMini() {
				return sideMenuConfigVue.menuContainerSize === 'menuContainerHide'
			},
		},
	}
</script>

<style module>
	.logoWrapper {
		width: 110px;
	}

	.logoWrapperMini {
		width: 30px;
	}
</style>
