import { getCorrectTypeValKit } from '@/assets/js/check/getCorrectTypeValKit.js'
import CacheLiveManager from '@/assets/js/cache/CacheLiveManager.js'

const CachePromise = class {
	constructor({ timeLiveMs } = {}) {
		const cacheLiveManager = new CacheLiveManager({ timeLiveMs })
		this.cacheLiveManager = cacheLiveManager

		this.isInProgress = false
		this.responseCache = undefined
		this.promise = Promise.resolve()
	}

	refreshPromise({ getPromiseWhenNoLive, keyString }) {
		const correctKit = getCorrectTypeValKit({ getPromiseWhenNoLive: [getPromiseWhenNoLive, Function] })
		const getPromiseFunc = correctKit.getPromiseWhenNoLive

		this.isInProgress = true
		const promiseNew = getPromiseFunc()
		const correctKit2 = getCorrectTypeValKit({ promiseNew: [promiseNew, Promise] })
		const promiseCorrect = correctKit2.promiseNew

		this.promise = promiseCorrect
			.then((response) => {
				this.cacheLiveManager.setParams({ keyString })
				this.responseCache = response
				return response
			})
			.finally(() => {
				this.isInProgress = false
			})
	}

	getPromise({ isNeedResetCache, keyString, getPromiseWhenNoLive }) {
		const isLive = this.cacheLiveManager.getIsLive({ keyString })
		const correctKit = getCorrectTypeValKit({ isNeedResetCache: [isNeedResetCache, Boolean] })
		const isNeedResetCacheCorrect = correctKit.isNeedResetCache

		if (isNeedResetCacheCorrect || (!this.isInProgress && !isLive)) {
			this.refreshPromise({ getPromiseWhenNoLive, keyString })
		}

		return this.promise
	}
}

export default CachePromise
