<template>
	<div>
		<ElBadge v-if="countUnread > 0" is-dot>
			<slot></slot>
		</ElBadge>
		<slot v-if="countUnread === 0"></slot>
	</div>
</template>

<script>
	import { Badge } from 'element-ui'
	import { mapState, mapActions } from 'vuex'

	import { getRouterMallId } from '@/assets/js/routerHelper.js'

	export default {
		components: {
			ElBadge: Badge,
		},
		computed: {
			...mapState(['storeSupportInfo']),
			isReadySupportInfo() {
				return this.storeSupportInfo.isReady
			},
			countUnread() {
				return this.storeSupportInfo.countUnread
			},
			shoppingCenterId() {
				return getRouterMallId()
			},
		},
		watch: {
			shoppingCenterId() {
				this.getSupportInfo()
			},
		},
		mounted() {
			this.getSupportInfo()
		},
		methods: {
			...mapActions({ getSupportInfo: 'storeSupportInfo/get' }),
		},
	}
</script>
<style lang="scss" scoped>
	::v-deep .el-badge__content.is-dot {
		top: 2px;
		right: 10px;
		width: 12px;
		height: 12px;
		border-width: 2px;
	}
</style>
