<template>
	<component :is="tag" v-clickOutside="closeDropDown" :class="[{ show: isOpen }, `drop${direction}`]">
		<div @click.stop="toggleDropDown()">
			<slot name="title-container" :is-open="isOpen">
				<component
					:is="titleTag"
					class="btn-rotate"
					:class="[{ 'dropdown-toggle': hasToggle }, titleClasses]"
					:aria-expanded="isOpen"
					data-toggle="dropdown"
				>
					<slot name="title" :is-open="isOpen"> <i :class="icon"></i> {{ title }} </slot>
				</component>
			</slot>
		</div>
		<ul
			class="dropdown-menu dropdownMenu"
			:class="[{ show: isOpen }, { 'dropdown-menu-right': menuOnRight }, menuClasses]"
			@click.stop="toggleDropDown()"
		>
			<slot></slot>
		</ul>
	</component>
</template>
<script>
	import { directiveClickOutsideKit } from '@/assets/js/vueDirectives/directiveClickOutsideKit.js'

	export default {
		name: 'BaseDropdown',
		directives: {
			clickOutside: directiveClickOutsideKit,
		},
		props: {
			tag: {
				type: String,
				default: 'div',
				description: 'Dropdown html tag (e.g div, ul etc)',
			},
			titleTag: {
				type: String,
				default: 'button',
				description: 'Dropdown title (toggle) html tag',
			},
			title: {
				type: String,
				description: 'Dropdown title',
				default: undefined,
			},
			direction: {
				type: String,
				default: 'down', // up | down
				description: 'Dropdown menu direction (up|down)',
			},
			icon: {
				type: String,
				description: 'Dropdown icon',
				default: undefined,
			},
			titleClasses: {
				type: [String, Object, Array],
				description: 'Title css classes',
				default: undefined,
			},
			menuClasses: {
				type: [String, Object],
				description: 'Menu css classes',
				default: undefined,
			},
			menuOnRight: {
				type: Boolean,
				description: 'Whether menu should appear on the right',
			},
			hasToggle: {
				type: Boolean,
				description: 'Whether dropdown has arrow icon shown',
				default: true,
			},
		},
		data() {
			return {
				isOpen: false,
			}
		},
		methods: {
			toggleDropDown() {
				this.isOpen = !this.isOpen
				this.$emit('change', this.isOpen)
			},
			closeDropDown() {
				this.isOpen = false
				this.$emit('change', false)
			},
		},
	}
</script>
<style lang="scss" scoped>
	.dropdown {
		cursor: pointer;
		user-select: none;
	}

	.dropdownMenu {
		z-index: 1500;
	}
</style>
