const kit = {
	position: ({ mallId, positionId }) => {
		const url = `/api/v2/sc/${mallId}/positions/${positionId}`
		return window.$nuxt.$axios.$get(url)
	},

	positionList: ({ mallId }) => {
		const url = `/api/v2/sc/${mallId}/positions`
		return window.$nuxt.$axios.$get(url)
	},

	positionCreate: ({
		shoppingCenterId, positionName, areaSize, floorId, sectorId, positionType, existSince, mapLocationId,
	}) => {
		const url = `/api/v2/sc/${shoppingCenterId}/positions`
		const params = {
			entity_id: shoppingCenterId,
			name: positionName,
			area_size: areaSize,
			floor_id: floorId,
			sector_id: sectorId,
			type: positionType,
			exist_since: existSince,
			map_location_id: mapLocationId === '' ? undefined : mapLocationId,
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	positionUpdate: ({
		shoppingCenterId, positionId, positionName, areaSize, floorId, sectorId, positionType, existSince, mapLocationId,
	}) => {
		const url = `/api/v2/sc/${shoppingCenterId}/positions/${positionId}`
		const params = {
			entity_id: shoppingCenterId,
			name: positionName,
			area_size: areaSize,
			floor_id: floorId,
			sector_id: sectorId,
			type: positionType,
			exist_since: existSince,
			map_location_id: mapLocationId === '' ? undefined : mapLocationId,
		}
		return window.$nuxt.$axios.$put(url, params)
	},
	positionOutdoorCreate: ({
		mallId, positionName, areaSize, existSince,
	}) => {
		const url = `/api/v2/sc/${mallId}/positions`
		const params = {
			type: 'outdoor',
			entity_id: mallId,
			name: positionName,
			area_size: areaSize,
			exist_since: existSince,
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	positionOutdoorUpdate: ({
		mallId, positionId, positionName, areaSize, existSince,
	}) => {
		const url = `/api/v2/sc/${mallId}/positions/${positionId}`
		const params = {
			type: 'outdoor',
			entity_id: mallId,
			name: positionName,
			area_size: areaSize,
			exist_since: existSince,
		}
		return window.$nuxt.$axios.$put(url, params)
	},
	positionDelete: ({ mallId, positionId }) => {
		const url = `/api/v2/sc/${mallId}/positions/${positionId}`
		const params = { params: { entity_id: mallId } }
		return window.$nuxt.$axios.$delete(url, params)
	},

	positionFullness: ({ mallId }) => {
		const url = `/api/v2/sc/${mallId}/positions/fullness`
		return window.$nuxt.$axios.$get(url)
	},
	positionLinkToMap: ({ shoppingCenterId, positionId, data }) => {
		const url = `/positions/${positionId}`
		const params = { entity_id: shoppingCenterId, ...data }
		return window.$nuxt.$axios.$put(url, params)
	},
	positionFloorsAndCategories: ({ shoppingCenterId }) => {
		const url = '/positions/floors-and-categories'
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},

	positionLocationUpdate: ({ shoppingCenterId, positionId, mapLocationId }) => {
		const url = `/positions/${positionId}/location`
		const params = {
			entity_id: shoppingCenterId,
			map_location_id: mapLocationId,
		}
		return window.$nuxt.$axios.$put(url, params)
	},

	positionsFullnessDetailed: ({ shoppingCenterId }) => {
		const url = '/positions-fullness-detailed'
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
}

export default kit
