<template>
	<Modal :show="isShow" size="xl" @close="emitCloseModal()">
		<template #header>
			<h5 class="modal-title">
				{{ translate('modalMallInformation.title') }}
			</h5>
		</template>

		<div class="d-flex flex-column gap-5">
			<div class="g-fs-14">
				<div class="row border-bottom border-color-light pb-1">
					<div class="col-4 font-weight-bold">
						{{ translate('common.title') }}
					</div>
					<div class="col-8 d-flex gap-2 align-items-center">
						<template v-if="mallDataLoaded">
							<div>
								{{ `${mallKit.abbreviation} ${mallKit.title}` }}
							</div>
							<Badge
								v-if="isLimitedAccess(mallKit.restrictionsLevel)"
								class="text-sm"
								color="danger"
								:text="limitAccessText(mallKit.restrictionsLevel)"
								:tooltip="limitAccessText(mallKit.restrictionsLevel)"
								:icon="limitAccessIcon(mallKit.restrictionsLevel)"
							></Badge>
						</template>
						<FakeBlock v-else width="50%"></FakeBlock>
					</div>
				</div>
				<div class="row border-bottom border-color-light py-1">
					<div class="col-4 font-weight-bold">
						{{ translate('modalMallInformation.city') }}
					</div>
					<div class="col-8">
						<template v-if="mallDataLoaded">
							{{ mallKit.city }}
						</template>
						<FakeBlock v-else width="50%"></FakeBlock>
					</div>
				</div>
				<div class="row border-bottom border-color-light py-1">
					<div class="col-4 font-weight-bold">
						{{ translate('modalMallInformation.address') }}
					</div>
					<div class="col-8">
						<template v-if="mallDataLoaded">
							{{ mallKit.address }}
						</template>
						<FakeBlock v-else width="50%"></FakeBlock>
					</div>
				</div>
				<div class="row border-bottom border-color-light py-1">
					<div class="col-4 font-weight-bold">
						{{ translate('modalMallInformation.developer') }}
					</div>
					<div class="col-8">
						<template v-if="mallDataLoaded">
							{{ mallKit.general_company_name }}
						</template>
						<FakeBlock v-else width="50%"></FakeBlock>
					</div>
				</div>
				<div class="row border-bottom py-1">
					<div class="col-4 font-weight-bold">
						{{ translate('modalMallInformation.has_map_locations') }}
					</div>
					<div class="col-8">
						<template v-if="mallDataLoaded">
							{{ mallKit.has_map_locations ? translate('common.have') : translate('common.no') }}
						</template>
						<FakeBlock v-else width="50%"></FakeBlock>
					</div>
				</div>
				<div v-if="isStaff" class="row pt-1">
					<div class="col-4 font-weight-bold">
						{{ translate('common.comment') }}
					</div>
					<div class="col-8">
						<template v-if="mallDataLoaded">
							{{ mallKit.comment }}
						</template>
						<FakeBlock v-else width="50%"></FakeBlock>
					</div>
				</div>
			</div>
			<div>
				<h4>{{ translate('shoppingCenter.lessors') }}</h4>
				<TableSkeleton v-if="!mallDataLoaded" :column-data="skeletonLessorsTableColumns"></TableSkeleton>
				<ElTable
					v-else-if="lessors.length"
					:data="lessors"
					header-cell-class-name="g-fs-11 font-weight-bolder"
					header-row-class-name="thead-light"
					:stripe="true"
				>
					<ElTableColumn :label="translate('common.legalName')" prop="legalName">
						<template #default="{ row }">
							{{ row.legalName }}
						</template>
					</ElTableColumn>
					<ElTableColumn :label="translate('abbreviation.inn')" prop="inn">
						<template #default="{ row }">
							{{ row.inn }}
						</template>
					</ElTableColumn>
					<ElTableColumn :label="translate('abbreviation.kpp')" prop="kpp">
						<template #default="{ row }">
							{{ row.kpp }}
						</template>
					</ElTableColumn>
				</ElTable>
				<div v-else class="g-fs-14">
					{{ translate('modalMallInformation.no_lessors') }}
				</div>
			</div>
			<div>
				<h4>{{ translate('common.contacts') }}</h4>
				<TableSkeleton v-if="!contactsDataLoaded" :column-data="skeletonContactsTableColumns"></TableSkeleton>
				<ElTable
					v-else-if="contacts.length"
					:data="contacts"
					header-cell-class-name="g-fs-11 font-weight-bolder"
					header-row-class-name="thead-light"
					:stripe="true"
				>
					<ElTableColumn :label="translate('common.fio')" prop="full_name">
						<template #default="{ row }">
							{{ row.full_name }}
						</template>
					</ElTableColumn>
					<ElTableColumn label="Email" prop="email">
						<template #default="{ row }">
							{{ row.email }}
						</template>
					</ElTableColumn>
					<ElTableColumn :label="translate('common.phone')" prop="phone">
						<template #default="{ row }">
							{{ row.phone }}
						</template>
					</ElTableColumn>
					<ElTableColumn :label="translate('common.comment')" prop="comment">
						<template #default="{ row }">
							{{ row.comment }}
						</template>
					</ElTableColumn>
				</ElTable>
				<div v-else class="g-fs-14">
					{{ translate('modalMallInformation.no_contacts') }}
				</div>
			</div>
			<div>
				<h4>{{ translate('blockFiles.mallTitle') }}</h4>
				<TableSkeleton v-if="!filesDataLoaded" :column-data="skeletonFilesTableColumns"></TableSkeleton>
				<TableFiles
					v-else-if="lessors.length"
					:table-item-list="fileList"
					:resource-id="mallId"
					:is-actions="false"
					header-cell-class-name="g-fs-11 font-weight-bolder"
					pagination-class-name="g-fs-13"
				></TableFiles>
				<div v-else class="g-fs-14">
					{{ translate('modalMallInformation.no_files') }}
				</div>
			</div>
		</div>
	</Modal>
</template>
<script>
	import Modal from '@/components/argon-core/Modal.vue'
	import Badge from '@/components/other/Badge.vue'
	import FakeBlock from '@/components/other/FakeBlock.vue'
	import TableSkeleton from '@/components/tables/TableSkeleton.vue'
	import TableFiles from '@/components/tables/TableFiles.vue'

	import { getPromiseQuery } from '@/assets/js/query/getPromiseQuery.js'
	import { Table, TableColumn } from 'element-ui'
	import { translate } from '@/assets/i18n/i18nextConfig.js'
	import { limitAccessText, isLimitedAccess, limitAccessIcon } from '@/assets/js/malls/limitAccessText.js'
	import { getAuthKit } from '@/assets/js/routerHelper.js'

	export default {
		components: {
			TableFiles,
			FakeBlock,
			Badge,
			ElTable: Table,
			ElTableColumn: TableColumn,
			Modal,
			TableSkeleton,
		},
		props: {
			isShow: { type: Boolean, default: false },
			mallId: { type: String, default: '' },
		},
		data() {
			return {
				mallDataLoaded: false,
				contactsDataLoaded: false,
				filesDataLoaded: false,
				mallKit: {
					abbreviation: '',
					title: '',
					city: '',
					address: '',
					has_map_locations: false,
					general_company_name: '',
					restrictionsLevel: 'none',
				},
				lessors: [],
				contacts: [],
				fileList: [],
				skeletonLessorsTableColumns: [
					{ label: translate('common.legalName') },
					{ label: translate('abbreviation.inn') },
					{ label: translate('abbreviation.kpp') },
				],
				skeletonContactsTableColumns: [
					{ label: translate('common.fio') },
					{ label: 'email' },
					{ label: translate('common.phone') },
					{ label: translate('common.comment') },
				],
				skeletonFilesTableColumns: [
					{ label: translate('common.title') },
					{ label: translate('common.description') },
					{ label: translate('common.type') },
					{ label: translate('common.createdDate') },
				],
			}
		},
		computed: {
			isSuspended() {
				const restrictionsLevelList = ['suspended', 'contract_suspended', 'payment_required']
				return restrictionsLevelList.includes(this.mallKit.restrictionsLevel)
			},
			authKit() {
				return getAuthKit()
			},
			isStaff() {
				return this.authKit.user.is_staff
			},
		},
		watch: {
			mallId: {
				handler() {
					if (this.mallId) {
						this.fetchMallInformation()
						this.fetchContactsData()
						this.fetchFiles()
					}
				},
				immediate: true,
			},
		},
		methods: {
			translate,
			limitAccessText,
			isLimitedAccess,
			limitAccessIcon,
			emitCloseModal() {
				document.body.classList.remove('modal-open')
				this.$emit('closeModal')
				this.reset()
			},
			reset() {
				this.mallKit.abbreviation = ''
				this.mallKit.title = ''
				this.mallKit.city = ''
				this.mallKit.address = ''
				this.mallKit.has_map_locations = false
				this.mallKit.general_company_name = ''
				this.mallKit.restrictionsLevel = 'none'
				this.lessors = []
				this.contacts = []
			},
			getStaffNote() {
				if (!this.isStaff) {
					this.mallDataLoaded = true
					return
				}
				getPromiseQuery({
					getStaffNote: {
						mallId: this.mallId,
					},
				})
					.then((response) => {
						this.mallKit.comment = response.staff_note
						this.mallDataLoaded = true
					})
			},
			fetchMallInformation() {
				this.mallDataLoaded = false
				getPromiseQuery({
					mallInformation: { mallId: this.mallId },
				})
					.then((response) => {
						const mallResponseData = response.shopping_center
						this.mallKit.title = mallResponseData.title
						this.mallKit.abbreviation = mallResponseData.abbreviation
						this.mallKit.city = mallResponseData.city
						this.mallKit.address = mallResponseData.address
						this.mallKit.has_map_locations = mallResponseData.has_map_locations
						this.mallKit.general_company_name = mallResponseData.general_company_name
						this.mallKit.restrictionsLevel = mallResponseData.level_of_restrictions
						this.lessors = mallResponseData.lessors
						this.getStaffNote()
					})
			},
			fetchContactsData() {
				this.contactsDataLoaded = false
				const resourceId = this.mallId
				getPromiseQuery({
					businessContactsList: { resourceId },
				})
					.then((response) => {
						this.contacts = response.contacts
						this.contactsDataLoaded = true
					})
			},
			fetchFiles() {
				this.filesDataLoaded = false
				getPromiseQuery({
					fileResources: { resourceId: this.mallId },
				})
					.then((response) => {
						this.fileList = response
						this.filesDataLoaded = true
					})
			},
		},
	}
</script>
<style lang="scss" scoped>
.border-color-light {
	border-color: #ebeef5;
}
</style>
