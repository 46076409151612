const simpleCallList = [Number, String, Boolean, Symbol]

const getConstructorDefault = (constructor) => {
	if (simpleCallList.includes(constructor)) {
		return constructor()
	}

	if (constructor === BigInt) {
		return BigInt(0)
	}

	if (constructor === Promise) {
		return new Promise(() => {})
	}

	try {
		return new constructor()
	} catch (_error1) {
		return {}
	}
}

export { getConstructorDefault }
