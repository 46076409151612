import { getPromiseQuery } from '@/assets/js/query/getPromiseQuery.js'
import { getRouterMallId } from '@/assets/js/routerHelper.js'

let key = ''

export default {
	state: () => {
		return {
			isReady: false,
			isInProgress: false,
			isUpdate: false,
			countUnread: 0,
			countOpened: 0,
			countNewIn48hours: 0,
		}
	},
	getters: {},
	mutations: {
		setIsReady(state, bool) {
			state.isReady = bool
		},
		setIsInProgress(state, bool) {
			state.isInProgress = bool
		},
		setIsUpdate(state, bool) {
			state.isUpdate = bool
		},
		setCountUnread(state, number) {
			state.countUnread = number
		},
		setCountOpened(state, number) {
			state.countOpened = number
		},
		setCountNewIn48hours(state, number) {
			state.countNewIn48hours = number
		},
	},
	actions: {
		get({ state, commit }) {
			if (state.isInProgress) {
				return
			}

			commit('setIsReady', false)
			commit('setIsInProgress', true)

			const mallId = getRouterMallId()
			commit('setIsUpdate', mallId === key)
			key = mallId

			getPromiseQuery({ availableMalls: {} })
				.then((response) => {
					const mallInfo = response.shopping_centers.find((mallLocal) => {
						return mallLocal.id === mallId
					}) ?? {}

					commit('setCountUnread', mallInfo?.number_of_unread_tickets ?? 0)
					commit('setCountOpened', mallInfo?.number_of_open_tickets ?? 0)
					commit('setCountNewIn48hours', mallInfo?.number_of_new_tickets_in_the_last_48_hours ?? 0)

					commit('setIsReady', true)
				})
				.finally(() => {
					commit('setIsInProgress', false)
				})
		},
	},
}
