const getKitByFieldName = (list, fieldName) => {
	const listBy = {}
	list.forEach((item) => {
		const key = item[fieldName]
		listBy[key] = item
	})
	return listBy
}

export { getKitByFieldName }
