<template>
	<div class="d-flex align-items-center">
		<div :class="$style.delimiter"></div>
		<div
			v-if="mallList.length > 1"
			:class="[$style.container, 'd-flex flex-row align-items-center gap-1']"
		>
			<BaseDropdown
				class="nav-item"
				tag="li"
				title-tag="a"
				:title-classes="[$style.hoverItem, 'nav-link g-fs-22 px-2 pb-0']"
				:has-toggle="false"
			>
				<template slot="title">
					<div
						v-if="isPageMalls"
						class="d-flex align-items-center text-uppercase"
					>
						<div
							:class="$style.allMallsContainer"
							class="d-flex flex-row align-items-center"
						>
							<div :class="$style.allMalls">{{ textAllMalls }}</div>
							<Badge
								class="m-0 ml-2 g-fs-12 px-2"
								color="primary"
								:tooltip="translate('common.betaTooltip')"
								icon="fa-regular fa-atom-simple"
							></Badge>
						</div>
					</div>
					<div
						v-else-if="!isPageMalls"
						class="d-flex align-items-center gap-2"
					>
						<div>{{ mallCurrentKit.title }}</div>
						<Badge
							v-if="isLimitedAccess(mallCurrentKit.level_of_restrictions)"
							class="m-0 g-fs-12 px-2"
							color="danger"
							:text="limitAccessText(mallCurrentKit.level_of_restrictions)"
							:tooltip="limitAccessText(mallCurrentKit.level_of_restrictions)"
							:icon="limitAccessIcon(mallCurrentKit.level_of_restrictions)"
						></Badge>
					</div>
				</template>

				<div :class="[$style.menuList]">
					<NuxtLink
						v-access="accessRoleListKit.allMalls"
						:class="[$style.link, 'dropdown-item text-uppercase']"
						:to="{ name: 'malls-dashboard' }"
					>
						<div class="d-flex flex-row align-items-center">
							<div :class="$style.allMalls">{{ textAllMalls }}</div>
							<Badge
								class="m-0 ml-2 g-fs-12 px-2"
								color="primary"
								:tooltip="translate('common.betaTooltip')"
								icon="fa-regular fa-atom-simple"
							></Badge>
						</div>
					</NuxtLink>
					<div
						v-for="(mallKit, mallKitIndex) in mallList"
						:key="`${mallKit.id}-${mallKitIndex}`"
						:class="[
							'dropdown-item p-0',
							mallKit.id === mallCurrentKit.id ? 'highlighted' : ''
						]"
					>
						<NuxtLink
							class="d-block w-100"
							:class="[{ 'py-1': !isStaff }, $style.link]"
							:to="{ name: 'entity', params: { entity: mallKit.id } }"
						>
							<div class="px-3 d-flex align-items-center justify-content-between">
								{{ mallKit.title }}
								<div v-if="isLimitedAccess(mallKit.level_of_restrictions)" class="text-sm">
									<Badge
										class="m-0 ml-2 g-fs-12 px-2"
										color="danger"
										:text="limitAccessText(mallKit.level_of_restrictions)"
										:tooltip="limitAccessText(mallKit.level_of_restrictions)"
										:icon="limitAccessIcon(mallKit.level_of_restrictions)"
									></Badge>
								</div>
								<MallInformationBadge
									v-if="isStaff"
									:mall-id="mallKit.id"
									:mall-abbreviation="mallKit.abbreviation"
									:mall-title="mallKit.title"
									@onClick="showMallInformationModal($event)"
								></MallInformationBadge>
							</div>
						</NuxtLink>
					</div>
				</div>
			</BaseDropdown>
			<MallInformationBadge
				v-if="isStaff && mallCurrentKit.id"
				color="white"
				:mall-id="mallCurrentKit.id"
				:mall-abbreviation="mallCurrentKit.abbreviation"
				:mall-title="mallCurrentKit.title"
				:show-tooltip="true"
				@onClick="showMallInformationModal($event)"
			></MallInformationBadge>
		</div>
		<div
			v-else
			:class="[$style.container, 'text-white']"
		>{{ mallCurrentKit.title }}</div>
		<ModalMallInformation
			v-if="isStaff && mallCurrentKit.id && isShowMallInformationModal"
			:is-show="isShowMallInformationModal"
			:mall-id="mallInformationMallId"
			@closeModal="closeMallInformationModal"
		></ModalMallInformation>
		<i
			v-if="!isInitMallList"
			class="fa-solid fa-circle-notch fa-spin ml-4 g-fs-18 text-white"
		></i>
	</div>
</template>

<script>
	import { translate } from '@/assets/i18n/i18nextConfig.js'
	import { accessRoleListKit } from '@/assets/js/access/accessRoleListKit.js'
	import { getAuthMallList, getRouterMallKit, getAuthKit } from '@/assets/js/routerHelper.js'
	import { limitAccessText, isLimitedAccess, limitAccessIcon } from '@/assets/js/malls/limitAccessText.js'
	import directiveAccessKit from '@/assets/js/vueDirectives/directiveAccessKit.js'
	import { getPromiseCache } from '@/assets/js/query/cacheQuery.js'

	import BaseDropdown from '@/components/argon-core/BaseDropdown.vue'
	import Badge from '@/components/other/Badge.vue'
	import ModalMallInformation from '@/components/modals/ModalMallInformation.vue'
	import MallInformationBadge from '@/components/MallInformationBadge/MallInformationBadge.vue'

	export default {
		directives: {
			access: directiveAccessKit,
		},
		components: {
			MallInformationBadge,
			ModalMallInformation,
			Badge,
			BaseDropdown,
		},
		data() {
			return {
				accessRoleListKit,
				textAllMalls: translate('common.forCompany'),
				isShowMallInformationModal: false,
				mallInformationMallId: '',
				mallList: getAuthMallList(),
				timer: null,
				isInitMallList: this.$store.state.isInitMallList,
			}
		},
		computed: {
			mallCurrentKit() {
				return getRouterMallKit()
			},
			isPageMalls() {
				return this.$route.name.startsWith('malls')
			},
			authKit() {
				return getAuthKit()
			},
			isStaff() {
				return this.authKit.user.is_staff
			},
		},
		mounted() {
			this.$nuxt.$on('newMallCreated', this.onNewMallCreated)

			if (this.isInitMallList) {
				return
			}
			this.timer = setInterval(() => {
				this.updateMalls()
			}, 100)
		},
		beforeDestroy() {
			this.$nuxt.$off('newMallCreated', this.onNewMallCreated)
			if (this.timer) {
				clearInterval(this.timer)
			}
		},
		methods: {
			translate,
			limitAccessText,
			isLimitedAccess,
			limitAccessIcon,
			showMallInformationModal(mallId) {
				this.mallInformationMallId = mallId
				this.isShowMallInformationModal = true
			},
			closeMallInformationModal() {
				this.mallInformationMallId = ''
				this.isShowMallInformationModal = false
			},
			onNewMallCreated() {
				getPromiseCache('availableMalls', {}).then((result) => {
					this.$store.commit('setMalls', result.shopping_centers)
					this.$store.commit('setIsInitMallList', true)
					this.mallList = getAuthMallList()
				})
			},
			updateMalls() {
				if (!this.$store.state.isInitMallList) {
					return
				}
				this.isInitMallList = this.$store.state.isInitMallList
				this.mallList = getAuthMallList()
				clearInterval(this.timer)
			},
		},
	}
</script>

<style scoped>
	.highlighted {
		background-color: rgba(22, 195, 200, 0.2);
	}
</style>

<style lang="scss" module>
	.delimiter {
		width: 2px;
		height: 30px;
		margin-right: 10px;
		margin-left: 20px;
		background-color: var(--white);
		border-radius: 4px;
	}

	.container {
		font-size: 1.6275rem;
		font-weight: 600;
		line-height: 1.5;
	}

	.hoverItem {
		border: 1px solid transparent;
		border-radius: 8px;
		color: var(--white) !important;
		cursor: pointer;
		transition: background 0.2s ease;

		&:hover {
			// background: #a4dcdd;
			border: 1px solid #a4dcdd;
		}
	}

	.menuList {
		max-height: calc(100vh - 100px);
		overflow-y: auto;
	}

	.link {
		color: var(--dark);

		&:hover {
			color: var(--dark);
		}
	}

	.allMalls {
		padding-top: 3px;
	}

	.allMallsContainer {
		padding-bottom: 2px;
	}

	.mall-info {
		color: #fff;
	}
</style>
