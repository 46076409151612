<template>
	<component
		:is="componentName"
		:to="menuSideItem.routeTo"
		:class="[
			$style.item,
			isActive ? $style.isActive : '',
			menuSideItem.isDisabled ? $style.isDisabled : '',
			'px-3 py-2'
		]"
	>
		<div :class="[$style.itemText, 'g-fs-16 g-flh-16']">{{ menuSideItem.text }}</div>
	</component>
</template>

<script>
	import { MenuSideItem } from '@/assets/js/sideMenu/MenuSideItem.js'
	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'

	export default {
		props: {
			menuSideItem: { type: MenuSideItem, default: () => (new MenuSideItem()) },
		},
		data() {
			return {
				sideMenuConfigVue,
			}
		},
		computed: {
			componentName() {
				return this.menuSideItem.isLink ? 'NuxtLink' : 'div'
			},
			isActive() {
				if (!this.menuSideItem.isLink) {
					return false
				}
				const hrefCurrent = this.$route.fullPath
				const href = this.$router.resolve(this.menuSideItem.routeTo).href ?? ''
				const isActive = hrefCurrent.startsWith(href)
				return isActive
			},
		},
	}
</script>

<style lang="scss" module>
	.item {
		display: flex;
		box-sizing: border-box;
		align-items: center;
		justify-content: space-between;
		border: 1px solid transparent;
		border-radius: 8px;
		color: var(--gray-dark);
		transition: background 0.2s ease;

		&:hover {
			background: #e4e7e9;
			color: var(--gray-dark);
		}
	}

	.itemText {
		flex: 1;
		font-family: 'TTRoundsNeue', 'Comfortaa', sans-serif;
	}

	.isActive {
		background: rgba(22, 195, 200, 0.2);
	}

	.isDisabled {
		color: var(--gray-600);
		cursor: default;
		pointer-events: none;
	}
</style>
