<template>
	<div>
		<ModalDocPage
			:is-show="isShowModal"
			:page-id="pageId"
			@closeModal="closeModal()"
		></ModalDocPage>
	</div>
</template>

<script>
	import ModalDocPage from '@/components/modals/docs/ModalDocPage.vue'

	export default {
		components: {
			ModalDocPage,
		},
		data() {
			return {
				isShowModal: false,
				pageId: '',
				hrefPathString: '/docs/pages/',
				parentSelector: '.docs-page-content',
			}
		},
		mounted() {
			document.addEventListener('click', this.handler)
		},
		beforeDestroy() {
			document.removeEventListener('click', this.handler)
		},
		methods: {
			closeModal() {
				this.isShowModal = false
			},
			handler(event) {
				const { target, target: { href } } = event
				const isValid = Boolean(href && href.includes(this.hrefPathString))
				const hasParent = Boolean(target.closest(this.parentSelector))
				if (!isValid || !hasParent) {
					return
				}
				event.preventDefault()
				const tmpList = href.split(this.hrefPathString)
				this.pageId = tmpList[1]
				this.isShowModal = true
			},
		},
	}
</script>
