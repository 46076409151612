const kit = {
	monitoringSellLocationNotMap: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/sell_locations_not_attached_to_map`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	monitoringPositionsWithout: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/positions_without_sell_locations_and_not_attached_to_map`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	monitoringPositionsAttached: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/positions_attached_to_map_and_without_active_sell_locations`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},

	monitoringRentersWithoutSellLocations: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/renters_without_sell_locations`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	monitoringSellLocationsWithBlankContractFields: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/sell_locations_with_blank_contract_fields`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	monitoringSellLocationsWithoutContract: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/sell_locations_without_contract`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	monitoringSellLocationsWithoutRentalData: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/sell_locations_without_rental_data`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	monitoringRentersWithIncompleteContactDetails: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/renters_with_incomplete_contact_details`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	monitoringSellLocationsWithoutKkts: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/sell_locations_without_kkts`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
	monitoringKktsWithoutOfd: ({ shoppingCenterId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/monitoring/kkts_without_ofd`
		const params = { params: { entity_id: shoppingCenterId } }
		return window.$nuxt.$axios.$get(url, params)
	},
}

export default kit
