<template>
	<div class="wrapper" translate="no">
		<Notifications></Notifications>
		<MenuMain
			:panel-side-item-list="panelSideItemList"
		>
			<div>
				<ControlAlertProblem></ControlAlertProblem>
				<Nuxt></Nuxt>
			</div>
		</MenuMain>

		<OffLineOnLineMessage></OffLineOnLineMessage>
		<WebSocket></WebSocket>
		<ControlModalShopAnomalies></ControlModalShopAnomalies>
		<LogRocket></LogRocket>
		<SentrySetUser></SentrySetUser>
	</div>
</template>
<script>
	import MenuMain from '@/components/menu/MenuMain.vue'
	import OffLineOnLineMessage from '@/components/OffLineOnLineMessage.vue'
	import WebSocket from '@/components/WebSocket.vue'
	import ControlModalShopAnomalies from '@/components/ControlModalShopAnomalies.vue'
	import ControlAlertProblem from '@/components/ControlAlertProblem.vue'
	import LogRocket from '@/components/LogRocket.vue'
	import SentrySetUser from '@/components/SentrySetUser.vue'

	import { getMallsMenuItemList } from '@/assets/js/sideMenu/getMallsMenuItemList.js'
	import { getRouterMallKit, getRouterMallId } from '@/assets/js/routerHelper.js'

	export default {
		components: {
			MenuMain,
			OffLineOnLineMessage,
			WebSocket,
			ControlModalShopAnomalies,
			ControlAlertProblem,
			LogRocket,
			SentrySetUser,
		},
		data() {
			return {
				panelSideItemList: getMallsMenuItemList(),
				systemStatus: 'hidden',
			}
		},
		head() {
			return {
				title: 'RENTU',
				meta: [
					{ hid: 'robots', name: 'robots', content: 'noindex, nofollow' },
					{ hid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=0.31' },
				],
			}
		},
		computed: {
			mallKit() {
				return getRouterMallKit()
			},
			mallId() {
				return getRouterMallId()
			},
		},
		watch: {
			mallId() {
				this.panelSideItemList = getMallsMenuItemList()
			},
		},
		mounted() {
			// const statusStorageString = localStorage.getItem('system_status')
			const statusStorageString = null
			if (statusStorageString) {
				let statusStorage = {}
				try {
					statusStorage = JSON.parse(statusStorageString)
				} catch (e) {
					statusStorage = {}
				}

				if (new Date().getTime() > statusStorage.expiry) {
					localStorage.removeItem('system_status')
					this.systemStatus = ''
				} else {
					this.systemStatus = statusStorage.item || ''
				}
			}
		},
	}
</script>
