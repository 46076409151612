<template>
	<div :class="[$style.menuPanelSide, menuCssClass, ]">
		<div :class="[$style.menuItemListWrapper, 'pb-4 pt-3']">
			<MenuPanelSideItemList :panel-side-item-list="panelSideItemList"></MenuPanelSideItemList>
		</div>
		<div :class="[$style.menuBlockBottom, 'pt-1', 'border-top']">
			<MenuPanelSideItemList :panel-side-item-list="panelSideBottomItemList"></MenuPanelSideItemList>
			<MenuPanelSideVersion></MenuPanelSideVersion>
			<MenuPanelTogglerBottom></MenuPanelTogglerBottom>
		</div>
	</div>
</template>

<script>
	import MenuPanelTogglerBottom from '@/components/menu/MenuPanelTogglerBottom.vue'
	import MenuPanelSideItemList from '@/components/menu/menuSide/MenuPanelSideItemList.vue'
	import MenuPanelSideVersion from '@/components/menu/menuSide/MenuPanelSideVersion.vue'

	import { sideMenuConfigVue } from '@/assets/js/sideMenu/sideMenuConfigVue.js'

	export default {
		components: {
			MenuPanelTogglerBottom,
			MenuPanelSideItemList,
			MenuPanelSideVersion,
		},
		props: {
			panelSideItemList: { type: Array, default: () => ([]) },
			panelSideBottomItemList: { type: Array, default: () => ([]) },
		},
		data() {
			return {
				sideMenuConfigVue,
			}
		},
		computed: {
			menuCssClass() {
				return this.$style[this.sideMenuConfigVue.menuSize] ?? ''
			},
		},
	}
</script>

<style module>
	.menuPanelSide {
		position: fixed;
		z-index: 1030;
		top: 52px;
		left: 0;
		display: flex;
		height: calc(100vh - 52px);
		flex-direction: column;
		justify-content: space-between;
		background-color: var(--white);
		box-shadow: 0 0 3rem 0 rgb(136 152 170 / 25%);
		transition-duration: 0.3s;
		transition-property: width, margin-left;
	}

	.menuItemListWrapper {
		width: 100%;
		flex: auto;
		-ms-overflow-style: none;
		overflow-x: hidden;
		overflow-y: scroll;
		scrollbar-width: none;
	}

	.menuItemListWrapper::-webkit-scrollbar {
		display: none;
	}

	.menuBlockBottom {
		width: 100%;
		flex: none;
		overflow-x: hidden;
	}

	.menuBig {
		width: 250px;
		margin-left: 0;
	}

	.menuSmall {
		width: 62px;
		margin-left: 0;
	}

	.menuHide {
		width: 62px;
		margin-left: -62px;
	}
</style>
