import { format } from 'date-fns'

const queryMallsKit = {
	mallsDashboardConnections: ({ mallIdList, dateRange }) => {
		const url = '/api/v2/shopping_centers/dashboard/connections'
		const params = {
			shopping_center_ids: mallIdList,
			begin_date: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_date: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	mallsDashboardAnomalies: ({ mallIdList, dateRange }) => {
		const url = '/api/v2/shopping_centers/dashboard/anomalies'
		const params = {
			shopping_center_ids: mallIdList,
			begin_date: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_date: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	mallsDashboardNoData: ({ mallIdList, dateRange }) => {
		const url = '/api/v2/shopping_centers/dashboard/without_data'
		const params = {
			shopping_center_ids: mallIdList,
			begin_date: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_date: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	mallsAnalyticsChartData: ({
		mallIdList,
		dateRange,
		isPerArea,
		isIncrease,
		roundScale,
		categoryNameList,
		profileNameList,
	}) => {
		const url = '/api/v2/shopping_centers/analytics/chart_data'
		const params = {
			shopping_center_ids: mallIdList,
			begin_date: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_date: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
			is_per_area: isPerArea,
			is_increase: isIncrease,
			round_scale: roundScale,
			category_names: categoryNameList,
			profile_names: profileNameList,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	mallsCategoryList: ({ mallIdList }) => {
		const url = '/api/v2/shopping_centers/categories/list'
		const params = { shopping_center_ids: mallIdList }
		return window.$nuxt.$axios.$post(url, params)
	},

	mallsDashboardShopsTopByProfile: ({ mallIdList, dateRange, dataType, profileName }) => {
		const url = '/api/v2/shopping_centers/dashboard/profiles_top_list'
		const params = {
			shopping_center_ids: mallIdList,
			begin_date: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_date: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
			data_type: dataType,
			profile_name: profileName,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	mallsDashboardTops: ({ mallIdList, dateRange, dataType }) => {
		const url = '/api/v2/shopping_centers/dashboard/profiles_top'
		const params = {
			shopping_center_ids: mallIdList,
			begin_date: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_date: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
			data_type: dataType,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	mallsDashboardOutsiders: ({ mallIdList, dateRange, dataType }) => {
		const url = '/api/v2/shopping_centers/dashboard/profile_outsiders'
		const params = {
			shopping_center_ids: mallIdList,
			begin_date: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_date: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
			data_type: dataType,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	mallsDashboardLeaders: ({ mallIdList, dateRange, dataType }) => {
		const url = '/api/v2/shopping_centers/dashboard/profile_leaders'
		const params = {
			shopping_center_ids: mallIdList,
			begin_date: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_date: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
			data_type: dataType,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	mallsDashboardCategories: ({ mallIdList, dateRange, dataType }) => {
		const url = '/api/v2/shopping_centers/dashboard/by_category'
		const params = {
			shopping_center_ids: mallIdList,
			begin_date: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_date: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
			data_type: dataType,
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	addMallToFavorites: ({ mallId }) => {
		const url = '/api/v2/users/add_sc_to_favorites'
		const params = { favorite_sc_id: mallId }
		return window.$nuxt.$axios.$post(url, params)
	},

	removeMallFromFavorites: ({ mallId }) => {
		const url = '/api/v2/users/remove_sc_from_favorites'
		const params = { favorite_sc_id: mallId }
		return window.$nuxt.$axios.$post(url, params)
	},

	mallsDashboardVisitors: ({ mallIdList, dateRange }) => {
		const url = '/api/v2/shopping_centers/analytics/traffic_chart_data'
		const params = {
			shopping_center_ids: mallIdList,
			begin_date: format(new Date(dateRange[0]), 'yyyy-MM-dd'),
			end_date: format(new Date(dateRange[1]), 'yyyy-MM-dd'),
		}
		return window.$nuxt.$axios.$post(url, params)
	},

	mallInformation: ({ mallId }) => {
		const url = `/api/v2/shopping_centers/${mallId}`
		return window.$nuxt.$axios.$get(url)
	},
	mallCreate: ({ title, prefix, abbreviation, cityName, address, startDate }) => {
		const url = '/api/v2/shopping_centers'
		const params = {
			title,
			prefix,
			abbreviation,
			city_name: cityName,
			address,
			general_company_abbreviation: 'unknown',
			start_date: format(new Date(startDate), 'yyyy-MM-dd'),
		}
		return window.$nuxt.$axios.$post(url, params)
	},
	cities: () => {
		const url = '/api/v2/cities'
		return window.$nuxt.$axios.$get(url)
	},
}

export { queryMallsKit }
