<template>
	<div :class="['pagination', isHideComponent ? $style.componentHide : '']">
		<div class="page-item prev-page">
			<a
				:class="['page-link', pageActive <= 1 ? $style.disabled : '']"
				aria-label="Previous"
				href="#"
				@click.prevent="setPagePrev()"
			>
				<span aria-hidden="true"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
			</a>
		</div>
		<div
			v-for="pageNumber in pageShowList"
			:key="pageNumber"
			:class="['page-item', { active: pageActive === pageNumber }]"
		>
			<a class="page-link" href="#" @click.prevent="changePageActive(pageNumber)">{{ pageNumber }}</a>
		</div>
		<div class="page-item next-page">
			<a
				:class="['page-link', pageActive >= pageTotal ? $style.disabled : '']"
				aria-label="Next"
				href="#"
				@click.prevent="setPageNext()"
			>
				<span aria-hidden="true"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
			</a>
		</div>
	</div>
</template>

<script>
	const validator1 = (val) => Number.isInteger(val) && val > 0
	const validator2 = (val) => Number.isInteger(val) && val >= 0

	export default {
		props: {
			pageActive: { type: Number, default: 1, required: true, validator: validator1 },
			itemTotal: { type: Number, default: 0, required: true, validator: validator2 },
			itemPerPage: { type: Number, default: 1, required: true, validator: validator1 },
		},
		data() {
			return {
				pageShowAdditional: 2,
			}
		},
		computed: {
			isHideComponent() {
				return this.pageTotal < 2
			},
			pageTotal() {
				return Math.ceil(this.itemTotal / this.itemPerPage)
			},
			pageShowList() {
				const pageToShowCount = this.pageShowAdditional * 2 + 1
				if (this.pageTotal <= pageToShowCount) {
					return Array(this.pageTotal).fill(0).map((item, itemIndex) => itemIndex + 1)
				}

				const diffStart = this.pageActive - 1
				const diffEnd = this.pageTotal - this.pageActive

				let pageShowStart = 1
				if (diffStart < this.pageShowAdditional) {
					pageShowStart = this.pageActive - diffStart
				} else {
					pageShowStart = this.pageActive - this.pageShowAdditional
				}
				if (diffEnd < this.pageShowAdditional) {
					pageShowStart -= this.pageShowAdditional - diffEnd
				}
				return Array(pageToShowCount).fill(0).map((item, itemIndex) => itemIndex + pageShowStart)
			},
		},
		methods: {
			changePageActive(pageActiveNew) {
				this.$emit('changePageActive', pageActiveNew)
			},

			setPagePrev() {
				if (this.pageActive <= 1) {
					return
				}
				const pageActiveNew = this.pageActive - 1
				this.changePageActive(pageActiveNew)
			},
			setPageNext() {
				if (this.pageActive >= this.pageTotal) {
					return
				}
				const pageActiveNew = this.pageActive + 1
				this.changePageActive(pageActiveNew)
			},
		},
	}
</script>

<style lang="scss" module>
	.componentHide {
		opacity: 0;
		pointer-events: none;
	}

	.disabled {
		border-color: #dee2e6;
		background-color: #fff;
		color: #8898aa;
		cursor: auto;
		opacity: 0.4;
		pointer-events: none;
	}
</style>
