<template>
	<span :class="colorClass">{{ getDateFormatted(dateString) }}</span>
</template>

<script>
	import { format, differenceInDays, formatDistanceToNow, differenceInYears } from 'date-fns'

	import { getCorrectTypeValKit } from '@/assets/js/check/getCorrectTypeValKit.js'
	import { translate } from '@/assets/i18n/i18nextConfig.js'

	export default {
		props: {
			isDefaultFormat: { type: Boolean, default: false },
			dateString: { type: String, default: '' },
			colorClass: { type: String, default: '' },
		},
		methods: {
			translate,
			getDateFormatted(dateString) {
				// TODO: the date should come from the back in such a format that safari does not give errors
				let dateStr = dateString
				if (dateString.includes('-') && !dateString.includes('T')) {
					dateStr = dateStr.replaceAll('-', '/')
				}
				const correctKit = getCorrectTypeValKit({ dateTmp: [new Date(dateStr), 'dateCorrect'] })
				const date = correctKit.dateTmp

				if (this.isDefaultFormat) {
					const formatDefault = 'dd.MM.yyyy'
					return format(new Date(date), formatDefault)
				}

				if (differenceInDays(new Date(), date) < 1) {
					return formatDistanceToNow(date, { includeSeconds: true, addSuffix: true })
				}
				if (differenceInYears(new Date(), date) < 1) {
					return format(new Date(date), `d MMM ${this.translate('common.in')} HH:mm`)
				}
				return format(new Date(date), `d MMM y ${this.translate('common.in')} HH:mm`)
			},
		},
	}
</script>
