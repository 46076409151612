<template>
	<BaseDropdown
		menu-on-right
		class="clear-button"
		tag="li"
		title-tag="a"
		title-classes="pr-0"
	>
		<a slot="title-container" href="#" class="nav-link pr-0 py-0" @click.prevent>
			<div class="d-flex align-items-center">
				<span class="avatar avatar-xs bg-white">
					<i class="fa-regular fa-user-gear text-dark g-fs-16"></i>
				</span>
				<div class="ml-2">
					<span class="mb-0 text-sm font-weight-bold text-white">{{ authKit.user.name }}</span>
				</div>
			</div>
		</a>

		<template v-if="mall.id">
			<div class="dropdown-header noti-title">
				<h6 class="text-overflow m-0">{{ mall.title }}</h6>
			</div>
			<div class="dropdown-divider"></div>
		</template>
		<div
			class="px-3 pt-2 pb-3 d-flex gap-1 flex-column position-relative g-fs-14"
			@click.stop=""
		>
			<div class="g-fs-12">{{ translate('menu.yourManager') }}</div>
			<TextCopyClipboard
				:text="manager.name"
				:copy-text="manager.name"
				:notification-message="translate('common.copied')"
			></TextCopyClipboard>
			<TextCopyClipboard
				class="text-primary"
				:text="manager.email"
				:copy-text="manager.email"
				:notification-message="translate('common.copied')"
			></TextCopyClipboard>
			<TextCopyClipboard
				class="text-primary"
				:text="manager.phone"
				:copy-text="manager.phone"
				:notification-message="translate('common.copied')"
			></TextCopyClipboard>
		</div>
		<a class="dropdown-item" @click.prevent="signOut()">
			<i class="ni ni-user-run"></i>
			<span>{{ translate('menu.toExit') }}</span>
		</a>
	</BaseDropdown>
</template>

<script>
	import BaseDropdown from '@/components/argon-core/BaseDropdown.vue'
	import TextCopyClipboard from '@/components/other/TextCopyClipboard.vue'

	import { authOut } from '@/assets/js/logInLogOut.js'
	import { getAuthKit, getRouterMallKit } from '@/assets/js/routerHelper.js'
	import { translate } from '@/assets/i18n/i18nextConfig.js'

	export default {
		components: {
			BaseDropdown,
			TextCopyClipboard,
		},
		data() {
			return {
				manager: {
					name: 'Екатерина',
					email: 'ek@rentu.ru',
					phone: '+7 (904) 466-14-96',
				},
			}
		},
		computed: {
			authKit() {
				return getAuthKit()
			},
			mall() {
				return getRouterMallKit()
			},
		},
		methods: {
			translate,
			async signOut() {
				authOut({ isShowMessage: true })
			},
		},
	}
</script>
