<template>
	<LightTooltip :disabled="!showTooltip" :open-delay="100">
		<div slot="content">
			{{ `${translate('mallInformationBadge.tooltip')} ${mallAbbreviation} ${mallTitle}` }}
		</div>
		<div
			class="mall-info clear-button"
			:class="`mall-info_${color}`"
			@click.prevent="emitClick"
		><i class="fa-light fa-circle-info"></i></div>
	</LightTooltip>
</template>
<script>
	import { translate } from '@/assets/i18n/i18nextConfig.js'
	import LightTooltip from '@/components/LightTooltip.vue'

	export default {
		components: {
			LightTooltip,
		},
		props: {
			color: { type: String, default: 'default' },
			mallId: { type: String, default: '' },
			mallAbbreviation: { type: String, default: '' },
			mallTitle: { type: String, default: '' },
			showTooltip: { type: Boolean, default: false },
		},
		methods: {
			translate,
			emitClick() {
				this.$emit('onClick', this.mallId)
			},
		},
	}
</script>
<style lang="scss" scoped>
.mall-info {
	cursor: pointer;
	font-size: 24px;
	transition: color 0.3s;

	&_default {
		color: var(--gray);

		&:hover {
			color: #707578;
		}
	}

	&_white {
		color: #fff;
	}
}
</style>
