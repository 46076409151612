<template>
	<div class="wrapper" translate="no">
		<Notifications></Notifications>
		<MenuMainOnlyTop>
			<div>
				<Nuxt></Nuxt>
			</div>
		</MenuMainOnlyTop>
		<LogRocket></LogRocket>
		<SentrySetUser></SentrySetUser>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'
	import MenuMainOnlyTop from '@/components/menu/MenuMainOnlyTop.vue'
	import LogRocket from '@/components/LogRocket.vue'
	import SentrySetUser from '@/components/SentrySetUser.vue'

	import { getAuthKit, getRouterMallKit, getRouterMallId } from '@/assets/js/routerHelper.js'

	export default {
		components: { MenuMainOnlyTop, LogRocket, SentrySetUser },
		data() {
			return {
				ws: null,
				wsn: null,
				systemStatus: 'hidden',
			}
		},
		head() {
			return {
				title: 'RENTU | Доска обращений',
				meta: [{ hid: 'robots', name: 'robots', content: 'noindex, nofollow' },
					{ hid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=0.31' }],
			}
		},
		computed: {
			authKit() {
				return getAuthKit()
			},
			watchIsOffline() {
				return this.$nuxt.isOffline
			},
			watchIsOnline() {
				return this.$nuxt.isOnline
			},
			currentShoppingCenter() {
				if (getRouterMallId() && this.authKit.user) {
					return getRouterMallKit()
				}
				return {}
			},
		},
		watch: {
			watchIsOffline(newVal, oldVal) {
				if (newVal) {
					this.$notify({
						verticalAlign: 'top',
						horizontalAlign: 'left',
						message: 'Потеряно интернет соединение',
						timeout: 5000,
						icon: 'ni ni-bell-55',
						type: 'danger',
					})
				}
			},
			watchIsOnline(newVal, oldVal) {
				if (newVal) {
					this.$notify({
						verticalAlign: 'top',
						horizontalAlign: 'left',
						message: 'Интернет соединение восстановлено',
						timeout: 5000,
						icon: 'ni ni-bell-55',
						type: 'success',
					})
				}
			},
		},
		mounted() {
			// const statusStorageString = localStorage.getItem('system_status')
			const statusStorageString = null

			if (statusStorageString) {
				let statusStorage = {}
				try {
					statusStorage = JSON.parse(statusStorageString)
				} catch (e) {
					statusStorage = {}
				}

				if (new Date().getTime() > statusStorage.expiry) {
					localStorage.removeItem('system_status')
					this.systemStatus = ''
				} else {
					this.systemStatus = statusStorage.item || ''
				}
			}
		},
		methods: {
			...mapMutations({ addNotification: 'storeNotificationListKit/addNotification' }),
		},
	}
</script>
<style lang="scss" scoped>
	.systemstatus {
		position: relative;
		z-index: 1000;
		background: var(--yellow);

		&__close {
			position: absolute;
			top: 50%;
			right: 12px;
			cursor: pointer;
			font-size: 22px;
			transform: translateY(-50%);
		}
	}

	::v-deep .badge-floating {
		position: absolute;
		top: -22px;
	}
</style>
