<template>
	<div
		:class="[$style.fakeBlock]"
		:style="{'width': width, 'height': height, 'border-radius': borderRadius}"
	></div>
</template>

<script>
	export default {
		name: 'FakeBlock',

		props: {
			width: { type: String, default: '100%' },
			height: { type: String, default: '1em' },
			borderRadius: { type: String, default: '3px' },
		},
		computed: {
		},
	}
</script>

<style lang="scss" module>
.fakeBlock {
	overflow: hidden;
	animation: effect-wave 1s infinite;
	background-color: #ddd;
	background-image: linear-gradient(90deg, #eee 0%, #eee 20%, #ddd 30%, #ddd 70%, #eee 80%, #eee 100%);
	background-position: 50% 50%;
	background-size: 200% 100%;
	color: transparent;
	user-select: none;
	white-space: nowrap;
}

@keyframes effect-wave {
	0% {
		background-position: 50% 50%;
	}

	100% {
		background-position: -150% 50%;
	}
}
</style>
