<template>
	<Modal :show="isShow" :size="'xl'" :header-classes="'pb-0'" @close="emitCloseModal()">
		<template slot="header">
			<h5 class="modal-title">
				<span class="g-fw-900 g-fs-20">{{ pageTitle }}</span>
			</h5>
		</template>
		<div v-if="isLoaded" class="row">
			<div class="col docs-page-content">
				<!-- eslint-disable-next-line vue/no-v-html -->
				<div v-html="pageHtml"></div>
			</div>
		</div>
		<div v-else class="row">
			<div class="col" style="height: 200px;">
				<SvgLoader></SvgLoader>
			</div>
		</div>
	</Modal>
</template>

<script>
	import SvgLoader from '@/components/pages/SvgLoader.vue'
	import Modal from '@/components/argon-core/Modal.vue'

	import { getPromiseQuery } from '@/assets/js/query/getPromiseQuery.js'
	import { getRouterMallId } from '@/assets/js/routerHelper.js'

	export default {
		components: { SvgLoader, Modal },
		props: {
			isShow: { type: Boolean, default: false },
			pageId: { type: String, default: '' },
		},
		data() {
			return {
				isLoaded: false,
				page: null,
			}
		},
		computed: {
			pageTitle() {
				return this.page ? this.page.title : ''
			},
			pageHtml() {
				return this.page ? this.page.html_content : '...'
			},
		},
		watch: {
			isShow() {
				if (this.isShow && this.pageId) {
					this.requestDocsPage(this.pageId)
				}
			},
		},
		methods: {
			emitCloseModal() {
				this.$emit('closeModal')
			},
			requestDocsPage(pageId) {
				this.isLoaded = false
				getPromiseQuery({
					docPage: {
						mallId: getRouterMallId(),
						pageId,
					},
				}).then((response) => {
					this.page = response
					this.isLoaded = true
				})
			},
		},
	}
</script>
<style>
.page-item {
	border-radius: 2px;
	outline: 1px solid transparent;
	transition: outline 0.25s ease-in;
}
</style>
<style lang="scss" scoped>
@import "@/assets/sass/docs.scss";
</style>
