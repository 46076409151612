<template>
	<SellLocationAnomaliesModal
		:shop-name="anomalyModalData.name"
		:opened="anomalyModalData.isTableModalOpened"
		:initital-data="anomalyModalData.tableDataInitital"
		:shop-id="anomalyModalData.id"
		:show-archived-initialy="anomalyModalData.showArchived"
		@update-opened="handleAnomalyModalClose"
		@anomalyCounterChange="handleAnomalyCounterChange"
	></SellLocationAnomaliesModal>
</template>

<script>
	import SellLocationAnomaliesModal from '@/components/SellLocationAnomaliesModal.vue'

	export default {
		components: {
			SellLocationAnomaliesModal,
		},
		data() {
			return {
				anomalyModalData: {
					id: '',
					name: '',
					isTableModalOpened: false,
					tableDataInitial: [],
					showArchived: false,
				},
			}
		},
		beforeMount() {
			this.$nuxt.$on('anomalyModalOpenEvent', this.anomalyModalOpenEvent)
			this.$nuxt.$on('anomalyModalArchivedActiveEvent', this.onAnomalyModalArchivedActiveEvent)
		},
		beforeDestroy() {
			this.$nuxt.$off('anomalyModalOpenEvent', this.anomalyModalOpenEvent)
			this.$nuxt.$off('anomalyModalArchivedActiveEvent', this.onAnomalyModalArchivedActiveEvent)
		},
		methods: {
			anomalyModalOpenEvent(data) {
				this.anomalyModalData = data
			},
			onAnomalyModalArchivedActiveEvent() {
				this.anomalyModalData.showArchived = true
			},
			handleAnomalyModalClose() {
				this.anomalyModalData.isTableModalOpened = false
				this.$nuxt.$emit(`${this.anomalyModalData.id}CloseEvent`, {
					isTableModalOpened: this.anomalyModalData.isTableModalOpened,
				})
			},
			handleAnomalyCounterChange(level) {
				this.$nuxt.$emit(`${this.anomalyModalData.id}AnomalyCounterDecrement`, level)
			},
		},
	}
</script>
