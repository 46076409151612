
	import Vue from 'vue'

	import { DocsMenuItem } from '@/types/docs.ts'

	export default Vue.component('DocsMenuLink', {
		props: {
			item: { type: Object, default: () => ({}) },
			type: {
				type: String,
				default: 'category',
				validator(value: string): boolean {
					return ['page', 'category'].includes(value)
				},
			},
		},
		computed: {
			Item(): DocsMenuItem {
				return this.item as DocsMenuItem
			},
			isActive(): boolean {
				const w = window as any
				const params = w.$nuxt.$route?.params
				return params?.slug === this.Item.slug || params?.id === this.Item.id
			},
			routeName(): string {
				if (this.type === 'category') {
					return 'docs-categories-slug'
				}
				return 'docs-pages-id'
			},
		},
	})
