const getAuthKit = () => {
	const authKit = window.$nuxt?.$auth ?? {}
	return authKit
}
const getAuthUserKit = () => {
	const userKit = window.$nuxt?.$auth?.user ?? {}
	return userKit
}
const getAuthMallList = () => {
	const mallList = getAuthUserKit()?.malls ?? []
	return mallList
}

const getAuthMallKitByMallId = (mallId) => {
	const mallList = getAuthUserKit()?.malls ?? []
	const mallKit = mallList.find((mall) => mall.id === mallId)
	return mallKit ?? {}
}

const getAuthRoleListByMallId = (mallId) => {
	const mallList = getAuthMallList()
	const mallKit = mallList.find((mall) => mall.id === mallId) ?? {}
	const roleMallList = mallKit?.sc_roles ?? []

	const userKit = getAuthUserKit()
	const roleUserList = []
	if (userKit.is_admin) {
		roleUserList.push('systemAdmin')
	}
	if (userKit.is_staff) {
		roleUserList.push('systemStaff')
	}
	const roleList = [...roleUserList, ...roleMallList]
	return roleList
}


const getRouterMallId = () => {
	return window.$nuxt.$route?.params?.entity ?? ''
}

const getRouterMallKit = () => {
	const mallId = getRouterMallId()
	const mallKit = getAuthMallKitByMallId(mallId)
	return mallKit
}

const getIsRouterPageShop = () => {
	return window.$nuxt.$route?.name === 'entity-shops-id'
}
const getRouterShopId = () => {
	if (!getIsRouterPageShop()) {
		return ''
	}
	return window.$nuxt.$route?.params?.id ?? ''
}

const getIsRouterPageRenter = () => {
	return window.$nuxt.$route?.name === 'entity-arendators-id'
}
const getRouterRenterId = () => {
	if (!getIsRouterPageRenter()) {
		return ''
	}
	return window.$nuxt.$route?.params?.id ?? ''
}

const getRouterRoleList = () => {
	const mallId = getRouterMallId()
	const roleList = getAuthRoleListByMallId(mallId)
	return roleList
}


/* example for vue file
const mallId = getRouterMallId()
const mallKit = getRouterMallKit()
const isPageShop = getIsRouterPageShop()
const shopId = getRouterShopId()
*/

export {
	getAuthKit,
	getAuthUserKit,
	getAuthMallList,
	getAuthMallKitByMallId,
	getAuthRoleListByMallId,

	getRouterMallId,
	getRouterMallKit,
	getIsRouterPageShop,
	getRouterShopId,
	getIsRouterPageRenter,
	getRouterRenterId,
	getRouterRoleList,
}
