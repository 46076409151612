const kit = {
	createOFDCredentials: ({ MallId, data }) => {
		const url = `/api/v2/sc/${MallId}/ofd_credentials`
		return window.$nuxt.$axios.$post(url, data)
	},

	deleteOFDCredentials: ({ MallId, ofd }) => {
		const url = `/api/v2/sc/${MallId}/ofd_credentials/${ofd.id}`
		return window.$nuxt.$axios.$delete(url)
	},

	getOFDCredentialsList: ({ MallId }) => {
		const url = `/api/v2/sc/${MallId}/ofd_credentials`
		return window.$nuxt.$axios.$get(url)
	},
}

export default kit
