<template>
	<div>
		<PaginationInfo
			:page-active="pageMoreActive"
			:item-per-page="itemMorePerPage"
			:item-total="itemTotal"
		></PaginationInfo>
		<NewButton
			:class="isCanShowMore ? '' : $style.hideShowMore"
			:size="'sm'"
			:color="'success'"
			@onClick="showMore()"
		>{{ translate('common.showMore') }}</NewButton>
		<PaginationSwitcher
			:page-active="pageActive"
			:item-per-page="itemPerPage"
			:item-total="itemTotal"
			@changePageActive="changePageActive($event)"
			@changeParams="emitChangeParams()"
		></PaginationSwitcher>
	</div>
</template>

<script>
	import PaginationSwitcher from '@/components/pagination/PaginationSwitcher.vue'
	import PaginationInfo from '@/components/pagination/PaginationInfo.vue'
	import NewButton from '@/components/other/NewButton.vue'

	import { translate } from '@/assets/i18n/i18nextConfig.js'

	export default {
		components: { PaginationSwitcher, PaginationInfo, NewButton },
		props: {
			pageActive: { type: Number, default: 1, required: true },
			itemTotal: { type: Number, default: 0, required: true },
			itemPerPage: { type: Number, default: 1, required: true },
			pageMoreActive: { type: Number, default: 1, required: true },
			itemMorePerPage: { type: Number, default: 1, required: true },
		},
		computed: {
			isCanShowMore() {
				return this.pageActive * this.itemPerPage < this.itemTotal
			},
		},
		methods: {
			translate,
			changePageActive(pageActiveNew) {
				this.$emit('changePageActive', pageActiveNew)
			},
			showMore() {
				this.$emit('showMore')
			},
		},
	}
</script>

<style lang="scss" module>
	.hideShowMore {
		opacity: 0;
		pointer-events: none;
	}
</style>
