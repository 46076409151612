<template>
	<div class="wrapper h-100" translate="no">
		<Notifications></Notifications>
		<Nuxt></Nuxt>
	</div>
</template>

<script>
	import { getAuthKit, getRouterMallId, getRouterMallKit } from '@/assets/js/routerHelper.js'
	import { hasAccess } from '@/assets/js/access/hasAccess.js'
	import { translate } from '@/assets/i18n/i18nextConfig.js'

	export default {
		components: {
		},
		data() {
			return {
				mallId: getRouterMallId(),
				systemStatus: 'hidden',
			}
		},
		head() {
			return {
				title: `RENTU | ${this.currentMall.title}`,
				meta: [
					{ hid: 'robots', name: 'robots', content: 'noindex, nofollow' },
					{ hid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=0.31' },
				],
			}
		},
		computed: {
			authKit() {
				return getAuthKit()
			},
			currentMall() {
				if (getRouterMallId() && this.authKit.user) {
					return getRouterMallKit()
				}
				return {}
			},
			isVisitor() {
				return hasAccess({ accessRoleList: ['visitor'] })
			},
		},
		watch: {
			$route() {
				const mallId = getRouterMallId()
				this.mallId = mallId
			},
		},
		mounted() {
			if (!this.isVisitor) {
				this.$notify({ message: translate('terminal.visitorsAlert'), type: 'warning' })
			}

			this.$nextTick(() => {
				this.setContainerHeight()
			})
		},
		methods: {
			translate,
			setContainerHeight() {
				document.body.className = 'h-100'
				document.documentElement.className = 'h-100'
				const nuxtElement = document.getElementById('__nuxt')
				const layoutElement = document.getElementById('__layout')
				if (nuxtElement) {
					nuxtElement.className = 'h-100'
				}
				if (layoutElement) {
					layoutElement.className = 'h-100'
				}
			},
		},
	}
</script>
