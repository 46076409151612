import { getIsValidValueConstructor } from '@/assets/js/check/parts/getIsValidValueConstructor.js'
import { getConstructorDefault } from '@/assets/js/check/parts/getConstructorDefault.js'

const customTypeConfigKit = {
	dateCorrect: {
		constructor: Date,
		getIsValid: (val) => {
			return Number.isNaN(val.getTime()) === false
		},
		getDefault: () => {
			return new Date()
		},
	},
	integer: {
		constructor: Number,
		getIsValid: (val) => {
			return Number.isInteger(val)
		},
		getDefault: () => {
			return Number()
		},
	},
}

const getTypeValCustomInfo = (val, type) => {
	const customTypeConfig = customTypeConfigKit[type]
	const isValidValueConstructor = getIsValidValueConstructor(val, customTypeConfig.constructor)
	const isValidValue = isValidValueConstructor ? customTypeConfig.getIsValid(val) : false
	const valCorrect = isValidValueConstructor && isValidValue ? val : customTypeConfig.getDefault()

	const info = {
		val,
		type,
		valCorrect,
		isValidValueType: isValidValueConstructor,
		isValidValue,
	}
	return info
}

const getTypeValInfo = (val, type) => {
	if (customTypeConfigKit[type]) {
		return getTypeValCustomInfo(val, type)
	}

	const isValidValueConstructor = getIsValidValueConstructor(val, type)
	const valCorrect = isValidValueConstructor ? val : getConstructorDefault(type)

	const info = {
		val,
		type,
		valCorrect,
		isValidValueType: isValidValueConstructor,
		isValidValue: isValidValueConstructor,
	}

	return info
}

export { getTypeValInfo }
