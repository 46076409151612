const kit = {
	kktList: ({ shoppingCenterId, sellLocationId }) => {
		const url = `/api/v2/sc/${shoppingCenterId}/sell_locations/${sellLocationId}/kkts`
		return window.$nuxt.$axios.$get(url)
	},
	kktActiveList: ({ shoppingCenterId, date }) => {
		const url = `/entities/${shoppingCenterId}/active-kkts`
		const params = {
			params: {
				entity_id: shoppingCenterId,
				date,
				spreadsheet: false,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	kktActiveListXlsx: ({ shoppingCenterId, date }) => {
		const url = `/entities/${shoppingCenterId}/active-kkts`
		const params = {
			params: {
				entity_id: shoppingCenterId,
				date,
				spreadsheet: true,
			},
		}
		return window.$nuxt.$axios.$get(url, params)
	},
	unknownPaymentsTerminals: () => {
		const url = '/api/v2/unknown_payment_terminals'
		return window.$nuxt.$axios.$get(url)
	},
	unknownPaymentsTerminalsArchivedList: () => {
		const url = '/api/v2/unknown_payment_terminals/archived_list'
		return window.$nuxt.$axios.$get(url)
	},
	kktArchive: ({ mallId, kktId }) => {
		const url = `/kkts/${kktId}/archive`
		const queryData = { state: true, page: 'sell_location', entity_id: mallId }
		const queryParams = { params: { entity_id: mallId } }
		return window.$nuxt.$axios.$post(url, queryData, queryParams)
	},
	kktUnarchive: ({ mallId, kktId }) => {
		const url = `/kkts/${kktId}/archive`
		const queryData = { state: false, page: 'sell_location', entity_id: mallId }
		const queryParams = { params: { entity_id: mallId } }
		return window.$nuxt.$axios.$post(url, queryData, queryParams)
	},

	kktCreate: ({
		mallId,
		renterId,
		sellLocationId,
		regId,
		ofdId,
		kktName,
		serialNumber,
		source,
		fnNumber,
		isProxy,
		comment,
		isBackup,
	}) => {
		const url = `/api/v2/sc/${mallId}/kkts`
		const data = {
			reg_id: regId,
			arendator_id: renterId,
			source,
			serial_number: serialNumber,
			name: kktName,
			fn_number: fnNumber,
			sell_location_id: sellLocationId,
			ofd_id: ofdId !== '' ? ofdId : undefined,
			comment,
			is_proxy: isProxy,
			backup: isBackup,
		}
		return window.$nuxt.$axios.$post(url, data)
	},
	kktUpdate: ({
		kktId,
		mallId,
		renterId,
		sellLocationId,
		regId,
		ofdId,
		kktName,
		serialNumber,
		source,
		fnNumber,
		isProxy,
		createdAt,
		comment,
		isPageSellLocation,
		isBackup,
	}) => {
		const url = `/api/v2/sc/${mallId}/kkts/${kktId}`
		const data = {
			reg_id: regId,
			arendator_id: renterId,
			source,
			serial_number: serialNumber,
			name: kktName,
			fn_number: fnNumber,
			sell_location_id: sellLocationId,
			ofd_id: ofdId !== '' ? ofdId : undefined,
			comment,
			is_proxy: isProxy,
			backup: isBackup,
		}
		if (isPageSellLocation) {
			data.page = 'sell_location'
		}
		return window.$nuxt.$axios.$put(url, data)
	},

	UnknownPaymentsKktsReject: ({
		kktId,
	}) => {
		const url = `/api/v2/unknown_payment_terminals/${kktId}/change_status`
		const params = { status: 'was_rejected' }
		return window.$nuxt.$axios.$put(url, params)
	},
	UnknownPaymentsKktsPostpone: ({
		kktId,
	}) => {
		const url = `/api/v2/unknown_payment_terminals/${kktId}/change_status`
		const params = { status: 'postponed' }
		return window.$nuxt.$axios.$put(url, params)
	},
	UnknownPaymentsKktsChangeComment: ({
		kktId,
		comment,
	}) => {
		const url = `/api/v2/unknown_payment_terminals/${kktId}/change_comment`
		const params = { comment }
		return window.$nuxt.$axios.$put(url, params)
	},
	UnknownKktCommentList: ({
		kktId,
	}) => {
		const url = `/api/v2/comments/by_resource?resource_id=${kktId}`
		return window.$nuxt.$axios.$get(url)
	},
	UnknownKktAddComment: ({
		kktId,
		comment,
	}) => {
		const url = `/api/v2/comments?resource_id=${kktId}&text=${comment}&resource_type=unknown_payment_terminal`
		return window.$nuxt.$axios.$post(url)
	},
	kktDelete: ({ mallId, kktId }) => {
		const url = `/api/v2/sc/${mallId}/kkts/${kktId}`
		return window.$nuxt.$axios.$delete(url)
	},

	kktProviderList: () => {
		const url = '/kkt-providers'
		return window.$nuxt.$axios.$get(url)
	},
	unknownPaymentsTerminal: ({ kktId }) => {
		const url = `/api/v2/unknown_payment_terminals/${kktId}`
		return window.$nuxt.$axios.$get(url)
	},
}

export default kit
