<template>
	<div>
		<ElTable
			:data="tableDataFake"
			row-key="id"
			header-row-class-name="thead-light"
		>
			<TableSkeletonColumns :column-data="columnData"></TableSkeletonColumns>
		</ElTable>
	</div>
</template>

<script>
	import { Table } from 'element-ui'
	import TableSkeletonColumns from '@/components/tables/TableSkeletonColumns.vue'

	export default {
		components: {
			ElTable: Table,
			TableSkeletonColumns,
		},
		props: {
			columnData: { type: Array, default: () => ([]) },
		},
		data() {
			return {
				tableDataFake: [1, 2, 3],
			}
		},
	}
</script>
<style lang="scss" scoped>
	::v-deep .el-table .el-table__cell {
		padding-right: 12px;
		padding-left: 12px;
	}
</style>
