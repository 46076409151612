import Vue from 'vue'
import { getAuthKit } from '@/assets/js/routerHelper.js'
import { translate } from '@/assets/i18n/i18nextConfig.js'
import { clearCache } from '@/assets/js/clearCache.js'

const vue = new Vue()

const authIn = ({ login, password, otp }) => {
	const params = {
		data: {
			user: { login, password },
			otp,
		},
	}
	return new Promise((resolve, reject) => {
		window.$nuxt.$auth.loginWith('local', params)
			.then(() => {
				const authKit = getAuthKit()
				if (!authKit.loggedIn) {
					reject()
					return
				}
				resolve(authKit)
			})
	})
}

const authOut = ({ isShowMessage }) => {
	window.$nuxt.$auth.logout()
		.then(() => {
			clearCache()
			if (isShowMessage) {
				vue.$notify({
					message: translate('login.successLogOut'),
					type: 'success',
				})
			}
		})
}

export { authIn, authOut }
