<template>
	<div class="wrapper h-100 d-flex flex-column overflow-hidden" translate="no">
		<MenuPanelTop></MenuPanelTop>
		<Notifications></Notifications>
		<div class="docs-container flex-1 d-flex flex-row w-100 h-100 overflow-hidden">
			<DocsMenu
				:ready="objIsReady.isReady"
				:categories="Categories"
			></DocsMenu>
			<div class="docs-page p-3">
				<div class="docs-page-wrapper py-5">
					<Nuxt></Nuxt>
				</div>
			</div>
		</div>
		<DocWrapperModal></DocWrapperModal>
		<LogRocket></LogRocket>
		<SentrySetUser></SentrySetUser>
	</div>
</template>
<script>
	import { mapMutations } from 'vuex'

	import { getAuthKit } from '@/assets/js/routerHelper.js'
	import { depot, getObjIsReady, getPromiseDepotRequest } from '@/assets/js/depotQueryObjects.js'
	import { translate } from '@/assets/i18n/i18nextConfig.js'
	import { KeysConverter } from 'assets/ts/data/KeysConverter.ts'

	import MenuPanelTop from '@/components/menu/MenuPanelTop.vue'
	import DocsMenu from '@/components/pages/docs/DocsMenu/DocsMenu.vue'
	import DocWrapperModal from '@/components/doc/DocWrapperModal.vue'
	import LogRocket from '@/components/LogRocket.vue'
	import SentrySetUser from '@/components/SentrySetUser.vue'

	export default {
		components: {
			MenuPanelTop,
			DocsMenu,
			DocWrapperModal,
			LogRocket,
			SentrySetUser,
		},
		data() {
			return {
				depot,
				objIsReady: getObjIsReady(['docCategories']),
				ws: null,
				wsn: null,
				systemStatus: 'hidden',
				defPrevData: {
					text: translate('docs.backToSystem'),
					iconCssClass: 'fa-solid fa-arrow-circle-left',
					itemCssClass: 'border-bottom mb-1',
				},
			}
		},
		head() {
			return {
				title: `${translate('docs.title')} RENTU`,
				meta: [
					{ hid: 'robots', name: 'robots', content: 'noindex, nofollow' },
					{ hid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=0.31' },
				],
			}
		},
		computed: {
			authKit() {
				return getAuthKit()
			},
			isOffline() {
				return this.$nuxt.isOffline
			},
			isOnline() {
				return this.$nuxt.isOnline
			},
			Categories() {
				if (!this.objIsReady.isReady) {
					return []
				}
				return KeysConverter.toCamelCase(depot.docCategories)
			},
		},
		watch: {
			isOffline(newVal) {
				if (!newVal) {
					return
				}

				this.$notify({
					verticalAlign: 'top',
					horizontalAlign: 'left',
					message: translate('common.connectionLost'),
					timeout: 5000,
					icon: 'ni ni-bell-55',
					type: 'danger',
				})
			},
			isOnline(newVal) {
				if (!newVal) {
					return
				}

				this.$notify({
					verticalAlign: 'top',
					horizontalAlign: 'left',
					message: translate('common.connectionRestored'),
					timeout: 5000,
					icon: 'ni ni-bell-55',
					type: 'success',
				})
			},
		},
		mounted() {
			this.setContainerHeight()

			if (this.authKit.loggedIn) {
				this.requestDocCategories()
			}

			// const statusStorageString = localStorage.getItem('system_status')
			const statusStorageString = null

			if (!process.client || !statusStorageString) {
				return
			}

			let statusStorage = {}
			try {
				statusStorage = JSON.parse(statusStorageString)
			} catch (e) {
				statusStorage = {}
			}

			if (Date.now() > statusStorage.expiry) {
				// localStorage.removeItem('system_status')
				this.systemStatus = ''
				return
			}

			this.systemStatus = statusStorage.item || ''
		},
		beforeDestroy() {
		},
		methods: {
			translate,
			...mapMutations({ addNotification: 'storeNotificationListKit/addNotification' }),
			requestDocCategories() {
				getPromiseDepotRequest(['docCategories'])
			},
			setContainerHeight() {
				document.body.className = 'h-100'
				document.documentElement.className = 'h-100'
				const nuxtElement = document.getElementById('__nuxt')
				const layoutElement = document.getElementById('__layout')
				if (nuxtElement) {
					nuxtElement.className = 'h-100'
				}
				if (layoutElement) {
					layoutElement.className = 'h-100'
				}
			},
		},
	}
</script>

<style lang="scss">
	.docs-page {
		display: flex;
		flex: 8;
		flex-direction: row;
		align-items: flex-start;
		justify-content: center;
		background-color: #fff;
		overflow-y: auto;
	}

	.docs-page-wrapper {
		max-width: 1280px;
		flex: 1;
	}
</style>
